import {getPathPriceCollection} from '@algo/firebase-common';
import {collection, CollectionReference, Firestore} from 'firebase/firestore';

/**
 * collection for price information
 * @returns
 */
export function getReferencePriceCollection<T>(firestoreApp: Firestore) {
  return collection(firestoreApp, getPathPriceCollection()) as CollectionReference<T>;
}

import {sleepMs} from '@coolcolduk/util';
import {IgUserToken} from '../create/createIgUserToken';
import {IgDirectionEnum} from '../enum/IgDirectionEnum';
import {IgTimeInForceEnum} from '../enum/IgTimeInForceEnum';
import {confirmIgPosition} from '../position/confirmIgPosition';
import {igHelperClosePosition} from './igHelperClosePosition';

/**
 * Close ig position given deal id, and resolve deal reference

 */
export async function igHelperClosePositionDetail(
  userToken: IgUserToken,
  dealId: string,
  size: number,
  direction = IgDirectionEnum.BUY,
  timeInForce = IgTimeInForceEnum.FILL_OR_KILL,
) {
  console.debug('igHelperClosePositionDetail');

  const closeResult = await igHelperClosePosition(userToken, dealId, size, direction, timeInForce);

  if (!closeResult.data) {
    return undefined;
  }

  await sleepMs(300);
  return confirmIgPosition(userToken, closeResult.data.dealReference);
}

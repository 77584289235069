import {IgPriceResolutionEnum} from '@algo/broker-ig';
import {Replay} from '@mui/icons-material';
import {Button, ButtonGroup, Grid, IconButton} from '@mui/material';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Dayjs} from 'dayjs';
import 'dayjs/locale/en-gb';
import DropdownListOrganism from '../../components/dropdownList/DropdownListOrganism';

export interface AnalysisCandleSelectionProps {
  epics: string[];
  epic: string;
  setEpic: (s: string) => void;

  resolutions: IgPriceResolutionEnum[];
  resolution: IgPriceResolutionEnum;
  setResolution: (s: IgPriceResolutionEnum) => void;

  startDate: Dayjs;
  setStartDate: (djs: Dayjs) => void;

  endDate: Dayjs;
  setEndDate: (djs: Dayjs) => void;

  endDays: number;
  setEndDays: (d: number) => void;

  handleLoad: () => void;
}

export default function AnalysisCandleSelection(props: AnalysisCandleSelectionProps) {
  const {
    epic,
    setEpic,
    epics,
    resolution,
    setResolution,
    resolutions,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    endDays,
    setEndDays,
    handleLoad,
  } = props;

  return (
    <Grid container direction="row" wrap="wrap" justifyContent="space-evenly">
      <Grid item>
        <DropdownListOrganism
          selectedItem={epic}
          label="Epic"
          items={epics}
          onClickHandle={setEpic}
          sx={{minWidth: 200, minHeight: 50}}
        />
      </Grid>
      <Grid item>
        <DropdownListOrganism
          selectedItem={resolution}
          label="Epic"
          items={resolutions}
          onClickHandle={setResolution}
          sx={{minWidth: 200, minHeight: 50}}
        />
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DateTimePicker
            label="Start date"
            value={startDate}
            onChange={(newValue) => {
              if (!newValue) return;
              setStartDate(newValue);
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item>
        <ButtonGroup>
          {[0, 1, 2, 3, 5, 7].map((d) => (
            <Button
              key={`AnalysisCandleSelection-Btn-${d}`}
              variant="contained"
              onClick={() => setEndDays(d)}
              color={endDays === d ? 'warning' : 'primary'}
            >
              {d}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DateTimePicker
            label="End date"
            value={endDate}
            onChange={(newValue) => {
              if (!newValue) return;
              setEndDate(newValue);
            }}
            ampm={false}
            disabled={endDays !== 0}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item>
        <IconButton aria-label="Left" size="small" onClick={handleLoad}>
          <Replay fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

import {IgPriceDetail} from '@algo/broker-ig';

/**
 * extract mid price from ask and bid
 * @param price
 * @returns
 */
export function calculateIgPriceDetailMid(price?: IgPriceDetail) {
  return price ? (price.ask + price.bid) / 2 : 0;
}

import {literal, number, object, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {SmaConfigV3, smaV3, zSmaConfigV3} from '../../../movingAverage';
import {CciOutputV3, zCciOutputV3} from './CciOutputV3';

export interface CciConfigV3 extends IndicatorConfigV3<CciOutputV3, IndicatorCandle> {
  type: 'cciConfigV3';
  period: number;
  smaConfig: SmaConfigV3;
}

export const zCciConfigV3 = zIndicatorConfigV3(zCciOutputV3, zIndicatorCandle).merge(
  object({
    type: literal('cciConfigV3').default('cciConfigV3'),
    period: number().min(3).default(20),
    smaConfig: zSmaConfigV3.default(smaV3.createConfig({})),
  }),
) satisfies ZodType<CciConfigV3, any, any>;

// export type CciConfigV3 = output<typeof zCciConfigV3>;

export const initCciConfigV3 = zCciConfigV3.parse({});

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {BollingerBandOutput, zBollingerBandOutput} from './BollingerBandOutput';

/**
 * bollinger config
 */
export interface BollingerBandConfigV3 extends IndicatorConfigV3<BollingerBandOutput, number> {
  type: 'bollConfigV3';
  /** period to check, min 3 */
  period: number;
  numberOfSd: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zBollingerBandConfigV3 = zIndicatorConfigV3(zBollingerBandOutput, number()).merge(
  object({
    type: literal('bollConfigV3').default('bollConfigV3'),
    period: number().min(3).default(25),
    numberOfSd: number().min(0.1).default(2),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<BollingerBandConfigV3, any, any>;

export const initBollingerBandConfigV3 = zBollingerBandConfigV3.parse({});

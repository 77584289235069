import {
  AtrClassV2,
  bollingerBandCmd,
  calculateIndicatorV3Candles,
  CciClassV2,
  emaCmd,
  IndicatorCandle,
  initAtrConfigV2,
  initCciConfigV2,
  initNeighbourBarRangingConfig,
  initRsiConfigV2,
  macdCmd,
  NeighbourBarRangingClass,
  pctMaCmd,
  previousHighLowV3,
  psarCmd,
  RsiClassV2,
  sdCmd,
  smaCmd,
  stochasticCmd,
  superTrendV3,
} from '@algo/indicators';
import {UtilObject} from '@algo/util';

/**
 * Calculate a range of indicators with candle sticks
 * @param candles
 * @param calculations indicator commands
 * @returns
 */
export default function calculateCandleIndicators(
  candles: IndicatorCandle[],
  calculations: string[],
): Record<string, number[]> {
  return calculations.reduce(
    (prev, cur) => {
      if (cur in prev) return prev;
      const ema = emaCmd(cur, candles);
      const sma = smaCmd(cur, candles);
      const sd = sdCmd(cur, candles);
      const neighbour = new NeighbourBarRangingClass(initNeighbourBarRangingConfig).cmd(cur, candles);
      const rsi = new RsiClassV2(initRsiConfigV2).cmd(cur, candles);
      const cci = new CciClassV2(initCciConfigV2).cmd(cur, candles);
      const atr = new AtrClassV2(initAtrConfigV2).cmd(cur, candles);
      const psar = psarCmd(cur, candles)?.map((v) => (v === undefined ? 0 : v));
      const single = ema || sma || psar || sd || neighbour || rsi || cci || atr;
      const combineSingle = single ? {[cur]: single} : {};

      // stochastic
      const sto = stochasticCmd(cur, candles);
      const combineSto = UtilObject.fromArrayObject(sto, `${cur}_`) || {};

      // macd
      const macdOutput = macdCmd(cur, candles);
      const combineMacd = UtilObject.fromArrayObject(macdOutput, `${cur}_`) || {};

      // bollinger
      const bollOutput = bollingerBandCmd(cur, candles);
      const combineBoll = UtilObject.fromArrayObject(bollOutput, `${cur}_`) || {};

      // pct ma output
      const pctMaOutput = pctMaCmd(cur, candles);
      const combinePctMa = UtilObject.fromArrayObject(pctMaOutput, `${cur}_`) || {};

      // super trend output
      const superTrendConfig = superTrendV3.createConfigFromCmd(cur);
      const superTrendOutput =
        superTrendConfig &&
        calculateIndicatorV3Candles(superTrendV3, superTrendConfig, candles).result.filter((v) => v);
      const combineSuperTrend = superTrendOutput ? UtilObject.fromArrayObject(superTrendOutput, `${cur}_`) : {};

      // super trend output
      const previousHighLowConfig = previousHighLowV3.createConfigFromCmd(cur);
      const previousHighLowOutput =
        previousHighLowConfig &&
        calculateIndicatorV3Candles(previousHighLowV3, previousHighLowConfig, candles).result.filter((v) => v);
      const combinePreviousHighLow = previousHighLowOutput
        ? UtilObject.fromArrayObject(previousHighLowOutput, `${cur}_`)
        : {};

      // bollinger
      // const sto = stochasticCmd(cur, candles);
      // const stoK = sto ? sto.map((s) => s.k) : undefined;
      // const stoD = sto ? sto.map((s) => s.d) : undefined;
      // const combineSto = stoD && stoK ? {[`${cur}_k`]: stoK, [`${cur}_d`]: stoD} : undefined;

      return {
        ...prev,
        ...combineSingle,
        ...combineSto,
        ...combineMacd,
        ...combineBoll,
        ...combinePctMa,
        ...combineSuperTrend,
        ...combinePreviousHighLow,
      };
    },
    {} as Record<string, number[]>,
  );
}

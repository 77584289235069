import {getPathSessionCollection, ISessions} from '@algo/firebase-common';
import {collection, CollectionReference, doc, Firestore} from 'firebase/firestore';

/**
 * doc for session information
 * - user can only access their own session
 * @returns
 */
export function getReferenceSessionDocument(firestoreApp: Firestore, uid: string) {
  const colRef = collection(firestoreApp, getPathSessionCollection()) as CollectionReference<ISessions>;
  return doc(colRef, uid);
}

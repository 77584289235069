import {IgUserToken} from '@algo/broker-ig';
import {ISessionIg} from '@algo/firebase-common';

export function mapUserTokenToSession(userToken: IgUserToken): ISessionIg {
  return {
    apiKey: userToken.apiKey,
    accountToken: userToken.accountToken || '',
    clientToken: userToken.clientToken || '',
    currentAccountId: userToken.accountId || '',
    expiry: userToken.expiry || '',
    lightstreamerEndpoint: userToken.lightstreamerEndpoint || '',
  };
}

import {StochasticConfig, zStochasticConfig} from './interface/StochasticConfig';

/**
 * Initialise config
 * @param fastPeriod period used for K line, default to 14
 * @param slowPeriod period used for D line, default to 3
 * @returns
 */
export function stochasticInit(fastPeriod = 14, slowPeriod = 3): StochasticConfig {
  return zStochasticConfig.parse({fastPeriod, slowPeriod, data: [], kValues: []});
}

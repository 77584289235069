import {IgPriceCandle} from '@algo/broker-ig';
import {IPositionV2} from '@algo/model';
import {iPositionV2HasStop} from '../../status/IPositionV2/iPositionV2HasStop';
import {isIPositionV2Long} from '../../status/IPositionV2/isIPositionV2Long';

/**
 * Calculate if position triggered stop price
 * @returns stop price if candle reaches stop, undefined otherwise
 */
export function calculateIPositionV2StopTrigger(position: IPositionV2, candle: IgPriceCandle): number | undefined {
  if (!iPositionV2HasStop(position)) return undefined;
  if (isIPositionV2Long(position)) {
    return candle.lowPrice.bid < position.stop ? position.stop : undefined;
  }
  return candle.highPrice.ask > position.stop ? position.stop : undefined;
}

export interface IgUserTokenKey {
  /** api key for request - required */
  apiKey: string;
  /** define live or demo account - required */
  isLive: boolean;
}

/**
 * create user token key from provided parameters for login session only
 * @param apiKey
 * @param isLive
 * @returns
 */
export function createIgUserTokenKey(apiKey: string, isLive: boolean): IgUserTokenKey {
  console.debug('createIgUserTokenKey');

  return {
    apiKey,
    isLive,
  };
}

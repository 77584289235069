import {SmaConfig, zSmaConfig} from './interface/SmaConfig';

/**
 * Initialise config
 * @param period
 * @returns
 */
export function smaInit(period = 25): SmaConfig {
  return zSmaConfig.parse({period, price: []});
}

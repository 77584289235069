import {getPathUserHouseCollection, IUserHouse} from '@algo/firebase-common';
import {collection, CollectionReference, Firestore} from 'firebase/firestore';

/**
 * collection for house information
 * @param uid
 * @returns
 */
export function getReferenceUserHouseCollection(firestoreApp: Firestore, uid: string) {
  return collection(firestoreApp, getPathUserHouseCollection(uid)) as CollectionReference<IUserHouse>;
}

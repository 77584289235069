import {number, ZodType} from 'zod';

/**
 * Range from 0-100, where 0 is also used when calculation hasn't meet period size
 */
export type RsiOutputV3 = number;

export const zRsiOutputV3 = number().min(0).max(100) satisfies ZodType<RsiOutputV3, any, any>;

export const initRsiOutputV3 = 0;

import {IgMarketData} from '@algo/broker-ig';
import {IMarket} from '@algo/model';
import {createMarket} from '../../create/IMarket/createMarket';
/**
 * convert igMarketData to Market
 * @param marketData
 * @returns
 */
export function mapMarketFromIgMarketData(marketData: IgMarketData): IMarket {
  const close = (marketData.bid + marketData.offer) / 2;
  const open = close - marketData.netChange;
  return createMarket({...marketData, close, open});
}

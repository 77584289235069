/**
 *
 * @param value
 * @param min
 * @param max
 * @param inclusive include the max and min value when true (default: false)
 * @returns
 */
export function isBetween(value: number, min: number, max: number, inclusive = false) {
  if (inclusive) return value >= min && value <= max;
  return value > min && value < max;
}

import {IUserBroker} from '@algo/firebase-common';
import {Button, ButtonGroup} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {MatchAlgoOptionsEnum} from '../enum/MatchAlgoOptionsEnum';
import {MatchAlgoStatusEnum} from '../enum/MatchAlgoStatusEnum';
import removeMatchAlgoFsOpenPosition from '../helper/removeMatchAlgoFsOpenPosition';
import updateMatchAlgoCopierFsRecord from '../helper/updateMatchAlgoCopierFsRecord';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';
import removeMatchAlgoCopierFsRecord from '../helper/removeMatchAlgoCopierFsRecord';

/**
 * Not used as this collide with ProfitLoss
 */
export const detailButtonColumn = (
  uid: string,
  account: undefined | IUserBroker,
  selection: MatchAlgoOptionsEnum,
): GridColDef => ({
  field: 'detail',
  headerName: 'Functions',
  width: 350,
  align: 'center',
  headerAlign: 'center',
  editable: false,
  renderCell: (params: GridRenderCellParams<IMatchAlgoPosition, string, any>) => {
    // const val = params.value || '';
    const {status} = params.row;

    if (selection === MatchAlgoOptionsEnum.ALGO_PAPER && status === MatchAlgoStatusEnum.NOT_RECORDED) {
      // no action in ORPHAN
      return (
        <ButtonGroup>
          <Button size="small" onClick={removeMatchAlgoFsOpenPosition(selection, params.row)} variant="contained">
            Remove record
          </Button>
        </ButtonGroup>
      );
    }

    if (
      (selection === MatchAlgoOptionsEnum.ALGO_DEV || selection === MatchAlgoOptionsEnum.ALGO_PROD) &&
      status === MatchAlgoStatusEnum.NOT_RECORDED
    ) {
      return (
        <ButtonGroup>
          <Button
            size="small"
            onClick={account ? updateMatchAlgoCopierFsRecord(uid, account, selection, params.row) : undefined}
            variant="contained"
          >
            Update fs record
          </Button>
        </ButtonGroup>
      );
    }

    if (
      (selection === MatchAlgoOptionsEnum.ALGO_PAPER_DEV || selection === MatchAlgoOptionsEnum.ALGO_PAPER_PROD) &&
      status === MatchAlgoStatusEnum.NOT_RECORDED
    ) {
      return (
        <ButtonGroup>
          <Button size="small" onClick={removeMatchAlgoCopierFsRecord(selection, params.row)} variant="contained">
            Remove record
          </Button>
        </ButtonGroup>
      );
    }

    return null;
  },
});

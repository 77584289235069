import {IgPositionConfirmV1Response} from '@algo/broker-ig';
import {IPositionV2} from '@algo/model';
import {DataGrid, GridPaginationModel} from '@mui/x-data-grid';
import {useState} from 'react';
import {matchPositionsColumns} from './datatable/matchPositionsColumns';
import mapPositionToData from './map/mapPositionToData';

export interface MatchTableProps {
  duplicate: Record<string, IgPositionConfirmV1Response>;
  positionLeft: IPositionV2[];
  positionRight: IPositionV2[];
}

/**
 *
 * @param props
 * @returns
 */
export default function MatchTable(props: MatchTableProps) {
  const {duplicate, positionLeft, positionRight} = props;
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize: 10});

  const combinedData = mapPositionToData(duplicate, positionLeft, positionRight);
  return (
    <DataGrid
      sx={{minHeight: 600}}
      rows={combinedData}
      columns={matchPositionsColumns}
      paginationModel={paginationModel}
      onPaginationModelChange={(model) => setPaginationModel(model)}
      pageSizeOptions={[10, 25, 50, 100]}
      disableRowSelectionOnClick
      getRowId={(row) => row.dealIdLeft || row.dealIdRight}
    />
  );
}

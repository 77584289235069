/**
 * filter all undefined keys from object and return new object
 * @param obj object to check
 * @returns new object with no undefined value
 */
export function filterUndefined<T>(obj: Record<string, T | undefined>) {
  return Object.keys(obj).reduce(
    (acc, key) => (obj[key] === undefined ? acc : {...acc, [key]: obj[key] as T}),
    {} as Record<string, T>,
  );
}

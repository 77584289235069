import {getPathStrategyCollection} from './getPathStrategyCollection';

/**
 * collection path of monitor for strategy
 * @param strategyName
 * @returns
 */
export function getPathStrategyMonitorCollection(strategyName: string) {
  console.debug('getPathStrategyMonitorCollection');
  return `${getPathStrategyCollection()}/${strategyName}/monitor`;
}

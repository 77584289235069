import {IgPriceCandle} from '@algo/broker-ig';
import {mapIgPriceCandleToClose} from '../../map/number/mapIgPriceCandleToClose';
import {mapIgPriceCandleToOpen} from '../../map/number/mapIgPriceCandleToOpen';

/**
 *
 * @param prices Price candle in ascending order (latest data last)
 * @param thresholdPct
 * @returns
 */
export function igPriceCandleHasGap(prices: IgPriceCandle[], thresholdPct = 0.001) {
  if (prices.length < 2) return false;
  return prices.some((price, i) => {
    if (prices.length <= i + 1) return false;

    const nextPrice = prices[i + 1] as IgPriceCandle;
    const close = mapIgPriceCandleToClose(price);
    const open = mapIgPriceCandleToOpen(nextPrice);

    return Math.abs((close - open) / open) > thresholdPct;
  });
}

import {defaultIAsset, IAsset} from '@algo/model';

export function createAsset(asset: Partial<IAsset>) {
  return {
    id: asset.id || defaultIAsset.id,
    name: asset.name || defaultIAsset.name,
    ask: asset.ask,
    bid: asset.bid,
    lotSize: asset.lotSize,
    high: asset.high,
    low: asset.low,
    changeNet: asset.changeNet,
    changePct: asset.changePct,
    lastUpdateUtc: asset.lastUpdateUtc,
  };
}

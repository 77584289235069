import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import convertStrToFloat from '../../common/util/convertStrToFloat';
import convertStrToInt from '../../common/util/convertStrToInt';
import {bollingerBandCandle} from './bollingerBandCandle';
import {BollingerBandOutput} from './interface/BollingerBandOutput';

/**
 * Function to calculate a range of sd values given candles and cmd
 * @param cmd sd_period_hloc e.g. sd_25_2
 * @param candles
 * @returns
 */
export function bollingerBandCmd(cmd: string, candles: IndicatorCandle[]): BollingerBandOutput[] | undefined {
  const cmdArr = cmd.split('_');
  if (cmdArr[0] !== IndicatorTypeEnum.BOLLINGER_BAND) return undefined;
  return bollingerBandCandle(
    candles,
    convertStrToInt(cmdArr[1]),
    convertStrToFloat(cmdArr[2]),
    convertStrToInt(cmdArr[3]),
  );
}

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {psar} from './psar';

/**
 * Function to calculate a range of PSAR given candles
 */
export function psarCandle(candles: IndicatorCandle[], step?: number, max?: number, start?: number) {
  return psar(
    candles.map((candle) => candle.high),
    candles.map((candle) => candle.low),
    step,
    max,
    start,
  );
}

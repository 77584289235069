import {IgPriceCandle} from '@algo/broker-ig';
import {calculateIPositionV2Profit, isIPositionV2ActiveAtTimestamp, isIPositionV2Closed} from '@algo/helper';
import {IPositionV2} from '@algo/model';
import createLineGraphAtomProps from '../graph/line/LineGraphAtomProps';

/**
 * Convert position profit to bar graph. Magnitude is profit
 * @param name
 * @param color
 * @param candles
 * @param positions
 * @returns
 */
export default function mapPositionProfitToBarGraph(
  name: string,
  color: string,
  candles: IgPriceCandle[],
  positions: IPositionV2[],
) {
  const data = candles.map((c) => {
    const activePosition = positions.filter(
      (p) => isIPositionV2ActiveAtTimestamp(p, c.snapshotTimeUTC) && isIPositionV2Closed(p),
    );
    return {
      x: new Date(c.snapshotTime),
      y: activePosition.reduce((prev, cur) => prev + calculateIPositionV2Profit(cur), 0),
    };
  });
  return createLineGraphAtomProps(name, 'positions', data, color);
}

import {ZodType, array, number, object, string} from 'zod';

export interface IUserDataFirebaseIdentities {
  email: string[];
  'google.com': string[];
  sign_in_provider: string;
}

export interface IUserDataFirebase {
  identities: IUserDataFirebaseIdentities;
}

export interface IUserData {
  role: string[];
  uid: string;
  picture: string;
  name: string;
  email: string;
  exp: number;
  auth_time: number;
}

export const zIUserData = object({
  uid: string(),
  picture: string(),
  name: string(),
  email: string(),
  exp: number(),
  auth_time: number(),
  role: array(string()).default(['user']),
}) satisfies ZodType<IUserData, any, any>;

function priceRange(price: number, min: number, max?: number) {
  if (price < min) return 0;
  return max && price > max ? max - min : price - min;
}

function combinePriceRate(prices: number[], rates: number[]) {
  return prices.reduce((prev, cur, i) => prev + cur * (rates[i]! / 100), 0);
}

export default function calculateStampDuty(
  price: number,
  isFirstHome: boolean,
  isAdditionalProperty: boolean,
  isOversea: boolean,
) {
  const assessIsFirstHome = isFirstHome && price <= 500000 && !isOversea && !isAdditionalProperty;

  if (assessIsFirstHome) {
    // less than 500k, not overseas
    const firstPrice = [priceRange(price, 0, 300000), priceRange(price, 300000, 500000)];
    const firstRate = [0, 5];
    return combinePriceRate(firstPrice, firstRate);
  }

  const prices = [
    priceRange(price, 0, 125000),
    priceRange(price, 125000, 250000),
    priceRange(price, 250000, 925000),
    priceRange(price, 925000, 1500000),
    priceRange(price, 1500000),
  ];

  const additional = (isAdditionalProperty ? 3 : 0) + (isOversea ? 2 : 0);
  const rates = [0 + additional, 2 + additional, 5 + additional, 10 + additional, 12 + additional];
  return combinePriceRate(prices, rates);
}

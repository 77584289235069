import {IgMarketData} from '@algo/broker-ig';
import {Grid} from '@mui/material';
import MarketMarketList from './MarketMarketList';

export interface MarketMarketProps {
  markets: IgMarketData[];
}

export default function MarketMarket(props: MarketMarketProps) {
  const {markets} = props;
  return (
    <Grid container direction="column" wrap="wrap" spacing="3">
      {markets.map((m) => (
        <Grid item key={`MarketMarket-${m.epic}`}>
          <MarketMarketList market={m} />
        </Grid>
      ))}
    </Grid>
  );
}

import {IgPriceCandle} from '@algo/broker-ig';
import {calculateIgPriceDetailMid} from '../../calculate/IgPriceDetail/calculateIgPriceDetailMid';

/**
 * Identify if price candle is up or down
 * @param price
 * @returns
 */
export function isIgPriceCandleUp(price: IgPriceCandle) {
  return calculateIgPriceDetailMid(price.closePrice) > calculateIgPriceDetailMid(price.openPrice);
}

import {Firestore} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {createFirestoreUserPositionCollectionHelper} from '../../firestore/user/createFirestoreUserPositionCollectionHelper';

/**
 * load all position data from firestore/users/positions
 *
 * mainly used for loading meta data only
 *
 * @returns position record from firestore/user/positions
 */
export function useSubscribeUserPosition<T extends Record<string, any>>(firestoreApp: Firestore, uid?: string) {
  console.debug('useSubscribeUserPosition');
  const [data, setData] = useState<Record<string, T> | undefined>(undefined);

  useEffect(() => {
    console.debug('useSubscribeUserPosition.useEffect');
    // erase data when user logs out
    if (!uid) {
      setData(undefined);
      return undefined;
    }

    // subscribe to meta data collection
    const unsubscribe = createFirestoreUserPositionCollectionHelper<T>(firestoreApp, uid).subscribeAll(setData);
    return () => unsubscribe();
    // eslint-disable-next-line
  }, [uid]);

  return data || {};
}

import {getMarketNavigation, IgMarketNavigationGetV1Response, IgResponse} from '@algo/broker-ig';
import {IUserBroker} from '@algo/firebase-common';
import {Box, Grid} from '@mui/material';
import {useEffect, useState} from 'react';
import igSessionQuery from '../../logic/helper/ig/igSessionQuery';
import MarketList from './MarketList';
import MarketMarket from './MarketMarket';

export interface MarketPageProps {
  uid: string;
  accounts: IUserBroker[];
}

export default function MarketPage(props: MarketPageProps) {
  const {uid, accounts} = props;
  const [market, setMarket] = useState<IgResponse<IgMarketNavigationGetV1Response> | undefined>();
  const [nodeId, setNodeId] = useState<string | undefined>();
  const account = accounts.filter((a) => a.name === 'default')[0];
  const nodes = market?.data?.nodes || [];
  const markets = market?.data?.markets || [];

  useEffect(() => {
    async function effect() {
      setMarket(
        account
          ? (
              await igSessionQuery(uid, account, async (userToken) => {
                const data = await getMarketNavigation(userToken, nodeId);
                return {data, token: data.token};
              })
            ).data
          : undefined,
      );
    }
    effect();
  }, [account, nodeId, uid]);

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="row" wrap="wrap" justifyContent="space-evenly">
        <Grid item sx={{minWidth: '40%'}}>
          <MarketList nodes={nodes} setNodeId={setNodeId} />
        </Grid>
        <Grid item sx={{minWidth: '40%'}}>
          <MarketMarket markets={markets} />
        </Grid>
      </Grid>
    </Box>
  );
}

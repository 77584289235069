import {nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface MacdInitV3 extends IndicatorInitV3 {
  slowPeriod: number;
  fastPeriod: number;
  signalPeriod: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zMacdInitV3 = zIndicatorInitV3.merge(
  object({
    slowPeriod: number().min(3).default(26),
    fastPeriod: number().min(3).default(12),
    signalPeriod: number().min(3).default(9),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<MacdInitV3, any, any>;

export const initMacdInitV3 = zMacdInitV3.parse({});

import {PositionDirectionEnum} from '../__generated__/resolvers-types';

/**
 * Position data provided by broker
 */
export interface IPositionMain {
  /** position id from broker or gnerated locally */
  id: string;

  /** broker name */
  broker: string;

  /** asset id from broker, or identifiable string for asset */
  assetId: string;

  /** Position size */
  size: number;

  /** Direction of position */
  direction: PositionDirectionEnum;

  /** Position open price */
  open: number;
  /** Position open timestamp */
  openTsUtc: string;

  /** Position close price */
  close?: number;
  /** Position close timestamp */
  closeTsUtc?: string;

  /** Limit price */
  limit?: number;
  /** Stop price */
  stop?: number;
}

export const defaultIPositionMain: IPositionMain = {
  id: '',
  broker: '',
  assetId: '',
  size: 0,
  direction: PositionDirectionEnum.LONG,
  open: 0,
  openTsUtc: '',
};

import {getPathStrategyUnknownCollection} from '@algo/firebase-common';
import {collection, CollectionReference, Firestore} from 'firebase/firestore';

/**
 * collection of unknown of strategy
 */
export function getReferenceStrategyUnknownCollection<T>(
  firestoreApp: Firestore,
  strategyName: string,
  collectionName: string,
) {
  return collection(
    firestoreApp,
    getPathStrategyUnknownCollection(strategyName, collectionName),
  ) as CollectionReference<T>;
}

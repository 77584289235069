import {ZodType, number, object} from 'zod';

/**
 * Stochastic output data type
 */
export interface BollingerBandOutput {
  /** middle line */
  ma: number;
  /** upper line */
  upper: number;
  /** lower line */
  lower: number;
  /** percentage (0-1) of price between upper and lower */
  percentageBetween: number;
}

export const initBollingerBandOutput: BollingerBandOutput = {
  ma: 0,
  upper: 0,
  lower: 0,
  percentageBetween: 0,
};

export const zBollingerBandOutput: ZodType<BollingerBandOutput> = object({
  ma: number(),
  upper: number(),
  lower: number(),
  percentageBetween: number(),
});

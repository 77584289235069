import {IPositionV2} from '@algo/model';
import {isIPositionV2Long} from '../../status/IPositionV2/isIPositionV2Long';

/**
 * Filter for position
 * @param isLong
 * @returns
 */
export function filterIPositionV2Direction(isLong: boolean) {
  return (p: IPositionV2, _index: number, _array: IPositionV2[]) =>
    isLong ? isIPositionV2Long(p) : !isIPositionV2Long(p);
}

import {z} from 'zod';
import {IgDealStatusEnum} from '../../enum/IgDealStatusEnum';
import {IgDirectionEnum} from '../../enum/IgDirectionEnum';
import {IgStreamOrderTimeInForceEnum} from '../../enum/stream/IgStreamOrderTimeInForceEnum';
import {IgStreamPositionStatusEnum} from '../../enum/stream/IgStreamPositionStatusEnum';
import {IgStreamWorkingOrderTypeEnum} from '../../enum/stream/IgStreamWorkingOrderTypeEnum';

/**
 * Working order updates for an account
 */
export interface IgStreamOrder {
  /** User channel (do not bind to this value - it will be converted to a constant enum) */
  channel: string;
  /** currency */
  currency: string;
  /** Deal identifier */
  dealId: string;
  /** Deal reference */
  dealReference: string;
  /** Deal status */
  dealStatus: IgDealStatusEnum;
  /** Position direction */
  direction: IgDirectionEnum;
  /** Instrument EPIC identifier */
  epic: string;
  /** Instrument expiry */
  expiry: string;
  /** Good until specified date */
  goodTillDate: string | null;
  /** True if a guaranteed stop is in place */
  guaranteedStop: boolean;
  /** Trade level */
  level: number;
  /** Limit distance */
  limitDistance: number | null;
  /** Order type */
  orderType: IgStreamWorkingOrderTypeEnum;
  /** Trade size */
  size: number;
  /** Order status */
  status: IgStreamPositionStatusEnum;
  /** Stop distance */
  stopDistance: number | null;
  /** Time in force */
  timeInForce: IgStreamOrderTimeInForceEnum;
  /** Event date and time */
  timestamp: string;
}

export const zIgStreamOrder: z.ZodType<IgStreamOrder> = z.object({
  channel: z.string(),
  currency: z.string(),
  dealId: z.string(),
  dealReference: z.string(),
  dealStatus: z.nativeEnum(IgDealStatusEnum),
  direction: z.nativeEnum(IgDirectionEnum),
  epic: z.string(),
  expiry: z.string(),
  goodTillDate: z.string().nullable(),
  guaranteedStop: z.boolean(),
  level: z.number(),
  limitDistance: z.number().nullable(),
  orderType: z.nativeEnum(IgStreamWorkingOrderTypeEnum),
  size: z.number(),
  status: z.nativeEnum(IgStreamPositionStatusEnum),
  stopDistance: z.number().nullable(),
  timeInForce: z.nativeEnum(IgStreamOrderTimeInForceEnum),
  timestamp: z.string(),
});

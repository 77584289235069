import {IPositionV2, PositionDirectionEnum} from '@algo/model';
import {Grid, Chip} from '@mui/material';

export interface PositionTileAssetProps {
  position: IPositionV2;
}

export default function PositionTileAsset(props: PositionTileAssetProps) {
  const {position} = props;
  const isLong = position.direction === PositionDirectionEnum.LONG;
  return (
    <Grid container spacing={2} alignItems="center" direction="row">
      <Grid item>Asset:</Grid>
      <Grid item>
        <Chip label={position.assetId} />
      </Grid>
      <Grid item>
        <Chip label={`${isLong ? '+' : '-'}${position.size}`} color={isLong ? 'success' : 'error'} />
      </Grid>
    </Grid>
  );
}

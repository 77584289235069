import {number} from 'zod';

export const zNeighbourBarRangingOutput = number();

/**
 * 0 means no trend, 1 means trending and in between means some trending
 */
export type NeighbourBarRangingOutput = number;

export const initNeighbourBarRangingOutput: NeighbourBarRangingOutput = 0;

import {IndicatorCandlePriceEnum} from '../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {MacdOutput} from './interface/MacdOutput';
import {macd} from './macd';

/**
 * Function to calculate a range of macd values given candles
 * @param candles
 * @param period
 * @param hloc
 * @returns
 */
export function macdCandle(
  candles: IndicatorCandle[],
  slowPeriod?: number,
  fastPeriod?: number,
  signalPeriod?: number,
  hloc = IndicatorCandlePriceEnum.CLOSE,
): MacdOutput[] {
  return macd(candles.map(mapIndicatorCandleToNumberArr(hloc)), slowPeriod, fastPeriod, signalPeriod);
}

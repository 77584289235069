import {v4 as uuidv4} from 'uuid';
import {IPositionV2} from '../../../__generated__/resolvers-types';
import {MatchAlgoStatusEnum} from '../enum/MatchAlgoStatusEnum';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

export default function mapIPositionV2ToNotRecorded(
  algoName: string,
  docId: string,
  monitorPosition: IPositionV2,
): IMatchAlgoPosition {
  return {
    uniqueId: uuidv4(),
    status: MatchAlgoStatusEnum.NOT_RECORDED,
    algoName,
    docId,
    monitorPosition,
    epic: monitorPosition.assetId,
  };
}

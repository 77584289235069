import {ZodType, boolean, object} from 'zod';

export interface IgPositionCommonGuaranteeStop {
  /**
   * True if a guaranteed stop is required
   *   [Constraint: NotNull]
   */
  guaranteedStop: boolean;
}

export const zIgPositionCommonGuaranteeStop = object({
  guaranteedStop: boolean(),
}) satisfies ZodType<IgPositionCommonGuaranteeStop>;

export const initIgPositionCommonGuaranteeStop: IgPositionCommonGuaranteeStop = zIgPositionCommonGuaranteeStop.parse({
  guaranteedStop: false,
});

import {IPositionV2} from '@algo/model';
import {IPositionRecordV2} from 'model/IPositionRecord';

/**
 * Return function to Combine tag in positionData to position
 * @param positionData
 * @returns
 */
export default function mergeMetadata(positionData?: IPositionRecordV2) {
  return (position: IPositionV2) => {
    const findData = positionData && positionData[position.id];
    if (!findData) return position;
    return {
      ...position,
      tags: findData.tags,
    };
  };
}

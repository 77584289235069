import {number, object, ZodType} from 'zod';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface PsarInitV3 extends IndicatorInitV3 {
  step: number;
  max: number;
  start: number;
}

export const zPsarInitV3 = zIndicatorInitV3.merge(
  object({
    start: number().min(0).default(0.02),
    step: number().min(0).default(0.02),
    max: number().min(0).default(0.2),
  }),
) satisfies ZodType<PsarInitV3, any, any>;

// export type PsarInitV3 = input<typeof zPsarInitV3>;

export const initPsarInitV3 = zPsarInitV3.parse({});

import {IPositionV2} from '../../../__generated__/resolvers-types';
import {MatchAlgoStatusEnum} from '../enum/MatchAlgoStatusEnum';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

/**
 * converting match algo position to a matched one
 * @param matchAlgo
 * @param referencePosition
 * @returns
 */
export default function mapIMatchAlgoPositionToMatched(
  matchAlgo: IMatchAlgoPosition,
  referencePosition: IPositionV2,
): IMatchAlgoPosition {
  return {...matchAlgo, status: MatchAlgoStatusEnum.MATCHED, referencePosition};
}

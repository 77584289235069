import {ITradeSummary} from '@algo/model';
import {UtilNumber} from '@algo/util';
import {sum} from 'mathjs';
import {calculateIPositionV2ArrProfit} from '../../calculate/IPositionV2Arr/calculateIPositionV2ArrProfit';

/**
 * boolean filter for trade summary where profit > or < threshold
 * @param isGreater true for profit > threshold, false for profit < threshold
 * @param threshold threshold value
 * @returns
 */
export function filterITradeSummaryThresholdCompare(isGreater: boolean, threshold = '') {
  return (tradeSummary: ITradeSummary) => {
    if (threshold.length === 0) return true;
    const numThreshold = UtilNumber.tryParseFloat(threshold);

    // invalid value means not set
    if (numThreshold === undefined) return true;

    // calculate latest profit
    const profit = sum(calculateIPositionV2ArrProfit(Object.values(tradeSummary)));

    return isGreater ? profit > numThreshold : profit < numThreshold;
  };
}

import {IgPriceCandle} from '@algo/broker-ig';
import {calculateIgPriceDetailMid} from '../../calculate/IgPriceDetail/calculateIgPriceDetailMid';

export function extractIgPriceCandleMin(candles: IgPriceCandle[]) {
  return candles.reduce(
    (prev, cur) =>
      prev === 0 ? calculateIgPriceDetailMid(cur.lowPrice) : Math.min(prev, calculateIgPriceDetailMid(cur.lowPrice)),
    0,
  );
}

import {ZodType, number, object, string} from 'zod';
import {IgPriceDetail, initIgPriceDetail, zIgPriceDetail} from './IgPriceDetail';

export interface IgPriceCandle {
  /** Snapshot local time, format is yyyy/MM/dd hh:mm:ss, corresponding to start time of the candle */
  snapshotTime: string;
  /** Snapshot time UTC */
  snapshotTimeUTC: string;
  /** Open price */
  openPrice: IgPriceDetail;
  /** Close price */
  closePrice: IgPriceDetail;
  /** High price */
  highPrice: IgPriceDetail;
  /** Low price */
  lowPrice: IgPriceDetail;
  /** Last traded volume. This will generally be null for non exchange-traded instruments */
  lastTradedVolume: number | null;
}

export const initIgPriceCandle: IgPriceCandle = {
  snapshotTime: '1900-01-01T00:00:00',
  snapshotTimeUTC: '1900-01-01T00:00:00',
  openPrice: initIgPriceDetail,
  closePrice: initIgPriceDetail,
  highPrice: initIgPriceDetail,
  lowPrice: initIgPriceDetail,
  lastTradedVolume: null,
};

export const zIgPriceCandle = object({
  snapshotTime: string().min(19),
  snapshotTimeUTC: string().min(19),
  openPrice: zIgPriceDetail,
  closePrice: zIgPriceDetail,
  highPrice: zIgPriceDetail,
  lowPrice: zIgPriceDetail,
  lastTradedVolume: number().nonnegative().nullable(),
}) satisfies ZodType<IgPriceCandle>;

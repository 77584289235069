import {runDelay} from '@coolcolduk/util';
import {Grid, TextField} from '@mui/material';
import {ChangeEventHandler} from 'react';
import {AlgoStageEnum} from '../../__generated__/resolvers-types';
import {breakStrategyCode, buildStrategyCode} from './utilSummary';

export interface SummaryRowConfigProps {
  name: string;
  /** config for copy scaling */
  copyScale: Record<string, number>;

  updateFn: (stage: AlgoStageEnum, name: string, value: number) => void;
}

/**
 * Show number of duplicate for strategy
 * @param props
 * @returns
 */
export default function SummaryRowConfig(props: SummaryRowConfigProps) {
  const {name, copyScale, updateFn} = props;
  const isPaper = name.startsWith(AlgoStageEnum.PAPER);

  const strategyCodeObject = breakStrategyCode(name);

  const handleChange: (stage: AlgoStageEnum) => ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    (stage) => (e) => {
      const v = parseInt(e.target.value, 10) || 0;

      runDelay(() => updateFn(stage, strategyCodeObject.strategyName, v));
    };

  if (!isPaper) {
    return (
      <TextField
        type="number"
        label={strategyCodeObject.stage}
        defaultValue={copyScale[name] || 0}
        onChange={handleChange(strategyCodeObject.stage)}
      />
    );
  }

  const stages = [AlgoStageEnum.DEV, AlgoStageEnum.PROD];
  return (
    <Grid container spacing={2}>
      {stages.map((stage) => (
        <Grid item key={`${name}-${stage}`}>
          <TextField
            type="number"
            label={stage}
            defaultValue={copyScale[buildStrategyCode(stage, strategyCodeObject.strategyName)] || 0}
            onChange={handleChange(stage)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

import {number, object, ZodType} from 'zod';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

/**
 * Neighbouring bar ranging indicator config
 */
export interface NeighbourBarRangingInit extends IndicatorInit {
  /** period to check, min 3 */
  period: number;
}
export const zNeighbourBarRangingInit = zIndicatorInit.merge(
  object({
    period: number().min(3).default(3),
  }),
) satisfies ZodType<NeighbourBarRangingInit, any, any>;

export const initNeighbourBarRangingInit = zNeighbourBarRangingInit.parse({});

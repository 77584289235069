import {z} from 'zod';
import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgActivity, zIgActivity} from '../interface/history/IgActivity';
import {IgSendRequestBody} from '../interface/internal/IgSendRequestBody';
import {IgPageMetaData, zIgPageMetaData} from '../interface/price/IgPageMetaData';

const zIgHistoryActivityGetV3Request = z.object({
  /** Start date */
  from: z.date().default(new Date('2022-01-01')),
  /** End date (Default = current time. A date without time component refers to the end of that day.) */
  to: z.date().optional(),
  /** Indicates whether to retrieve additional details about the activity */
  detailed: z.boolean().default(false).optional(),
  /** Deal ID */
  dealId: z.string().optional(),
  /**
   * FIQL filter (supported operators: ==|!=|,|;)
   * @see https://labs.ig.com/rest-trading-api-guide
   * @see https://tools.ietf.org/html/draft-nottingham-atompub-fiql-00
   */
  filter: z.string().optional(),
  /** Page size (min: 10, max: 500, default: 50) */
  pageSize: z.number().int().min(10).max(500).default(50).optional(),
});

export type IgHistoryActivityGetV3Request = z.input<typeof zIgHistoryActivityGetV3Request>;

export interface IgHistoryActivityGetV3Response {
  activities: IgActivity[];
  metadata: IgPageMetaData;
}
export const zIgHistoryActivityGetV3Response: z.ZodType<IgHistoryActivityGetV3Response> = z.object({
  activities: z.array(zIgActivity),
  metadata: zIgPageMetaData,
});

/**
 * Get market category in navigation hierarchy
 * @version 3
 */
export async function getHistoryActivity(userToken: IgUserToken, param?: IgHistoryActivityGetV3Request) {
  console.debug('getHistoryActivity');
  const result = await sendIgRequest<IgHistoryActivityGetV3Response>(
    userToken,
    `/history/activity`,
    'get',
    3,
    zIgHistoryActivityGetV3Request.parse(param) as unknown as IgSendRequestBody,
  );

  return createIgResponse(userToken, result);
}

import {getPathStrategyCollection} from './getPathStrategyCollection';

/**
 * document path of strategy
 * @returns
 */
export function getPathStrategyStrategyDocument(strategyName: String) {
  console.debug('getPathStrategyStrategyDocument');
  return `${getPathStrategyCollection()}/${strategyName}`;
}

import {Replay} from '@mui/icons-material';
import {Grid, IconButton, TextField} from '@mui/material';
import {useState} from 'react';
import ButtonGroupOrganism from '../../components/ButtonGroup/ButtonGroupOrganism';
import DropdownListOrganism from '../../components/dropdownList/DropdownListOrganism';

export interface AnalysisStrategyProps<T extends string> {
  strategyList: string[];
  selectedStrategy?: string;
  onClickHandle: (name: string) => void;
  handleRefresh?: () => void;

  strategyType: Record<string, T>;
  selectedStrategyType?: T;
  handleStrategyType: (s: T) => void;
}

export default function AnalysisStrategy<T extends string>(props: AnalysisStrategyProps<T>) {
  const {
    onClickHandle,
    strategyList,
    strategyType,
    selectedStrategy,
    selectedStrategyType,
    handleStrategyType,
    handleRefresh,
  } = props;
  const [filter, setFilter] = useState('');

  const filterStrategyList = strategyList
    .filter((s) => (filter.length > 0 ? s.toLowerCase().includes(filter.toLowerCase()) : true))
    .sort((a, b) => (a > b ? 1 : -1));
  return (
    <Grid item>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <ButtonGroupOrganism enumItem={strategyType} handleClick={handleStrategyType} value={selectedStrategyType} />
        </Grid>

        <Grid item>
          <DropdownListOrganism
            selectedItem={selectedStrategy || 'Select a strategy'}
            onClickHandle={onClickHandle}
            label="Selected strategy"
            items={filterStrategyList}
          />
        </Grid>

        <Grid item>
          <IconButton aria-label="Left" size="small" onClick={handleRefresh}>
            <Replay fontSize="small" />
          </IconButton>
        </Grid>

        <Grid item>
          <TextField label="Strategy filter" value={filter} onChange={(e) => setFilter(e.target.value)} />
        </Grid>
      </Grid>
    </Grid>
  );
}

import {IgPositionConfirmV1Response} from '@algo/broker-ig';
import {IPositionV2} from '@algo/model';
import {uniqueArray} from '@coolcolduk/util';

/**
 * Close PositionV2
 * @param openData
 * @param closeData
 * @returns
 */
export function mapIPositionV2ToClose(
  openData: IPositionV2,
  closeData: IgPositionConfirmV1Response,
  addTags?: string[],
): IPositionV2 {
  return {
    ...openData,
    close: closeData.level,
    closeTsUtc: closeData.date,
    limit: closeData.limitLevel || 0,
    stop: closeData.stopLevel || 0,
    tags: uniqueArray([...openData.tags, ...(addTags || [])]),
  };
}

export enum IgInstrumentTypeEnum {
  BINARY = 'BINARY',
  BUNGEE_CAPPED = 'BUNGEE_CAPPED',
  BUNGEE_COMMODITIES = 'BUNGEE_COMMODITIES',
  BUNGEE_CURRENCIES = 'BUNGEE_CURRENCIES',
  BUNGEE_INDICES = 'BUNGEE_INDICES',
  COMMODITIES = 'COMMODITIES',
  CURRENCIES = 'CURRENCIES',
  INDICES = 'INDICES',
  KNOCKOUTS_COMMODITIES = 'KNOCKOUTS_COMMODITIES',
  KNOCKOUTS_CURRENCIES = 'KNOCKOUTS_CURRENCIES',
  KNOCKOUTS_INDICES = 'KNOCKOUTS_INDICES',
  KNOCKOUTS_SHARES = 'KNOCKOUTS_SHARES',
  OPT_COMMODITIES = 'OPT_COMMODITIES',
  OPT_CURRENCIES = 'OPT_CURRENCIES',
  OPT_INDICES = 'OPT_INDICES',
  OPT_RATES = 'OPT_RATES',
  OPT_SHARES = 'OPT_SHARES',
  RATES = 'RATES',
  SECTORS = 'SECTORS',
  SHARES = 'SHARES',
  SPRINT_MARKET = 'SPRINT_MARKET',
  TEST_MARKET = 'TEST_MARKET',
  UNKNOWN = 'UNKNOWN',
}

import {IFilter, initIFilter} from './IFilter';

/**
 * Create default filter object
 * @param data
 * @returns
 */
export default function createFilter(data?: IFilter): IFilter {
  if (!data) return initIFilter;
  return {...initIFilter, ...data};
}

import {LogLevelEnum} from '../enum/LogLevelEnum';

/**
 * Convert string to log level, default to undefined
 * @param logLevel
 * @returns
 */
export function convertStrToLogLevel(logLevel?: string | LogLevelEnum): LogLevelEnum | undefined {
  if (!logLevel) return undefined;

  // check if it matches any enum value
  const val = Object.values(LogLevelEnum);
  if (!val.includes(logLevel as LogLevelEnum)) return undefined;

  return logLevel as LogLevelEnum;
}

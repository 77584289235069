import {UtilObject} from '@algo/util';
import {gql, useQuery} from '@apollo/client';
import {useEffect} from 'react';
import {AlgoPaperConfig, IPositionV2} from '../__generated__/resolvers-types';

const QUERY = gql`
  query Query($strategyNames: [String!]) {
    listAlgoPaperConfig(strategyNames: $strategyNames) {
      id
      position {
        id
        broker
        assetId
        size
        direction
        open
        openTsUtc
        close
        closeTsUtc
        limit
        stop
        tags
        trailingStop
        trailingStep
      }
    }
  }
`;

/**
 * List current open position of algo in paper trade
 * @param strategyNames list of strategy name to obtain, undefined fetch all, empty array would not fetch anything
 * @returns
 */
export default function useQueryListAlgoPaperConfigPosition(
  strategyNames?: string[],
): Record<string, IPositionV2 | undefined> {
  const {data, refetch} = useQuery<{listAlgoPaperConfig: Pick<AlgoPaperConfig, 'id' | 'position'>[]}>(QUERY, {
    variables: {strategyNames},
    skip: !strategyNames,
  });

  useEffect(() => {
    if (!strategyNames || strategyNames.length > 0) {
      refetch({variables: {strategyNames}});
    }
    // eslint-disable-next-line
  }, [strategyNames]);

  if (strategyNames && strategyNames.length === 0) {
    return {};
  }

  if (!data) return {};

  return UtilObject.fromArray(data.listAlgoPaperConfig.map((d) => ({id: d.id, data: d.position || undefined})));
}

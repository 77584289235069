import {getPathStrategyCollection} from './getPathStrategyCollection';

/**
 * collection path of close positions
 * @param strategyName
 * @returns
 */
export function getPathStrategyCloseCollection(strategyName: string) {
  console.debug('getPathStrategyCloseCollection');
  return `${getPathStrategyCollection()}/${strategyName}/close`;
}

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {EmaConfigV2, initEmaConfigV2, zEmaConfigV2} from '../../ema';
import {MacdOutput, zMacdOutput} from './MacdOutput';

/**
 * MACD config v2
 */
export interface MacdConfigV2 extends IndicatorConfig<MacdOutput, number> {
  type: 'macdConfigV2';
  /** Fast EMA line */
  emaFastConfig: EmaConfigV2;
  /** slow EMA line */
  emaSlowConfig: EmaConfigV2;
  /** signal line */
  signalConfig: EmaConfigV2;
  hloc: IndicatorCandlePriceEnum;
}

export const zMacdConfigV2 = zIndicatorConfig(zMacdOutput, number()).merge(
  object({
    type: literal('macdConfigV2').default('macdConfigV2'),
    emaFastConfig: zEmaConfigV2.default(initEmaConfigV2),
    emaSlowConfig: zEmaConfigV2.default(initEmaConfigV2),
    signalConfig: zEmaConfigV2.default(initEmaConfigV2),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<MacdConfigV2, any, any>;

export const initMacdConfigV2 = zMacdConfigV2.parse({});

import {nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface SmaInitV3 extends IndicatorInitV3 {
  period: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zSmaInitV3 = zIndicatorInitV3.merge(
  object({
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<SmaInitV3, any, any>;

// export type SmaInitV3 = input<typeof zSmaInitV3>;

export const initSmaInitV3 = zSmaInitV3.parse({});

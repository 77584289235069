import {IgPositionConfirmV1Response} from '@algo/broker-ig';
import {IPositionV2} from '@algo/model';
import extractMatchPositions from '../extract/extractMatchPositions';
import mapSrcTargetToMatchTableData from './mapSrcTargetToMatchTableData';
import mapSrcToMatchTableData from './mapSrcToMatchTableData';
import mapTargetToMatchTableData from './mapTargetToMatchTableData';

/**
 * Convert data to match table data
 * @param duplicate
 * @param src
 * @param target
 * @returns
 */
export default function mapPositionToData(
  duplicate: Record<string, IgPositionConfirmV1Response>,
  src: IPositionV2[],
  target: IPositionV2[],
) {
  // identify matching positions
  const matchPosition = extractMatchPositions(duplicate, src, target);
  const resultMatch = matchPosition.map((p) => mapSrcTargetToMatchTableData(p.source, p.target));

  // extract id
  const foundSrcId = matchPosition.map((p) => p.source.id);
  const foundTargetId = matchPosition.map((p) => p.target.id);

  // find orphan
  const orphanSrc = src.filter((p) => !foundSrcId.includes(p.id));
  const orphanTarget = target.filter((p) => !foundTargetId.includes(p.id));

  // identify orphan position
  const resultSrc = orphanSrc.map((p) => mapSrcToMatchTableData(p));
  const resultTarget = orphanTarget.map((p) => mapTargetToMatchTableData(p));

  // combine and sort result
  const combinedData = [...resultMatch, ...resultSrc, ...resultTarget];
  combinedData.sort((a, _b) => (a.duplicate ? 1 : -1));
  return combinedData;
}

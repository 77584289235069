import {IgDirectionEnum, IgPositionConfirmV1Response} from '@algo/broker-ig';
import {IPositionV2, PositionDirectionEnum} from '@algo/model';

/**
 * IgPositionConfirm to IPositionV2Close
 * @param openData
 * @param closeData
 * @returns
 */
export function mapIgPositionToIPositionV2Closed(
  openData: IgPositionConfirmV1Response,
  closeData: IgPositionConfirmV1Response,
  tags?: string[],
): IPositionV2 {
  return {
    id: openData.dealId,
    broker: 'ig',
    assetId: openData.epic,
    size: openData.size,
    direction: openData.direction === IgDirectionEnum.BUY ? PositionDirectionEnum.LONG : PositionDirectionEnum.SHORT,
    open: openData.level,
    openTsUtc: openData.date,
    tags: tags || [],
    close: closeData.level,
    closeTsUtc: closeData.date,
    limit: closeData.limitLevel || 0,
    stop: closeData.stopLevel || 0,
  };
}

import {IPositionV2} from '@algo/model';
import {IFilter} from '../../../../model/filter/IFilter';
import positionFilterMaxLatestData from './positionFilterMaxLatestData';
import positionFilterProfitLoss from './positionFilterProfitLoss';
import positionFilterTags from './positionFilterTags';

/**
 * Apply all filters as required
 * @param positions
 * @param filter
 * @returns
 */
export default function positionFilterAll(positions: IPositionV2[], filter: IFilter) {
  const filterProfitLoss = positionFilterProfitLoss(positions, filter.profitLoss);
  const excludeTags = positionFilterTags(filterProfitLoss, filter.excludeTags, false);
  const includeTags = positionFilterTags(excludeTags, filter.includeTags, true);
  return positionFilterMaxLatestData(includeTags, filter.maxLatestPosition);
}

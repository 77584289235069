import {IgUserToken} from '../create/createIgUserToken';
import {IgDirectionEnum} from '../enum/IgDirectionEnum';
import {IgOrderExecutionTypeEnum} from '../enum/IgOrderExecutionTypeEnum';
import {IgTimeInForceEnum} from '../enum/IgTimeInForceEnum';
import {generateDealReference} from '../generate/generateDealReference';
import {openIgPosition} from '../position/openIgPosition';

/**
 * Various options for opening market order
 */
export interface IgOpenPositionMarketOption {
  stopLevel?: number;
  limitLevel?: number;
  stopDistance?: number;
  limitDistance?: number;
  dealReference?: string;
  forceOpen?: boolean;
  expiry?: string;
  timeInForce?: IgTimeInForceEnum.EXECUTE_AND_ELIMINATE;
  currencyCode?: string;
  guaranteedStop?: boolean;
}

const defaultIgOpenMarketOption = {
  forceOpen: true,
  expiry: 'DFB',
  timeInForce: IgTimeInForceEnum.EXECUTE_AND_ELIMINATE,
  currencyCode: 'GBP',
  guaranteedStop: false,
};

/**
 * open ig position market order
 */
export async function igHelperOpenPositionMarket(
  userToken: IgUserToken,
  epic: string,
  size: number,
  direction = IgDirectionEnum.BUY,
  options: IgOpenPositionMarketOption = {},
) {
  console.debug('igHelperOpenPositionMarket');

  return openIgPosition(userToken, {
    orderType: IgOrderExecutionTypeEnum.MARKET,
    epic,
    size,
    direction,
    dealReference: generateDealReference(),
    ...options,
    forceOpen: options.forceOpen || defaultIgOpenMarketOption.forceOpen,
    expiry: options.expiry || defaultIgOpenMarketOption.expiry,
    timeInForce: options.timeInForce || defaultIgOpenMarketOption.timeInForce,
    currencyCode: options.currencyCode || defaultIgOpenMarketOption.currencyCode,
    guaranteedStop: options.guaranteedStop || defaultIgOpenMarketOption.guaranteedStop,
  });
}

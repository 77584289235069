import {emaInit} from '../ema';
import {MacdConfig, zMacdConfig} from './interface/MacdConfig';

/**
 * Initialise config
 * @param slowPeriod
 * @param fastPeriod
 * @param signalPeriod
 * @returns
 */
export function macdInit(slowPeriod = 26, fastPeriod = 12, signalPeriod = 9): MacdConfig {
  return zMacdConfig.parse({
    emaFastConfig: emaInit(fastPeriod),
    emaSlowConfig: emaInit(slowPeriod),
    signalConfig: emaInit(signalPeriod),
  });
}

import {ZodType, number, object} from 'zod';

/**
 * Supertrend output data type
 */
export interface SuperTrendOutput {
  /** calculated upper band */
  upperBand: number;
  /** calculated lower band */
  lowerBand: number;
  /** resulting trend value */
  superTrend: number;
  /** same as supertrend, only show upper band */
  superTrendUpper: number;
  /** same as supertrend, only show lower band */
  superTrendLower: number;
}

export const initSuperTrendOutput: SuperTrendOutput = {
  upperBand: 0,
  lowerBand: 0,
  superTrend: 0,
  superTrendUpper: 0,
  superTrendLower: 0,
};

export const zSuperTrendOutput: ZodType<SuperTrendOutput, any, any> = object({
  upperBand: number().min(0).default(0),
  lowerBand: number().min(0).default(0),
  superTrend: number().min(0).default(0),
  superTrendUpper: number().min(0).default(0),
  superTrendLower: number().min(0).default(0),
});

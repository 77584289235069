/**
 * Order type for execution
 */
export enum IgOrderExecutionTypeEnum {
  /**
   * Limit orders get executed at the price seen by IG at the moment of booking a trade.
   * A limit determines the level at which the order or the remainder of the order will be rejected.
   */
  LIMIT = 'LIMIT',

  /**
   * Market orders get executed at the price seen by the IG at the time of booking the trade.
   * A level cannot be specified. Not applicable to BINARY instruments
   */
  MARKET = 'MARKET',

  /**
   * Quote orders get executed at the specified level.
   * The level has to be accompanied by a valid quote id.
   * This type is only available subject to agreement with IG.
   */
  QUOTE = 'QUOTE',
}

/**
 * try to run parseInt, and return undefined if invalid
 * @param s
 * @returns
 */
export function tryParseInt(s: string = '') {
  try {
    if (s.length > 0) {
      const result = parseInt(s, 10);
      return Number.isNaN(result) ? undefined : result;
    }
  } catch (e) {
    return undefined;
  }
  return undefined;
}

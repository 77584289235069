import {RefreshRounded} from '@mui/icons-material';
import {Autocomplete, Chip, Grid, IconButton, TextField} from '@mui/material';
import callableGenerateTradeSummary from '../../logic/helper/firebase/callable/callableGenerateTradeSummary';

export interface SummaryHeaderFilterProps {
  setNameFilter: (s: string[]) => void;
}

export default function SummaryHeaderFilter(props: SummaryHeaderFilterProps) {
  const {setNameFilter} = props;

  return (
    <Grid container alignItems="center" direction="row">
      <Grid item>
        <Autocomplete<string, true, false, true>
          sx={{minWidth: 200, '& input': {minWidth: '200px !important'}}}
          multiple
          freeSolo
          options={[]}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              // eslint-disable-next-line
              <Chip variant="outlined" label={option} {...getTagProps({index})} />
            ))
          }
          // eslint-disable-next-line
          renderInput={(params) => <TextField {...params} label="Strategy name filter" />}
          onChange={(_event, value) => setNameFilter(value)}
        />
        {/* <TextField label="Strategy name filter" value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} /> */}
      </Grid>
      <Grid item>
        <IconButton aria-label="refresh" onClick={callableGenerateTradeSummary}>
          <RefreshRounded />
        </IconButton>
      </Grid>
    </Grid>
  );
}

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {SmaOutputV2, zSmaOutputV2} from './SmaOutputV2';

export interface SmaConfigV2 extends IndicatorConfig<SmaOutputV2, number> {
  type: 'smaConfigV2';
  period: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zSmaConfigV2 = zIndicatorConfig(zSmaOutputV2, number()).merge(
  object({
    type: literal('smaConfigV2').default('smaConfigV2'),
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<SmaConfigV2, any, any>;

// export type SmaConfigV2 = output<typeof zSmaConfigV2>;

export const initSmaConfigV2 = zSmaConfigV2.parse({});

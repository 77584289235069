import {IPositionV2, PositionDirectionEnum} from '@algo/model';

/**
 * Checks if posiiton is long position
 * @param position
 * @returns
 */
export function isIPositionV2Long(position: IPositionV2) {
  return position.direction === PositionDirectionEnum.LONG;
}

import {IUserHouse} from '@algo/firebase-common';
import {Box, Grid, TextField, Typography} from '@mui/material';

export interface MortgageCalculatorAssetProps {
  formData: IUserHouse;

  setFormData: (k: string, v: string | boolean) => void;
}

/**
 * Show analysis page to do analysis
 * @returns
 */
export default function MortgageCalculatorAsset(props: MortgageCalculatorAssetProps) {
  const {formData, setFormData} = props;

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography typography="title">Asset</Typography>
        </Grid>
        <Grid item>
          <TextField
            label="Deposit"
            value={formData.cash}
            inputProps={{type: 'number', min: 10000, step: 10000}}
            onChange={(e) => setFormData('cash', e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Purchase fee"
            value={formData.fee}
            inputProps={{type: 'number', min: 0, step: 1}}
            onChange={(e) => setFormData('fee', e.target.value)}
          />
        </Grid>
        <Grid item>
          <Typography>Cash available: {parseFloat(formData.cash) - parseFloat(formData.fee)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

import {convertStrToLogLevel} from './convertStrToLogLevel';
import {LogLevelEnum, logLevelNumber} from '../enum/LogLevelEnum';

/**
 * Check if log should occur
 * @param logLevelSetting The log level in setting, off by default
 * @param requiredLevel The level that will be logged, default to debug
 * @returns
 */
export function shouldLog(logLevelSetting: LogLevelEnum | string, levelToLog: string | LogLevelEnum) {
  const levelSetting = convertStrToLogLevel(logLevelSetting) || LogLevelEnum.OFF;
  const toLog = convertStrToLogLevel(levelToLog) || LogLevelEnum.DEBUG;

  return logLevelNumber[levelSetting] <= logLevelNumber[toLog];
}

import {Firestore} from 'firebase/firestore';
import {FirestoreCollectionHelper} from '../../base/FirestoreCollectionHelper';
import {getReferenceStrategyUnknownCollection} from '../../reference/strategy/getReferenceStrategyUnknownCollection';

export const createFirestoreStrategyUnknownCollectionHelper = <T extends Record<string, any>>(
  firestoreApp: Firestore,
  strategyName: string,
  collectionName: string,
) =>
  new FirestoreCollectionHelper(getReferenceStrategyUnknownCollection<T>(firestoreApp, strategyName, collectionName));

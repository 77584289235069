import {IPositionV2} from '@algo/model';

/**
 * Calculate net size
 * @param positions
 * @returns total size
 */
export default function positionCalculateNetSize(positions: IPositionV2[]) {
  return positions.reduce((prev, cur) => prev + cur.size, 0);
}

import {getPathAlgoConfigDocument} from './getPathAlgoConfigDocument';

/**
 * algo Close position document
 * @param docName
 * @returns
 */
export function getPathAlgoPositionCloseCollection(docName: string) {
  console.debug('getPathAlgoPositionCloseCollection');
  return `${getPathAlgoConfigDocument(docName)}/close`;
}

import {IndicatorCandle} from '../../interface';
import {IndicatorConfigV3} from './IndicatorConfigV3';
import {IndicatorV3} from './IndicatorV3';
import {calculateIndicatorV3Series} from './calculateIndicatorV3Series';

/**
 * calculate indicator with indicator candle as input
 * @param indicator
 * @param config
 * @param candles
 * @returns
 */
export function calculateIndicatorV3Candles<
  Config extends IndicatorConfigV3<Output, Input>,
  Output = number,
  Init = Config,
  Input = number,
>(indicator: IndicatorV3<Config, Output, Init, Input>, config: Config, candles: IndicatorCandle[]) {
  const series = candles.map((c) => indicator.mapCandleToInput(config, c));

  return calculateIndicatorV3Series(indicator, config, series);
}

import {ZodType, number, object} from 'zod';

export interface IgPriceDetail {
  /** Bid price, highest price a buyer will pay for a security */
  bid: number;
  /** Asking price, lowest price a seller will accept for a security */
  ask: number;
  /** Last traded price. This will generally be null for non exchange-traded instruments */
  lastTraded: null | number;
}

export const initIgPriceDetail: IgPriceDetail = {
  bid: 0,
  ask: 0,
  lastTraded: null,
};

export const zIgPriceDetail = object({
  bid: number().nonnegative(),
  ask: number().nonnegative(),
  lastTraded: number().nonnegative().nullable(),
}) satisfies ZodType<IgPriceDetail>;

import {IPositionV2, PositionDirectionEnum} from '@algo/model';
import {MatchTableData} from '../interface/MatchTableData';

/**
 * map data when only target exists
 * @param targetPosition
 * @returns
 */
export default function mapSrcToMatchTableData(targetPosition: IPositionV2): MatchTableData {
  return {
    duplicate: false,
    dealIdLeft: '',
    dealIdRight: targetPosition.id,
    assetId: targetPosition.assetId,
    size: targetPosition.direction === PositionDirectionEnum.LONG ? targetPosition.size : -targetPosition.size,
    openPrice: targetPosition.open,
    price: targetPosition.close,
    profitLoss: (
      (targetPosition.close - targetPosition.open) *
      targetPosition.size *
      (targetPosition.direction === PositionDirectionEnum.LONG ? 1 : -1)
    ).toFixed(1),
    stoplossMatch: targetPosition.stop.toString(),
    takeprofitMatch: targetPosition.limit.toString(),
    openTs: targetPosition.openTsUtc || '',
  };
}

/**
 * Position meta data that is added by user
 */
export interface IPositionMeta {
  tags: string[];
  note: string | null;
}

export const defaultIPositionMeta: IPositionMeta = {
  tags: [],
  note: null,
};

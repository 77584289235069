import {isIPositionV2Closed} from '@algo/helper';
import {IPositionV2} from '@algo/model';

/**
 * Filter to all closed trade
 * @param positions
 * @returns
 */
export default function positionFilterClosedOnly(positions: IPositionV2[]) {
  return positions.filter(isIPositionV2Closed);
}

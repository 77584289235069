import {Typography} from '@mui/material';
import {envLocalDb} from '../../logic/helper/env';

export interface IHeaderTitleProps {
  title: string;
}

function HeaderTitle(props: IHeaderTitleProps) {
  const {title} = props;
  return (
    <Typography variant="h6">
      {title}
      {envLocalDb() ? ' - local db' : ''}
    </Typography>
  );
}

export default HeaderTitle;

import {gql, useQuery} from '@apollo/client';
import {useEffect} from 'react';

const QUERY = gql`
  query Query {
    listAllPaperAlgos
  }
`;
/**
 * List all algo in paper trade
 * @param reloadTrigger trigger to reload, put in random string to trigger reload
 * @returns
 */
export default function useQueryListAllPaperAlgos(reloadTrigger = ''): string[] {
  const {data, refetch} = useQuery<{listAllPaperAlgos: string[]}>(QUERY);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [reloadTrigger]);

  return data?.listAllPaperAlgos || [];
}

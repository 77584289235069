import {CollectionReference, doc} from 'firebase/firestore';
import {firestoreLoadDoc} from './firestoreLoadDoc';

/**
 * Firestore helper to load all docs in given collection, and return as record
 * @param collection
 * @returns
 */
export async function firestoreLoadDocFromCollection<T extends Record<string, any>>(
  collection: CollectionReference<T>,
  name: string,
): Promise<T | undefined> {
  return firestoreLoadDoc(doc(collection, name));
}

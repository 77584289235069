import {IPositionV2} from '@algo/model';
import dayjs, {OpUnitType, QUnitType} from 'dayjs';

/**
 * Calculate the total duration of all trades. Start from earliest open date, end at latest close date
 * @param positions
 * @param unit
 * @returns
 */
export default function positionCalculateTotalDuration(positions: IPositionV2[], unit: QUnitType | OpUnitType) {
  if (positions.length === 0) return 0;
  // earliest open date
  const openTs = positions.reduce(
    (prev, cur) => (prev.length === 0 || prev > cur.openTsUtc ? cur.openTsUtc : prev),
    '',
  );

  // latest close date
  const closeTs = positions.reduce((prev, cur) => {
    if (!cur.closeTsUtc) return prev;
    return prev.length === 0 || prev < cur.closeTsUtc ? cur.closeTsUtc : prev;
  }, '');

  return dayjs(closeTs).diff(openTs, unit, true);
}

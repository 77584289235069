import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import convertStrToFloat from '../../common/util/convertStrToFloat';
import convertStrToInt from '../../common/util/convertStrToInt';
import {MacdOutput} from './interface/MacdOutput';
import {macdCandle} from './macdCandle';

/**
 * Function to calculate a range of macd values given candles and cmd
 * @param cmd macd_slow_fast_signal e.g. macd_25_12_9
 * @param candles
 * @returns
 */
export function macdCmd(cmd: string, candles: IndicatorCandle[]): MacdOutput[] | undefined {
  const cmdArr = cmd.split('_');
  if (cmdArr[0] !== IndicatorTypeEnum.MACD) return undefined;
  return macdCandle(candles, convertStrToInt(cmdArr[1]), convertStrToFloat(cmdArr[2]), convertStrToInt(cmdArr[3]));
}

import {IPositionV2} from '@algo/model';
import {Firestore} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {createFirestoreStrategyUnknownCollectionHelper} from '../../firestore/strategy/createFirestoreStrategyUnknownCollectionHelper';

/**
 * Load all strategy position
 * @param firestoreApp
 * @param strategyName
 * @returns
 */
export function useLoadStrategyUnknown(firestoreApp?: Firestore, strategyName?: string, collectionName?: string) {
  console.debug('useLoadStrategyUnknown');
  const [data, setData] = useState<Record<string, IPositionV2>>({});

  useEffect(() => {
    const downloadData = async (fs: Firestore, name: string, collName: string) => {
      setData(await createFirestoreStrategyUnknownCollectionHelper<IPositionV2>(fs, name, collName).loadAll());
    };

    if (firestoreApp && strategyName && collectionName) {
      downloadData(firestoreApp, strategyName, collectionName);
    } else {
      setData({});
    }
  }, [firestoreApp, strategyName, collectionName]);

  return data;
}

import {IgActivity} from '@algo/broker-ig';
import {IPositionV2} from '@algo/model';
import {uniqueArray} from '@coolcolduk/util';

/**
 * Close PositionV2
 * @param openData
 * @param closeData
 * @returns
 */
export function mapIPositionV2ToCloseWithActivity(
  openData: IPositionV2,
  closeData: IgActivity,
  addTags?: string[],
): IPositionV2 {
  const {details} = closeData;
  if (!details) throw new Error('details required');
  return {
    ...openData,
    close: details.level,
    closeTsUtc: closeData.date,
    limit: details.limitLevel || 0,
    stop: details.stopLevel || 0,
    tags: uniqueArray([...openData.tags, ...(addTags || [])]),
  };
}

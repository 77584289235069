import {IPositionV2, PositionDirectionEnum} from '@algo/model';
import {Grid, Typography} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';

export const sizeColumn: GridColDef = {
  field: 'size',
  headerName: 'Size',
  width: 70,
  headerAlign: 'center',
  editable: false,
  renderCell: (params: GridRenderCellParams<IPositionV2, number, any>) => {
    const isLong = params.row.direction === PositionDirectionEnum.LONG;
    const size = params.row.size as number;
    const sizeSigned = (isLong ? 1 : -1) * size;
    return (
      <Grid
        container
        sx={{backgroundColor: isLong ? 'green' : 'red', width: '100%', height: '100%'}}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography color="white">{sizeSigned}</Typography>
        </Grid>
      </Grid>
    );
  },
};

import {IgDirectionEnum, IgPositionGetV2Response} from '@algo/broker-ig';
import {IPositionMain, PositionDirectionEnum} from '@algo/model';
import {createPositionMain} from '../../create/IPosition/createPositionMain';

/**
 * Create position from IgPosition
 * @param positionResponse
 * @returns
 */
export function mapPositionFromIgPosition(positionResponse: IgPositionGetV2Response): IPositionMain {
  const {position, market} = positionResponse;
  return createPositionMain({
    broker: 'ig',
    id: position.dealId,
    assetId: market.epic,
    // asset: createAssetFromIgMarketData(market),
    size: position.size,
    direction: position.direction === IgDirectionEnum.BUY ? PositionDirectionEnum.LONG : PositionDirectionEnum.SHORT,
    open: position.level,
    openTsUtc: position.createdDateUTC,
    limit: position.limitLevel || undefined,
    stop: position.stopLevel || undefined,
  });
}

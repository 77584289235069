import {generateDealReference, IgDirectionEnum, IgPriceCandle} from '@algo/broker-ig';
import {initIPositionV2, IPositionV2, PositionDirectionEnum, zIPositionV2} from '@algo/model';
import {extractPriceCandlePrice} from '../../extract/IgPriceDetail/extractPriceDetailPrice';

/**
 * Open paper trade position with candle
 * @throws invalid data
 */
export function createIPositionV2PaperOpenWithCandle(
  epic: string,
  size: number,
  direction: IgDirectionEnum,
  candle: IgPriceCandle,
  tags: string[] = [],
  overridePrice?: number,
  stopLossDistance?: number,
  takeProfitDistance?: number,
  trailingStep?: number,
): IPositionV2 {
  const open =
    overridePrice !== undefined
      ? overridePrice
      : extractPriceCandlePrice(candle.closePrice, true, direction === IgDirectionEnum.BUY);
  const stop =
    stopLossDistance !== undefined
      ? open - (direction === IgDirectionEnum.BUY ? stopLossDistance : -stopLossDistance)
      : 0;
  const limit =
    takeProfitDistance !== undefined
      ? open + (direction === IgDirectionEnum.BUY ? takeProfitDistance : -takeProfitDistance)
      : 0;
  const data: Partial<IPositionV2> = {
    ...initIPositionV2,
    assetId: epic,
    size,
    direction: direction === IgDirectionEnum.BUY ? PositionDirectionEnum.LONG : PositionDirectionEnum.SHORT,
    id: generateDealReference(),
    broker: 'paper',
    open,
    openTsUtc: candle.snapshotTime,
    tags,
    stop: stop < 0 ? 0 : stop,
    limit: limit < 0 ? 0 : limit,
    ...(trailingStep && stopLossDistance ? {trailingStep, trailingStop: stopLossDistance} : {}),
  };

  return zIPositionV2.parse(data);
}

import {IPositionV2} from '@algo/model';
import {TableCell, TableHead, TableRow} from '@mui/material';
import positionCalculateLimitRisk from '../../logic/helper/position/calculate/positionCalculateLimitRisk';
import positionCalculateTotalProfit from '../../logic/helper/position/calculate/positionCalculateTotalProfit';
import positionCalculateTotalRisk from '../../logic/helper/position/calculate/positionCalculateTotalRisk';
import {IPositionTableFields, positionTableFieldsOrder} from './IPositionTableFields';

export interface IPositionTableSummaryProps {
  positions: IPositionV2[];
}

function PositionTableSummary(props: IPositionTableSummaryProps) {
  const {positions} = props;

  const data: Partial<IPositionTableFields> = {
    profitLoss: positionCalculateTotalProfit(positions).toFixed(2),
    totalRisk: positionCalculateTotalRisk(positions).toFixed(2),
    limitRisk: positionCalculateLimitRisk(positions).toFixed(2),
  };

  return (
    <TableHead>
      <TableRow>
        {positionTableFieldsOrder.map((d, i) => (
          <TableCell key={`PositionTableSummary-cell-${d + i.toString()}`} align="center">
            {d in data && data[d as keyof IPositionTableFields]}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default PositionTableSummary;

import {std} from 'mathjs';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {SdConfigV2, zSdConfigV2} from './interface/SdConfigV2';
import {SdInitV2, zSdInitV2} from './interface/SdInitV2';
import {SdOutputV2} from './interface/SdOutputV2';

/**
 * Calculate percentage change from ma
 */
export class SdClassV2 extends IndicatorTemplate<SdConfigV2, SdOutputV2, SdInitV2, number> {
  override type = IndicatorTypeEnum.SD;

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<SdConfigV2, SdOutputV2> {
    return {config: this.config, result: std(this.config.data, 'uncorrected') as number};
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(params: Partial<SdInitV2> | SdConfigV2 = {}): SdConfigV2 {
    const testInit = zSdInitV2.strict().safeParse(params);

    if (testInit.success) {
      return zSdConfigV2.strip().parse({
        ...testInit.data,
        maxPriceLength: testInit.data.period,
      });
    }

    // this is config
    return zSdConfigV2.strict().parse(params);
  }

  /**
   * map command string to init variable
   * - period
   */
  override mapCmdToInit(s: string[]): SdInitV2 {
    const period = s[0] ? convertStrToInt(s[0]) : undefined;
    return zSdInitV2.parse({period});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): number {
    return mapIndicatorCandleToNumberArr(this.config.hloc)(candle);
  }
}

import {UtilObject} from '@algo/util';
import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgPositionCommonDealReference} from '../interface/position/IgPositionCommonDealReference';
import {IgPositionCommonGuaranteeStop} from '../interface/position/IgPositionCommonGuaranteeStop';
import {IgPositionCommonStopLimit} from '../interface/position/IgPositionCommonStopLimit';
import {IgPositionCommonTrailing} from '../interface/position/IgPositionCommonTrailing';

/**
 * Request for position update
 */
export type IgPositionUpdateV2Request = Partial<IgPositionCommonTrailing> &
  Partial<Pick<IgPositionCommonStopLimit, 'stopLevel' | 'limitLevel'>> &
  Partial<IgPositionCommonGuaranteeStop>;

/**
 * Response for position update
 */
export type IgPositionUpdateV2Response = IgPositionCommonDealReference;

/**
 * Check parameter to be valid
 * @param param
 * @returns
 */
function isValid(param: IgPositionUpdateV2Request) {
  // [Constraint: If guaranteedStop equals true, then set stopLevel]
  // [Constraint: If guaranteedStop equals true, then trailingStop must be false]
  if (param.guaranteedStop) {
    if (!param.stopLevel) return false;
    if (param.trailingStop) return false;
  }

  if (param.trailingStop) {
    // [Constraint: If trailingStop equals true, then guaranteedStop must be false]
    if (param.guaranteedStop) return false;

    // [Constraint: If trailingStop equals true,
    // then set trailingStopDistance,trailingStopIncrement,stopLevel]
    if (!param.trailingStopDistance || !param.trailingStopIncrement || !param.stopLevel) {
      return false;
    }
  } else if (param.trailingStopDistance || param.trailingStopIncrement) {
    // [Constraint: If trailingStop equals false,
    // then DO NOT set trailingStopDistance,trailingStopIncrement]

    return false;
  }

  return true;
}

/**
 * update ig position
 * @throw invalid data
 */
export async function updateIgPosition(userToken: IgUserToken, dealId: string, params: IgPositionUpdateV2Request) {
  console.debug('updateIgPosition');

  const newParams = UtilObject.filterUndefined(params) as IgPositionUpdateV2Request;
  if (!isValid(newParams)) {
    throw new Error('updateIgPosition - data incorrect');
  }

  const result = await sendIgRequest<IgPositionUpdateV2Response>(
    userToken,
    `/positions/otc/${dealId}`,
    'put',
    2,
    undefined,
    undefined,
    {stopLevel: null, limitLevel: null, ...newParams},
  );

  return createIgResponse(userToken, result);
}

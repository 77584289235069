import z, {array, object, string, ZodType} from 'zod';
import {ISignalParameter, zISignalParameter} from './ISignalParameter';

/**
 * Signal object layout
 */
export interface ISignal<Config = any> {
  /** name of signal */
  name: string;
  /** code of signal */
  code: string;
  /** description of signal */
  description: string;
  /** List of parameter required */
  parameterNames: ISignalParameter[];

  init: () => Config;
}

export const initISignal: ISignal = {
  name: '',
  code: '',
  description: '',
  parameterNames: [],
  init: () => {},
};

export const zISignal: ZodType<ISignal> = object({
  name: string(),
  code: string(),
  description: string(),
  parameterNames: array(zISignalParameter),
  init: z.function(),
});

import {object, ZodType} from 'zod';
import {EmaConfig, initEmaConfig, zEmaConfig} from '../../ema/interface/EmaConfig';

/**
 * MACD config
 */
export interface MacdConfig {
  /** Fast EMA line */
  emaFastConfig: EmaConfig;
  /** slow EMA line */
  emaSlowConfig: EmaConfig;
  /** signal line */
  signalConfig: EmaConfig;
}

export const initMacdConfig: MacdConfig = {
  emaFastConfig: initEmaConfig,
  emaSlowConfig: initEmaConfig,
  signalConfig: initEmaConfig,
};

export const zMacdConfig: ZodType<MacdConfig> = object({
  emaFastConfig: zEmaConfig,
  emaSlowConfig: zEmaConfig,
  signalConfig: zEmaConfig,
});

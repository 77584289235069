import {z} from 'zod';

/**
 * percentage ma output data type
 */
export interface PctMaOutput {
  /** slope of line */
  slope: number;
  /** first data point of line, index 0 */
  first: number;
  /** last data point of line, index = length-1 */
  last: number;
  /** percentage of (last-middle)/middle*100, can be positive or negative, middle is average of first and last */
  pct: number;
}

export const initPctMaOutput: PctMaOutput = {
  slope: 0,
  first: 0,
  last: 0,
  pct: 0,
};

export const zPctMaOutput: z.ZodType<PctMaOutput> = z.object({
  slope: z.number(),
  first: z.number().nonnegative(),
  last: z.number().nonnegative(),
  pct: z.number(),
});

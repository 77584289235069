import {Grid} from '@mui/material';
import SummaryRowConfig, {SummaryRowConfigProps} from './SummaryRowConfig';
import SummaryRowData, {SummaryRowDataProps} from './SummaryRowData';
import SummaryRowTitle, {SummaryRowTitleProps} from './SummaryRowTitle';

export interface SummaryRowProps extends SummaryRowDataProps, SummaryRowTitleProps, SummaryRowConfigProps {}

export default function SummaryRow(props: SummaryRowProps) {
  const {name, copyScale, positions, updateFn} = props;
  return (
    <Grid item>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <SummaryRowTitle name={name} />
        </Grid>
        <Grid item>
          <SummaryRowConfig name={name} copyScale={copyScale} updateFn={updateFn} />
        </Grid>
        <Grid item>
          <SummaryRowData positions={positions} />
        </Grid>
      </Grid>
    </Grid>
  );
}

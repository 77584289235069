import {array, number, object, ZodType} from 'zod';

/**
 * EMA config
 */
export interface EmaConfig {
  /** Period parameter for ema */
  period: number;
  /** Calculated exponent parameter for ema */
  exponent: number;
  /** Previous point EMA, defined once it reaches period+1 */
  previousEma: number | null;
  /** previous values */
  values: number[];
}

export const initEmaConfig: EmaConfig = {
  period: 0,
  exponent: 0,
  previousEma: null,
  values: [],
};

export const zEmaConfig: ZodType<EmaConfig> = object({
  period: number().nonnegative(),
  exponent: number().nonnegative(),
  previousEma: number().nonnegative().nullable(),
  values: array(number()),
});

import {number, object} from 'zod';

/**
 * PreviousHighLow output
 */
export interface PreviousHighLowOutputV3 {
  /** highest high during period */
  high: number;
  /** lowest low during period */
  low: number;
}

export const zPreviousHighLowOutputV3 = object({high: number(), low: number()});

export const initPreviousHighLowOutputV3 = {high: 0, low: 0};

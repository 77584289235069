export enum IndicatorTypeEnum {
  EMA = 'ema',
  SMA = 'sma',
  SD = 'sd',
  MACD = 'macd',
  CCI = 'cci',
  ATR = 'atr',
  RSI = 'rsi',
  BOLLINGER_BAND = 'bb',
  SUPER_TREND = 'superTrend',
  PSAR = 'psar',
  STOCHASTIC = 'stochastic',
  PREVIOUS_HL = 'previousHL',
  /** own strategy to identify trend */
  PCT_MA = 'pctMa',
  NEIGHBOUR_BAR_RANGING = 'neighbourBarRanging',
  TEMPLATE = 'template',
}

import {IgUserToken} from '../create/createIgUserToken';

/**
 * Check if user token is logged in
 * @param userToken
 * @returns
 */
export function isIgLogin(userToken: IgUserToken) {
  return !!userToken.clientToken;
}

import {IPositionV2} from '@algo/model';

/**
 * Calculate risk based on stoploss
 * Same asset with one long and one short will result in 2x risk instead of cancel out
 * @param positions
 * @returns
 */
export default function positionCalculateLimitRisk(positions: IPositionV2[]) {
  return positions.reduce((prev, cur) => prev + Math.abs((cur.open - (cur.stop || 0)) * cur.size), 0);
}

import {sleepMs} from '@coolcolduk/util';
import {createIgUserTokenKey, IgUserTokenKey} from '../create';
import {IgUserToken} from '../create/createIgUserToken';
import {loginIgSession} from '../session/loginIgSession';
import {switchIgSession} from '../session/switchIgSession';
import {isIgTokenValid} from '../status/isIgTokenValid';

/**
 * Login and switch to required account
 * @description
 * when token is already logged in/valid, only switches account
 * when token is invalid/not logged in/expired, tries to login
 * @param userToken
 * @param username
 * @param password
 * @param accountId
 * @returns
 */
export async function igHelperLoginSwitchSession(
  userToken: IgUserToken | IgUserTokenKey,
  username: string,
  password: string,
  accountId: string,
) {
  console.debug('igHelperLoginSwitchSession');
  // token invalid or failed getting ig session, tries to login
  const loginResult = isIgTokenValid(userToken)
    ? (userToken as IgUserToken)
    : (await loginIgSession(createIgUserTokenKey(userToken.apiKey, userToken.isLive), username, password)).token;

  if (!loginResult) return undefined;

  // check account and switch if required
  if (loginResult.accountId === accountId) return loginResult;

  // switch account if required
  await sleepMs(300);
  const switchResult = await switchIgSession(loginResult, accountId);
  return switchResult.data ? switchResult.token : undefined;
}

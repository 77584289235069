import {UtilObject} from '@algo/util';
import {gql, useQuery} from '@apollo/client';
import {useEffect} from 'react';
import {AlgoCopyConfigObject, AlgoStageEnum} from '../__generated__/resolvers-types';

const QUERY = gql`
  query Query($stage: AlgoStageEnum!) {
    getAlgoCopyConfig(stage: $stage) {
      id
      data
    }
  }
`;

/**
 * algo copy sizing config
 * @param stage
 * @returns
 */
export default function useQueryAlgoCopyConfig(stage: AlgoStageEnum, reloadTrigger?: string): Record<string, number> {
  const {data, refetch} = useQuery<{getAlgoCopyConfig: AlgoCopyConfigObject[]}>(QUERY, {variables: {stage}});

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [reloadTrigger]);

  if (!data) return {};

  return UtilObject.fromArray(data.getAlgoCopyConfig);
}

import {IgPriceCandle} from '@algo/broker-ig';
import {calculateIgPriceDetailMid} from '@algo/helper';
import dayjs from 'dayjs';
import {CandleStickData} from './CandleStickData';

export default function mapIgPriceCandleToVictoryCandlestick(candle: IgPriceCandle): CandleStickData {
  return {
    x: dayjs(candle.snapshotTime).toDate(),
    close: calculateIgPriceDetailMid(candle.closePrice),
    open: calculateIgPriceDetailMid(candle.openPrice),
    low: calculateIgPriceDetailMid(candle.lowPrice),
    high: calculateIgPriceDetailMid(candle.highPrice),
  };
}

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {SdOutputV2, zSdOutputV2} from './SdOutputV2';

/**
 * SD config
 */
export interface SdConfigV2 extends IndicatorConfig<SdOutputV2, number> {
  type: 'sdConfigV2';
  /** Period of sd */
  period: number;
  hloc: IndicatorCandlePriceEnum;
}

// export const initSdConfigV2: SdConfigV2 = {
//   ...initIndicatorConfig,
//   period: 0,
// };

export const zSdConfigV2 = zIndicatorConfig(zSdOutputV2, number()).merge(
  object({
    type: literal('sdConfigV2').default('sdConfigV2'),
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<SdConfigV2, any, any>;

export const initSdConfigV2 = zSdConfigV2.parse({});

import {nativeEnum, object, z, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

export interface SdInitV2 extends IndicatorInit {
  period: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zSdInitV2 = zIndicatorInit.merge(
  object({
    period: z.number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<SdInitV2, any, any>;

export const initSdInitV2 = zSdInitV2.parse({});

import {Grid, Typography} from '@mui/material';

export interface SummaryRowDataFieldProps {
  name: string;
  value: string;
  isPositive?: boolean;
}

function getBackgroundColor(isPositive?: boolean) {
  if (isPositive === undefined) return {};
  return {backgroundColor: isPositive ? 'green' : 'red', color: 'white'};
}

export default function SummaryRowDataField(props: SummaryRowDataFieldProps) {
  const {name, value, isPositive} = props;

  return (
    <Grid container direction="row" spacing="5">
      <Grid item>
        <Typography>{name}:</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{...getBackgroundColor(isPositive), paddingLeft: '5px', paddingRight: '5px'}}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

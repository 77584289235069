import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import convertStrToInt from '../../common/util/convertStrToInt';
import {smaCandle} from './smaCandle';

/**
 * Function to calculate a range of sma values given candles and cmd
 * @param cmd sma_period_hloc e.g. sma_25_2
 * @param candles
 * @returns
 */
export function smaCmd(cmd: string, candles: IndicatorCandle[]): number[] | undefined {
  const cmdArr = cmd.split('_');
  if (cmdArr[0] !== IndicatorTypeEnum.SMA) return undefined;
  return smaCandle(candles, convertStrToInt(cmdArr[1]), convertStrToInt(cmdArr[2]));
}

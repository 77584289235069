import {IndicatorReturn} from '../../common/interface/IndicatorReturn';
import {emaCalculate} from '../ema/emaCalculate';
import {MacdConfig} from './interface/MacdConfig';
import {MacdOutput} from './interface/MacdOutput';

/**
 * Calculate next macd value
 * @param config new config
 * @param nextPrice
 * @returns
 */
export function macdCalculate(config: MacdConfig, nextPrice: number): IndicatorReturn<MacdConfig, MacdOutput> {
  const fastCalculation = emaCalculate(config.emaFastConfig, nextPrice);
  const slowCalculation = emaCalculate(config.emaSlowConfig, nextPrice);
  const macd = fastCalculation.result - slowCalculation.result;

  // signal calculation
  const signalCalculation = emaCalculate(config.signalConfig, macd);
  const signal = signalCalculation.result;
  const histogram = macd - signal;

  return {
    config: {
      ...config,
      emaFastConfig: fastCalculation.config,
      emaSlowConfig: slowCalculation.config,
      signalConfig: signalCalculation.config,
    },
    result: {
      macd,
      signal,
      histogram,
    },
  };
}

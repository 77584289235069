/**
 * filter all keys with value match remove array from object and return new object
 * @param obj object to check
 * @param remove values to remove
 * @returns new object with no undefined/null value
 * @description the result may no longer match interface
 */
export function filterValue(obj: Record<string, any>, remove: any[]) {
  return Object.keys(obj).reduce(
    (acc: Record<string, any>, cur: string) =>
      remove.indexOf(obj[cur]) < 0 ? ({...acc, [cur]: obj[cur]} as Record<string, any>) : acc,
    {} as Record<string, any>,
  );
}

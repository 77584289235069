import {IgPriceCandle} from '@algo/broker-ig';
import {IPositionV2} from '@algo/model';
import {iPositionV2HasLimit} from '../../status/IPositionV2/iPositionV2HasLimit';
import {isIPositionV2Long} from '../../status/IPositionV2/isIPositionV2Long';

/**
 * Calculate if position triggered Limit price
 * @returns Limit price if candle reaches Limit, undefined otherwise
 */
export function calculateIPositionV2LimitTrigger(position: IPositionV2, candle: IgPriceCandle): number | undefined {
  if (!iPositionV2HasLimit(position)) return undefined;
  if (isIPositionV2Long(position)) {
    return candle.lowPrice.bid > position.limit ? position.limit : undefined;
  }
  return candle.highPrice.ask < position.limit ? position.limit : undefined;
}

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {createIgUserToken, IgUserToken} from '../create/createIgUserToken';
import {IgSendRequestBody} from '../interface/internal/IgSendRequestBody';

export interface IgSessionPutV2Request extends IgSendRequestBody {
  accountId: string;
  defaultAccount?: boolean;
}

export interface IgSessionPutV1Response {
  dealingEnabled: boolean;
  hasActiveDemoAccounts: boolean;
  hasActiveLiveAccounts: boolean;
  trailingStopsEnabled: boolean;
}

/**
 * switch user account
 * @param userToken
 * @param accountId
 * @param defaultAccount
 * @returns
 */
export async function switchIgSession(userToken: IgUserToken, accountId: string, defaultAccount?: boolean) {
  console.debug('switchIgSession');
  const body: IgSessionPutV2Request = {
    accountId,
    ...(defaultAccount ? {defaultAccount} : defaultAccount),
  };

  const result = await sendIgRequest<IgSessionPutV1Response>(
    userToken,
    '/session',
    'put',
    1,
    undefined,
    undefined,
    body,
  );

  return createIgResponse(
    result.response
      ? createIgUserToken(
          userToken.apiKey,
          userToken.isLive,
          result.response.headers['x-security-token'] || '',
          userToken.clientToken,
          accountId,
          userToken.lightstreamerEndpoint,
          userToken.expiry,
        )
      : userToken,
    result,
  );
}

import {Grid, TextField, Typography} from '@mui/material';

export interface SummaryHeaderProfitProps {
  setMaxProfit: (s: string) => void;
  setMinProfit: (s: string) => void;
}

export default function SummaryHeaderProfit(props: SummaryHeaderProfitProps) {
  const {setMaxProfit, setMinProfit} = props;

  return (
    <Grid container alignItems="center" direction="row" spacing={1}>
      <Grid item>
        <Typography>Profit</Typography>
      </Grid>
      <Grid item>
        <TextField label="minimum" name="minimum" onChange={(e) => setMinProfit(e.target.value)} />
      </Grid>
      <Grid item>
        <TextField label="maximum" name="maximum" onChange={(e) => setMaxProfit(e.target.value)} />
      </Grid>
    </Grid>
  );
}

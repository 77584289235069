import {createFirestoreUserPositionCollectionHelper} from '@algo/firebase-web';
import {IPositionV2} from '@algo/model';
import {firestoreApp} from '../firebase/app';

/**
 * helper function to update tags in position document
 * @param uid
 * @param position
 * @param tags
 * @returns
 */
export default function updatePositionTags(uid: string | undefined, position: IPositionV2, tags: string[]) {
  console.debug('updatePositionTags', tags);
  if (uid) {
    createFirestoreUserPositionCollectionHelper(firestoreApp, uid).save(
      position.id,
      {
        ...position,
        tags,
      },
      false,
    );
  }
}

import {DatetimeEnum, UtilDatetime} from '@algo/util';
import {IgUserToken, IgUserTokenKey} from '../create';

/**
 * Check if user token is still valid based on expiry and accountToken exists
 * - IgUserTokenKey is invalid
 * @param userToken
 * @returns
 */
export function isIgTokenValid(userToken: IgUserToken | IgUserTokenKey) {
  const result =
    'accountToken' in userToken &&
    userToken.accountToken &&
    'expiry' in userToken &&
    userToken.expiry > UtilDatetime.getLocalDatetime(DatetimeEnum.STANDARD_DATETIME);

  console.debug(
    'isIgTokenValid',
    (userToken as IgUserToken).expiry,
    (userToken as IgUserToken).expiry > UtilDatetime.getLocalDatetime(DatetimeEnum.STANDARD_DATETIME),
    result,
  );

  return result;
}

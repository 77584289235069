import {IndicatorCandlePriceEnum} from '../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {ema} from './ema';

/**
 * Function to calculate a range of ema values given candles
 * @param candles
 * @param period
 * @param hloc
 * @returns
 */
export function emaCandle(
  candles: IndicatorCandle[],
  period?: number,
  hloc = IndicatorCandlePriceEnum.CLOSE,
): number[] {
  return ema(candles.map(mapIndicatorCandleToNumberArr(hloc)), period);
}

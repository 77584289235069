import {z} from 'zod';
import {IgDealStatusEnum} from '../../enum/IgDealStatusEnum';
import {IgDirectionEnum} from '../../enum/IgDirectionEnum';
import {IgStreamPositionStatusEnum} from '../../enum/stream/IgStreamPositionStatusEnum';

/**
 * Open position updates for an account
 */
export interface IgStreamPosition {
  /** User channel (do not bind to this value - it will be converted to a constant enum) */
  channel: string;
  /** Deal identifier */
  dealId: string;
  /** Deal identifier of the originating deal */
  dealIdOrigin: string;
  /** Deal reference */
  dealReference: string;
  /** Deal status */
  dealStatus: IgDealStatusEnum;
  /** position direction */
  direction: IgDirectionEnum;
  /** Instrument EPIC identifier */
  epic: string;
  /** Instrument expiry */
  expiry: string;
  /** True if a guaranteed stop is in place */
  guaranteedStop: boolean;
  /** Trade level */
  level: number;
  /** Limit level */
  limitLevel: number | null;
  /** Trade size */
  size: number;
  /** Position status */
  status: IgStreamPositionStatusEnum;
  /** Stop level */
  stopLevel: number | null;

  /** Event date and time */
  timestamp?: string;
  /** Currency */
  currency?: string | null;
  /** Trailing stop distance */
  trailingStopDistance?: number | null;
  /** Trailing stop increment */
  trailingStep?: number | null;
}

export const zIgStreamPosition: z.ZodType<IgStreamPosition> = z.object({
  channel: z.string(),
  dealId: z.string(),
  dealIdOrigin: z.string(),
  dealReference: z.string(),
  dealStatus: z.nativeEnum(IgDealStatusEnum),
  direction: z.nativeEnum(IgDirectionEnum),
  epic: z.string(),
  expiry: z.string(),
  guaranteedStop: z.boolean(),
  level: z.number(),
  limitLevel: z.number().nullable(),
  size: z.number(),
  status: z.nativeEnum(IgStreamPositionStatusEnum),
  stopLevel: z.number().nullable(),
  timestamp: z.string().optional(),
  currency: z.string().nullable().optional(),
  trailingStopDistance: z.number().optional().nullable(),
  trailingStep: z.number().optional().nullable(),
});

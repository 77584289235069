import {IndicatorConfigV3} from './IndicatorConfigV3';
import {IndicatorV3} from './IndicatorV3';

/**
 * Calculate an array of input
 * @param template
 * @param config
 * @param input
 * @returns
 */
export function calculateIndicatorV3Series<
  Config extends IndicatorConfigV3<Output, Input>,
  Output = number,
  Init = Config,
  Input = number,
>(indicator: IndicatorV3<Config, Output, Init, Input>, config: Config, input: Input[]) {
  let localConfig = {...config};
  const output: Output[] = [];

  input.forEach((i) => {
    const result = indicator.nextValue(localConfig, i);
    localConfig = result.config;
    output.push(result.result);
  });

  return {result: output, config: localConfig};
}

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {EmaOutputV2, zEmaOutputV2} from './EmaOutputV2';

export interface EmaConfigV2 extends IndicatorConfig<EmaOutputV2, number> {
  type: 'emaConfigV2';
  period: number;
  hloc: IndicatorCandlePriceEnum;
  exponent: number;
}

export const zEmaConfigV2 = zIndicatorConfig(zEmaOutputV2, number()).merge(
  object({
    type: literal('emaConfigV2').default('emaConfigV2'),
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
    exponent: number().nonnegative().default(0),
  }),
) satisfies ZodType<EmaConfigV2, any, any>;

// export type EmaConfigV2 = output<typeof zEmaConfigV2>;

export const initEmaConfigV2 = zEmaConfigV2.parse({});

import {z} from 'zod';
import {IgDealStatusEnum} from '../../enum/IgDealStatusEnum';
import {IgDirectionEnum} from '../../enum/IgDirectionEnum';
import {IgStreamConfirmStatusEnum} from '../../enum/stream/IgStreamConfirmStatusEnum';
import {IgPositionAffectedDeal, zIgPositionAffectedDeal} from '../position/IgPositionAffectedDeal';

/**
 * Trade confirmation for an account
 */
export interface IgStreamConfirm {
  /** Deals affected */
  affectedDeals: IgPositionAffectedDeal[];
  /** Deal identifier */
  dealId: string;
  /** Deal reference */
  dealReference: string;
  /** Deal status */
  dealStatus: IgDealStatusEnum;
  /** order direction */
  direction: IgDirectionEnum;
  /** Instrument EPIC identifier */
  epic: String;
  /** Instrument expiry */
  expiry: string;
  /** True if a guaranteed stop is in place */
  guaranteedStop: boolean;
  /** Trade level */
  level: number;
  /** Limit level */
  limitLevel: number | null;
  /** Trade size */
  size: number;
  /** Resultant position or working order status */
  status: IgStreamConfirmStatusEnum;
  /** Stop level */
  stopLevel: number | null;
}

export const zIgStreamConfirm: z.ZodType<IgStreamConfirm> = z.object({
  affectedDeals: z.array(zIgPositionAffectedDeal),
  dealId: z.string(),
  dealReference: z.string(),
  dealStatus: z.nativeEnum(IgDealStatusEnum),
  direction: z.nativeEnum(IgDirectionEnum),
  epic: z.string(),
  expiry: z.string(),
  guaranteedStop: z.boolean(),
  level: z.number(),
  limitLevel: z.number().nullable(),
  size: z.number(),
  status: z.nativeEnum(IgStreamConfirmStatusEnum),
  stopLevel: z.number().nullable(),
});

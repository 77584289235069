import {literal, number, object, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {SmaConfigV2, zSmaConfigV2} from '../../../movingAverage/sma/interface/SmaConfigV2';
import {SmaClassV2} from '../../../movingAverage/sma/SmaClassV2';
import {CciOutputV2, zCciOutputV2} from './CciOutputV2';

export interface CciConfigV2 extends IndicatorConfig<CciOutputV2, IndicatorCandle> {
  type: 'cciConfigV2';
  period: number;
  smaConfig: SmaConfigV2;
}

export const zCciConfigV2 = zIndicatorConfig(zCciOutputV2, zIndicatorCandle).merge(
  object({
    type: literal('cciConfigV2').default('cciConfigV2'),
    period: number().min(3).default(20),
    smaConfig: zSmaConfigV2.default(new SmaClassV2({period: 20}).getConfig()),
  }),
) satisfies ZodType<CciConfigV2, any, any>;

// export type CciConfigV2 = output<typeof zCciConfigV2>;

export const initCciConfigV2 = zCciConfigV2.parse({});

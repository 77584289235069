import {IPositionV2} from '@algo/model';
import {DataGrid, GridPaginationModel} from '@mui/x-data-grid';
import {useState} from 'react';
import updatePositionTags from '../../logic/helper/data/updatePositionTags';
import positionSortByCloseTs from '../../logic/helper/position/sort/positionSortByCloseTs';
import {analysisTableColumns} from './analysisTableColumns';

export interface AnalysisTableProps {
  uid: string;
  closedPositions: IPositionV2[];
  /** show position detail in popup dialog */
  showPositionDetail: (position: IPositionV2 | undefined) => void;
  /** show position on graph */
  showPositionOnGraph: (position: IPositionV2) => void;
}

export default function AnalysisTable(props: AnalysisTableProps) {
  const {uid, closedPositions, showPositionDetail, showPositionOnGraph} = props;

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize: 10});

  const setTags = (position: IPositionV2, tags: string[]) => updatePositionTags(uid, position, tags);

  // descending closed position
  const descPositions = positionSortByCloseTs(closedPositions, false);
  return (
    <DataGrid<IPositionV2>
      sx={{minHeight: 600}}
      rows={descPositions}
      columns={analysisTableColumns(setTags, showPositionDetail, showPositionOnGraph)}
      paginationModel={paginationModel}
      onPaginationModelChange={(model) => setPaginationModel(model)}
      pageSizeOptions={[10, 25, 50, 100]}
      disableRowSelectionOnClick
      getRowId={(row) => row.id}
      getRowHeight={() => 80}
    />
  );
}

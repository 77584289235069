import {BaseGraphProps} from '../BaseGraphProps';
import {GraphTypeEnum} from '../GraphTypeEnum';
import {LineGraphData} from './LineGraphData';

export interface LineGraphAtomProps extends BaseGraphProps {
  graphType: GraphTypeEnum.LINE;
  data: LineGraphData[];
}

export default function createLineGraphAtomProps(
  name: string,
  graphId: string,
  data: LineGraphData[],
  color?: string,
): LineGraphAtomProps {
  return {name, graphId, data, color, graphType: GraphTypeEnum.LINE};
}

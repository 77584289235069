import {object, z, ZodType} from 'zod';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

export interface AtrInitV2 extends IndicatorInit {
  period: number;
}

export const zAtrInitV2 = zIndicatorInit.merge(
  object({
    period: z.number().min(3).default(20),
  }),
) satisfies ZodType<AtrInitV2, any, any>;

export const initAtrInitV2 = zAtrInitV2.parse({});

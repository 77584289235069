import {PSAR} from './PsarClass';

/**
 * Function to calculate a range of PSAR
 */
export function psar(high: number[], low: number[], step?: number, max?: number, start?: number) {
  if (high.length !== low.length) {
    throw new Error(`psar high and low has to be of the same length. High is ${high.length} and low ${low.length}`);
  }

  const calculation = new PSAR(step, max, start);
  return low.map((_, i) => calculation.next(high[i] as number, low[i] as number));
}

import {IPositionV2} from '../../../__generated__/resolvers-types';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';
import mapIMatchAlgoPositionToMatched from './mapIMatchAlgoPositionToMatched';
import mapIPositionV2ToNotRecorded from './mapIPositionV2ToNotRecorded';
import mapIPositionV2ToOrphan from './mapIPositionV2ToOrphan';

function matchIg(fsPositions: IMatchAlgoPosition[], referencePositions: IPositionV2[]): IMatchAlgoPosition[] {
  return fsPositions.map((p) => {
    const findPosition = referencePositions.find((r) => r.id === p.monitorPosition?.id || r.id === p.docId);
    if (!findPosition) return p;
    return mapIMatchAlgoPositionToMatched(p, findPosition);
  });
}

function appendMissingIgPosition(fsPositions: IMatchAlgoPosition[], igPositions: IPositionV2[]): IMatchAlgoPosition[] {
  const existingPositionsDealId = fsPositions.map((p) => p.referencePosition?.id).filter((p) => !!p) as string[];
  const missingPosition = igPositions.filter((p) => !existingPositionsDealId.includes(p.id));
  return [...fsPositions, ...missingPosition.map(mapIPositionV2ToOrphan)];
}

/**
 * Map collection docs to IMatchAlgoPosition array
 * - orphan by default
 * @param algoName
 * @param positions
 * @returns
 */
function mapDocRecToIMatchAlgoOrphanArr(
  algoName: string,
  positions: Record<string, IPositionV2>,
): IMatchAlgoPosition[] {
  const result = Object.entries(positions).reduce(
    (prev, [docId, position]) => [...prev, mapIPositionV2ToNotRecorded(algoName, docId, position)],
    [] as IMatchAlgoPosition[],
  );

  return result;
}

/**
 * pre-process data to IMatchAlgoPosition array
 * @param positions
 * @returns
 */
function mapToIMatchAlgoOrphanArr(positions: Record<string, Record<string, IPositionV2>>) {
  // loop through individual algo
  const strategyPosition = Object.entries(positions).reduce(
    (prev, [algoName, positionRecord]) => [...prev, ...mapDocRecToIMatchAlgoOrphanArr(algoName, positionRecord)],
    [] as IMatchAlgoPosition[],
  );
  return strategyPosition;
}

/**
 * Logic to build and match the algo position
 * @param modifyingPosition records to be optimised / removed / updated
 * @param referencePositions records as reference, changes are not required or not from this app
 * @returns
 */
export default function buildIMatchAlgoPosition(
  modifyingPosition: Record<string, Record<string, IPositionV2>>,
  referencePositions: IPositionV2[],
): IMatchAlgoPosition[] {
  // everything to not recorded by default
  const orphanArr = mapToIMatchAlgoOrphanArr(modifyingPosition);

  // identify ig match
  const igMatchedArr = matchIg(orphanArr, referencePositions);

  // unmatched position in ig/reference
  return appendMissingIgPosition(igMatchedArr, referencePositions);
}

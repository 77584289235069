import {bollingerBandCalculate} from './bollingerBandCalculate';
import {bollingerBandInit} from './bollingerBandInit';
import {BollingerBandConfig} from './interface/BollingerBandConfig';

/**
 * Calculate simple deviation
 */
export class BollingerBand {
  config: BollingerBandConfig;

  constructor(period?: number, numberOfSd?: number) {
    this.config = bollingerBandInit(period, numberOfSd);
  }

  next(nextValue: number) {
    const result = bollingerBandCalculate(this.config, nextValue);
    this.config = result.config;
    return result.result;
  }
}

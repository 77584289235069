import {VictoryArea, VictoryAxis, VictoryChart} from 'victory';
import {DomainPropType} from 'victory-core';

export interface SummaryGraphData {
  x: number;
  y: number;
}

export interface SummaryGraphProps {
  data: SummaryGraphData[];
  width?: number;
  height?: number;
  xDomain?: DomainPropType | undefined;
  yDomain?: DomainPropType | undefined;
  xTick?: any[];
  yTick?: any[];
}

export default function SummaryGraph(props: SummaryGraphProps) {
  const {data, width = 250, height = 200, xDomain, yDomain, xTick, yTick} = props;
  return (
    <VictoryChart height={height} width={width} domainPadding={0} padding={{top: 20, bottom: 30, right: 20, left: 50}}>
      <VictoryArea data={data} height={height} width={width} />

      <VictoryAxis
        crossAxis
        width={width}
        height={height}
        standalone={false}
        tickValues={xTick}
        domain={xDomain}
        style={{
          axis: {stroke: '#ffffff'},
        }}
      />

      <VictoryAxis
        dependentAxis
        crossAxis
        width={width}
        height={height}
        tickValues={yTick}
        domain={yDomain}
        // domain={[-10, 10]}
        // offsetX={50}
        standalone={false}
      />
      {/* <VictoryAxis tickFormat={() => ''} height={0} width={0} /> */}
    </VictoryChart>
  );
}

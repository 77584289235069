import {createFirestoreStrategyUnknownCollectionHelper} from '@algo/firebase-web';
import {firestoreApp} from '../../../logic/helper/firebase/app';
import getAlgoCopierName from '../../../logic/helper/getAlgoCopierName';
import {MatchAlgoOptionsEnum} from '../enum/MatchAlgoOptionsEnum';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

/**
 * Remove the firestore record only
 * @param selection
 * @param matchPosition
 */
export default function removeMatchAlgoCopierFsRecord(
  selection: MatchAlgoOptionsEnum,
  matchPosition: IMatchAlgoPosition,
) {
  // save closed position to firestore
  return async () => {
    if (selection !== MatchAlgoOptionsEnum.ALGO_PAPER_DEV && selection !== MatchAlgoOptionsEnum.ALGO_PAPER_PROD) return;

    createFirestoreStrategyUnknownCollectionHelper(
      firestoreApp,
      getAlgoCopierName(selection === MatchAlgoOptionsEnum.ALGO_PAPER_PROD),
      matchPosition.algoName,
    ).del(matchPosition.docId);
  };
}

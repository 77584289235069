import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import {getIndicatorV3Input} from '../../common/template/v3/getIndicatorV3Input';
import mapIndicatorV3InputToCandle from '../../common/template/v3/mapIndicatorV3InputToCandle';
import convertStrToFloat from '../../common/util/convertStrToFloat';
import {PsarConfigV3, zPsarConfigV3} from './interface/PsarConfigV3';
import {PsarInitV3, zPsarInitV3} from './interface/PsarInitV3';
import {PsarOutputV3} from './interface/PsarOutputV3';
import psarCalculateAccelerateExtreme from './psarCalculateAccelerateExtreme';
import psarCalculateSar from './psarCalculateSar';
import psarIsChangeTrend from './psarIsChangeTrend';
import psarIsFirstDatapoint from './psarIsFirstDatapoint';

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<PsarInitV3>) {
  const parsedInit = zPsarInitV3.strict().parse(init);

  return zPsarConfigV3.strip().parse({
    ...parsedInit,
    accelerationFactorStep: parsedInit.step,
    accelerationFactorMax: parsedInit.max,
    accelerationFactorStart: parsedInit.start,
    maxInputLength: 1,
  });
}

/**
 * Calculate Psar
 * @param config
 * @returns
 */
function calculate(config: PsarConfigV3): IndicatorReturnV3<PsarConfigV3, PsarOutputV3, IndicatorCandle> {
  // const currentBar: IndicatorCandle = {high, low, close: 0, open: 0};
  const currentBar = getIndicatorV3Input<PsarConfigV3, PsarOutputV3, IndicatorCandle>(config);

  // first data point
  if (psarIsFirstDatapoint(config)) {
    return {
      config: {
        ...config,
        furthestBar: currentBar,
        previousBar: currentBar,
        isUptrend: true,
        sar: currentBar.low,
        extreme: currentBar.high,
        accelerationFactor: config.accelerationFactorStart,
      },
      result: currentBar.low,
    };
  }

  // calculate next set of values
  const sar = psarCalculateSar(config);
  const other = psarCalculateAccelerateExtreme(config, currentBar);
  const isChangeTrend = psarIsChangeTrend(config, currentBar, sar);

  if (isChangeTrend) {
    return {
      config: {
        ...config,
        furthestBar: config.previousBar,
        previousBar: currentBar,
        accelerationFactor: config.accelerationFactorStep,
        sar: other.extreme,
        isUptrend: !config.isUptrend,
        extreme: config.isUptrend ? currentBar.low : currentBar.high,
      },
      result: config.extreme,
    };
  }
  return {
    config: {
      ...config,
      furthestBar: config.previousBar,
      previousBar: currentBar,
      sar,
      ...other,
    },
    result: sar,
  };
}

/**
 * Psar indicator
 */
export const psarV3 = createIndicatorV3(
  IndicatorTypeEnum.PSAR,
  createConfig,
  calculate,
  (s) =>
    zPsarInitV3.parse({
      step: convertStrToFloat(s[0]),
      max: convertStrToFloat(s[1]),
      start: convertStrToFloat(s[2]),
    }),
  mapIndicatorV3InputToCandle,
  zPsarConfigV3,
);

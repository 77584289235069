import {number, object, ZodType} from 'zod';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface PreviousHighLowInitV3 extends IndicatorInitV3 {
  period: number;
}

export const zPreviousHighLowInitV3 = zIndicatorInitV3.merge(
  object({
    period: number().min(2).default(20),
  }),
) satisfies ZodType<PreviousHighLowInitV3, any, any>;

export const initPreviousHighLowInitV3 = zPreviousHighLowInitV3.parse({});

import {z} from 'zod';

export interface IgPageMetaDataDetail {
  // example "/history/activity?version=3&from=2022-12-27T12:00:00&to=2022-12-27T14:21:13&detailed=false&pageSize=10"
  /** Next page */
  next: string | null;
  /** Page size */
  size: number;
}

export const zIgPageMetaDataDetail: z.ZodType<IgPageMetaDataDetail> = z.object({
  next: z.string().nullable(),
  size: z.number(),
});

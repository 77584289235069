import {StochasticOutput, initStochasticOutput, zStochasticOutput} from './StochasticOutput';

/**
 * Stochastic output
 */
export type StochasticOutputV2 = StochasticOutput;

export const zStochasticOutputV2 = zStochasticOutput;

export const initStochasticOutputV2 = initStochasticOutput;

import {Menu, MenuItem} from '@mui/material';

export interface DropdownListMenuProps<T extends string> {
  /** unique identifier for function of dropdownl list menu */
  label: string;

  /** items available in dropdown list for selection */
  items: T[];

  /** click hander on menu item */
  onClickHandle: (name: T) => void;

  /** Anchor to display list */
  anchorEl: null | HTMLElement;

  /** handle to close menu */
  onCloseHandle: () => void;
}

/**
 * Create a button that pop a dropdown when clicked for selection
 * @param props
 * @returns
 */
export default function DropdownListMenu<T extends string>(props: DropdownListMenuProps<T>) {
  const {onClickHandle, items, label, onCloseHandle, anchorEl} = props;
  const open = !!anchorEl;

  return (
    <Menu
      id={`DropdownListOrganism-${label}`}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onCloseHandle}
    >
      {items.map((item) => (
        <MenuItem
          key={`DropdownListMenu-${item}`}
          onClick={() => {
            onCloseHandle();
            onClickHandle(item);
          }}
        >
          {item}
        </MenuItem>
      ))}
    </Menu>
  );
}

import {gql, useQuery} from '@apollo/client';
import {House} from '../__generated__/resolvers-types';

const QUERY = gql`
  query Query {
    getHouses {
      additionalHome
      cash
      fee
      firstTimeBuyer
      name
      oversea
      price
      ratePct
      termsYrs
    }
  }
`;

/**
 * Load all house for user
 * @param uid
 * @returns all houses for user
 */
export default function useQueryUserHouse(): Record<string, House> {
  const {data} = useQuery<{getHouses: House[]}>(QUERY);
  return Object.fromEntries((data?.getHouses || []).map((h) => [h.name, h]));
}

import {IgPriceCandle, IgPriceResolutionEnum} from '@algo/broker-ig';
import {getPathPriceEpicResolutionCollection} from '@algo/firebase-common';
import {CollectionReference, Firestore, collection} from 'firebase/firestore';

/**
 * collection for price candle
 * @returns
 */
export function getReferencePriceEpicResolutionCollection(
  firestoreApp: Firestore,
  epic: string,
  resolution: IgPriceResolutionEnum,
) {
  return collection(
    firestoreApp,
    getPathPriceEpicResolutionCollection(epic, resolution),
  ) as CollectionReference<IgPriceCandle>;
}

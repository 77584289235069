import {IUserHouse} from '@algo/firebase-common';
import {Box, Grid, TextField, Typography} from '@mui/material';
import calculateMonthlyRepayment from '../../logic/mortgage/calculateMonthlyRepayment';
import calculateStampDuty from '../../logic/mortgage/calculateStampDuty';

export interface MortgageCalculatorHouseProps {
  formData: IUserHouse;

  setFormData: (k: string, v: string | boolean) => void;
}

/**
 * Show analysis page to do analysis
 * @returns
 */
export default function MortgageCalculatorHouse(props: MortgageCalculatorHouseProps) {
  const {formData, setFormData} = props;

  // convert to numbers
  const iPrice = parseFloat(formData.price);
  const iCash = parseFloat(formData.cash);
  const iFee = parseFloat(formData.fee);
  const iInterest = parseFloat(formData.ratePct);
  const iTermsYears = parseFloat(formData.termsYrs);

  // cash available to buy house
  const cashForHouse = iCash - iFee;
  const stampDuty = calculateStampDuty(
    parseFloat(formData.price),
    formData.firstTimeBuyer,
    formData.additionalHome,
    formData.oversea,
  );
  const deposit = cashForHouse - stampDuty;

  const mortgage = iPrice - deposit > 0 ? iPrice - deposit : 0;
  const ltv = Math.floor((mortgage / iPrice) * 100);

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography typography="title">House</Typography>
        </Grid>
        <Grid item>
          <Typography>Cash available after fees: {cashForHouse}</Typography>
        </Grid>
        <Grid item>
          <TextField
            label="Price"
            value={formData.price}
            inputProps={{type: 'number', min: 0, step: 10000}}
            onChange={(e) => setFormData('price', e.target.value.length ? e.target.value : '0')}
          />
        </Grid>
        <Grid item>
          <Typography>Stamp duty: {stampDuty}</Typography>
        </Grid>
        <Grid item>
          <Typography>Net deposit: {deposit}</Typography>
        </Grid>
        <Grid item>
          <Typography>Mortgage: {mortgage}</Typography>
        </Grid>
        <Grid item>
          <Typography>LTV: {ltv} %</Typography>
        </Grid>
        <Grid item>
          <Typography>Repayment: {calculateMonthlyRepayment(mortgage, iInterest, iTermsYears).toFixed(0)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

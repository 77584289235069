import {castArray} from '@coolcolduk/util';
import {CollectionReference, getDocs, Query, query, QueryConstraint} from 'firebase/firestore';

/**
 * Firestore helper to load all docs in given collection that matches the constrains
 */
export async function firestoreLoadAllDocs<T extends Record<string, any>>(
  collection: CollectionReference<T>,
  constrains: QueryConstraint | QueryConstraint[] = [],
): Promise<Record<string, T>> {
  const constrainsArr = castArray(constrains);
  const q: Query<T> = query(collection, ...constrainsArr);
  const querySnapshot = await getDocs(q);

  const result: Record<string, T> = {};
  querySnapshot.forEach((doc) => {
    result[doc.id] = doc.data();
  });
  return result;
}

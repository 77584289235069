import {filterArrayUndefined, mapPromiseFnSeries, sleepMs} from '@coolcolduk/util';
import {IgUserToken} from '../create/createIgUserToken';
import {IgDirectionEnum} from '../enum/IgDirectionEnum';
import {IgTimeInForceEnum} from '../enum/IgTimeInForceEnum';
import {igHelperClosePosition} from '../igHelper/igHelperClosePosition';
import {confirmIgPosition} from '../position/confirmIgPosition';

export interface IgCloseMarketBulk {
  dealId: string;
  size: number;
  direction?: IgDirectionEnum;
  timeInForce?: IgTimeInForceEnum;
}

/**
 * close market order in bulk and resolve all deal reference
 * @param userToken
 * @param orders
 * @returns
 */
export async function igServiceClosePositionDetail(userToken: IgUserToken, orders: IgCloseMarketBulk[]) {
  console.debug('igServiceClosePositionDetail');

  const orderResults = await mapPromiseFnSeries(orders, async (order) => {
    const r = await igHelperClosePosition(userToken, order.dealId, order.size, order.direction, order.timeInForce);
    await sleepMs(300);
    return r;
  });

  // get deal reference
  const confirmResult = await mapPromiseFnSeries(orderResults, async (order) => {
    const r = order && order.data ? await confirmIgPosition(userToken, order.data.dealReference) : undefined;
    await sleepMs(300);
    return r;
  });

  return filterArrayUndefined(confirmResult);
}

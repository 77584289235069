import {IgPriceCandle} from '@algo/broker-ig';
import {LineGraphData} from './LineGraphData';
import mapIgPriceCandleIndicatorToVictoryLine from './mapIgPriceCandleIndicatorToVictoryLine';

export default function mapListIgPriceCandleIndicatorToVictoryLine(
  candles: IgPriceCandle[],
  indicator: number[],
): LineGraphData[] {
  // make them equal length
  const candleSlice = candles.slice(-indicator.length);
  const indicatorSlice = indicator.slice(-candleSlice.length);
  return candleSlice.map((c, i) => mapIgPriceCandleIndicatorToVictoryLine(c, indicatorSlice[i] as number));
}

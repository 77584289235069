import {ApolloClient, gql, useQuery} from '@apollo/client';
import {BugReport} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {useCallback} from 'react';
import {IAuthState} from '../../hooks/auth/IAuth';
import createApolloClient from '../../logic/helper/gql/createApolloClient';

export interface HeaderButtonsTestProps {
  user: IAuthState;
}

export const GET_LOCATIONS = gql`
  query ExampleQuery($thingId: String!) {
    thing(id: $thingId)
  }
`;

function HeaderButtonsTest(props: HeaderButtonsTestProps) {
  // const client = useApolloClient()
  const {user} = props;

  const client = useCallback(
    () => createApolloClient(user ? {Authorization: `Bearer ${user.accessToken}`} : undefined) as ApolloClient<any>,
    [user],
  );
  const {refetch} = useQuery(GET_LOCATIONS, {
    variables: {thingId: 'sum'},
    skip: true,
    client: client(),
  });
  const handleTest = () => {
    refetch({thingId: 'fast'});
  };

  return (
    <IconButton aria-label="test btn" color="inherit" onClick={handleTest}>
      <BugReport />
    </IconButton>
  );
}

export default HeaderButtonsTest;

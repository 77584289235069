import {z} from 'zod';

/**
 * Schema for date
 * @see https://github.com/colinhacks/zod#dates
 * @deprecated currently not in used, use z.string().datetime()
 */
export const dateSchema = z.preprocess((arg) => {
  if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
  return undefined;
}, z.date()) as z.ZodEffects<z.ZodDate, Date, string | Date>;

/**
 * Date or dateString
 * @deprecated currently not in used, use z.string().datetime()
 */
export type DateSchema = z.infer<typeof dateSchema>;

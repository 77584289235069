import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {createIgUserToken, IgUserToken} from '../create/createIgUserToken';
import {generateTokenExpiry} from '../generate/generateTokenExpiry';
import {IgSendRequestBody} from '../interface/internal/IgSendRequestBody';

export interface IgSessionRefreshV1Request extends IgSendRequestBody {
  refresh_token: string;
}
export interface IgSessionRefreshV1Response {
  /** Access token */
  access_token: string;
  /** Access token expiry in seconds */
  expires_in: string;
  /** Refresh token */
  refresh_token: string;
  /** Scope of the access token */
  scope: string;
  /** Token type */
  token_type: string;
}

/**
 * refresh login token - only works with v3 login session
 * @param userToken
 * @todo need to validate to work
 * @returns
 */
export async function refreshIgSession(userToken: IgUserToken) {
  console.debug('refreshIgSession');
  const body: IgSessionRefreshV1Request = {
    refresh_token: userToken.accountToken || '',
  };
  const result = await sendIgRequest<IgSessionRefreshV1Response>(
    userToken,
    '/session/refresh-token',
    'post',
    1,
    undefined,
    undefined,
    body,
  );

  return createIgResponse(
    result.response
      ? createIgUserToken(
          userToken.apiKey,
          userToken.isLive,
          result.response.headers['x-security-token'] || '',
          result.response.headers['cst'] || '',
          userToken.accountId,
          userToken.lightstreamerEndpoint,
          generateTokenExpiry(result.response.data.expires_in),
        )
      : userToken,
    result,
  );
}

import {IUserBroker} from '@algo/firebase-common';
import {mapIgPositionGetToIPositionV2Open} from '@algo/helper';
import {IPositionV2} from '@algo/model';
import {useEffect, useRef, useState} from 'react';
import {igDownloadActivePositions} from '../logic/helper/ig';
import convertIgPositionResponseToIAssetRecord from '../logic/helper/ig/convert/convertIgPositionResponseToIAssetRecord';
import {IAssetRecord} from '../model/IAssetRecord';
import {IPositionRecordV2} from '../model/IPositionRecord';
import mergeMetadata from './mergeMetadata';

/**
 * Download data and set position and asset
 * @param brokerAccount
 * @param setPositions
 * @param setAssets
 * @param userToken
 */
const downloadData = async (
  uid: string,
  brokerAccount: IUserBroker,
  setPositions: (positions: IPositionV2[] | undefined) => void,
  setAssets: (assets: IAssetRecord | undefined) => void,
) => {
  console.debug('useLoadIgPositionsActive.downloadData');
  const result = await igDownloadActivePositions(uid, brokerAccount);

  console.debug('useLoadIgPositionsActive.downloadData', 'set data');
  const assets = result ? convertIgPositionResponseToIAssetRecord(result) : undefined;
  if (assets) setAssets(assets);
  // active position is open position, so map to IPositionV2Open
  // data from ig don't have tags, so provide empty array
  // if no result, set to empty array
  setPositions(result ? result.map((p) => mapIgPositionGetToIPositionV2Open(p)) : []);
};

/**
 * hook to get all open position from broker
 * combine with positionData from user for meta data
 * @param uid
 * @param brokerAccount broker account
 * @param positionData
 * @returns
 */
export default function useLoadIgPositionsActive(
  uid: string,
  brokerAccount: IUserBroker | undefined,
  positionData: IPositionRecordV2 = {},
): {
  positions: IPositionV2[];
  assets: IAssetRecord;
} {
  console.debug('useLoadIgPositionsActive');
  // undefined is not loaded or login, empty array means no position opened
  const [positions, setPositions] = useState<IPositionV2[] | undefined>();
  const assetsRecord = useRef<IAssetRecord | undefined>();

  useEffect(() => {
    console.debug('useLoadIgPositionsActive.useEffect');
    if (!brokerAccount) {
      console.debug('useLoadIgPositionsActive.useEffect', 'no broker');
      assetsRecord.current = undefined;
      setPositions(undefined);
    } else {
      downloadData(uid, brokerAccount, setPositions, (newAssetRecord: IAssetRecord | undefined) => {
        console.log('useLoadIgPositionsActive.useEffect', 'set asset');
        assetsRecord.current = newAssetRecord;
      });
    }
    // eslint-disable-next-line
  }, [brokerAccount]);

  // merge position with meta data
  return {
    positions: positions?.map(mergeMetadata(positionData)) || [],
    assets: assetsRecord.current || {},
  };
}

export interface IMarket {
  /** Instrument epic identifier */
  epic: string;

  /** Instrument lot size */
  lotSize: number;

  /** multiplying factor to determine actual pip value for the levels used by the instrument */
  scalingFactor: number;

  /** Bid price */
  bid: number;

  /** Offer price */
  offer: number;

  /** Highest price of the day */
  high: number;

  /** Lowest price of the day */
  low: number;

  /** Open price of the daily bar (on-going), calculated using close and netChange */
  open: number;

  /** Close price of the bar (on-going), calculated using mid of bid and offer */
  close: number;

  /** Price net change */
  netChange: number;

  /** Percentage price change on the day */
  percentageChange: number;

  /** Local time of last price update (milliseconds since epoch) */
  updateTime: string;

  /** Time of last price update */
  updateTimeUTC: string;
}

export const defaultIMarket: IMarket = {
  epic: '',
  lotSize: 1,
  scalingFactor: 1,
  bid: 0,
  offer: 0,
  high: 0,
  low: 0,
  open: 0,
  close: 0,
  netChange: 0,
  percentageChange: 0,
  updateTime: '',
  updateTimeUTC: '',
};

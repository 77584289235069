import {IndicatorCandle} from '../../interface/IndicatorCandle';
import {IndicatorConfigV3} from './IndicatorConfigV3';
import {IndicatorV3} from './IndicatorV3';

/**
 * Perform a tick calculation with new candle input
 * @param indicator
 * @param config
 * @param candle
 * @returns
 */
export function nextIndicatorV3Config<
  Config extends IndicatorConfigV3<Output, Input>,
  Output = number,
  Init = Config,
  Input = number,
>(indicator: IndicatorV3<Config, Output, Init, Input>, config: Config, candle: IndicatorCandle): Config {
  return indicator.nextValue(config, indicator.mapCandleToInput(config, candle)).config;
}

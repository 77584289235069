import {z} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';

/**
 * Stochastic config
 */
export interface StochasticConfig {
  /// ////////////////config////////////////
  /** Fast Stochastic period */
  fastPeriod: number;
  /** D line, SMA of K line */
  slowPeriod: number;
  /// ////////////////operation variable////////////////
  data: IndicatorCandle[];
  kValues: number[];
}

export const initStochasticConfig: StochasticConfig = {
  fastPeriod: 0,
  slowPeriod: 0,
  data: [],
  kValues: [],
};

export const zStochasticConfig: z.ZodType<StochasticConfig> = z.object({
  fastPeriod: z.number().min(0),
  slowPeriod: z.number().min(0),
  data: z.array(zIndicatorCandle),
  kValues: z.array(z.number()),
});

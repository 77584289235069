import {createIgUserTokenKey, igHelperLoginSwitchSession, IgUserToken} from '@algo/broker-ig';
import {ISessionIg, IUserBroker} from '@algo/firebase-common';
import {createFirestoreSessionDocumentHelper} from '@algo/firebase-web';
import {mapSessionToUserToken, mapUserTokenToSession} from '@algo/helper';
import {UtilObject} from '@algo/util';
import {sleepMs} from '@coolcolduk/util';
import {firestoreApp} from '../firebase/app';

export type IgSessionQueryResult<T> = {data: T; token: IgUserToken};

/**
 * Performs ig query with logging in if required
 * @param broker
 * @param query
 */
export default async function igSessionQuery<T>(
  uid: string,
  broker: IUserBroker,
  query: (token: IgUserToken) => Promise<IgSessionQueryResult<T>>,
) {
  console.debug('igSessionQuery');
  // check session store for session, load and convert to user token
  const loadSessions = await createFirestoreSessionDocumentHelper(firestoreApp, uid).load();
  const storedSession = loadSessions ? loadSessions[broker.apiKey] : undefined;
  const sessionToken = storedSession
    ? mapSessionToUserToken(broker, storedSession)
    : createIgUserTokenKey(broker.apiKey, broker.isLive);

  // login if no session
  // switch account if required
  const userToken = await igHelperLoginSwitchSession(sessionToken, broker.username, broker.password, broker.accountId);

  if (!userToken) {
    console.error('igSessionQuery Cannot login');
    throw new Error('Error logging in');
  }

  // perform query
  sleepMs(300);
  const result = await query(userToken);

  // save session if required
  const newSession = mapUserTokenToSession(result.token);
  if (!storedSession || (storedSession && !UtilObject.shallowCompare<ISessionIg>(storedSession, newSession))) {
    await createFirestoreSessionDocumentHelper(firestoreApp, uid).save({
      ...(loadSessions || {}),
      [broker.apiKey]: newSession,
    });
  }

  // return results
  return result;
}

import {IgDirectionEnum, IgPositionGetV2Response} from '@algo/broker-ig';
import {IPositionV2, PositionDirectionEnum} from '@algo/model';

/**
 * Create position from IgPosition Get response
 * @param positionResponse
 * @param tags
 * @returns
 */
export function mapIgPositionGetToIPositionV2Open(
  positionResponse: IgPositionGetV2Response,
  tags?: string[],
): IPositionV2 {
  const {position, market} = positionResponse;
  return {
    id: position.dealId,
    broker: 'ig',
    assetId: market.epic,
    // asset: createAssetFromIgMarketData(market),
    size: position.size,
    direction: position.direction === IgDirectionEnum.BUY ? PositionDirectionEnum.LONG : PositionDirectionEnum.SHORT,
    open: position.level,
    openTsUtc: position.createdDateUTC,
    tags: tags || [],
    close:
      positionResponse.position.direction === IgDirectionEnum.BUY
        ? positionResponse.market.bid
        : positionResponse.market.offer,
    closeTsUtc: '',
    limit: positionResponse.position.limitLevel || 0,
    stop: positionResponse.position.stopLevel || 0,
  };
}

import {ListItemButton, ListItemText} from '@mui/material';

export interface MarketMarketListItemProps {
  text: string;
}

export default function MarketMarketListItem(props: MarketMarketListItemProps) {
  const {text} = props;

  return (
    <ListItemButton>
      <ListItemText primary={text} />
    </ListItemButton>
  );
}

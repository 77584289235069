import {literal, number, object, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {initSmaConfigV2, SmaConfigV2, zSmaConfigV2} from '../../../movingAverage/sma/interface/SmaConfigV2';
import {StochasticOutputV2, zStochasticOutputV2} from './StochasticOutputV2';

export interface StochasticConfigV2 extends IndicatorConfig<StochasticOutputV2, IndicatorCandle> {
  type: 'stocConfigV2';
  /// ////////////////config////////////////
  /** Fast Stochastic period */
  fastPeriod: number;
  /** D line, SMA of K line */
  // slowPeriod: number;
  smaConfig: SmaConfigV2;
  /// ////////////////operation variable////////////////
}

export const zStochasticConfigV2 = zIndicatorConfig(zStochasticOutputV2, zIndicatorCandle).merge(
  object({
    type: literal('stocConfigV2').default('stocConfigV2'),
    fastPeriod: number().min(2).default(14),
    smaConfig: zSmaConfigV2.default(initSmaConfigV2),
  }),
) satisfies ZodType<StochasticConfigV2, any, any>;

// export type StochasticConfigV2 = output<typeof zStochasticConfigV2>;

export const initStochasticConfigV2 = zStochasticConfigV2.parse({});

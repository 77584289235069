import dayjs, {Dayjs} from 'dayjs';

/**
 * explode datetime (dayjs) to individual field
 * @param datetime
 * @returns
 */
export function split(datetime?: Dayjs | string) {
  const dt = dayjs(datetime);
  return {
    /** year */
    year: dt.get('year'),
    /** month, 0-11 */
    month: dt.get('month'),
    /** day of month, 1-31 */
    day: dt.get('date'),
    /** hour, 0-23 */
    hour: dt.get('hour'),
    /** minute, 0-59 */
    minute: dt.get('minute'),
    /** seconds, 0-59 */
    second: dt.get('second'),
    /** millisecond, 0-999 */
    millisecond: dt.get('millisecond'),
    /** day of week, 0 (sunday) - 6 (saturday) */
    dayOfWeek: dt.get('day'),
  };
}

export type DatetimeSplit = ReturnType<typeof split>;

import {createTheme} from '@mui/material';

const defaultTheme = createTheme({});

// theme - can use https://cimdalli.github.io/mui-theme-generator/
const theme = createTheme(defaultTheme, {
  palette: {},
  components: {},
});

export default theme;

import {filterITradeSummaryMinPosition, filterITradeSummaryThresholdCompare} from '@algo/helper';
import {Box, Grid} from '@mui/material';
import {useState} from 'react';
import useMutateAlgoConfig from '../../logic/hook/useMutateAlgoConfig';
import useQueryTradeSummary from '../../logic/hook/useQueryTradeSummary';
import useSummaryConfig from '../../logic/hook/useSummaryConfig';
import SummaryHeader from './SummaryHeader';
import SummaryRow from './SummaryRow';

/**
 * Show analysis page to do analysis
 * @returns
 */
export default function SummaryPage() {
  const positions = useQueryTradeSummary();
  const [nameFilter, setNameFilter] = useState<string[]>([]);
  const [minProfit, setMinProfit] = useState<string>('');
  const [maxProfit, setMaxProfit] = useState<string>('');
  const [minPosition, setMinPosition] = useState<string>('');
  const config = useSummaryConfig();
  const updateAlgoConfig = useMutateAlgoConfig();

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <SummaryHeader
            setNameFilter={setNameFilter}
            setMinProfit={setMinProfit}
            setMaxProfit={setMaxProfit}
            setMinPosition={setMinPosition}
          />
        </Grid>
        {Object.entries(positions)
          .filter(([k]) => nameFilter.length === 0 || nameFilter.every((n) => k.includes(n)))
          .filter(([_k, v]) => filterITradeSummaryThresholdCompare(true, minProfit)(v))
          .filter(([_k, v]) => filterITradeSummaryThresholdCompare(false, maxProfit)(v))
          .filter(([_k, v]) => filterITradeSummaryMinPosition(minPosition)(v))
          .map(([k, v]) => (
            <SummaryRow
              key={`SummaryPage-${k}`}
              name={k}
              positions={Object.values(v)}
              copyScale={config}
              updateFn={updateAlgoConfig.fn}
            />
          ))}
      </Grid>
    </Box>
  );
}

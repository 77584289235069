import {mean} from 'mathjs';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {EmaConfigV2, zEmaConfigV2} from './interface/EmaConfigV2';
import {EmaInitV2, zEmaInitV2} from './interface/EmaInitV2';
import {EmaOutputV2} from './interface/EmaOutputV2';

/**
 * Calculate percentage change from ma
 * - calculation different to technicalanalysis package
 */
export class EmaClassV2 extends IndicatorTemplate<EmaConfigV2, EmaOutputV2, EmaInitV2> {
  override type = IndicatorTypeEnum.EMA;

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<EmaConfigV2, EmaOutputV2> {
    // https://www.investopedia.com/terms/e/ema.asp
    // const multiplier = config.smoothing / (1 + config.period);
    const nextPrice = this.config.data.at(-1);

    if (nextPrice === undefined) throw new Error('No price available');

    const previousEma = this.config.results.at(-1);
    // calculate ema
    const result =
      this.config.data.length === this.config.period && previousEma
        ? nextPrice * this.config.exponent + previousEma * (1 - this.config.exponent) // calculating ema
        : mean(this.config.data);

    // once period reach, always define previousEma to enable ema calculation
    return {config: this.config, result};
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(params: Partial<EmaInitV2> | EmaConfigV2 = {}): EmaConfigV2 {
    const testInit = zEmaInitV2.strict().safeParse(params);

    if (testInit.success) {
      return zEmaConfigV2.strip().parse({
        ...testInit.data,
        exponent: testInit.data.smoothing / (1 + testInit.data.period),
        maxPriceLength: testInit.data.period,
      });
    }

    // this is config
    return zEmaConfigV2.strict().parse(params);
  }

  /**
   * map command string to init variable
   */
  override mapCmdToInit(s: string[]): EmaInitV2 {
    const period = s[0] ? convertStrToInt(s[0]) : undefined;
    return zEmaInitV2.parse({period});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): number {
    return mapIndicatorCandleToNumberArr(this.config.hloc)(candle);
  }
}

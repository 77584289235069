import {NextOrObserver, onAuthStateChanged, User} from 'firebase/auth';
import React, {useState} from 'react';
import {firebaseAuth} from '../../logic/helper/firebase/app';
import {IAuthState} from './IAuth';

/**
 * Listen to auth change
 * @returns
 */
export default function useAuth(): IAuthState {
  console.debug('useAuth');

  const [state, setState] = useState<IAuthState>();

  // action when changes happen to auth state
  const onChange: NextOrObserver<User> = (user: User | null) => {
    console.debug('useAuth.onChange');
    if (user) {
      user.getIdToken().then((accessToken) => setState({...user, accessToken}));
    } else {
      setState(null);
    }
  };

  // listener for auth changes
  React.useEffect(() => {
    console.debug('useAuth.useEffect');
    // listen for auth state changes
    const unsubscribe = onAuthStateChanged(firebaseAuth, onChange);
    // unsubscribe to the listener when unmounting
    return () => {
      console.debug('useAuth.unsubscribe');
      unsubscribe();
    };
  }, []);

  return state;
}

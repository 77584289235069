import {ILogFn, setDebug, setError, setInfo, setLog, setTrace, setWarning} from './set';

/**
 * based on parameter passed, disable required logger
 * @param trace
 * @param debug
 * @param log
 * @param info
 * @param warning
 * @param error
 */
export function setLogger(
  trace?: ILogFn,
  debug?: ILogFn,
  log?: ILogFn,
  info?: ILogFn,
  warning?: ILogFn,
  error?: ILogFn,
) {
  if (trace) setTrace(trace);
  if (debug) setDebug(debug);
  if (log) setLog(log);
  if (info) setInfo(info);
  if (warning) setWarning(warning);
  if (error) setError(error);
}

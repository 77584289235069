import {PsarConfig} from './interface/PsarConfig';
import {psarCalculate} from './psarCalculate';
import {psarInit} from './psarInit';

/**
 * Calculate Parabolic stop-and-reverse (PSAR)
 */
export class PSAR {
  config: PsarConfig;

  constructor(step?: number, max?: number, start?: number) {
    this.config = psarInit(step, max, start);
  }

  next(high: number, low: number) {
    const result = psarCalculate(this.config, high, low);
    this.config = result.config;
    return result.result;
  }
}

/**
 * replace parseInt and return undefined if undefined or NaN
 * @param str
 * @returns
 */
export default function convertStrToInt(str?: string) {
  if (!str) return undefined;
  const result = parseInt(str, 10);
  return Number.isNaN(result) ? undefined : result;
}

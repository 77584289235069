import {IPositionV2} from '@algo/model';

/**
 * Check if position has stop loss
 * @param position
 * @returns
 */
export function iPositionV2HasStop(position: IPositionV2) {
  return position.stop > 0;
}

import {CollectionBaseEnum} from '../enum/CollectionBaseEnum';

/**
 *
 * @returns Collection path for algo data
 */
export function getPathAlgoCollection() {
  console.debug('getPathAlgoCollection');
  return CollectionBaseEnum.ALGO;
}

import {Cancel, Check} from '@mui/icons-material';
import {Typography} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {MatchTableData} from '../interface/MatchTableData';

export const matchPositionsColumns: GridColDef<MatchTableData>[] = [
  {
    field: 'dealIdLeft',
    headerName: 'Src position',
    width: 200,
  },
  {
    field: 'duplicate',
    headerName: 'Duplicated',
    renderCell: (params: GridRenderCellParams<MatchTableData, boolean, any>) => {
      const val = params.value;
      return val ? <Check /> : <Cancel />;
    },
    width: 80,
  },
  {
    field: 'dealIdRight',
    headerName: 'Target position',
    width: 200,
  },
  {
    field: 'assetId',
    headerName: 'Asset',
    width: 200,
  },
  {
    field: 'size',
    headerName: 'size',
    width: 100,
  },
  {
    field: 'openPrice',
    headerName: 'open',
    width: 100,
  },
  {
    field: 'price',
    headerName: 'current',
    width: 100,
  },
  {
    field: 'profitLoss',
    headerName: 'PL',
    width: 100,
  },
  // {
  //   field: 'slLeft',
  //   headerName: 'SL left',
  // },
  {
    field: 'stoplossMatch',
    headerName: 'SL',
    renderCell: (params: GridRenderCellParams<MatchTableData, string, any>) => {
      const val = params.value;
      return val === 'match' ? <Check /> : <Typography>{val}</Typography>;
    },
    width: 120,
  },
  // {
  //   field: 'slRight',
  //   headerName: 'SL right',
  // },
  // {
  //   field: 'tpLeft',
  //   headerName: 'TP left',
  // },
  {
    field: 'takeprofitMatch',
    headerName: 'TP',
    renderCell: (params: GridRenderCellParams<MatchTableData, string, any>) => {
      const val = params.value;
      return val === 'match' ? <Check /> : <Typography>{val}</Typography>;
    },
    width: 120,
  },
  {
    field: 'openTs',
    headerName: 'TS',
    width: 180,
  },
  // {
  //   field: 'tpRight',
  //   headerName: 'TP right',
  // },
].map((a) => ({...a} as GridColDef));

import {nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

export interface BollingerBandInitV2 extends IndicatorInit {
  period: number;
  numberOfSd: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zBollingerBandInitV2 = zIndicatorInit.merge(
  object({
    period: number().min(3).default(25),
    numberOfSd: number().min(0.1).default(2),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<BollingerBandInitV2, any, any>;

export const initBollingerBandInitV2 = zBollingerBandInitV2.parse({});

import {isIPositionV2InProfit} from '@algo/helper';
import {calculateIndicatorV3Series, smaV3} from '@algo/indicators';
import {IPositionV2} from '@algo/model';

export default function positionConvertRollingWinRatio(positions: IPositionV2[]) {
  if (positions.length === 0) return [];

  const wins = positions.map((p) => (isIPositionV2InProfit(p) ? 1 : 0));
  const config = smaV3.createConfig({period: 100});
  return calculateIndicatorV3Series(smaV3, config, wins).result;
}

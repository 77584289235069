import {IgMarketData} from '@algo/broker-ig';
import {IAsset} from '@algo/model';

export function mapAssetFromIgMarketData(market: IgMarketData): IAsset {
  return {
    id: market.epic,
    name: market.instrumentName,
    ask: market.offer,
    bid: market.bid,
    lotSize: market.lotSize,
    high: market.high,
    low: market.low,
    changeNet: market.netChange,
    changePct: market.percentageChange,
    lastUpdateUtc: market.updateTimeUTC,
  };
}

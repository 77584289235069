/**
 * replace parseFloat and return undefined if undefined or NaN
 * @param str
 * @returns
 */
export default function convertStrToFloat(str?: string) {
  if (!str) return undefined;
  const result = parseFloat(str);
  return Number.isNaN(result) ? undefined : result;
}

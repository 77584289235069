import {IUserBroker} from '@algo/firebase-common';
import {Grid} from '@mui/material';
import {DataGrid, GridPaginationModel} from '@mui/x-data-grid';
import {useState} from 'react';
import {MatchAlgoOptionsEnum} from './enum/MatchAlgoOptionsEnum';
import sortIMatchAlgoPositionCloseTs from './helper/sortIMatchAlgoPositionCloseTs';
import {IMatchAlgoPosition} from './interface/IMatchAlgoPosition';
import {matchAlgoTableColumns} from './matchAlgoColumns';

export interface MatchAlgoTableProps {
  openPositions: IMatchAlgoPosition[];
  uid: string;
  account?: IUserBroker;
  selection: MatchAlgoOptionsEnum;
}

export default function MatchAlgoTable(props: MatchAlgoTableProps) {
  const {openPositions, uid, account, selection} = props;
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize: 10});

  const descPositions = [...openPositions].sort(sortIMatchAlgoPositionCloseTs);

  return (
    <Grid item>
      <DataGrid<IMatchAlgoPosition>
        sx={{minHeight: 600}}
        rows={descPositions}
        columns={matchAlgoTableColumns(uid, account, selection)}
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        pageSizeOptions={[10, 25, 50, 100]}
        disableRowSelectionOnClick
        getRowId={(row) => row.uniqueId}
        getRowHeight={() => 80}
      />
    </Grid>
  );
}

import {StochasticOutput, initStochasticOutput, zStochasticOutput} from './StochasticOutput';

/**
 * Stochastic output
 */
export type StochasticOutputV3 = StochasticOutput;

export const zStochasticOutputV3 = zStochasticOutput;

export const initStochasticOutputV3 = initStochasticOutput;

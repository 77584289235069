import {IndicatorCandlePriceEnum} from '../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {sd} from './sd';

/**
 * Function to calculate a range of sd values given candles
 * @param candles
 * @param period
 * @param hloc
 * @returns
 */
export function sdCandle(candles: IndicatorCandle[], period?: number, hloc = IndicatorCandlePriceEnum.CLOSE): number[] {
  return sd(candles.map(mapIndicatorCandleToNumberArr(hloc)), period);
}

import {IUserHouse} from '@algo/firebase-common';
import {Box, Grid} from '@mui/material';
import MortgageCalculatorAsset from './MortgageCalculatorAsset';
import MortgageCalculatorBuyer from './MortgageCalculatorBuyer';
import MortgageCalculatorHouse from './MortgageCalculatorHouse';
import MortgageCalculatorMortgage from './MortgageCalculatorMortgage';

// function extractPositiveFloat(s: string) {
//   const result = castPositiveFloat(s);
//   return result ? s.replace(/^0+/, '') : '0';
// }

export interface MortgageCalculatorProps {
  formData: IUserHouse;

  setFormData: (k: string, v: string | boolean) => void;
}

/**
 * Show analysis page to do analysis
 * @returns
 */
export default function MortgageCalculator(props: MortgageCalculatorProps) {
  const {formData, setFormData} = props;

  return (
    <Box sx={{paddingTop: 2, paddingBottom: 2}}>
      <Grid container direction="row" spacing={2} wrap="wrap">
        <MortgageCalculatorBuyer formData={formData} setFormData={setFormData} />
        <MortgageCalculatorAsset formData={formData} setFormData={setFormData} />
        <MortgageCalculatorMortgage formData={formData} setFormData={setFormData} />
        <MortgageCalculatorHouse formData={formData} setFormData={setFormData} />
      </Grid>
    </Box>
  );
}

import {number, object, ZodType} from 'zod';

/**
 * Stochastic output data type
 */
export interface MacdOutputV3 {
  /** fast-slow line */
  macd: number;
  /** ema of macd line, acting as reference */
  signal: number;
  /** macd - signal */
  histogram: number;
}

export const initMacdOutputV3: MacdOutputV3 = {
  macd: 0,
  signal: 0,
  histogram: 0,
};

export const zMacdOutputV3: ZodType<MacdOutputV3> = object({
  macd: number(),
  signal: number(),
  histogram: number(),
}) satisfies ZodType<MacdOutputV3, any, any>;

import {ZodType, literal, object} from 'zod';
import {IgOrderExecutionTypeEnum} from '../../enum';
import {IgSendRequestBody} from '../internal/IgSendRequestBody';
import {IgPositionOpenPartCommon, zIgPositionOpenPartCommon} from './IgPositionOpenPartCommon';
import {IgPositionOpenPartStopLimit, zIgPositionOpenPartStopLimit} from './IgPositionOpenPartStopLimit';

/**
 * Request to open position with order type market
 */
export interface IgPositionOpenMarketV2Request
  extends IgPositionOpenPartStopLimit,
    IgPositionOpenPartCommon,
    IgSendRequestBody {
  /** Describes the order level model to be used for a position operation */
  orderType: IgOrderExecutionTypeEnum.MARKET;
}

export const zIgPositionOpenMarketV2Request = zIgPositionOpenPartStopLimit.merge(zIgPositionOpenPartCommon).merge(
  object({
    orderType: literal(IgOrderExecutionTypeEnum.MARKET),
  }),
) satisfies ZodType<IgPositionOpenMarketV2Request>;

import {IUserBroker} from '@algo/firebase-common';
import {GridColDef} from '@mui/x-data-grid';
import {MatchAlgoOptionsEnum} from '../enum/MatchAlgoOptionsEnum';
import {algoNameColumn} from './algoNameColumn';
import {detailButtonColumn} from './detailButtonColumn';
import {docIdColumn} from './docIdColumn';
// import { closePriceColumn } from './closePriceColumn';
import {epicColumn} from './epicColumn';
import {idColumn} from './idColumn';
import {monitorColumn} from './monitorColumn';
import {openPriceColumn} from './openPriceColumn';
import {openTsColumn} from './openTsColumn';
import {referenceColumn} from './referenceColumn';
import {sizeColumn} from './sizeColumn';
import {statusColumn} from './statusColumn';

export const matchAlgoTableColumns = (uid: string, account: undefined | IUserBroker, selection: MatchAlgoOptionsEnum) =>
  [
    detailButtonColumn(uid, account, selection),
    algoNameColumn,
    docIdColumn,
    statusColumn,
    monitorColumn,
    referenceColumn,
    idColumn,
    epicColumn,
    sizeColumn,
    openPriceColumn,
    // closePriceColumn,
    // profitLossColumn,
    // tagsColumn(setTags),
    openTsColumn,
    // closeTsColumn,
  ] as GridColDef[];

import {IPositionV2} from '@algo/model';

/**
 * Check if position is trailing
 * @param position
 * @returns
 */
export function isIPositionV2TrailingStop(position: IPositionV2) {
  return !!position.trailingStop && !!position.trailingStep;
}

import {mean, std} from 'mathjs';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {BollingerBandConfigV2, zBollingerBandConfigV2} from './interface/BollingerBandConfigV2';
import {BollingerBandInitV2, zBollingerBandInitV2} from './interface/BollingerBandInitV2';
import {BollingerBandOutput} from './interface/BollingerBandOutput';

/**
 * Calculate percentage change from ma
 */
export class BollingerBandClassV2 extends IndicatorTemplate<
  BollingerBandConfigV2,
  BollingerBandOutput,
  BollingerBandInitV2
> {
  override type = IndicatorTypeEnum.RSI;

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<BollingerBandConfigV2, BollingerBandOutput> {
    const price = this.config.data;
    const latestPrice = price.at(-1);
    if (!latestPrice) throw new Error('No price available');

    const stdDev = std(price, 'uncorrected') as number;
    const ma = mean(price);

    const upper = ma + this.config.numberOfSd * stdDev;
    const lower = ma - this.config.numberOfSd * stdDev;

    const pb = (latestPrice - lower) / (upper - lower);

    return {
      config: this.config,
      result: {
        ma,
        upper,
        lower,
        percentageBetween: Number.isNaN(pb) ? 0 : pb,
      },
    };
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(params: Partial<BollingerBandInitV2> | BollingerBandConfigV2 = {}): BollingerBandConfigV2 {
    const testInit = zBollingerBandInitV2.strict().safeParse(params);

    if (testInit.success) {
      return zBollingerBandConfigV2.strip().parse({
        ...testInit.data,
        maxPriceLength: testInit.data.period,
      });
    }

    // this is config
    return zBollingerBandConfigV2.strict().parse(params);
  }

  /**
   * map command string to init variable
   */
  override mapCmdToInit(s: string[]): BollingerBandInitV2 {
    const period = s[0] ? convertStrToInt(s[0]) : undefined;
    return zBollingerBandInitV2.parse({period});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): number {
    return mapIndicatorCandleToNumberArr(this.config.hloc)(candle);
  }
}

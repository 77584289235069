import {IgPositionConfirmV1Response} from '@algo/broker-ig';
import {IPositionV2} from '@algo/model';

export interface IMatchPositions {
  source: IPositionV2;
  target: IPositionV2;
}

/**
 * Identify all matching position and put in array based on duplicate record
 * @param duplicate duplicate record
 * @param src source account position array
 * @param target target account position array
 * @returns array of matching position from src and target
 */
export default function extractMatchPositions(
  duplicate: Record<string, IgPositionConfirmV1Response>,
  src: IPositionV2[],
  target: IPositionV2[],
) {
  return src.reduce((prev, cur) => {
    // identify target deal id
    const targetDealId = duplicate[cur.id]?.dealId;
    // target position
    const targetPosition = targetDealId ? target.find((t) => t.id === targetDealId) : undefined;
    return targetPosition ? [...prev, {source: cur, target: targetPosition}] : prev;
  }, [] as IMatchPositions[]);
}

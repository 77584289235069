import z from 'zod';
/**
 * cache collection
 */
export enum CacheCollectionEnum {
  /** summarising trade for analysis */
  TRADE_SUMMARY = 'tradeSummary',
}

export const zCacheCollectionEnum: z.ZodType<CacheCollectionEnum> = z.nativeEnum(CacheCollectionEnum);

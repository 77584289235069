export enum MatchAlgoStatusEnum {
  MATCHED = 'matched',
  /**
   * when position closed in IG but not recorded in firestore
   * unmatched modify position
   */
  NOT_RECORDED = 'not recorded',
  /**
   * IG position that does not have a match in copy algo - not managed
   * - position existed in right/target, not in left
   * un-matched reference position
   * */
  ORPHAN = 'orphan',
}

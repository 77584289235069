import {UtilLogging} from '@algo/util';
import {ApolloProvider} from '@apollo/client';
import {CssBaseline, ThemeProvider} from '@mui/material';
import Cookie from 'js-cookie';
import useAuth from '../../hooks/auth/useAuth';
import {isEnvDev} from '../../logic/helper/env';
import createApolloClient from '../../logic/helper/gql/createApolloClient';
import AppRouter from './AppRoute';
import muiTheme from './theme';

if (isEnvDev()) {
  const logLevel = Cookie.get('log') || 'info';
  UtilLogging.setLogLevel(logLevel);
}

export default function App() {
  const auth = useAuth();
  const isInit = auth !== undefined;

  const client = auth ? createApolloClient({Authorization: `Bearer ${auth.accessToken}`}) : createApolloClient();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        {isInit ? <AppRouter auth={auth} /> : null}
      </ThemeProvider>
    </ApolloProvider>
  );
}

import {IPositionV2, PositionDirectionEnum} from '@algo/model';
import {MatchTableData} from '../interface/MatchTableData';

/**
 * map data when both exists
 * @param srcPosition
 * @param targetPosition
 * @returns
 */
export default function mapSrcTargetToMatchTableData(
  srcPosition: IPositionV2,
  targetPosition: IPositionV2,
): MatchTableData {
  return {
    duplicate: true,
    dealIdLeft: srcPosition.id,
    dealIdRight: targetPosition.id,
    assetId: srcPosition.assetId || '',
    size: srcPosition.direction === PositionDirectionEnum.LONG ? srcPosition.size : -srcPosition.size,
    openPrice: srcPosition.open,
    price: srcPosition.close,
    profitLoss: (
      (srcPosition.close - srcPosition.open) *
      (srcPosition.size + targetPosition.size) *
      (srcPosition.direction === PositionDirectionEnum.LONG ? 1 : -1)
    ).toFixed(1),
    openTs: srcPosition.openTsUtc || '',
    stoplossMatch: srcPosition.stop === targetPosition.stop ? 'match' : `${srcPosition.stop}/${targetPosition.stop}`,
    takeprofitMatch:
      srcPosition.limit === targetPosition.limit ? 'match' : `${srcPosition.limit}/${targetPosition.limit}`,
    slLeft: srcPosition.stop,
    slRight: targetPosition.stop,
    tpLeft: srcPosition.limit,
    tpRight: targetPosition.limit,
  };
}

import {igHelperHistoryActivityRecentWithDealId} from '@algo/broker-ig';
import {IUserBroker} from '@algo/firebase-common';
import {createFirestoreStrategyUnknownCollectionHelper} from '@algo/firebase-web';
import {mapIPositionV2ToCloseWithActivity} from '@algo/helper';
import {IPositionV2, zIPositionV2} from '@algo/model';
import {firestoreApp} from '../../../logic/helper/firebase/app';
import getAlgoCopierName from '../../../logic/helper/getAlgoCopierName';
import igSessionQuery from '../../../logic/helper/ig/igSessionQuery';
import {MatchAlgoOptionsEnum} from '../enum/MatchAlgoOptionsEnum';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

/**
 * update db record with ig activity
 * @param uid
 * @param account
 * @param selection
 * @param position
 * @returns
 */
export default function updateMatchAlgoCopierFsRecord(
  uid: string,
  account: IUserBroker,
  selection: MatchAlgoOptionsEnum,
  matchPosition: IMatchAlgoPosition,
) {
  const monitorPosition = matchPosition.monitorPosition as IPositionV2;
  return async () => {
    if (!(selection === MatchAlgoOptionsEnum.ALGO_DEV || selection === MatchAlgoOptionsEnum.ALGO_PROD)) return;
    // get close activity
    const result = await igSessionQuery(uid, account, async (token) => ({
      token,
      data: await igHelperHistoryActivityRecentWithDealId(
        token,
        monitorPosition.id,
        monitorPosition.assetId,
        undefined,
        new Date(monitorPosition.openTsUtc),
      ),
    }));

    const closeActivity = result.data.find((p) => p.description.includes('closed:'));

    // quite if no activity
    if (!closeActivity) {
      console.log('no activity found');
      return;
    }

    // save closed position to firestore
    const closedPosition = mapIPositionV2ToCloseWithActivity(zIPositionV2.parse(monitorPosition), closeActivity);
    await createFirestoreStrategyUnknownCollectionHelper(
      firestoreApp,
      getAlgoCopierName(selection === MatchAlgoOptionsEnum.ALGO_PROD),
      matchPosition.algoName,
    ).save(matchPosition.docId, closedPosition);
  };
}

import {CollectionBaseEnum} from '../enum/CollectionBaseEnum';

/**
 * collection path of user
 * @returns
 */
export function getPathUserCollection() {
  console.debug('getPathUserCollection');
  return CollectionBaseEnum.USER;
}

import {IAsset, IPositionV2} from '@algo/model';
import {TableCell, TableRow} from '@mui/material';
import React from 'react';
import PositionTableRow from '../../components/PositionTableRow/PositionTableRow';
import positionCalculateAverageOpenPrice from '../../logic/helper/position/calculate/positionCalculateAverageOpen';
import positionCalculateLimitRisk from '../../logic/helper/position/calculate/positionCalculateLimitRisk';
import positionCalculateNetSize from '../../logic/helper/position/calculate/positionCalculateNetSize';
import positionCalculateTotalProfit from '../../logic/helper/position/calculate/positionCalculateTotalProfit';
import positionCalculateTotalRisk from '../../logic/helper/position/calculate/positionCalculateTotalRisk';
import {IPositionTableFields, positionTableFieldsOrder} from './IPositionTableFields';

export interface IPositionTableAssetProps {
  /** User id */
  uid: string;
  /** Asset information to display */
  asset: IAsset;
  /** List of all tags */
  allTags: string[];
  /** List of all positions */
  positions: IPositionV2[];
}

/**
 * Display multiple position for a particular asset
 * @param props
 * @returns
 */
function PositionTableAsset(props: IPositionTableAssetProps) {
  const [open, setOpen] = React.useState(false);
  const {asset, positions, allTags, uid} = props;

  // identify related positions - position belong to given asset
  const relatedPositions = positions.filter((p) => p.assetId === asset.id) || [];

  // calculate total for all position in given asset
  const averageOpenPrice = positionCalculateAverageOpenPrice(relatedPositions) || 0;
  const totalSize = positionCalculateNetSize(relatedPositions);
  const totalProfit = asset.ask && asset.bid ? positionCalculateTotalProfit(relatedPositions, asset.ask, asset.bid) : 0;
  const totalRisk = positionCalculateTotalRisk(relatedPositions);
  const limitRisk = positionCalculateLimitRisk(relatedPositions);

  // group data to display
  const data: Partial<IPositionTableFields> = {
    name: asset.name,
    size: totalSize.toString(),
    open: averageOpenPrice.toPrecision(5),
    latest: ((totalSize > 0 ? asset.bid : asset.ask) || 0).toString(),
    stop: '',
    limit: '',
    profitLoss: totalProfit.toFixed(2),
    totalRisk: totalRisk.toFixed(2),
    limitRisk: limitRisk.toFixed(2),
  };

  return (
    <>
      <TableRow
        key={`PositionTableAsset-${asset.id}`}
        sx={{'&:last-child td, &:last-child th': {border: 0}, backgroundColor: '#dddddd'}}
        onClick={() => setOpen(!open)}
      >
        {positionTableFieldsOrder.map((d, i) => (
          <TableCell key={`PositionTableAsset-${asset.id}-cell-${d + i.toString()}`} align="center">
            {d in data && data[d as keyof IPositionTableFields]}
          </TableCell>
        ))}
      </TableRow>

      {relatedPositions.map((p) => (
        <PositionTableRow
          key={`PositionTableAsset-${asset.id}-position-${p.id}`}
          onClickHandle={() => setOpen(!open)}
          asset={asset}
          position={p}
          uid={uid}
          show={open}
          allTags={allTags}
        />
      ))}
    </>
  );
}

export default PositionTableAsset;

import {IgDirectionEnum, IgPositionConfirmV1Response} from '@algo/broker-ig';
import {IPositionV2, PositionDirectionEnum} from '@algo/model';

/**
 * Create position from IgPosition confirm response
 * @param position
 * @param tags
 * @returns
 */
export function mapIgPositionConfirmToIPositionV2Open(
  position: IgPositionConfirmV1Response,
  tags?: string[],
): IPositionV2 {
  return {
    id: position.dealId,
    broker: 'ig',
    assetId: position.epic,
    // asset: createAssetFromIgMarketData(market),
    size: position.size,
    direction: position.direction === IgDirectionEnum.BUY ? PositionDirectionEnum.LONG : PositionDirectionEnum.SHORT,
    open: position.level,
    openTsUtc: position.date,
    tags: tags || [],
    close: 0,
    closeTsUtc: '',
    limit: position.limitLevel || 0,
    stop: position.stopLevel || 0,
  };
}

import {Box} from '@mui/material';
import {isEnvDev} from '../../logic/helper/env';
import HeaderButtonsAccount, {HeaderButtonsAccountProps} from './HeaderButtonsAccount';
import HeaderButtonsDetail from './HeaderButtonsDetail';
import HeaderButtonsTest from './HeaderButtonsTest';

export interface HeaderButtonsProps extends HeaderButtonsAccountProps {}

export default function HeaderButtons(props: HeaderButtonsProps) {
  const {user} = props;

  return (
    <>
      {isEnvDev() && user && <HeaderButtonsTest user={user} />}
      <Box sx={{display: {xs: 'flex', sm: 'flex'}}}>
        <HeaderButtonsDetail user={user} />
        <HeaderButtonsAccount user={user} />
      </Box>
    </>
  );
}

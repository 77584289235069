import {IndicatorCandlePriceEnum} from '../../enum';
import {IndicatorCandle} from '../../interface';

export function mapIndicatorCandleToNumberArr(hlocType: IndicatorCandlePriceEnum | number) {
  return (candle: IndicatorCandle) => {
    switch (hlocType) {
      case IndicatorCandlePriceEnum.HIGH:
        return candle.high;
      case IndicatorCandlePriceEnum.LOW:
        return candle.low;
      case IndicatorCandlePriceEnum.OPEN:
        return candle.open;
      default:
        return candle.close;
    }
  };
}

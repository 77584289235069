import {IPositionV2} from '@algo/model';
import {GridColDef} from '@mui/x-data-grid';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

export const openPriceColumn: GridColDef = {
  field: 'open',
  headerName: 'Open price',
  width: 100,
  align: 'center',
  headerAlign: 'center',
  valueGetter: (_v, row: IMatchAlgoPosition) => ((row.monitorPosition || row.referencePosition) as IPositionV2).open,
  editable: false,
};

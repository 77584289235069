import {httpsCallable} from 'firebase/functions';
import {firebaseFn} from '../app';

export default async function callableGenerateTradeSummary() {
  try {
    const fn = httpsCallable(firebaseFn, 'generateTradeSummary');
    return !!(await fn());
  } catch (_e) {
    return false;
  }
}

import {IPositionV2} from '@algo/model';

/**
 * get object of all tags with its count
 * @param positions
 * @returns
 */
export default function positionExtractTagCount(positions: IPositionV2[]): Record<string, number> {
  const allTags = positions.reduce((prev, cur) => [...prev, ...cur.tags], [] as string[]);

  return allTags.reduce((prev, cur) => ({...prev, [cur]: (prev[cur] || 0) + 1}), {} as Record<string, number>);
}

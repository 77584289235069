import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgWatchlistData} from '../interface/watchlist/IgWatchlistData';

export interface IgWatchlistsGetV1Response {
  watchlists: IgWatchlistData[];
}

/**
 * Get a list of all watchlists
 * @param userToken
 * @returns
 */
export async function listWatchlist(userToken: IgUserToken) {
  console.debug('listWatchlist');
  const result = await sendIgRequest<IgWatchlistsGetV1Response>(userToken, '/watchlists', 'get', 1);

  return createIgResponse(userToken, result);
}

import {getPathStrategyCollection} from './getPathStrategyCollection';

/**
 * collection path of unknown collection for strategy
 * @param strategyName
 * @returns
 */
export function getPathStrategyUnknownCollection(strategyName: string, collectionName: string) {
  console.debug('getPathStrategyUnknownCollection');
  return `${getPathStrategyCollection()}/${strategyName}/${collectionName}`;
}

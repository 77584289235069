import {IndicatorConfigV3} from './IndicatorConfigV3';

/**
 * Get item from input array
 * @returns
 * @throws when no results available
 */
export function getIndicatorV3Input<Config extends IndicatorConfigV3<Output, Input>, Output = number, Input = number>(
  config: Config,
  position: number = -1,
) {
  if (config.input.length === 0) {
    throw new Error('No input available');
  }
  const result = config.input.at(position);
  if (result === undefined) {
    throw new Error(
      `Input out of range, position: ${position}, input size: ${config.input.length}, data: ${config.input}`,
    );
  }
  return result;
}

import {IgPriceCandle} from '@algo/broker-ig';
import {IPositionV2, PositionDirectionEnum, zIPositionV2} from '@algo/model';
import {uniqueArray} from '@coolcolduk/util';
import {extractPriceCandlePrice} from '../../extract';

/**
 * Close paper trade iposition with candle
 * @param position
 * @param candle
 * @param tags
 * @param overridePrice override close price
 * @throws invalid data
 * @returns
 */
export function createIPositionV2PaperCloseWithCandle(
  position: IPositionV2,
  candle: IgPriceCandle,
  tags: string[] = [],
  overridePrice?: number,
): IPositionV2 {
  const close =
    overridePrice !== undefined
      ? overridePrice
      : extractPriceCandlePrice(candle.closePrice, false, position.direction === PositionDirectionEnum.LONG);

  if (close === 0) {
    console.warn(`no close price ${JSON.stringify(candle)}`);
  }

  const data: IPositionV2 = {
    ...position,
    close,
    closeTsUtc: candle.snapshotTime,
    tags: uniqueArray([...position.tags, ...tags]),
  };
  return zIPositionV2.parse(data);
}

import {createIgUserToken, IgUserToken} from '@algo/broker-ig';
import {ISessionIg, IUserBroker} from '@algo/firebase-common';

export function mapSessionToUserToken(account: IUserBroker, session: ISessionIg): IgUserToken {
  console.debug('mapSessionToUserToken');
  return createIgUserToken(
    session.apiKey,
    account.isLive,
    session.accountToken,
    session.clientToken,
    session.currentAccountId,
    session.lightstreamerEndpoint,
    session.expiry,
  );
}

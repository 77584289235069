import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {createIgUserTokenKey} from '../create/createIgUserTokenKey';

export interface IgSessionDeleteV1Response {}

/**
 * logout user
 * @param userToken
 * @returns
 */
export async function logoutIgSession(userToken: IgUserToken) {
  console.debug('logoutIgSession');
  const result = await sendIgRequest<IgSessionDeleteV1Response>(userToken, '/session', 'delete');

  return createIgResponse(
    result.response ? createIgUserTokenKey(userToken.apiKey, userToken.isLive) : userToken,
    result,
  );
}

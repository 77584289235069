import {Grid} from '@mui/material';
import SummaryHeaderFilter, {SummaryHeaderFilterProps} from './SummaryHeaderFilter';
import SummaryHeaderPosition, {SummaryHeaderPositionProps} from './SummaryHeaderPosition';
import SummaryHeaderProfit, {SummaryHeaderProfitProps} from './SummaryHeaderProfit';

export interface SummaryHeaderProps
  extends SummaryHeaderFilterProps,
    SummaryHeaderProfitProps,
    SummaryHeaderPositionProps {}

export default function SummaryHeader(props: SummaryHeaderProps) {
  const {setNameFilter, setMaxProfit, setMinProfit, setMinPosition} = props;

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <SummaryHeaderFilter setNameFilter={setNameFilter} />
      </Grid>
      <Grid item>
        <SummaryHeaderProfit setMaxProfit={setMaxProfit} setMinProfit={setMinProfit} />
      </Grid>
      <Grid item>
        <SummaryHeaderPosition setMinPosition={setMinPosition} />
      </Grid>
    </Grid>
  );
}

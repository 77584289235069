import {IPositionV2} from '@algo/model';
import {Grid, Paper} from '@mui/material';
import InfoTile from '../../components/InfoTile/InfoTile';
import CumulativeReturnTile from '../../components/tiles/CumulativeReturnTile';
import ProfitOfTradeTile from '../../components/tiles/ProfitOfTradeTile';
import TradeDurationTile from '../../components/tiles/TradeDurationTile';
import WinLossRatioRollingTile from '../../components/tiles/WinLossRatioRollingTile';
import WinLossRatioTile from '../../components/tiles/WinLossRatioTile';
import positionCalculateLongRatio from '../../logic/helper/position/calculate/positionCalculateLongRatio';
import positionCalculateMaxDrawdown from '../../logic/helper/position/calculate/positionCalculateMaxDrawdown';
import positionCalculateTotalDuration from '../../logic/helper/position/calculate/positionCalculateTotalDuration';
import positionExtractTagCount from '../../logic/helper/position/extract/positionExtractTagCount';
import positionSortByCloseTs from '../../logic/helper/position/sort/positionSortByCloseTs';

export interface AnalysisSummaryProps {
  closedPositions: IPositionV2[];
}

export default function AnalysisSummary(props: AnalysisSummaryProps) {
  const {closedPositions} = props;

  const limit = 1300;
  const ascPositions = positionSortByCloseTs(closedPositions, true);

  const tagCount = positionExtractTagCount(ascPositions);
  const tagArr = Object.entries(tagCount)
    .map(([key, value]) => ({key, value}))
    .filter((v) => !v.key.includes('='));
  tagArr.sort((a, b) => (a.value > b.value ? -1 : 1));
  // return
  // return per day
  // trade duration - group together
  return (
    <Paper>
      <Grid container direction="row" wrap="wrap">
        <CumulativeReturnTile sortedClosePositions={ascPositions} limit={limit} />
        <TradeDurationTile sortedClosePositions={ascPositions} />
        <WinLossRatioTile sortedClosePositions={ascPositions} limit={limit} />
        <WinLossRatioRollingTile sortedClosePositions={ascPositions} limit={limit} />
        <ProfitOfTradeTile sortedClosePositions={ascPositions} limit={limit} />
        <InfoTile
          title="Ratio of Long position"
          data={[
            {
              title: 'average',
              value: (positionCalculateLongRatio(ascPositions) * 100).toFixed(1),
              suffix: '%',
            },
          ]}
        />
        <InfoTile
          title="Trade info"
          description="General trade info"
          data={[
            {
              title: 'Counts',
              description: 'Total number of trades',
              value: ascPositions.length.toString(),
              suffix: 'trades',
            },
            {
              title: 'Duration',
              description: 'Total trade duration',
              value: positionCalculateTotalDuration(ascPositions, 'days').toFixed(1),
              suffix: 'days',
            },
            {
              title: 'Max drawdown',
              description: 'Maximum drawdown',
              value: (positionCalculateMaxDrawdown(1000, ascPositions) * 100).toFixed(1),
              suffix: '%',
            },
          ]}
        />
        <InfoTile
          title="Tag count"
          description="General trade info"
          data={tagArr.slice(0, 10).map((t) => ({
            title: t.key,
            description: '',
            value: t.value.toString(),
          }))}
        />
      </Grid>
    </Paper>
  );
}

// This component is responsible for the actual routing
import {useSubscribeUserPosition} from '@algo/firebase-web';
import {IPositionV2} from '@algo/model';
import {Navigate, Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {IAuthState} from '../../hooks/auth/IAuth';
import useQueryUserBroker from '../../hooks/useQueryUserBroker';
import {firestoreApp} from '../../logic/helper/firebase/app';
import AnalysisPage from '../../pages/analysis/AnalysisPage';
import HeaderPage from '../../pages/header/HeaderOrganism';
import LoginPage from '../../pages/login/LoginPage';
import MarketPage from '../../pages/market/MarketPage';
import MatchAlgoPage from '../../pages/matchAlgo/MatchAlgoPage';
import MatchPositionsPage from '../../pages/matchPositions/MatchPositionsPage';
import MortgagePage from '../../pages/mortgage/MortgagePage';
import PositionsPage from '../../pages/positions/PositionsPage';
import SummaryPage from '../../pages/summary/SummaryPage';

export interface AppRouterProps {
  auth: IAuthState;
}
export default function AppRouter(props: AppRouterProps) {
  const {auth} = props;

  const accounts = Object.values(useQueryUserBroker());
  const positionRecords = useSubscribeUserPosition<IPositionV2>(firestoreApp, auth?.uid);

  const uid = auth && auth.uid;
  const isAuthenticated = uid && uid.length > 0;

  return (
    <Router>
      <div>
        {isAuthenticated && <HeaderPage title="Trading Journal" user={auth} />}
        <Routes>
          {!isAuthenticated && <Route path="/" element={<LoginPage />} />}
          {!isAuthenticated && <Route path="/*" element={<Navigate to="/" />} />}

          {isAuthenticated && <Route path="/" element={<MortgagePage uid={auth.uid} />} />}

          {/* authenticated route */}
          {isAuthenticated && (
            <Route
              path="/positions"
              element={<PositionsPage uid={uid} accounts={accounts} positionRecords={positionRecords} />}
            />
          )}

          {/* {isAuthenticated && <Route path="/" element={<DashboardPage />} />} */}
          {isAuthenticated && (
            <Route path="/analysis" element={<AnalysisPage uid={uid} positionRecords={positionRecords} />} />
          )}

          {isAuthenticated && <Route path="/market" element={<MarketPage uid={uid} accounts={accounts} />} />}

          {/* {isAuthenticated && <Route path="/" element={<DashboardPage />} />} */}
          {isAuthenticated && (
            <Route
              path="/match"
              element={<MatchPositionsPage accounts={accounts} positionRecords={positionRecords} uid={uid} />}
            />
          )}

          {isAuthenticated && <Route path="/matchAlgo" element={<MatchAlgoPage uid={uid} accounts={accounts} />} />}

          {isAuthenticated && <Route path="/summary" element={<SummaryPage />} />}

          {isAuthenticated && <Route path="/mortgage" element={<MortgagePage uid={uid} />} />}
        </Routes>
      </div>
    </Router>
  );
}

import {createFirestoreAlgoOpenCollectionHelper} from '@algo/firebase-web';
import {firestoreApp} from '../../../logic/helper/firebase/app';
import {MatchAlgoOptionsEnum} from '../enum/MatchAlgoOptionsEnum';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

/**
 * Remove algo open trade in algoPaper stage
 * @param uid
 * @param account
 * @param selection
 * @param position
 * @returns
 */
export default function removeMatchAlgoFsOpenPosition(selection: MatchAlgoOptionsEnum, position: IMatchAlgoPosition) {
  return async () => {
    if (selection !== MatchAlgoOptionsEnum.ALGO_PAPER) return;

    await createFirestoreAlgoOpenCollectionHelper(firestoreApp, position.algoName).del(position.docId);
  };
}

import {useLoadStrategyUnknown} from '@algo/firebase-web';
import {IPositionV2} from '@algo/model';
import {IPositionRecordV2} from 'model/IPositionRecord';
import {firestoreApp} from '../logic/helper/firebase/app';
import mergeMetadata from './mergeMetadata';
import {StrategyTypeEnum} from './useListAlgoStrategy';
import useQueryListAlgoPaperClosePosition from './useQueryListAlgoPaperClosePosition';

/**
 * Download archive positions of given strategy
 * @param name
 * @param positionData
 */
export default function useLoadStrategyArchivePositions(
  name: string,
  strategyType: StrategyTypeEnum,
  positionData: IPositionRecordV2,
): IPositionV2[] {
  console.debug('useLoadStrategyArchivePositions');
  const algoPositionRec = useQueryListAlgoPaperClosePosition(strategyType === StrategyTypeEnum.ALGO ? [name] : []);
  const algoDevPosition = useLoadStrategyUnknown(
    strategyType === StrategyTypeEnum.COPY_DEV ? firestoreApp : undefined,
    'algoCopierDev',
    name,
  );

  const algoPosition: Record<string, IPositionV2> = algoPositionRec[name] || {};

  const positions = {...algoPosition, ...algoDevPosition};
  // merge position with meta data
  return Object.values(positions).map(mergeMetadata(positionData)) || [];
}

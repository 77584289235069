import {IgPriceDetail} from '@algo/broker-ig';

/**
 * Extract correct price based on condition
 * @param candle
 * @param isOpen
 * @param isLong
 */
export function extractPriceCandlePrice(candle: IgPriceDetail, isOpen: boolean, isLong: boolean) {
  // source: https://roboforex.com/about/client/faq/top10/6/#:~:text=Long%20positions%20(Buy)
  // Long positions (Buy) are opened at the Ask price and closed at the Bid price.
  if (isLong) return isOpen ? candle.ask : candle.bid;
  // Short positions (Sell), in their turn, are opened at the Bid price and closed at the Ask price.
  return isOpen ? candle.bid : candle.ask;
}

import {z} from 'zod';
import {IgDirectionEnum} from '../../enum';
import {IgActivityDetailAction, zIgActivityDetailAction} from './IgActivityDetailAction';

export interface IgActivityDetail {
  actions: IgActivityDetailAction[];
  currency: string;
  dealReference: null | string;
  direction: IgDirectionEnum;
  goodTillDate: string | null;
  guaranteedStop: boolean;
  level: number;
  limitDistance: number | null;
  limitLevel: number | null;
  marketName: string;
  size: number;
  stopDistance: number | null;
  stopLevel: number | null;
  trailingStep: null | number;
  trailingStopDistance: null | number;
}

export const zIgActivityDetail: z.ZodType<IgActivityDetail> = z.object({
  actions: z.array(zIgActivityDetailAction),
  currency: z.string().min(1),
  dealReference: z.string().nullable(),
  direction: z.nativeEnum(IgDirectionEnum),
  goodTillDate: z.string().min(1).nullable(),
  guaranteedStop: z.boolean(),
  level: z.number().nonnegative(),
  limitDistance: z.number().nonnegative().nullable(),
  limitLevel: z.number().nonnegative().nullable(),
  marketName: z.string().min(1),
  size: z.number().nonnegative(),
  stopDistance: z.number().nonnegative().nullable(),
  stopLevel: z.number().nonnegative().nullable(),
  trailingStep: z.number().nonnegative().nullable(),
  trailingStopDistance: z.number().nonnegative().nullable(),
});

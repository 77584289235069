import {IPositionCalculate, IPositionMain} from '@algo/model';
import {calculatePositionProfitLoss} from '../../calculate/IPosition/calculatePositionProfitLoss';

/**
 * create position calculated values
 * @param position
 * @returns
 */
export function createPositionCalculate(position: IPositionMain): IPositionCalculate {
  return {
    profitLoss: calculatePositionProfitLoss(position.direction, position.size, position.open, position.close),
  };
}

import {getPathUserPositionCollection} from '@algo/firebase-common';
import {collection, CollectionReference, Firestore} from 'firebase/firestore';

/**
 * collection for positions of user
 * @param uid
 * @returns
 */
export function getReferenceUserPositionCollection<T>(firestoreApp: Firestore, uid: string) {
  return collection(firestoreApp, getPathUserPositionCollection(uid)) as CollectionReference<T>;
}

import {IndicatorTypeEnum} from '../../interface';
import {IndicatorConfigV3} from './IndicatorConfigV3';
import {IndicatorInitV3} from './IndicatorInitV3';

export default function createIndicatorV3ConfigFromCmd<
  Init extends IndicatorInitV3,
  Config extends IndicatorConfigV3<Output, Input>,
  Output = number,
  Input = number,
>(type: IndicatorTypeEnum, initFunction: (s: string[]) => Init, createConfig: (init: Partial<Init>) => Config) {
  return (cmd: string) => {
    const cmdArr = cmd.split('_');
    const [extractedType, ...rest] = cmdArr;
    if (type !== extractedType) return undefined;

    return createConfig(initFunction(rest));
  };
}

import {SdConfig} from './interface/SdConfig';
import {sdCalculate} from './sdCalculate';
import {sdInit} from './sdInit';

/**
 * Calculate simple deviation
 */
export class SD {
  config: SdConfig;

  constructor(period?: number) {
    this.config = sdInit(period);
  }

  next(nextValue: number) {
    const result = sdCalculate(this.config, nextValue);
    this.config = result.config;
    return result.result;
  }
}

import {IPositionV2} from '@algo/model';
import calculateStartIndex from '../../logic/helper/calculate/calculateStartIndex';
import positionConvertRollingWinRatio from '../../logic/helper/position/convert/positionConvertRollingWinRatio';
import InfoTile from '../InfoTile/InfoTile';
import SummaryGraph from '../summaryGraph/SummaryGraph';

export interface WinLossRatioRollingTileProps {
  /** All closed position sorted by close TS in asc order */
  sortedClosePositions: IPositionV2[];

  /** limit number to display on graph */
  limit?: number;
}
export default function WinLossRatioRollingTile(props: WinLossRatioRollingTileProps) {
  const {sortedClosePositions, limit} = props;

  // cumulative winRatio
  const cumulativeWinRatio = positionConvertRollingWinRatio(sortedClosePositions);
  const startIndex = calculateStartIndex(sortedClosePositions.length, limit || sortedClosePositions.length);

  // last index, or -1 if empty
  const lastIndex = cumulativeWinRatio.length - 1;

  const plotData = cumulativeWinRatio.map((val, i) => ({x: i + 1, y: val})).slice(startIndex);
  const totalWinRatio = cumulativeWinRatio[cumulativeWinRatio.length - 1];
  return (
    <InfoTile
      title="Rolling Win Ratio"
      description="Rolling Win ratio to show the average win for last rolling 100 trades"
      data={[{title: 'Cumulative', suffix: '%', value: ((totalWinRatio || 0) * 100).toFixed(1)}]}
      graph={
        plotData.length ? (
          <SummaryGraph
            data={plotData}
            height={150}
            yDomain={[0, 1]}
            yTick={[0, 0.25, 0.5, 0.75, 1]}
            xDomain={[startIndex + 1, lastIndex > 0 ? lastIndex + 1 : 1]}
            xTick={[startIndex + 1, lastIndex > 0 ? lastIndex + 1 : 1]}
          />
        ) : undefined
      }
    />
  );
}

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';

export interface IgWatchlistUpdateRemoveV1Response {
  status: 'SUCCESS';
}

/**
 * Add epic to watchlist
 * @param userToken
 * @param name
 * @param epics
 * @returns
 */
export async function updateWatchlistRemoveEpic(userToken: IgUserToken, watchlistId: string, epic: string) {
  console.debug('updateWatchlistRemoveEpic');
  const result = await sendIgRequest<IgWatchlistUpdateRemoveV1Response>(
    userToken,
    `/watchlists/${watchlistId}/${epic}`,
    'delete',
    1,
    undefined,
    undefined,
    {epic},
  );

  return createIgResponse(userToken, result);
}

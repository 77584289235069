import {ZodType, boolean, object} from 'zod';
import zodPositiveDecimalNumber from '../zod/zodPositiveDecimalNumber';

export interface IgPositionCommonTrailing {
  /** Whether the stop has to be moved towards the current level in case of a favourable trade */
  trailingStop: boolean;
  /** Trailing stop distance */
  trailingStopDistance: number;
  /** increment step in pips for the trailing stop */
  trailingStopIncrement: number;
}

export const zIgPositionCommonTrailing = object({
  trailingStop: boolean(),
  trailingStopDistance: zodPositiveDecimalNumber(3),
  trailingStopIncrement: zodPositiveDecimalNumber(3),
}) satisfies ZodType<IgPositionCommonTrailing>;

export const initIgPositionCommonTrailing: IgPositionCommonTrailing = zIgPositionCommonTrailing.parse({
  trailingStop: false,
  trailingStopDistance: 0.1,
  trailingStopIncrement: 0.1,
});

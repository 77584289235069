import {UtilNumber} from '@algo/util';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {SmaClassV2} from '../../movingAverage';
import {StochasticConfigV2, zStochasticConfigV2} from './interface/StochasticConfigV2';
import {StochasticInitV2, zStochasticInitV2} from './interface/StochasticInitV2';
import {StochasticOutputV2} from './interface/StochasticOutputV2';

/**
 * Calculate percentage change from ma
 * - calculation different to technicalanalysis package
 */
export class StochasticClassV2 extends IndicatorTemplate<
  StochasticConfigV2,
  StochasticOutputV2,
  StochasticInitV2,
  IndicatorCandle
> {
  override type = IndicatorTypeEnum.STOCHASTIC;

  /**
   * Calculate Stochastic K value
   * @param data new config
   * @returns
   */
  private stochasticCalculateK(data: IndicatorCandle[]): number {
    const tick = data.at(-1) as IndicatorCandle;
    const periodLow = Math.min(...data.map((d) => d.low));
    const periodHigh = Math.max(...data.map((d) => d.high));
    const calculatedK = ((tick.close - periodLow) / (periodHigh - periodLow)) * 100;
    return Number.isNaN(calculatedK) ? 0 : calculatedK;
  }

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<StochasticConfigV2, StochasticOutputV2, IndicatorCandle> {
    // https://www.investopedia.com/terms/e/ema.asp
    // const multiplier = config.smoothing / (1 + config.period);
    // const nextPrice = this.config.data.at(-1);

    // if (nextPrice === undefined) throw new Error('No price available');

    // const previousStochastic = this.config.results.at(-1);
    // // calculate ema
    // const result =
    //   this.config.data.length === this.config.period && previousStochastic
    //     ? nextPrice * this.config.exponent + previousStochastic * (1 - this.config.exponent) // calculating ema
    //     : UtilMath.average(this.config.data);

    // // once period reach, always define previousStochastic to enable ema calculation
    // return {config: this.config, result};

    const k = this.stochasticCalculateK(this.config.data);

    const sma = new SmaClassV2(this.config.smaConfig);
    sma.nextValue(k);

    return {
      config: {
        ...this.config,
        smaConfig: sma.getConfig(),
      },
      result: {k, d: sma.getLastResult()},
    };
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(params: Partial<StochasticInitV2> | StochasticConfigV2 = {}): StochasticConfigV2 {
    const testInit = zStochasticInitV2.strict().safeParse(params);

    if (testInit.success) {
      return zStochasticConfigV2.strip().parse({
        ...testInit.data,
        maxPriceLength: testInit.data.fastPeriod,
        smaConfig: new SmaClassV2({period: testInit.data.slowPeriod}).getConfig(),
      });
    }

    // this is config
    return zStochasticConfigV2.strict().parse(params);
  }

  /**
   * map command string to init variable
   */
  override mapCmdToInit(s: string[]): StochasticInitV2 {
    const fastPeriod = s[0] ? convertStrToInt(s[0]) : undefined;
    const slowPeriod = s[1] ? convertStrToInt(s[1]) : undefined;
    return zStochasticInitV2.parse({fastPeriod, slowPeriod});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): IndicatorCandle {
    return candle;
  }

  /**
   * Check if cross over occured, undefined means no data point
   * @param location position to check (default = -1)
   * @returns true when cross over occured, false when not and undefined when cannot identify
   */
  isCrossOver(location = -1): boolean {
    try {
      const current = this.config.results.at(location);
      const before = this.config.results.at(location - 1);
      if (!current || !before) return false;
      return UtilNumber.isCrossover(before.k, current.k, before.d, current.d);
    } catch (e) {
      return false;
    }
  }

  /**
   * Check if cross under occured, undefined means no data point
   * @param location position to check (default = -1)
   * @returns true when cross under occured, false when not and undefined when cannot identify
   */
  isCrossUnder(location = -1): boolean {
    try {
      const current = this.config.results.at(location);
      const before = this.config.results.at(location - 1);
      if (!current || !before) return false;
      return UtilNumber.isCrossunder(before.k, current.k, before.d, current.d);
    } catch (e) {
      return false;
    }
  }
}

import {AppBar, Box, Toolbar} from '@mui/material';
import {envLocalDb} from '../../logic/helper/env';
import HeaderButtons, {HeaderButtonsProps} from './HeaderButtons';
import HeaderMenu from './HeaderMenu';
import {HeaderMenuDrawerListItemProps} from './HeaderMenuDrawerListItem';
import HeaderTitle, {IHeaderTitleProps} from './HeaderTitle';

export interface IHeaderProps extends IHeaderTitleProps, HeaderButtonsProps {}

function Header(props: IHeaderProps) {
  const menu: HeaderMenuDrawerListItemProps[] = [
    {text: 'Positions', url: '/positions'},
    // { text: 'Orphan Positions', url: '/orphan' },
    {text: 'Market', url: '/market'},
    {text: 'Analysis', url: '/analysis'},
    {text: 'Summary', url: '/summary'},
    {text: 'Match positions', url: '/match'},
    {text: 'Match algo', url: '/matchAlgo'},
    {text: 'Mortgage calculator', url: '/mortgage'},
  ];

  const {title, user} = props;

  return (
    <AppBar position="static" color={envLocalDb() ? 'secondary' : 'primary'}>
      <Toolbar>
        {user && <HeaderMenu menu={menu} />}
        <HeaderTitle title={title} />

        <Box sx={{flexGrow: 1}} />

        <HeaderButtons user={user} />
      </Toolbar>
    </AppBar>
  );
}

export default Header;

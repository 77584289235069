import {IgPriceCandle} from '@algo/broker-ig';
import createCandleGraphAtomProps from '../graph/candle/CandleGraphAtomProps';
import mapIgPriceCandleToVictoryCandlestick from '../graph/candle/mapIgPriceCandleToVictoryCandlestick';

export default function createCandleGraphFromPriceCandles(candles: IgPriceCandle[]) {
  return createCandleGraphAtomProps('price', 'main', candles.map(mapIgPriceCandleToVictoryCandlestick), {
    positive: '#5f5c5b',
    negative: '#c43a31',
  });
}

import {sortIPositionV2CloseTs} from '@algo/helper';
import {IPositionV2} from '@algo/model';

/**
 * Filter positions to latest max number of positions
 * @param positions
 * @param maxLatestPosition
 * @returns
 */
export default function positionFilterMaxLatestData(positions: IPositionV2[], maxLatestPosition?: number) {
  if (!maxLatestPosition || maxLatestPosition <= 0) return positions;

  const newPosition = [...positions];
  newPosition.sort(sortIPositionV2CloseTs(false));

  // filter for matching profit and filter
  return newPosition.slice(0, maxLatestPosition);
}

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {RsiOutputV2, zRsiOutputV2} from './RsiOutputV2';

/**
 * rsi config
 */
export interface RsiConfigV2 extends IndicatorConfig<RsiOutputV2, number> {
  type: 'rsiConfigV2';
  /** period to check, min 3 */
  period: number;
  previousGain: number;
  previousLoss: number;
  hloc: IndicatorCandlePriceEnum;
}

// export const initRsiConfig: RsiConfig = {
//   ...initIndicatorConfig,
//   period: 3,
// };

export const zRsiConfigV2 = zIndicatorConfig(zRsiOutputV2, number()).merge(
  object({
    type: literal('rsiConfigV2').default('rsiConfigV2'),
    period: number().min(3).default(14),
    previousGain: number().default(0),
    previousLoss: number().default(0),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<RsiConfigV2, any, any>;

export const initRsiConfigV2 = zRsiConfigV2.parse({});

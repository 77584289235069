/**
 * Extract currency string if currency
 * @param epic
 * @returns undefined if not currency epic
 */
export function extractIgEpicToCurrency(epic: string) {
  if (!/^CS\.D\..*\.TODAY\.IP$/gm.test(epic)) return undefined;
  const epicArr = epic.split('.');
  return epicArr[2] as string;
}

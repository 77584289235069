import {IPositionV2} from '@algo/model';
import {Backdrop, Paper} from '@mui/material';
import PositionTile from '../../components/PositionTile/PositionTile';

export interface AnalysisDetailProps {
  position?: IPositionV2;
  setPositionDetail: (position: IPositionV2 | undefined) => void;
}

export default function AnalysisDetail(props: AnalysisDetailProps) {
  const {position, setPositionDetail} = props;
  return (
    <Backdrop open={!!position} onClick={() => setPositionDetail(undefined)} sx={{zIndex: 999}}>
      <Paper sx={{margin: 2, padding: 2, width: '50%'}}>{position && <PositionTile position={position} />}</Paper>
    </Backdrop>
  );
}

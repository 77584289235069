import {IgUserToken} from '../create';
import {getWatchlist} from '../watchlist/getWatchlist';

/**
 * Get a list of epics from given watchlist
 */
export async function igHelperGetWatchlistEpics(userToken: IgUserToken, watchlistId: string): Promise<string[]> {
  console.debug('igHelperGetWatchlistEpics');
  const watchlist = await getWatchlist(userToken, watchlistId);
  if (!watchlist.data) return [];
  return watchlist.data.markets.map((market) => market.epic);
}

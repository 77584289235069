import {mean} from 'mathjs';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import mapIndicatorV3InputToNumber from '../../common/template/v3/mapIndicatorV3InputToNumber';
import convertStrToInt from '../../common/util/convertStrToInt';
import {SmaConfigV3, zSmaConfigV3} from './interface/SmaConfigV3';
import {SmaInitV3, zSmaInitV3} from './interface/SmaInitV3';
import {SmaOutputV3} from './interface/SmaOutputV3';

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<SmaInitV3>) {
  const parsedInit = zSmaInitV3.strict().parse(init);

  return zSmaConfigV3.strip().parse({
    ...parsedInit,
    maxInputLength: parsedInit.period,
  });
}

/**
 * Calculate sma
 * @param config
 * @returns
 */
function calculate(config: SmaConfigV3): IndicatorReturnV3<SmaConfigV3, SmaOutputV3> {
  return {config, result: mean(config.input)};
}

/**
 * SMA indicator
 */
export const smaV3 = createIndicatorV3<SmaInitV3, SmaConfigV3, SmaOutputV3, number>(
  IndicatorTypeEnum.SMA,
  createConfig,
  calculate,
  (s) => zSmaInitV3.parse({period: convertStrToInt(s[0]), hloc: convertStrToInt(s[1])}),
  mapIndicatorV3InputToNumber,
  zSmaConfigV3,
);

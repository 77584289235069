import {object, string, ZodType} from 'zod';

/**
 * Description of individual parameter
 */
export interface ISignalParameter {
  /** name of parameter */
  name: string;
  /** description of parameter */
  description: string;
}

export const initISignalParameter: ISignalParameter = {
  name: '',
  description: '',
};

export const zISignalParameter: ZodType<ISignalParameter> = object({
  name: string(),
  description: string(),
});

import {IPositionV2, PositionDirectionEnum} from '@algo/model';
import {Grid, Chip} from '@mui/material';

export interface PositionTilePriceProps {
  position: IPositionV2;
}

export default function PositionTilePrice(props: PositionTilePriceProps) {
  const {position} = props;
  const isLong = position.direction === PositionDirectionEnum.LONG;
  const isProfit = (isLong && position.open < position.close) || (!isLong && position.open > position.close);
  return (
    <Grid container spacing={2} alignItems="center" direction="row" wrap="wrap">
      <Grid item>Price:</Grid>
      <Grid item>{`${position.open} => ${position.close}`}</Grid>
      <Grid item>
        <Chip
          label={`${((isLong ? 1 : -1) * (position.close - position.open)).toFixed(2)}`}
          color={isProfit ? 'success' : 'error'}
        />
      </Grid>
    </Grid>
  );
}

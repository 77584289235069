import {filterArrayUndefined, mapPromiseFnSeries, sleepMs} from '@coolcolduk/util';
import {IgUserToken} from '../create/createIgUserToken';
import {IgDirectionEnum} from '../enum/IgDirectionEnum';
import {IgOpenPositionMarketOption, igHelperOpenPositionMarket} from '../igHelper/igHelperOpenPositionMarket';
import {confirmIgPosition} from '../position/confirmIgPosition';

export interface IgServiceOpenPositionMarketOrder {
  epic: string;
  size: number;
  direction?: IgDirectionEnum;
  options?: IgOpenPositionMarketOption;
}

/**
 * Open market order in bulk and resolve all deal reference
 */
export async function igServiceOpenPositionMarketDetail(
  userToken: IgUserToken,
  orders: IgServiceOpenPositionMarketOrder[],
) {
  console.debug('igServiceOpenPositionMarketDetail');

  const orderResults = await mapPromiseFnSeries(orders, async (order) => {
    const r = await igHelperOpenPositionMarket(userToken, order.epic, order.size, order.direction, order.options);
    await sleepMs(300);
    return r;
  });

  // get deal reference
  const confirmResult = await mapPromiseFnSeries(orderResults, async (order) => {
    const r = order && order.data ? await confirmIgPosition(userToken, order.data.dealReference) : undefined;
    await sleepMs(300);
    return r;
  });

  return filterArrayUndefined(confirmResult);
}

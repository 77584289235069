import {CollectionBaseEnum} from '../enum/CollectionBaseEnum';

/**
 * collection path of strategy
 * @returns
 */
export function getPathStrategyCollection() {
  console.debug('getPathStrategyCollection');
  return CollectionBaseEnum.STRATEGY;
}

export interface IFilter {
  /** 1 to filter for profit, -1 loss and undefined no filter */
  profitLoss?: number;

  /** included tags */
  includeTags?: string[];

  /** exclude tags */
  excludeTags?: string[];

  /** maximum number of latest positions */
  maxLatestPosition?: number;
}

export const initIFilter: IFilter = {};
// export const initIFilter: IFilter = {includeTags: [], excludeTags: [], profitLoss: 0, maxLatestPosition: 0};

import {IgPositionGetV2Response} from '@algo/broker-ig';
import {mapAssetFromIgPosition} from '@algo/helper';
import {IAssetRecord} from 'model/IAssetRecord';

/**
 * Convert data format from ig to asset record
 * @param data
 * @returns
 */
export default function convertIgPositionResponseToIAssetRecord(data?: IgPositionGetV2Response[]): IAssetRecord {
  console.debug('useLoadIgDualPositionsActive.convertIgPositionResponseToIAssetRecord');
  if (!data) return {};
  const assetArr = data.map(mapAssetFromIgPosition);
  return assetArr.reduce((prev, cur) => ({...prev, [cur.id]: cur}), {});
}

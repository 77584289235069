import {Button, ButtonProps} from '@mui/material';
import {useState} from 'react';
import DropdownListMenu, {DropdownListMenuProps} from './DropdownListMenu';

export interface DropdownListOrganismProps<T extends string>
  extends Pick<DropdownListMenuProps<T>, 'label' | 'items' | 'onClickHandle'>,
    Pick<ButtonProps, 'sx'> {
  /** Item selected */
  selectedItem: T;
}

/**
 * Create a button that pop a dropdown when clicked for selection
 * @param props
 * @returns
 */
export default function DropdownListOrganism<T extends string>(props: DropdownListOrganismProps<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {onClickHandle, items, label, selectedItem, sx} = props;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (items.length > 0) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-label={label}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleMenu}
        color="inherit"
        variant="outlined"
        sx={sx}
      >
        {selectedItem || label}
      </Button>
      <DropdownListMenu
        anchorEl={anchorEl}
        onCloseHandle={handleClose}
        label={label}
        items={items}
        onClickHandle={onClickHandle}
      />
    </>
  );
}

import {number, object, ZodType} from 'zod';

export interface IndicatorInitV3 {
  maxInputLength: number;
  // hloc: IndicatorCandlePriceEnum;
  maxResultLength: number;
}

export const zIndicatorInitV3 = object({
  maxInputLength: number().min(1).default(10),
  // hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  maxResultLength: number().min(1).default(10),
}) satisfies ZodType<IndicatorInitV3, any, any>;

/**
 * Type for input only, field with default is optional. Everything should be partial in Init type
 */
// export type IndicatorInit = input<typeof zIndicatorInit>;

export const initIndicatorInitV3 = zIndicatorInitV3.parse({});

import {ApolloClient, InMemoryCache} from '@apollo/client';
import {isEnvDev} from '../env';

export default function createApolloClient(headers?: Record<string, string>) {
  return new ApolloClient({
    uri: isEnvDev()
      ? 'http://127.0.0.1:5001/algo-trading-c9daa/europe-west2/graphql'
      : 'https://europe-west2-algo-trading-c9daa.cloudfunctions.net/graphql',
    cache: new InMemoryCache(),
    headers,
  });
}

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';

export interface IgWatchlistDeleteV1Response {
  status: 'SUCCESS';
}
/**
 * delete watchlist
 * @param userToken
 * @param watchlistId
 */
export async function deleteWatchlist(userToken: IgUserToken, watchlistId: string) {
  console.debug('deleteWatchlist');
  const result = await sendIgRequest<IgWatchlistDeleteV1Response>(userToken, `/watchlists/${watchlistId}`, 'delete', 1);

  return createIgResponse(userToken, result);
}

import {getPathUserCollection} from './getPathUserCollection';

/**
 * collection for internal data
 * @param uid
 * @returns
 */
export function getPathUserInternalCollection(uid: string) {
  console.debug('getPathUserInternalCollection');
  return `${getPathUserCollection()}/${uid}/internal`;
}

import {sleepMs} from '@coolcolduk/util';
import {IgUserToken} from '../create';
import {createIgResponseData} from '../create/createIgResponseData';
import {IgPriceResolutionEnum} from '../enum/IgPriceResolutionEnum';
import {IgPriceCandle} from '../interface';
import {getIgPrice} from '../price/getIgPrice';

/**
 * Download all price within timestamp
 */
export async function igServiceDownloadPriceDatetimeRange(
  userToken: IgUserToken,
  epic: string,
  resolution: IgPriceResolutionEnum,
  from: string,
  to: string,
) {
  console.debug('igServiceDownloadPriceDatetimeRange');

  const result: IgPriceCandle[][] = [];
  const pageSize = 100;
  let pageNumber = 1;
  let totalPages = 99;
  while (pageNumber <= totalPages) {
    /* eslint-disable no-await-in-loop */
    await sleepMs(300);
    /* eslint-disable no-await-in-loop */
    const data = await getIgPrice(userToken, epic, {from, to, resolution, pageSize, pageNumber});
    if (!data.data) {
      console.error(data.error);
      pageNumber = totalPages + 1;
      break;
    } else {
      result.push(data.data.prices);
      totalPages = data.data.metadata.pageData.totalPages;
      pageNumber = data.data.metadata.pageData.pageNumber + 1;
    }
  }

  return createIgResponseData(
    userToken,
    result.reduce((prev, cur) => [...prev, ...cur], [] as IgPriceCandle[]),
  );
}

/**
 * Check if lineA cross over (below to above) line B
 * @param beforeA
 * @param afterA
 * @param beforeB
 * @param afterB
 * @returns true when line A goes from below to above line B
 */
export function isCrossover(beforeA: number, afterA: number, beforeB: number, afterB: number): boolean {
  return beforeA < beforeB && afterA > afterB;
}

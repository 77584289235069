import {CollectionReference, doc} from 'firebase/firestore';
import {firestoreSaveDoc} from './firestoreSaveDoc';

/**
 * Firestore helper to save doc given collection and name
 * @param collection
 * @param name
 * @param data
 * @param isMerge
 * @returns
 */
export function firestoreSaveDocToCollection<T extends Record<string, any>>(
  collection: CollectionReference<T>,
  name: string,
  data: T,
  isMerge = false,
): Promise<void> {
  return firestoreSaveDoc(doc(collection, name), data, isMerge);
}

import {IPositionV2} from '@algo/model';
import {UtilArrayStr} from '@algo/util';

/**
 * Filter positions for tags
 * @param positions
 * @param tags
 * @param isInclude define if the list of tags are included or excluded
 * @returns
 */
export default function positionFilterTags(positions: IPositionV2[], tags: string[] | undefined, isInclude: boolean) {
  // no filter for empty list
  if (!tags || tags.length === 0) return positions;

  // filter for matching profit and filter
  return positions.filter((p) => UtilArrayStr.isIntersection(p.tags, tags) === isInclude);
}

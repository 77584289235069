import {IPositionV2} from '@algo/model';
import {mapPromiseFnSeries} from '@coolcolduk/util';
import {Firestore, where} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {createFirestoreStrategyUnknownCollectionHelper} from '../../firestore';

/**
 * Download all trades of given script and strategy name and return as record
 * @param fs
 * @param scriptName
 * @param strategyName
 * @returns record of doc id and position
 */
async function downloadData(
  fs: Firestore,
  strategyName: string,
  algoName: string,
): Promise<Record<string, IPositionV2>> {
  return (
    (await createFirestoreStrategyUnknownCollectionHelper<IPositionV2>(fs, strategyName, algoName).loadAll(
      where('close', '==', 0),
    )) || {}
  );
}

/**
 *
 * @param fs
 * @param scriptName
 * @param strategyList
 * @returns record of strategy name and positions
 */
async function downloadAllData(
  fs: Firestore,
  strategyName: string,
  algoList: string[],
): Promise<Record<string, Record<string, IPositionV2>>> {
  const downloadedDataArray = await mapPromiseFnSeries(algoList, async (algoName) => {
    const strategyPosition = await downloadData(fs, strategyName, algoName);
    return {algoName, data: strategyPosition};
  });

  return downloadedDataArray.reduce(
    (prev, cur) => ({...prev, [cur.algoName]: cur.data}),
    {} as Record<string, Record<string, IPositionV2>>,
  );
}

/**
 * Get open position from algoCopier of given algo name
 * @param firestoreApp
 * @param strategyName
 * @param algoList
 * @param reloadTrigger trigger to reload, put in random string to trigger reload
 * @returns
 */
export function useLoadStrategyAlgoCopierOpen(
  firestoreApp?: Firestore,
  strategyName: string = '',
  algoList: string[] = [],
  reloadTrigger = '',
): Record<string, Record<string, IPositionV2>> {
  console.debug('useLoadStrategyAlgoCopierOpen');
  const [data, setData] = useState<Record<string, Record<string, IPositionV2>>>({});

  useEffect(() => {
    if (firestoreApp) {
      downloadAllData(firestoreApp, strategyName, algoList).then((result) => setData(result));
    } else {
      setData({});
    }
    // eslint-disable-next-line
  }, [firestoreApp, strategyName, JSON.stringify(algoList), reloadTrigger]);

  return data;
}

import {getPathStrategyCollection} from './getPathStrategyCollection';

/**
 * path /strategy/`script`/`strategy`/`dealId in paper trade`
 * @param scriptName running script name
 * @param strategyName algo strategy
 * @returns
 */
export function getPathStrategyAlgoCollection(scriptName: string, strategyName: string) {
  console.debug('getPathStrategyAlgoCollection');
  return `${getPathStrategyCollection()}/${scriptName}/${strategyName}`;
}

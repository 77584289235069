import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {createIgUserToken} from '../create/createIgUserToken';
import {createIgUserTokenKey, IgUserTokenKey} from '../create/createIgUserTokenKey';
import {IgAccountTypeEnum} from '../enum/IgAccountTypeEnum';
import {IgEnvironmentEnum} from '../enum/IgEnvironmentEnum';
import {generateTokenExpiry} from '../generate';
import {IgAccountDetail} from '../interface/account/IgAccountDetail';
import {IgAccountInfo} from '../interface/account/IgAccountInfo';
import {IgSendRequestBody} from '../interface/internal/IgSendRequestBody';

export interface IgSessionPostV2Request extends IgSendRequestBody {
  /** Whether the password has been sent encrypted. */
  encryptedPassword: boolean;

  /** Client login identifier */
  identifier: string;

  /** Client login password */
  password: string;
}

export interface IgSessionPostV2Response {
  accountInfo: IgAccountInfo;
  accountType: IgAccountTypeEnum;
  accounts: IgAccountDetail[];
  clientId: string;
  currencyIsoCode: string;
  currencySymbol: string;
  currentAccountId: string;
  dealingEnabled: boolean;
  hasActiveDemoAccounts: boolean;
  hasActiveLiveAccounts: boolean;
  lightstreamerEndpoint: string;
  reroutingEnvironment: IgEnvironmentEnum | null;
  timezoneOffset: number;
  trailingStopsEnabled: boolean;
}

/**
 * Login and get token from broker
 * @param userToken
 * @param username
 * @param password
 * @returns response
 * @throws {UserException}
 */
export async function loginIgSession(userToken: IgUserTokenKey, username: string, password: string) {
  console.debug('loginIgSession');
  const body: IgSessionPostV2Request = {
    identifier: username,
    password,
    encryptedPassword: false,
  };
  const result = await sendIgRequest<IgSessionPostV2Response>(userToken, '/session', 'post', 2, {}, {}, body);

  return createIgResponse(
    result.response
      ? createIgUserToken(
          userToken.apiKey,
          userToken.isLive,
          result.response.headers['x-security-token'] || '',
          result.response.headers['cst'] || '',
          result.response.data.currentAccountId,
          result.response.data.lightstreamerEndpoint,
          generateTokenExpiry(),
        )
      : createIgUserTokenKey(userToken.apiKey, userToken.isLive),
    result,
  );
}

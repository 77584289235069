import {number, object, ZodType} from 'zod';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface SuperTrendInitV3 extends IndicatorInitV3 {
  /** Period of ATR */
  period: number;
  /** what the ATR is multiplied by to offset the bands from price */
  multiplier: number;
}

export const zSuperTrendInitV3 = zIndicatorInitV3.merge(
  object({
    period: number().int().min(3).default(10),
    multiplier: number().min(0.1).default(3),
  }),
) satisfies ZodType<SuperTrendInitV3, any, any>;

export const initSuperTrendInitV3 = zSuperTrendInitV3.parse({});

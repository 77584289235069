import {IPositionV2, PositionDirectionEnum} from '@algo/model';

/**
 * Calculate profit based on direction and open and close price
 * @param position
 * @param closePrice optional close price for calculating profit
 * @returns
 */
export function calculateIPositionV2Profit(position: IPositionV2, closePrice?: number) {
  const actualClosePrice = closePrice !== undefined ? closePrice : position.close;
  return position.direction === PositionDirectionEnum.LONG
    ? actualClosePrice - position.open
    : position.open - actualClosePrice;
}

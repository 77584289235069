import {AxiosResponse} from 'axios';

/**
 * Check if axios reponse returned from IG server has status code within successful range
 * @param axiosResponse
 * @returns
 */
export function isIgResponseValid<TResult>(axiosResponse: AxiosResponse<TResult>) {
  return axiosResponse.status >= 200 && axiosResponse.status < 300;
}

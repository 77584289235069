import {IgDirectionEnum, IgPositionConfirmV1Response} from '@algo/broker-ig';
import {PositionDirectionEnum} from '@algo/model';
import {createPosition} from '../../create/IPosition/createPosition';

/**
 * Convert IgPositionConfirm to IPosition record
 * @param querySnapshot
 * @returns
 */
export function mapPositionFromIgPositionConfirm(
  openData: IgPositionConfirmV1Response,
  closeData?: IgPositionConfirmV1Response,
) {
  return createPosition({
    id: openData.dealId,
    broker: 'ig',
    assetId: openData.epic,
    size: openData.size,
    direction: openData.direction === IgDirectionEnum.BUY ? PositionDirectionEnum.LONG : PositionDirectionEnum.SHORT,
    open: openData.level,
    openTsUtc: openData.date,
    ...(closeData
      ? {
          close: closeData.level,
          closeTsUtc: closeData.date || undefined,
          limit: closeData.limitLevel || undefined,
          stop: closeData.stopLevel || undefined,
        }
      : {}),
  });
}

import {sleepMs} from '@coolcolduk/util';
import {createIgResponse, IgResponse, IgUserToken} from '../create';
import {getWatchlist, IgWatchlistGetV1Response, listWatchlist} from '../watchlist';

/**
 * List watchlist to find watchlist name, then get watchlist information
 */
export async function igHelperGetWatchlistByName(
  userToken: IgUserToken,
  watchlistName: string,
): Promise<IgResponse<IgWatchlistGetV1Response> | undefined> {
  console.debug('igHelperGetWatchlistByName');
  const listResult = await listWatchlist(userToken);

  // check if it exists
  if (!listResult.data) {
    // when no data exists, it will only have the error hence the cast should not affect the result
    return createIgResponse(userToken, listResult) as IgResponse<IgWatchlistGetV1Response>;
  }

  // find required watchlist
  const matchWatchlist = listResult.data.watchlists.find((w) => w.name === watchlistName);
  if (!matchWatchlist) return undefined;

  // get watchlist information
  await sleepMs(300);
  const getResult = await getWatchlist(userToken, matchWatchlist.id);

  return createIgResponse(userToken, getResult) as IgResponse<IgWatchlistGetV1Response>;
}

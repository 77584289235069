import {UtilRegex} from '@algo/util';
import {z} from 'zod';

/**
 * House mortgage calculation data
 */
export interface IUserHouse {
  /** id and identifier for user */
  name: string;
  /** Price of house */
  price: string;
  /** Total cash available */
  cash: string;
  /** Expected fee */
  fee: string;

  /** Mortgage rate in percentage */
  ratePct: string;
  /** Mortgage terms in years */
  termsYrs: string;

  /** First time buyer */
  firstTimeBuyer: boolean;
  /** Addition home */
  additionalHome: boolean;
  /** Overseas */
  oversea: boolean;
}

export const initIUserHouse: IUserHouse = {
  name: 'a',
  price: '400000',
  cash: '100000',
  fee: '4000',
  ratePct: '4.5',
  termsYrs: '30',
  firstTimeBuyer: false,
  additionalHome: false,
  oversea: false,
};

export const zIUserHouse: z.ZodType<IUserHouse> = z.object({
  name: z.string(),
  price: z.string().regex(UtilRegex.intOrZero),
  cash: z.string().regex(UtilRegex.intOrZero),
  fee: z.string().regex(UtilRegex.intOrZero),
  ratePct: z.string().regex(UtilRegex.floatOrZero),
  termsYrs: z.string().regex(UtilRegex.intOrZero),
  firstTimeBuyer: z.boolean(),
  additionalHome: z.boolean(),
  oversea: z.boolean(),
});

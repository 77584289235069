/**
 * Calculate mortgage monthly repayment
 * @param principal loan amount
 * @param interestPctPa interest in percentage, PA
 * @param termsYears terms in years
 * @returns
 */
export default function calculateMonthlyRepayment(principal: number, interestPctPa: number, termsYears: number) {
  const interest = interestPctPa / 1200;
  const termsMonth = termsYears * 12;
  const interestTerms = (1 + interest) ** termsMonth;
  // M = P [ i(1 + i)^n ] / [ (1 + i)^n – 1]
  return (principal * interest * interestTerms) / (interestTerms - 1);
}

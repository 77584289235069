import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import mapIndicatorV3InputToCandle from '../../common/template/v3/mapIndicatorV3InputToCandle';
import convertStrToInt from '../../common/util/convertStrToInt';
import {smaV3} from '../../movingAverage';
import {StochasticConfigV3, zStochasticConfigV3} from './interface/StochasticConfigV3';
import {StochasticInitV3, zStochasticInitV3} from './interface/StochasticInitV3';
import {StochasticOutputV3} from './interface/StochasticOutputV3';

/**
 * Calculate Stochastic K value
 * @param data new config
 * @returns
 */
function stochasticCalculateK(data: IndicatorCandle[]): number {
  const tick = data.at(-1) as IndicatorCandle;
  const periodLow = Math.min(...data.map((d) => d.low));
  const periodHigh = Math.max(...data.map((d) => d.high));
  const calculatedK = ((tick.close - periodLow) / (periodHigh - periodLow)) * 100;
  return Number.isNaN(calculatedK) ? 0 : calculatedK;
}

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<StochasticInitV3>) {
  const parsedInit = zStochasticInitV3.strict().parse(init);

  return zStochasticConfigV3.strip().parse({
    ...parsedInit,
    maxInputLength: parsedInit.fastPeriod,
    smaConfig: smaV3.createConfig({period: parsedInit.slowPeriod}),
  });
}

/**
 * Calculate Stochastic
 * @param config
 * @returns
 */
function calculate(
  config: StochasticConfigV3,
): IndicatorReturnV3<StochasticConfigV3, StochasticOutputV3, IndicatorCandle> {
  const k = stochasticCalculateK(config.input);

  const smaResult = smaV3.nextValue(config.smaConfig, k);

  return {
    config: {
      ...config,
      smaConfig: smaResult.config,
    },
    result: {k, d: smaResult.result},
  };
}

/**
 * Stochastic indicator
 */
export const stocV3 = createIndicatorV3<StochasticInitV3, StochasticConfigV3, StochasticOutputV3, IndicatorCandle>(
  IndicatorTypeEnum.STOCHASTIC,
  createConfig,
  calculate,
  (s) =>
    zStochasticInitV3.parse({
      fastPeriod: convertStrToInt(s[0]),
      slowPeriod: convertStrToInt(s[1]),
    }),
  mapIndicatorV3InputToCandle,
  zStochasticConfigV3,
);

import {IPositionV2} from '@algo/model';
import {Chip, Grid} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';

export const tagsColumn = (
  setTags: (position: IPositionV2, tags: string[]) => void,
  // allTags?: string[],
) =>
  ({
    field: 'tags',
    headerName: 'Tags',
    flex: 150,
    align: 'left',
    headerAlign: 'center',
    editable: true,
    maxWidth: 800,
    renderCell: (params: GridRenderCellParams<IPositionV2, string, string>) => {
      const val = params.value || '';
      const tags = val.split(',').filter((s) => s.trim());
      if (tags.length === 0) return null;
      return (
        <Grid container wrap="wrap">
          {val.split(',').map((tag: string) => (
            <Grid key={`AnalysisTable-${params.row.id}-${tag}`}>
              <Chip sx={{fontSize: '0.7rem', padding: 0, margin: 0}} color="primary" label={tag} size="small" />
            </Grid>
          ))}
        </Grid>
      );
    },
    valueGetter: (value: string[]) => (value || []).join(','),
    // renderEditCell,
    valueSetter: (value: string, row: IPositionV2) => {
      const tagStr = (value as string) || '';
      const tags = tagStr.split(',');
      setTags(row, tags);
      return {...row, tags};
    },
  }) as GridColDef;

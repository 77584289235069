import {nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

export interface MacdInitV2 extends IndicatorInit {
  slowPeriod: number;
  fastPeriod: number;
  signalPeriod: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zMacdInitV2 = zIndicatorInit.merge(
  object({
    slowPeriod: number().min(3).default(26),
    fastPeriod: number().min(3).default(12),
    signalPeriod: number().min(3).default(9),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<MacdInitV2, any, any>;

export const initMacdInitV2 = zMacdInitV2.parse({});

import {IPositionV2} from '@algo/model';
import {isIPositionV2Closed} from '../../status/IPositionV2/isIPositionV2Closed';
import {calculateIPositionV2Profit} from '../IPositionV2/calculateIPositionV2Profit';

/**
 * Calculate profit based on direction and open and close price
 * @param position
 * @returns
 */
export function calculateIPositionV2ArrProfit(positions: IPositionV2[]) {
  return positions.map((position) => (isIPositionV2Closed(position) ? calculateIPositionV2Profit(position) : 0));
}

import {GraphQLError} from 'graphql';
import {ErrorEnum} from '../enum/ErrorEnum';

export class GqlNotFound extends GraphQLError {
  constructor(message: string) {
    super(message, {
      extensions: {
        code: ErrorEnum.NOT_FOUND,
      },
    });
  }
}

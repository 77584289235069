import {IgPriceCandle, IgPriceResolutionEnum} from '@algo/broker-ig';
import {sortIgPriceCandleSnapshotTime} from '@algo/helper';
import {Firestore} from 'firebase/firestore';
import {useListPriceEpicResolution} from './useListPriceEpicResolution';

/**
 * Get price candle for given epic+resolution in ascending order array
 * @returns list of price candle
 */
export function useListPriceEpicResolutionArray(
  firestoreApp: Firestore,
  epic?: string,
  resolution?: IgPriceResolutionEnum,
  startDate?: string,
  endDate?: string,
  triggerReload?: boolean,
): IgPriceCandle[] {
  console.debug('useListPriceEpicResolutionArray');
  const data = useListPriceEpicResolution(firestoreApp, epic, resolution, startDate, endDate, triggerReload);

  const dataArr = Object.values(data);
  dataArr.sort(sortIgPriceCandleSnapshotTime(true));
  return dataArr;
}

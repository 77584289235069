import {std} from 'mathjs';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import mapIndicatorV3InputToNumber from '../../common/template/v3/mapIndicatorV3InputToNumber';
import convertStrToInt from '../../common/util/convertStrToInt';
import {SdConfigV3, zSdConfigV3} from './interface/SdConfigV3';
import {SdInitV3, zSdInitV3} from './interface/SdInitV3';
import {SdOutputV3} from './interface/SdOutputV3';

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<SdInitV3>) {
  const parsedInit = zSdInitV3.strict().parse(init);

  return zSdConfigV3.strip().parse({
    ...parsedInit,
    maxInputLength: parsedInit.period,
  });
}

/**
 * Calculate sd
 * @param config
 * @returns
 */
function calculate(config: SdConfigV3): IndicatorReturnV3<SdConfigV3, SdOutputV3> {
  return {config, result: std(config.input, 'uncorrected') as number};
}

/**
 * SD indicator
 */
export const sdV3 = createIndicatorV3<SdInitV3, SdConfigV3, SdOutputV3, number>(
  IndicatorTypeEnum.SD,
  createConfig,
  calculate,
  (s) => zSdInitV3.parse({period: convertStrToInt(s[0]), hloc: convertStrToInt(s[1])}),
  mapIndicatorV3InputToNumber,
  zSdConfigV3,
);

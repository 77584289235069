import {z} from 'zod';
import {IgActivityTypeEnum, IgDealStatusEnum} from '../../enum';
import {IgChannelEnum} from '../../enum/IgChannelEnum';
import {IgActivityDetail, zIgActivityDetail} from './IgActivityDetail';

export interface IgActivity {
  channel: IgChannelEnum;
  /** The date of the activity item e.g. 2022-12-27T16:56:15 */
  date: string;
  /** Deal identifier, unique to each deal with ending being position id e.g. DIAAAALGA2NZYA2 */
  dealId: string;
  /** Activity description e.g. Position opened: GA2NZYA2 */
  description: string;
  /** Activity details, provided if true */
  details: null | IgActivityDetail;
  /** Instrument epic identifier e.g. CS.D.EURUSD.TODAY.IP */
  epic: string;
  /** The period of the activity item, e.g. "DFB" or "02-SEP-11". This will be the expiry time/date for sprint markets, e.g. "2015-10-13T12:42:05" */
  period: string;
  /** Action status e.g. ACCEPTED */
  status: IgDealStatusEnum;
  /** Activity type */
  type: IgActivityTypeEnum;
}

export const zIgActivity: z.ZodType<IgActivity> = z.object({
  channel: z.nativeEnum(IgChannelEnum),
  date: z.string().regex(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/),
  dealId: z.string().min(1),
  description: z.string().min(1),
  details: zIgActivityDetail.nullable(),
  epic: z.string().min(1),
  period: z.string().min(1),
  status: z.nativeEnum(IgDealStatusEnum),
  type: z.nativeEnum(IgActivityTypeEnum),
});

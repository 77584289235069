/**
 * Status returned from ig api
 * Key is used internally, value is error code
 */
export enum IgStatusEnum {
  /** No error */
  OK = 'OK',

  /** unknown error */
  UNKNOWN = 'UNKNOWN',

  /** Size cannot be null */
  REQUEST_SIZE_NULL = 'validation.null-not-allowed.request.size',

  /** Position not existed or already closed */
  POSITION_NOT_FOUND = 'error.position.notfound',

  /** account token invalid, may require to login again */
  ACCOUNT_TOKEN_INVALID = 'error.security.account-token-invalid',

  /** client token invalid */
  CLIENT_TOKEN_INVALID = 'error.security.client-token-invalid',

  /** Watchlist not exists */
  WATCHLIST_NOT_FOUND = 'error.watchlists.management.watchlist-not-found',

  /** Market currently offline */
  MARKET_OFFLINE = 'MARKET_OFFLINE',

  /** Uncertain of cause, but order was closed by another order */
  MARKETDATA_POSITION_NOTATIONAL_NULL = 'error.service.marketdata.position.notional.details.null.error',
}

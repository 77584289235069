/**
 * Get header to use for request
 * @param header string to append to header
 * @param version version number
 * @returns
 */
export default function getIgRequestHeader(
  apiKey: string,
  accountToken?: string,
  clientToken?: string,
  header: Record<string, string> = {},
  version = 1,
) {
  console.debug('getIgRequestHeader');

  return {
    ...(apiKey ? {'X-IG-API-KEY': apiKey} : {}),
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json; charset=UTF-8',
    ...(accountToken ? {'X-SECURITY-TOKEN': accountToken} : {}),
    ...(clientToken ? {CST: clientToken} : {}),
    VERSION: version.toString(),
    ...header,
  };
}

import {IPositionV2, IPositionV2Handle} from '@algo/model';
import {Button, ButtonGroup} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';

/**
 * Not used as this collide with ProfitLoss
 */
export const detailButtonColumn = (showDetail: IPositionV2Handle, showOnGraph: IPositionV2Handle): GridColDef => ({
  field: 'detail',
  headerName: 'Functions',
  width: 150,
  align: 'center',
  headerAlign: 'center',
  editable: false,
  renderCell: (params: GridRenderCellParams<IPositionV2, string, any>) => (
    // const val = params.value || '';
    <ButtonGroup>
      <Button size="small" onClick={() => showDetail(params.row)} variant="contained">
        Details
      </Button>

      <Button size="small" onClick={() => showOnGraph(params.row)} variant="contained">
        Graph
      </Button>
    </ButtonGroup>
  ),
});

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {RsiOutputV3, zRsiOutputV3} from './RsiOutputV3';

/**
 * rsi config
 */
export interface RsiConfigV3 extends IndicatorConfigV3<RsiOutputV3, number> {
  type: 'rsiConfigV3';
  /** period to check, min 3 */
  period: number;
  previousGain: number;
  previousLoss: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zRsiConfigV3 = zIndicatorConfigV3(zRsiOutputV3, number()).merge(
  object({
    type: literal('rsiConfigV3').default('rsiConfigV3'),
    period: number().min(3).default(14),
    previousGain: number().default(0),
    previousLoss: number().default(0),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<RsiConfigV3, any, any>;

export const initRsiConfigV3 = zRsiConfigV3.parse({});

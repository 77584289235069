import {isCrossover} from './isCrossover';
import {isCrossunder} from './isCrossunder';

/**
 * Check if lineA cross line B
 * @param beforeA
 * @param afterA
 * @param beforeB
 * @param afterB
 * @returns true when crossover or crossunder
 */
export function isCross(beforeA: number, afterA: number, beforeB: number, afterB: number): boolean {
  return isCrossover(beforeA, afterA, beforeB, afterB) || isCrossunder(beforeA, afterA, beforeB, afterB);
}

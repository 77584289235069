import {IUserBroker} from '@algo/firebase-common';
import {Grid} from '@mui/material';
import DropdownListOrganism from '../../components/dropdownList/DropdownListOrganism';

export interface MatchPositionsPageProps {
  accountLeft: string;
  setAccountLeft: (s: string) => void;
  accountRight: string;
  setAccountRight: (s: string) => void;
  accounts: IUserBroker[];
}

/**
 * This is specific to personal use
 * @param props
 * @returns
 */
export default function MatchAccounts(props: MatchPositionsPageProps) {
  const {accountLeft, setAccountLeft, accountRight, setAccountRight, accounts} = props;
  return (
    <Grid container direction="row" wrap="wrap" justifyContent="space-evenly">
      <Grid item>
        <DropdownListOrganism
          selectedItem={accountLeft || 'Select account'}
          onClickHandle={setAccountLeft}
          label="account of current user on the left"
          items={accounts.map((a) => a.name).filter((a) => a !== accountRight)}
        />
      </Grid>
      <Grid item>
        <DropdownListOrganism
          selectedItem={accountRight || 'Select account'}
          onClickHandle={setAccountRight}
          label="account of current user on the right"
          items={accounts.map((a) => a.name).filter((a) => a !== accountLeft)}
        />
      </Grid>
    </Grid>
  );
}

import {IgPriceCandle} from '@algo/broker-ig';
import {IndicatorCandle} from '@algo/indicators';
import {calculateIgPriceDetailMid} from '../../calculate/IgPriceDetail/calculateIgPriceDetailMid';

/**
 * Convert price candle from IG to indicator candle
 * @param priceCandle
 * @returns
 */
export function mapIgPriceCandleToIndicatorCandle(priceCandle: IgPriceCandle): IndicatorCandle {
  return {
    high: calculateIgPriceDetailMid(priceCandle.highPrice),
    low: calculateIgPriceDetailMid(priceCandle.lowPrice),
    open: calculateIgPriceDetailMid(priceCandle.openPrice),
    close: calculateIgPriceDetailMid(priceCandle.closePrice),
  };
}

import {Help} from '@mui/icons-material';
import {Grid, Switch, Tooltip, Typography} from '@mui/material';

export interface InfoTileHeaderProps {
  title: string;
  description?: string;

  labelLeft?: string;
  labelRight?: string;
  switchState?: boolean;
  setSwitch?: (b: boolean) => void;
}

export default function InfoTileHeader(prop: InfoTileHeaderProps) {
  const {title, description, labelLeft, labelRight, switchState, setSwitch} = prop;

  const hasSwitch = switchState !== undefined && !!setSwitch;

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item>
        <Typography>
          {title}
          {description && (
            <Tooltip arrow title={description || false}>
              <Help viewBox="0 0 50 50" />
            </Tooltip>
          )}
        </Typography>
      </Grid>
      {hasSwitch ? (
        <Grid item>
          {labelLeft}
          <Switch size="small" checked={switchState} onChange={(_event, state) => setSwitch(state)} />
          {labelRight}
        </Grid>
      ) : null}
    </Grid>
  );
}

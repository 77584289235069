export enum EnvironmentNameEnum {
  DEV = 'dev',
  TEST = 'tst',
  STAGE = 'stg',
  PROD = 'prd',
}

/**
 * get current environment
 * @returns
 */
export const envEnvironment = (): EnvironmentNameEnum => {
  // eslint-disable-next-line
  if (process.env['NODE_ENV'] === 'development') return EnvironmentNameEnum.DEV;
  return EnvironmentNameEnum.PROD;
};

/**
 * Prefix environment with collection name
 * @param collectionName
 * @deprecated
 * @returns
 */
export const envCollectionName = (collectionName: string) => `${envEnvironment()}_${collectionName}`;

/**
 * Check if local DB or not
 * @returns true when REACT_APP_LOCALDB declared
 */
export const envLocalDb = () => 'REACT_APP_LOCALDB' in process.env;

export function isEnvDev() {
  return envEnvironment() === EnvironmentNameEnum.DEV;
}

import {IPositionV2} from '@algo/model';
import {castArray} from '@coolcolduk/util';
import dayjs, {OpUnitType, QUnitType} from 'dayjs';
import {sortIPositionV2CloseTs, sortIPositionV2OpenTs} from '../../sort';

/**
 * Calculate duration of trade
 * @param position
 * @param unit default milliseconds
 * @param float
 * @returns
 */
export function calculateIPositionV2Duration(
  positions: IPositionV2 | IPositionV2[],
  unit?: QUnitType | OpUnitType,
  float?: boolean,
) {
  const positionArr = castArray(positions);

  positionArr.sort(sortIPositionV2OpenTs(true));
  const openTs = positionArr[0]?.openTsUtc as string;

  positionArr.sort(sortIPositionV2CloseTs(false));
  const closeTs = positionArr[0]?.closeTsUtc;

  return dayjs(closeTs).diff(openTs, unit, float);
}

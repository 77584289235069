import {StochasticConfig} from './interface/StochasticConfig';
import {stochasticCalculate} from './stochasticCalculate';
import {stochasticInit} from './stochasticInit';

/**
 * Calculate Stochastic
 */
export class Stochastic {
  config: StochasticConfig;

  constructor(fastPeriod?: number, slowPeriod?: number) {
    this.config = stochasticInit(fastPeriod, slowPeriod);
  }

  next(high: number, low: number, close: number) {
    const result = stochasticCalculate(this.config, high, low, close);
    this.config = result.config;
    return result.result;
  }
}

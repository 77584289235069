import {ListItem, ListItemText} from '@mui/material';
import {useNavigate} from 'react-router';

export interface HeaderMenuDrawerListItemProps {
  text: string;
  url: string;
}

export default function HeaderMenuDrawerListItem(props: HeaderMenuDrawerListItemProps) {
  const navigate = useNavigate();
  const {text, url} = props;
  return (
    <ListItem button key={text}>
      <ListItemText primary={text} onClick={() => navigate(url)} />
    </ListItem>
  );
}

import {array, number, object, ZodType} from 'zod';

/**
 * SD config
 */
export interface SdConfig {
  /** Period of sd */
  period: number;
  /** Current price */
  price: number[];
}

export const initSdConfig: SdConfig = {
  period: 0,
  price: [],
};

export const zSdConfig: ZodType<SdConfig> = object({
  period: number().nonnegative(),
  price: array(number()),
});

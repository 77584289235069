/** type of fields available to display */
export type IPositionTableFieldsKeys = [
  'name',
  'size',
  'open',
  'latest',
  'stop',
  'limit',
  'profitLoss',
  'totalRisk',
  'limitRisk',
  'tags',
];

/** order to display fields in table */
export const positionTableFieldsOrder = [
  'name',
  'size',
  'open',
  'latest',
  'stop',
  'limit',
  'profitLoss',
  'totalRisk',
  'limitRisk',
  'tags',
];

/** interface for position table fields */
export type IPositionTableFields = {[k in IPositionTableFieldsKeys[number]]: string};

import {CacheCollectionEnum} from '../enum/CacheCollectionEnum';
import {getPathCacheCollection} from './getPathCacheCollection';

/**
 * cache document path for given name
 * @param cacheName
 * @returns
 */
export function getPathCacheDocument(cacheName: CacheCollectionEnum, docName?: string) {
  return `${getPathCacheCollection()}/${cacheName}${docName ? `-${docName}` : ''}`;
}

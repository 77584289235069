import {CollectionBaseEnum} from '../enum/CollectionBaseEnum';

/**
 * Collection path for price
 * @returns
 */
export function getPathPriceCollection() {
  console.debug('getPathPriceCollection');
  return CollectionBaseEnum.PRICE;
}

import {v4 as uuidv4} from 'uuid';
import {IPositionV2} from '../../../__generated__/resolvers-types';
import {MatchAlgoStatusEnum} from '../enum/MatchAlgoStatusEnum';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

/**
 * When reference position is not found
 * @param p
 * @returns
 */
export default function mapIPositionV2ToOrphan(p: IPositionV2): IMatchAlgoPosition {
  return {
    referencePosition: p,
    uniqueId: uuidv4(),
    epic: p.assetId,
    algoName: '',
    docId: '',
    status: MatchAlgoStatusEnum.ORPHAN,
  };
}

import {IgDealStatusEnum} from '../../enum/IgDealStatusEnum';
import {IgPositionStatusEnum} from '../../enum/IgPositionStatusEnum';
import {IgReasonEnum} from '../../enum/IgReasonEnum';
import {IgPositionConfirmV1Response} from '../../position/confirmIgPosition';

/**
 * Check if close trade confirmation is successful or not
 * @param response
 * @returns
 */
export function igHelperIsCloseTradeSuccessful(response: IgPositionConfirmV1Response | undefined) {
  return (
    response &&
    response.dealStatus === IgDealStatusEnum.ACCEPTED &&
    response.status === IgPositionStatusEnum.CLOSED &&
    response.reason === IgReasonEnum.SUCCESS
  );
}

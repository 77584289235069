/**
 * Check if position needs to be opened
 * - only consider to increase trade counts i.e. holding short position will not open long trade
 * @param currentTradeCount
 * @param targetTradeCount
 */
export function requireOpenTrade(currentTradeCount: number, targetTradeCount: number) {
  // new position 0 means no need to open trade
  if (targetTradeCount === 0) return false;

  return (
    (targetTradeCount > 0 && currentTradeCount < targetTradeCount) ||
    (targetTradeCount < 0 && currentTradeCount > targetTradeCount)
  );
}

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {EmaConfigV3, initEmaConfigV3, zEmaConfigV3} from '../../ema';
import {MacdOutput, zMacdOutput} from './MacdOutput';

/**
 * MACD config V3
 */
export interface MacdConfigV3 extends IndicatorConfigV3<MacdOutput, number> {
  type: 'macdConfigV3';
  /** Fast EMA line */
  emaFastConfig: EmaConfigV3;
  /** slow EMA line */
  emaSlowConfig: EmaConfigV3;
  /** signal line */
  signalConfig: EmaConfigV3;
  hloc: IndicatorCandlePriceEnum;
}

export const zMacdConfigV3 = zIndicatorConfigV3(zMacdOutput, number()).merge(
  object({
    type: literal('macdConfigV3').default('macdConfigV3'),
    emaFastConfig: zEmaConfigV3.default(initEmaConfigV3),
    emaSlowConfig: zEmaConfigV3.default(initEmaConfigV3),
    signalConfig: zEmaConfigV3.default(initEmaConfigV3),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<MacdConfigV3, any, any>;

export const initMacdConfigV3 = zMacdConfigV3.parse({});

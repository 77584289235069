import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import {getIndicatorV3Input} from '../../common/template/v3/getIndicatorV3Input';
import mapIndicatorV3InputToCandle from '../../common/template/v3/mapIndicatorV3InputToCandle';
import convertStrToInt from '../../common/util/convertStrToInt';
import {smaV3} from '../../movingAverage/sma/smaV3';
import {CciConfigV3, zCciConfigV3} from './interface/CciConfigV3';
import {CciInitV3, zCciInitV3} from './interface/CciInitV3';
import {CciOutputV3} from './interface/CciOutputV3';

function calculateTypicalPrice(config: CciConfigV3) {
  // const high = Math.max(...config.data.map((d) => d.high));
  // const low = Math.min(...config.data.map((d) => d.low));
  // return (high + low + (config.data.at(-1)?.close || 0)) / 3;
  const lastData = getIndicatorV3Input<CciConfigV3, CciOutputV3, IndicatorCandle>(config);
  return (lastData.high + lastData.low + lastData.close) / 3;
}

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<CciInitV3>) {
  const parsedInit = zCciInitV3.strict().parse(init);

  return zCciConfigV3.strip().parse({
    ...parsedInit,
    smaConfig: smaV3.createConfig({period: parsedInit.period}),
    maxInputLength: parsedInit.period,
  });
}

/**
 * Calculate Cci
 * @param config
 * @returns
 */
function calculate(config: CciConfigV3): IndicatorReturnV3<CciConfigV3, CciOutputV3, IndicatorCandle> {
  const nextPrice = config.input.at(-1);

  if (nextPrice === undefined) throw new Error('No price available');

  // https://www.babypips.com/forexpedia/commodity-channel-index
  const typicalPrice = calculateTypicalPrice(config);
  const tpAve = smaV3.nextValue(config.smaConfig, typicalPrice);

  const tpArr = tpAve.config.input;
  const md = tpArr.reduce((prev, tp) => Math.abs(tp - tpAve.result) + prev, 0) / tpArr.length;

  // once period reach, always define previousCci to enable ema calculation
  return {
    config: {...config, smaConfig: tpAve.config},
    result: md === 0 ? 0 : (typicalPrice - tpAve.result) / (0.015 * md),
  };
}

/**
 * Cci indicator
 */
export const cciV3 = createIndicatorV3(
  IndicatorTypeEnum.CCI,
  createConfig,
  calculate,
  (s) =>
    zCciInitV3.parse({
      period: convertStrToInt(s[0]),
    }),
  mapIndicatorV3InputToCandle,
  zCciConfigV3,
);

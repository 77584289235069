import {Table, TableBody} from '@mui/material';
import {IAssetRecord} from 'model/IAssetRecord';
import PositionTableAsset, {IPositionTableAssetProps} from './PositionTableAsset';
import PositionTableHeader, {IPositionTableHeaderProps} from './PositionTableHeader';
import PositionTableSummary from './PositionTableSummary';

export interface IPositionTableProps
  extends Pick<IPositionTableAssetProps, 'positions' | 'allTags'>,
    IPositionTableHeaderProps {
  /** User id */
  uid: string;
  /** Asset information such as price */
  assetRecord: IAssetRecord;
}

function PositionTable(props: IPositionTableProps) {
  const {positions, allTags, assetRecord, uid} = props;

  const positionAssetId = Array.from(new Set(positions.map((p) => p.assetId)));
  return (
    <Table sx={{minWidth: 650}} aria-label="simple table">
      <PositionTableHeader />
      <PositionTableSummary positions={positions} />
      <TableBody>
        {positionAssetId.map((assetId) => {
          const asset = assetRecord[assetId];
          if (!asset) return null;

          return (
            <PositionTableAsset
              uid={uid}
              key={`PositionTable-asset-${asset.id}`}
              asset={asset}
              positions={positions}
              allTags={allTags}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

export default PositionTable;

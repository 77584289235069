import {IPosition} from '@algo/model';
import {Grid, Typography} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import dayjs from 'dayjs';

export const closeTsColumn: GridColDef = {
  field: 'closeTsUtc',
  headerName: 'Close TS',
  width: 100,
  align: 'center',
  headerAlign: 'center',
  editable: false,
  valueGetter: (value: string) => value.substring(0, 19).replace('T', ' '),
  renderCell: (params: GridRenderCellParams<IPosition, string, any>) => {
    const val = params.value || '';
    const diff = dayjs(val).diff(params.row.openTsUtc, 'hours');
    return (
      <Grid container wrap="wrap">
        <Typography variant="subtitle2">{val.substring(0, 10)}</Typography>
        <Typography variant="subtitle2">{val.substring(10)}</Typography>
        <Typography variant="subtitle2">{diff} hrs</Typography>
      </Grid>
    );
  },
};

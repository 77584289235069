import {IndicatorCandle} from '../../common/interface/IndicatorCandle';

/**
 * Calculate Stochastic K value
 * @param data new config
 * @returns
 */
export default function stochasticCalculateK(data: IndicatorCandle[]): number {
  const tick = data.at(-1) as IndicatorCandle;
  const periodLow = Math.min(...data.map((d) => d.low));
  const periodHigh = Math.max(...data.map((d) => d.high));
  const calculatedK = ((tick.close - periodLow) / (periodHigh - periodLow)) * 100;
  return Number.isNaN(calculatedK) ? 0 : calculatedK;
}

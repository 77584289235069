import {IUserHouse} from '@algo/firebase-common';
import {Box, Grid, TextField, Typography} from '@mui/material';

export interface MortgageCalculatorMortgageProps {
  formData: IUserHouse;

  setFormData: (k: string, v: string | boolean) => void;
}

/**
 * Show analysis page to do analysis
 * @returns
 */
export default function MortgageCalculatorMortgage(props: MortgageCalculatorMortgageProps) {
  const {formData, setFormData} = props;

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography typography="title">Mortgage</Typography>
        </Grid>

        <Grid item>
          <TextField
            label="Interest (%)"
            value={formData.ratePct}
            inputProps={{type: 'number', min: 0, step: 0.1, max: 50}}
            onChange={(e) => setFormData('ratePct', e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Terms (years)"
            value={formData.termsYrs}
            inputProps={{type: 'number', min: 1, step: 1, max: 50}}
            onChange={(e) => setFormData('termsYrs', e.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

import {mapIPositionV2ArrToRecord} from '@algo/helper';
import {UtilObject} from '@algo/util';
import {gql, useQuery} from '@apollo/client';
import {useEffect} from 'react';
import {AlgoPaperPosition, IPositionV2} from '../__generated__/resolvers-types';

const QUERY = gql`
  query ListAlgoPaperOpenPosition($strategyNames: [String!]) {
    listAlgoPaperOpenPosition(strategyNames: $strategyNames) {
      data {
        id
        broker
        assetId
        size
        direction
        open
        openTsUtc
        close
        closeTsUtc
        limit
        stop
        tags
        trailingStop
        trailingStep
      }
      id
    }
  }
`;

/**
 * List current open position of algo in paper trade
 * @param strategyNames list of strategy name to obtain, undefined fetch all, empty array would not fetch anything
 * @returns
 */
export default function useQueryListAlgoPaperOpenPosition(
  strategyNames?: string[],
): Record<string, Record<string, IPositionV2>> {
  const {data, refetch} = useQuery<{listAlgoPaperOpenPosition: AlgoPaperPosition[]}>(QUERY, {
    variables: {strategyNames},
    skip: !strategyNames,
  });

  useEffect(() => {
    if (!strategyNames || strategyNames.length > 0) {
      refetch({variables: {strategyNames}});
    }
    // eslint-disable-next-line
  }, [strategyNames]);

  if (strategyNames && strategyNames.length === 0) {
    return {};
  }

  if (!data) return {};

  return UtilObject.executeFnToValue(UtilObject.fromArray(data.listAlgoPaperOpenPosition), mapIPositionV2ArrToRecord);
}

import {IgPriceCandle} from '@algo/broker-ig';
import {IPositionV2} from '@algo/model';
import {calculateIPositionV2LimitTrigger} from '../../calculate/IPositionV2/calculateIPositionV2LimitTrigger';

/**
 * Checks if position hit stoploss
 * @returns
 */
export function isIPositionV2Takeprofit(position: IPositionV2, candle: IgPriceCandle): boolean {
  return !!calculateIPositionV2LimitTrigger(position, candle);
}

import {gql, useMutation} from '@apollo/client';
import {AlgoStageEnum} from '../../__generated__/resolvers-types';

const MUTATE = gql`
  mutation setAlgoCopyConfig($stage: AlgoStageEnum!, $name: String!, $value: Float!) {
    setAlgoCopyConfig(stage: $stage, name: $name, value: $value)
  }
`;

export interface MutateAlgoConfigParams {
  stage: AlgoStageEnum;
  name: string;
  value: number;
}

export default function useMutateAlgoConfig() {
  const [fn, {data, error, loading}] = useMutation<{setAlgoCopyConfig: boolean}, MutateAlgoConfigParams>(MUTATE);

  return {
    fn: (stage: AlgoStageEnum, name: string, value: number) => {
      fn({variables: {stage, name, value}});
    },
    data,
    error,
    loading,
  };
}

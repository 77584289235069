import {IgMarketData} from '@algo/broker-ig';
import {IMarketList} from '@algo/model';
import {mapMarketFromIgMarketData} from './mapMarketFromIgMarketData';

/**
 * Convert market data array to market array
 * @param marketData
 * @returns
 */
export function mapMarketArrFromIgMarketDataArr(marketData: IgMarketData[]): IMarketList {
  return marketData.map(mapMarketFromIgMarketData);
}

import {IPositionV2} from '@algo/model';

/**
 * Extract uniquely all tags in given list of positions
 * @param positions
 * @returns
 */
export default function positionExtractTags(positions: IPositionV2[]) {
  // combine all tags as one array
  const allTags = positions.reduce((prev, cur) => [...prev, ...cur.tags], [] as string[]);

  const s = new Set(allTags).values();
  return Array.from(s);
}

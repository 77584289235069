import {EmaConfig, zEmaConfig} from './interface/EmaConfig';

/**
 * Initialise config
 * @param period period of ema (default=20)
 * @param smoothing smoothing factor (default=2)
 * @returns
 */
export function emaInit(period = 20, smoothing = 2): EmaConfig {
  return zEmaConfig.parse({period, exponent: smoothing / (1 + period), previousEma: null, values: []});
}

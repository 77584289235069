import {PsarConfig, zPsarConfig} from './interface/PsarConfig';

/**
 * Initialise config
 * @param step step size (default=0.02)
 * @param max max value (default=0.2)
 * @param start starting value (default=0.02)
 * @returns
 */
export function psarInit(step = 0.02, max = 0.2, start = 0.02): PsarConfig {
  return zPsarConfig.parse({
    accelerationFactorStep: step,
    accelerationFactorMax: max,
    accelerationFactorStart: start,
    sar: 0,
    extreme: 0,
    accelerationFactor: 0,
    isUptrend: true,
  });
}

import {array, literal, number, object, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {AtrOutputV2, zAtrOutputV2} from './AtrOutputV2';

/**
 * atr config
 */
export interface AtrConfigV2 extends IndicatorConfig<AtrOutputV2, IndicatorCandle> {
  type: 'atrConfigV2';
  /** Period of sd */
  period: number;

  /** True range calculation */
  trueRange: number[];
}

// export const initAtrConfigV2: AtrConfigV2 = {
//   ...initIndicatorConfig,
//   period: 0,
// };

export const zAtrConfigV2 = zIndicatorConfig(zAtrOutputV2, zIndicatorCandle).merge(
  object({
    type: literal('atrConfigV2').default('atrConfigV2'),
    period: number().min(3).default(14),
    trueRange: array(number()).default([]),
  }),
) satisfies ZodType<AtrConfigV2, any, any>;

export const initAtrConfigV2 = zAtrConfigV2.parse({});

import {number} from 'zod';

/**
 * Round number to given number of decimal place
 * - round up if required
 * @param num number to be converted
 * @param numberOfDigit (0-20) number of digit after decimal place
 * @returns
 */
export function roundDecimalPlace(num: number, numberOfDigit: number) {
  number().int().min(0).max(20).parse(numberOfDigit);
  return parseFloat(num.toFixed(numberOfDigit));
}

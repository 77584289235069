import {sleepMs} from '@coolcolduk/util';
import {IgUserToken} from '../create/createIgUserToken';
import {confirmIgPosition} from '../position/confirmIgPosition';
import {igHelperClosePositionWithDealId} from './igHelperClosePositionWithDealId';

/**
 * Close ig position given deal id, and resolve deal reference

 */
export async function igHelperClosePositionWithDealIdDetail(userToken: IgUserToken, dealId: string) {
  console.debug('igHelperClosePositionWithDealIdDetail');

  const closeResult = await igHelperClosePositionWithDealId(userToken, dealId);

  if (!closeResult?.data) {
    return undefined;
  }

  await sleepMs(300);
  return confirmIgPosition(userToken, closeResult.data.dealReference);
}

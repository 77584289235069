import {IndicatorCandlePriceEnum} from '../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {bollingerBand} from './bollingerBand';
import {BollingerBandOutput} from './interface';

/**
 * Function to calculate a range of sd values given candles
 * @param candles
 * @param period
 * @param hloc
 * @returns
 */
export function bollingerBandCandle(
  candles: IndicatorCandle[],
  period?: number,
  numberOfSd?: number,
  hloc = IndicatorCandlePriceEnum.CLOSE,
): BollingerBandOutput[] {
  return bollingerBand(candles.map(mapIndicatorCandleToNumberArr(hloc)), period, numberOfSd);
}

import {ZodType} from 'zod';
import {IndicatorCandle, IndicatorTypeEnum} from '../../interface';
import {IndicatorConfigV3} from './IndicatorConfigV3';
import {IndicatorInitV3} from './IndicatorInitV3';
import {IndicatorReturnV3} from './IndicatorReturnV3';
import {IndicatorV3} from './IndicatorV3';
import createIndicatorV3Calculate from './createIndicatorV3Calculate';
import createIndicatorV3ConfigFromCmd from './createIndicatorV3ConfigFromCmd';

/**
 * Create indicator object to be used by indicatorV3
 * @param type
 * @param createConfig
 * @param calculateFunction
 * @param cmdToInitFuncation
 * @param mapCandleToInput
 * @param parseConfig
 * @returns
 */
export function createIndicatorV3<
  Init extends IndicatorInitV3,
  Config extends IndicatorConfigV3<Output, Input>,
  Output = number,
  Input = number,
>(
  type: IndicatorTypeEnum,
  createConfig: (init: Partial<Init>) => Config,
  calculateFunction: (config: Config) => IndicatorReturnV3<Config, Output, Input>,
  cmdToInitFunction: (s: string[]) => Init,
  mapCandleToInput: (config: Config, candle: IndicatorCandle) => Input,
  /** parse parameter to config, or undefined if not valid */
  parseConfig: ZodType<Config, any, any>,
): IndicatorV3<Config, Output, Init, Input> {
  return {
    type,
    createConfig,
    nextValue: createIndicatorV3Calculate(calculateFunction),
    createConfigFromCmd: createIndicatorV3ConfigFromCmd<Init, Config, Output, Input>(
      type,
      cmdToInitFunction,
      createConfig,
    ),
    mapCandleToInput,
    parseConfig: (config: any) => {
      if (typeof config !== 'object') return undefined;
      if (config === null) return undefined;

      // input object doesn't have type
      const inputType = config.type;
      if (!inputType) return undefined;

      // failed parsing
      const result = parseConfig.safeParse(config);
      if (!result.success) return undefined;

      // can parsed, but of the wrong type could mean it's not the correct object type
      if (inputType !== result.data.type) return undefined;

      return result.data;
    },
  };
}

import {IgStatusEnum} from './IgStatusEnum';

/**
 * Convert string to IgStatusEnum or undefined if not found
 * @param err
 * @returns
 */
export function mapIgStatusEnum(err: string) {
  console.debug('mapIgStatusEnum');
  return Object.values(IgStatusEnum).includes(err as IgStatusEnum) ? (err as IgStatusEnum) : IgStatusEnum.UNKNOWN;
}

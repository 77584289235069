import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {SmaOutputV3, zSmaOutputV3} from './SmaOutputV3';

export interface SmaConfigV3 extends IndicatorConfigV3<SmaOutputV3, number> {
  type: 'smaConfigV3';
  period: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zSmaConfigV3 = zIndicatorConfigV3(zSmaOutputV3, number()).merge(
  object({
    type: literal('smaConfigV3').default('smaConfigV3'),
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<SmaConfigV3, any, any>;

export const initSmaConfigV3 = zSmaConfigV3.parse({});

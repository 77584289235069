import {IPositionV2} from '@algo/model';
import {UtilCalculateTradeCount} from '@algo/util';
import {calculateIPositionV2NetTradeCount} from './calculateIPositionV2NetTradeCount';

/**
 * Calculate the number of trade to open
 * - work out from positions array
 * - will not consider closing trade i.e. currently 1 short, need 1 long will open 1 trade
 * @param positions
 * @param requiredTradeCount
 * @returns positive is long, negative is short
 */
export function calculateIPositionV2OpenTradeCount(positions: IPositionV2[], requiredTradeCount: number) {
  const currentPositionSize = calculateIPositionV2NetTradeCount(positions);
  if (!UtilCalculateTradeCount.requireOpenTrade(currentPositionSize, requiredTradeCount)) return 0;

  const diff = requiredTradeCount - currentPositionSize;
  return Math.abs(diff) > Math.abs(requiredTradeCount) ? requiredTradeCount : diff;
}

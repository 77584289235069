import {array, number, object, ZodType} from 'zod';

/**
 * SMA config
 */
export interface SmaConfig {
  /** Period of sma */
  period: number;
  /** Current price */
  price: number[];
}

export const initSmaConfig: SmaConfig = {
  period: 0,
  price: [],
};

export const zSmaConfig: ZodType<SmaConfig> = object({
  period: number().nonnegative(),
  price: array(number()),
});

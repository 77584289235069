import {IPosition, IPositionMain, IPositionV2} from '@algo/model';

/**
 * map IPositionV2 | IPositionMain | IPosition to IPositionV2
 * @param position
 * @deprecated no longer have IPosition
 * @returns
 */
export function mapIPositionToIPositionV2(position: IPositionV2 | IPositionMain | IPosition): IPositionV2 {
  const tags = 'tags' in position ? position.tags : undefined;
  const metaTags = 'calculate' in position ? position.meta.tags : undefined;
  return {
    id: position.id,
    broker: position.broker,
    assetId: position.assetId,
    size: position.size,
    direction: position.direction,
    open: position.open,
    openTsUtc: position.openTsUtc,
    close: position.close || 0,
    stop: position.stop || 0,
    limit: position.limit || 0,
    closeTsUtc: position.closeTsUtc || '',
    tags: tags || metaTags || [],
  };
}

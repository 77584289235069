import {mean, std} from 'mathjs';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import {getIndicatorV3Input} from '../../common/template/v3/getIndicatorV3Input';
import mapIndicatorV3InputToNumber from '../../common/template/v3/mapIndicatorV3InputToNumber';
import convertStrToFloat from '../../common/util/convertStrToFloat';
import convertStrToInt from '../../common/util/convertStrToInt';
import {BollingerBandConfigV3, zBollingerBandConfigV3} from './interface/BollingerBandConfigV3';
import {BollingerBandInitV3, zBollingerBandInitV3} from './interface/BollingerBandInitV3';
import {BollingerBandOutput, zBollingerBandOutput} from './interface/BollingerBandOutput';

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<BollingerBandInitV3>) {
  const parsedInit = zBollingerBandInitV3.strict().parse(init);

  return zBollingerBandConfigV3.strip().parse({
    ...parsedInit,
    maxInputLength: parsedInit.period,
  });
}

/**
 * Calculate bollingerBand
 * @param config
 * @returns
 */
function calculate(config: BollingerBandConfigV3): IndicatorReturnV3<BollingerBandConfigV3, BollingerBandOutput> {
  const latestPrice = getIndicatorV3Input<BollingerBandConfigV3, BollingerBandOutput, number>(config);

  const stdDev = std(config.input, 'uncorrected') as number;
  const ma = mean(config.input);

  const upper = ma + config.numberOfSd * stdDev;
  const lower = ma - config.numberOfSd * stdDev;

  const pb = (latestPrice - lower) / (upper - lower);

  return {
    config,
    result: zBollingerBandOutput.parse({
      ma,
      upper,
      lower,
      percentageBetween: Number.isNaN(pb) ? 0 : pb,
    }),
  };
}

/**
 * BB indicator
 */
export const bollingerBandV3 = createIndicatorV3<
  BollingerBandInitV3,
  BollingerBandConfigV3,
  BollingerBandOutput,
  number
>(
  IndicatorTypeEnum.BOLLINGER_BAND,
  createConfig,
  calculate,
  (s) =>
    zBollingerBandInitV3.parse({
      period: convertStrToInt(s[0]),
      numberOfSd: convertStrToFloat(s[1]),
      hloc: convertStrToInt(s[2]),
    }),
  mapIndicatorV3InputToNumber,
  zBollingerBandConfigV3,
);

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import convertStrToFloat from '../../common/util/convertStrToFloat';
import {psarCandle} from './psarCandle';

/**
 * Function to calculate a range of PSAR given candles and command string
 *
 * @param cmd psar_step_max_start e.g. psar_0.02_0.2_0.02
 * @param candles
 * @returns
 */
export function psarCmd(cmd: string, candles: IndicatorCandle[]): (number | undefined)[] | undefined {
  const cmdArr = cmd.split('_');
  if (cmdArr[0] !== IndicatorTypeEnum.PSAR) return undefined;
  return psarCandle(candles, convertStrToFloat(cmdArr[1]), convertStrToFloat(cmdArr[2]), convertStrToFloat(cmdArr[3]));
}

import {ValueError} from '@algo/errors';

/**
 * run parseInt, and throw if invalid
 * @param s string to parse
 * @param errorMessage message to throw when error
 * @returns number parsed
 * @throws {ValueError} when NaN
 */
export function parseIntThrow(s: string, errorMessage?: string) {
  const result = parseInt(s, 10);
  if (Number.isNaN(result)) {
    throw new ValueError(errorMessage || `Cannot parse float for value: ${s}`);
  }
  return result;
}

import {mean, std} from 'mathjs';
import {IndicatorReturn} from '../../common/interface/IndicatorReturn';
import {BollingerBandConfig} from './interface/BollingerBandConfig';
import {BollingerBandOutput} from './interface/BollingerBandOutput';

/**
 * Calculate next SMA value
 * @param config new config
 * @param nextPrice
 * @returns
 */
export function bollingerBandCalculate(
  config: BollingerBandConfig,
  nextPrice: number,
): IndicatorReturn<BollingerBandConfig, BollingerBandOutput> {
  const price = [...config.price, nextPrice].slice(-config.period);
  const stdDev = std(price, 'uncorrected') as number;
  const ma = mean(price);
  const upper = ma + config.numberOfSd * stdDev;
  const lower = ma - config.numberOfSd * stdDev;
  const pb = (nextPrice - lower) / (upper - lower);
  return {
    config: {...config, price},
    result: {
      ma,
      upper,
      lower,
      percentageBetween: Number.isNaN(pb) ? 0 : (nextPrice - lower) / (upper - lower),
    },
  };
}

import {useLoadStrategyAlgoCopierOpen} from '@algo/firebase-web';
import {IPositionV2} from '@algo/model';
import {Firestore} from 'firebase/firestore';
import {AlgoStageEnum} from '../../../__generated__/resolvers-types';
import useQueryAlgoCopyConfig from '../../../hooks/useQueryAlgoCopyConfig';
import useQueryListAlgoPaperOpenPosition from '../../../hooks/useQueryListAlgoPaperOpenPosition';
import getAlgoCopierName from '../../../logic/helper/getAlgoCopierName';
import {MatchAlgoOptionsEnum} from '../enum/MatchAlgoOptionsEnum';
import buildIMatchAlgoPosition from '../helper/buildIMatchAlgoPosition';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

/**
 * Matching `paper open` to `copy record`
 * @description Not recorded - exist on `copy record` only
 * - close request to broker may fail leading to `copy record` remaining
 * - ACTION: remove file - this will cause the next step to have a new orphan (exists on `broker` but not on `copy record`). remove will cause statistics inaccurate (trade not counted)
 * @description Orphan - exist on `paper open` only
 * - orphan when size changed to 0 in algoCopier config
 * - ACTION: N/A
 * @param firestoreApp
 * @param selection
 * @returns
 */
export function useMatchAlgoPaperCopier(
  firestoreApp: Firestore,
  selection?: MatchAlgoOptionsEnum,
): IMatchAlgoPosition[] | undefined {
  console.debug('useMatchAlgoPaper');

  const isActive =
    selection === MatchAlgoOptionsEnum.ALGO_PAPER_DEV || selection === MatchAlgoOptionsEnum.ALGO_PAPER_PROD;
  const isProd = selection === MatchAlgoOptionsEnum.ALGO_PAPER_PROD;
  const fs = isActive ? firestoreApp : undefined;

  // get a list of algos from copier - less strategy
  const listOfAlgos = Object.keys(useQueryAlgoCopyConfig(isProd ? AlgoStageEnum.PROD : AlgoStageEnum.DEV));

  // get copy record position in firestore
  const copierOpen = useLoadStrategyAlgoCopierOpen(fs, getAlgoCopierName(isProd), listOfAlgos);

  // position from paper open
  const paperOpenRec = useQueryListAlgoPaperOpenPosition(isActive ? listOfAlgos : []);

  // formatting output
  const paperOpenObjectArr = Object.values(paperOpenRec).filter((o) => Object.keys(o).length);
  const paperOpen = paperOpenObjectArr.reduce((prev, cur) => [...prev, ...Object.values(cur)], [] as IPositionV2[]);

  return isActive ? buildIMatchAlgoPosition(copierOpen, paperOpen) : undefined;
}

import {IPositionV2, PositionDirectionEnum} from '@algo/model';

/**
 * Calculate net number of trades, long trades - short trades
 * - count number of trades, not size i.e. position size 1 and 10 is the same
 * - positive trades - negative trades to give net trades
 * @param positions
 * @returns
 */
export function calculateIPositionV2NetTradeCount(positions: IPositionV2[]) {
  const positive = positions.filter((p) => p.direction === PositionDirectionEnum.LONG).length;
  const negative = positions.length - positive;
  return positive - negative;
}

import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import mapIndicatorV3InputToNumber from '../../common/template/v3/mapIndicatorV3InputToNumber';
import convertStrToFloat from '../../common/util/convertStrToFloat';
import convertStrToInt from '../../common/util/convertStrToInt';
import {emaV3} from '../ema/emaV3';
import {MacdConfigV3, zMacdConfigV3} from './interface/MacdConfigV3';
import {MacdInitV3, zMacdInitV3} from './interface/MacdInitV3';
import {MacdOutputV3} from './interface/MacdOutputV3';

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<MacdInitV3>) {
  const parsedInit = zMacdInitV3.strict().parse(init);

  return zMacdConfigV3.strip().parse({
    ...parsedInit,
    emaFastConfig: emaV3.createConfig({period: parsedInit.fastPeriod, hloc: parsedInit.hloc}),
    emaSlowConfig: emaV3.createConfig({period: parsedInit.slowPeriod, hloc: parsedInit.hloc}),
    signalConfig: emaV3.createConfig({period: parsedInit.signalPeriod, hloc: parsedInit.hloc}),
    maxInputLength: 1, // data stored in ema
  });
}

/**
 * Calculate Macd
 * @param config
 * @returns
 */
function calculate(config: MacdConfigV3): IndicatorReturnV3<MacdConfigV3, MacdOutputV3> {
  const nextPrice = config.input.at(-1);

  if (!nextPrice) throw new Error('No price available');

  const fastCalculation = emaV3.nextValue(config.emaFastConfig, nextPrice);
  const fastResult = fastCalculation.result;

  const slowCalculation = emaV3.nextValue(config.emaSlowConfig, nextPrice);
  const slowResult = slowCalculation.result;

  const macd = fastResult - slowResult;

  // signal calculation
  const signalCalculation = emaV3.nextValue(config.signalConfig, macd);
  const signal = signalCalculation.result;
  const histogram = macd - signal;

  // update config

  return {
    config: {
      ...config,
      emaFastConfig: fastCalculation.config,
      emaSlowConfig: slowCalculation.config,
      signalConfig: signalCalculation.config,
    },
    result: {
      macd,
      signal,
      histogram,
    },
  };
}

/**
 * Macd indicator
 */
export const macdV3 = createIndicatorV3<MacdInitV3, MacdConfigV3, MacdOutputV3, number>(
  IndicatorTypeEnum.MACD,
  createConfig,
  calculate,
  (s) =>
    zMacdInitV3.parse({
      slowPeriod: convertStrToInt(s[0]),
      fastPeriod: convertStrToFloat(s[1]),
      signalPeriod: convertStrToInt(s[2]),
      hloc: convertStrToInt(s[3]),
    }),
  mapIndicatorV3InputToNumber,
  zMacdConfigV3,
);

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {PsarConfig} from './interface/PsarConfig';

/**
 * Calculate other parameters
 * @param config
 * @param currentBar
 * @returns
 */
export default function psarCalculateAccelerateExtreme(config: PsarConfig, currentBar: IndicatorCandle) {
  const isUpHigher = config.isUptrend && currentBar.high > config.extreme;
  const isDownLower = !config.isUptrend && currentBar.low < config.extreme;
  const accelerationFactor =
    isUpHigher || isDownLower
      ? Math.min(config.accelerationFactor + config.accelerationFactorStep, config.accelerationFactorMax)
      : config.accelerationFactor;

  const higherExtreme = isUpHigher ? currentBar.high : undefined;
  const lowerExtreme = isDownLower ? currentBar.low : undefined;
  return {extreme: higherExtreme || lowerExtreme || config.extreme, accelerationFactor};
}

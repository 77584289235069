import {ZodType, object, string} from 'zod';

export interface IgPositionCommonDealReference {
  /**
   * A user-defined reference identifying the submission of the order
   *   [Constraint: Pattern(regexp="[A-Za-z0-9_\\-]{1,30}")]
   */
  dealReference: string;
}

export const zIgPositionCommonDealReference = object({
  dealReference: string().regex(/[A-Za-z0-9_\\-]{1,30}/),
}) satisfies ZodType<IgPositionCommonDealReference>;

export const initIgPositionCommonDealReference: IgPositionCommonDealReference = zIgPositionCommonDealReference.parse({
  dealReference: 'a',
});

import {defaultIPositionCalculate, IPositionCalculate} from './IPositionCalculate';
import {defaultIPositionMain, IPositionMain} from './IPositionMain';
import {defaultIPositionMeta, IPositionMeta} from './IPositionMeta';

/**
 * Main IPosition combining main, calculate and meta
 */
export interface IPosition extends IPositionMain {
  calculate: IPositionCalculate;
  meta: IPositionMeta;
}

export const defaultIPosition: IPosition = {
  ...defaultIPositionMain,
  calculate: defaultIPositionCalculate,
  meta: defaultIPositionMeta,
};

import dayjs from 'dayjs';
import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgInstrumentTypeEnum} from '../enum/IgInstrumentTypeEnum';
import {IgPriceResolutionEnum} from '../enum/IgPriceResolutionEnum';
import {IgSendRequestBody} from '../interface/internal/IgSendRequestBody';
import {IgMetaData} from '../interface/price/IgMetaData';
import {IgPriceCandle} from '../interface/price/IgPriceCandle';

export interface IgPriceGetV3Request extends IgSendRequestBody {
  /** Price resolution, (Default=MINUTE) */
  resolution?: IgPriceResolutionEnum;
  /** Start date time (yyyy-MM-dd'T'HH:mm:ss) */
  from?: string;
  /** End date time (yyyy-MM-dd'T'HH:mm:ss) */
  to?: string;
  /** Limits the number of price points (not applicable if a date range has been specified), (Default=10) */
  max?: number;
  /** Page size (disable paging = 0), (Default=20) */
  pageSize?: number;
  /** Page number, (Default=1) */
  pageNumber?: number;
}

export interface IgPriceGetV3Response {
  instrumentType: IgInstrumentTypeEnum;
  metadata: IgMetaData;
  prices: IgPriceCandle[];
}

/**
 * get ig price
 * @param userToken
 * @param params
 * @returns
 */
export async function getIgPrice(userToken: IgUserToken, epic: string, params?: IgPriceGetV3Request) {
  console.debug('getIgPrice', epic);

  const result = await sendIgRequest<IgPriceGetV3Response>(userToken, `/prices/${epic}`, 'get', 3, params);

  // modify snapshotTime to iso date
  if (result.response) {
    result.response.data.prices = result.response.data.prices.map((p) => ({
      ...p,
      snapshotTime: dayjs(p.snapshotTime, 'YYYY/MM/DD hh:mm:ss').format('YYYY-MM-DDTHH:mm:ss'),
    }));
  }

  console.debug('allowance', result.response?.data.metadata.allowance);
  return createIgResponse(userToken, result);
}

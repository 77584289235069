import {literal, object, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {PreviousHighLowOutputV3, zPreviousHighLowOutputV3} from './PreviousHighLowOutputV3';

/**
 * Config for pervious high low config
 * @note maxInputLength determines number of data points for calculation thus period
 */
export interface PreviousHighLowConfigV3 extends IndicatorConfigV3<PreviousHighLowOutputV3, IndicatorCandle> {
  type: 'previousHighLowConfigV3';
  /** period is for info only, maxInputLength determines number of data points thus period */
}

export const zPreviousHighLowConfigV3 = zIndicatorConfigV3(zPreviousHighLowOutputV3, zIndicatorCandle).merge(
  object({
    type: literal('previousHighLowConfigV3').default('previousHighLowConfigV3'),
  }),
) satisfies ZodType<PreviousHighLowConfigV3, any, any>;

export const initPreviousHighLowConfigV3 = zPreviousHighLowConfigV3.parse({});

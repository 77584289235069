import {gql, useQuery} from '@apollo/client';
import {Broker} from '../__generated__/resolvers-types';

const QUERY = gql`
  query GetBrokers {
    getBrokers {
      accountId
      apiKey
      broker
      isLive
      name
      password
      username
    }
  }
`;

/**
 * Load all broker accounts
 * @param uid
 * @returns all broker accounts for user
 */
export default function useQueryUserBroker(): Broker[] {
  const {data} = useQuery<{getBrokers: Broker[]}>(QUERY);
  return data?.getBrokers || [];
}

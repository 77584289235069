import {cciV3} from '../../momentum/cci/cciV3';
import {psarV3} from '../../momentum/psar/psarV3';
import {rsiV3} from '../../momentum/rsi/rsiV3';
import {stocV3} from '../../momentum/stochastic/stocV3';
import {emaV3} from '../../movingAverage/ema/emaV3';
import {macdV3} from '../../movingAverage/macd/macdV3';
import {smaV3} from '../../movingAverage/sma/smaV3';
import {previousHighLowV3} from '../../other';
import {bollingerBandV3} from '../../trend/bollinger/bollingerBandV3';
import {superTrendV3} from '../../trend/supertrend/superTrendV3';
import {atrV3} from '../../volatility/atr/atrV3';
import {sdV3} from '../../volatility/sd/sdV3';
import {IndicatorCandle} from '../interface';
import {nextIndicatorV3Config} from '../template/v3/nextIndicatorV3Config';

/**
 * Checks and process config for a tick if it matches any indicator. Original value will be returned if non matched
 * @param config
 * @param candle
 * @returns
 */
export function processConfigV3<T>(config: T, candle: IndicatorCandle): T {
  const cciConfig = cciV3.parseConfig(config);
  if (cciConfig) return nextIndicatorV3Config(cciV3, cciConfig, candle) as T;

  const psarConfig = psarV3.parseConfig(config);
  if (psarConfig) return nextIndicatorV3Config(psarV3, psarConfig, candle) as T;

  const rsiConfig = rsiV3.parseConfig(config);
  if (rsiConfig) return nextIndicatorV3Config(rsiV3, rsiConfig, candle) as T;

  const stocConfig = stocV3.parseConfig(config);
  if (stocConfig) return nextIndicatorV3Config(stocV3, stocConfig, candle) as T;

  const emaConfig = emaV3.parseConfig(config);
  if (emaConfig) return nextIndicatorV3Config(emaV3, emaConfig, candle) as T;

  const macdConfig = macdV3.parseConfig(config);
  if (macdConfig) return nextIndicatorV3Config(macdV3, macdConfig, candle) as T;

  const smaConfig = smaV3.parseConfig(config);
  if (smaConfig) return nextIndicatorV3Config(smaV3, smaConfig, candle) as T;

  const previousHighLowConfig = previousHighLowV3.parseConfig(config);
  if (previousHighLowConfig) return nextIndicatorV3Config(previousHighLowV3, previousHighLowConfig, candle) as T;

  const bollingerBandConfig = bollingerBandV3.parseConfig(config);
  if (bollingerBandConfig) return nextIndicatorV3Config(bollingerBandV3, bollingerBandConfig, candle) as T;

  const superTrendConfig = superTrendV3.parseConfig(config);
  if (superTrendConfig) return nextIndicatorV3Config(superTrendV3, superTrendConfig, candle) as T;

  const atrConfig = atrV3.parseConfig(config);
  if (atrConfig) return nextIndicatorV3Config(atrV3, atrConfig, candle) as T;

  const sdConfig = sdV3.parseConfig(config);
  if (sdConfig) return nextIndicatorV3Config(sdV3, sdConfig, candle) as T;

  return config;
}

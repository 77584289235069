import {getPathAlgoConfigDocument} from './getPathAlgoConfigDocument';

/**
 * algo open position document
 * @param docName
 * @returns
 */
export function getPathAlgoPositionOpenCollection(docName: string) {
  console.debug('getPathAlgoPositionOpenCollection');
  return `${getPathAlgoConfigDocument(docName)}/open`;
}

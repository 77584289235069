/**
 * Super trend indicator
 * @see https://www.tradingview.com/support/solutions/43000634738-supertrend/
 */
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import {getIndicatorV3Input} from '../../common/template/v3/getIndicatorV3Input';
import {getIndicatorV3Result} from '../../common/template/v3/getIndicatorV3Result';
import mapIndicatorV3InputToCandle from '../../common/template/v3/mapIndicatorV3InputToCandle';
import convertStrToFloat from '../../common/util/convertStrToFloat';
import convertStrToInt from '../../common/util/convertStrToInt';
import {atrV3} from '../../volatility';
import {SuperTrendConfigV3, zSuperTrendConfigV3} from './interface/SuperTrendConfigV3';
import {SuperTrendInitV3, zSuperTrendInitV3} from './interface/SuperTrendInitV3';
import {SuperTrendOutput, initSuperTrendOutput} from './interface/SuperTrendOutput';

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<SuperTrendInitV3>) {
  const parsedInit = zSuperTrendInitV3.strict().parse(init);

  return zSuperTrendConfigV3.strip().parse({
    ...parsedInit,
    maxInputLength: parsedInit.period,
    atr: atrV3.createConfig({period: parsedInit.period}),
    maxResultLength: 3,
  });
}

/**
 * work out trend direction, true for up trend
 * @param config
 * @returns
 */
function isUpTrend(config: SuperTrendConfigV3, upperBand: number, lowerBand: number) {
  if (config.atr.results.at(-1) === 0) return false;

  const prevResult = getIndicatorV3Result<SuperTrendConfigV3, SuperTrendOutput, IndicatorCandle>(config);
  const latestPrice = getIndicatorV3Input<SuperTrendConfigV3, SuperTrendOutput, IndicatorCandle>(config);

  if (prevResult.superTrend === prevResult.upperBand) {
    return latestPrice.close > upperBand;
  }
  return latestPrice.close > lowerBand;
}

/**
 * Calculate superTrend
 * @param config
 * @returns
 */
function calculate(
  config: SuperTrendConfigV3,
): IndicatorReturnV3<SuperTrendConfigV3, SuperTrendOutput, IndicatorCandle> {
  const latestPrice = getIndicatorV3Input<SuperTrendConfigV3, SuperTrendOutput, IndicatorCandle>(config);
  const atr = atrV3.nextValue(config.atr, latestPrice);
  const newConfig = {...config, atr: atr.config};

  if (config.input.length < 2) {
    return {
      config: newConfig,
      result: initSuperTrendOutput,
    };
  }

  const prevPrice = getIndicatorV3Input<SuperTrendConfigV3, SuperTrendOutput, IndicatorCandle>(config, -2);
  const prevResult = getIndicatorV3Result<SuperTrendConfigV3, SuperTrendOutput, IndicatorCandle>(config);
  const midPrice = (latestPrice.high + latestPrice.low) / 2;
  const basicUpperBand = midPrice + config.multiplier * atr.result;
  const basicLowerBand = midPrice - config.multiplier * atr.result;

  const upperBand =
    basicUpperBand < prevResult.upperBand || prevPrice.close > prevResult.upperBand
      ? basicUpperBand
      : prevResult.upperBand;

  const lowerBand =
    basicLowerBand > prevResult.lowerBand || prevPrice.close < prevResult.lowerBand
      ? basicLowerBand
      : prevResult.lowerBand;

  const isUp = isUpTrend(newConfig, upperBand, lowerBand);
  const superTrend = isUp ? lowerBand : upperBand;

  return {
    config: newConfig,
    result: {
      upperBand,
      lowerBand,
      superTrend,
      superTrendUpper: isUp ? 0 : upperBand,
      superTrendLower: isUp ? lowerBand : 0,
    },
  };
}

/**
 * super trend indicator
 */
export const superTrendV3 = createIndicatorV3<SuperTrendInitV3, SuperTrendConfigV3, SuperTrendOutput, IndicatorCandle>(
  IndicatorTypeEnum.SUPER_TREND,
  createConfig,
  calculate,
  (s) =>
    zSuperTrendInitV3.parse({
      period: convertStrToInt(s[0]),
      multiplier: convertStrToFloat(s[1]),
    }),
  mapIndicatorV3InputToCandle,
  zSuperTrendConfigV3,
);

/**
 * Check if position needs to be closed6
 * @param currentTradeCount
 * @param targetTradeCount
 */
export function requireCloseTrade(currentTradeCount: number, targetTradeCount: number) {
  if (currentTradeCount === 0) return false;

  return (
    (currentTradeCount > 0 && targetTradeCount < currentTradeCount) ||
    (currentTradeCount < 0 && targetTradeCount > currentTradeCount)
  );
}

import {Button, ButtonGroup} from '@mui/material';

export interface ButtonGroupOrganismProps<T extends string = string> {
  /** pass in an enum and the value will be used */
  enumItem: Record<string, T>;
  value?: T;
  handleClick: (v: T) => void;
}

export default function ButtonGroupOrganism<T extends string>(props: ButtonGroupOrganismProps<T>) {
  const {enumItem, value, handleClick} = props;

  return (
    <ButtonGroup>
      {Object.values(enumItem).map((l) => (
        <Button
          key={l}
          variant="contained"
          onClick={() => (handleClick ? handleClick(l) : undefined)}
          color={value === l ? 'warning' : 'primary'}
        >
          {l}
        </Button>
      ))}
    </ButtonGroup>
  );
}

import {sortIPositionV2OpenTs} from '@algo/helper';
import {IPositionV2} from '@algo/model';
import {Box, Grid} from '@mui/material';
import dayjs from 'dayjs';
import {useState} from 'react';
import FilterBar from '../../components/FilterBar/FilterBar';
import {StrategyTypeEnum, useListAlgoStrategy} from '../../hooks/useListAlgoStrategy';
import useLoadStrategyArchivePositions from '../../hooks/useLoadStrategyArchivePositions';
import positionExtractTags from '../../logic/helper/position/extract/positionExtractTags';
import positionFilterAll from '../../logic/helper/position/filter/positionFilterAll';
import positionFilterClosedOnly from '../../logic/helper/position/filter/positionFilterClosedOnly';
import {IPositionRecordV2} from '../../model/IPositionRecord';
import createFilter from '../../model/filter/createFilter';
import AnalysisCandle from './AnalysisCandle';
import AnalysisDetail from './AnalysisDetail';
import AnalysisStrategy from './AnalysisStrategy';
import AnalysisSummary from './AnalysisSummary';
import AnalysisTable from './AnalysisTable';

export interface AnalysisPageProps {
  uid: string;
  positionRecords: IPositionRecordV2;
}

/**
 * Show analysis page to do analysis
 * @returns
 */
export default function AnalysisPage(props: AnalysisPageProps) {
  const {positionRecords, uid} = props;

  const [filter, setFilter] = useState(
    createFilter({
      profitLoss: 0,
      includeTags: [],
      excludeTags: [],
      maxLatestPosition: 0,
    }),
  );
  // strategy list
  const [strategyListLoad, setStrategyListLoad] = useState<string>(dayjs().toString());
  const [strategyType, setStrategyType] = useState<StrategyTypeEnum>(StrategyTypeEnum.ALGO);
  const strategyList = useListAlgoStrategy(strategyType, strategyListLoad);

  // position
  const [strategy, setStrategy] = useState('');
  const positions = useLoadStrategyArchivePositions(strategy, strategyType, positionRecords);
  const [positionDetail, setPositionDetail] = useState<IPositionV2 | undefined>(undefined);
  const [positionOnGraph, setPositionOnGraph] = useState<IPositionV2 | undefined>(undefined);

  const allTags = positionExtractTags(positions);

  // all filtering by filter
  const closedPositions = positionFilterClosedOnly(positions);
  const filteredPositions = positionFilterAll(closedPositions, filter);
  filteredPositions.sort(sortIPositionV2OpenTs(true));

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="column" spacing={2}>
        <AnalysisDetail position={positionDetail} setPositionDetail={setPositionDetail} />

        <AnalysisStrategy
          strategyType={StrategyTypeEnum}
          strategyList={strategyList}
          selectedStrategy={strategy}
          onClickHandle={setStrategy}
          handleRefresh={() => setStrategyListLoad(dayjs().toString())}
          selectedStrategyType={strategyType}
          handleStrategyType={(s) => setStrategyType(s as StrategyTypeEnum)}
        />

        <Grid item>
          <FilterBar allTags={allTags} currentFilter={filter} setFilter={setFilter} />
        </Grid>

        <Grid item>
          <AnalysisSummary closedPositions={filteredPositions} />
        </Grid>

        <Grid item>
          <AnalysisCandle positions={positions} positionOnGraph={positionOnGraph} />
        </Grid>

        <Grid item>
          <AnalysisTable
            closedPositions={filteredPositions}
            uid={uid}
            showPositionDetail={setPositionDetail}
            showPositionOnGraph={setPositionOnGraph}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

import {CollectionReference, QueryConstraint} from 'firebase/firestore';
import {firestoreDelDocFromCollection} from './firestoreDelDocFromCollection';
import {firestoreListAllDocs} from './firestoreListAllDocs';
import {firestoreLoadAllDocs} from './firestoreLoadAllDocs';
import {firestoreLoadDocFromCollection} from './firestoreLoadDocFromCollection';
import {firestoreSaveDocToCollection} from './firestoreSaveDocToCollection';
import {firestoreSubscribeCollection} from './firestoreSubscribeCollection';
import {firestoreSubscribeDocumentInCollection} from './firestoreSubscribeDocumentInCollection';

export class FirestoreCollectionHelper<T extends Record<string, any>> {
  collection: CollectionReference<T>;

  constructor(collection: CollectionReference<T>) {
    this.collection = collection;
  }

  del = (name: string) => firestoreDelDocFromCollection(this.collection, name);

  load = (name: string) => firestoreLoadDocFromCollection(this.collection, name);

  loadAll = (constrains?: QueryConstraint | QueryConstraint[]) => firestoreLoadAllDocs(this.collection, constrains);

  list = () => firestoreListAllDocs(this.collection);

  save = (name: string, data: T, isMerge?: boolean) =>
    firestoreSaveDocToCollection(this.collection, name, data, isMerge);

  subscribeAll = (handleData: (data: Record<string, T>) => void) =>
    firestoreSubscribeCollection(this.collection, handleData);

  subscribe = (name: string, handleData: (data: T | undefined) => void) =>
    firestoreSubscribeDocumentInCollection(this.collection, name, handleData);
}

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {DatetimeEnum} from '../enum';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Convert timestamp to string ISO string datetime, using UTC
 * @param unixTimestampMs unix timestamp in milliseconds
 * @param format
 * @returns
 */
export function mapTimestampToUtc(unixTimestampMs: number, format = DatetimeEnum.STANDARD_DATETIME) {
  return dayjs(unixTimestampMs).utc().format(format);
}

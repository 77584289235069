import {getPathStrategyCollection} from './getPathStrategyCollection';

/**
 * collection path of open positions
 * @param strategyName
 * @returns
 */
export function getPathStrategyOpenCollection(strategyName: string) {
  console.debug('getPathStrategyOpenCollection');
  return `${getPathStrategyCollection()}/${strategyName}/open`;
}

import {IgStatusEnum} from '../enum';
import {IgResponse} from './createIgResponse';
import {IgUserToken} from './createIgUserToken';

/**
 * Create standard response with data
 * @param token
 * @param data
 * @returns
 */
export function createIgResponseData<TData>(token: IgUserToken, data: TData) {
  return {
    token,
    data,
    statusCode: IgStatusEnum.OK,
  } as IgResponse<TData>;
}

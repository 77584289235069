import dayjs, {OpUnitType, QUnitType} from 'dayjs';

/**
 * Calculate time difference
 * @param timestampMilliseconds
 * @param unit
 * @returns
 */
export function calculateTimeDiff(timestampMilliseconds: number, unit: QUnitType | OpUnitType = 'seconds') {
  return dayjs().diff(dayjs(timestampMilliseconds), unit);
}

export enum LogLevelEnum {
  /** Trace only use if stack is required */
  TRACE = 'trace',

  /** Debug lowest level print message */
  DEBUG = 'debug',

  /** Log larger than debug and trace */
  LOG = 'log',

  /** Info larger than log */
  INFO = 'info',

  /** Warning */
  WARNING = 'warning',

  /** Error */
  ERROR = 'error',

  /** No logging */
  OFF = 'off',
}

export const logLevelNumber: Record<LogLevelEnum, number> = {
  [LogLevelEnum.TRACE]: 0,
  [LogLevelEnum.DEBUG]: 1,
  [LogLevelEnum.LOG]: 2,
  [LogLevelEnum.INFO]: 3,
  [LogLevelEnum.WARNING]: 4,
  [LogLevelEnum.ERROR]: 5,
  [LogLevelEnum.OFF]: 6,
};

import {enterTube} from '@coolcolduk/util';
import {IndicatorConfigV3} from './IndicatorConfigV3';
import {IndicatorReturnV3} from './IndicatorReturnV3';

/**
 * Used to create the template object. Take calculate function and populate input and result array before and after calculation
 * @param calculateFunction
 * @returns
 */
export default function createIndicatorV3Calculate<
  Config extends IndicatorConfigV3<Output, Input>,
  Output = number,
  Input = number,
>(
  calculateFunction: (config: Config) => IndicatorReturnV3<Config, Output, Input>,
): (config: Config, input: Input) => IndicatorReturnV3<Config, Output, Input> {
  return (config: Config, input: Input) => {
    // update price
    const beforeCalculateConfig = {
      ...config,
      input: enterTube(config.input, input, config.maxInputLength),
    };

    const calculateResult = calculateFunction(beforeCalculateConfig);

    // add result
    const outputConfig = {
      ...calculateResult.config,
      results: enterTube(
        calculateResult.config.results,
        calculateResult.result,
        calculateResult.config.maxResultLength,
      ),
    };
    return {...calculateResult, config: outputConfig};
  };
}

import {z} from 'zod';
import {IgPageMetaDataDetail, zIgPageMetaDataDetail} from './IgPageMetaDataDetail';

/**
 * Paging metadata
 */
export interface IgPageMetaData {
  paging: IgPageMetaDataDetail;
}

export const zIgPageMetaData: z.ZodType<IgPageMetaData> = z.object({
  paging: zIgPageMetaDataDetail,
});

import {nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface BollingerBandInitV3 extends IndicatorInitV3 {
  period: number;
  numberOfSd: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zBollingerBandInitV3 = zIndicatorInitV3.merge(
  object({
    period: number().min(3).default(25),
    numberOfSd: number().min(0.1).default(2),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<BollingerBandInitV3, any, any>;

export const initBollingerBandInitV3 = zBollingerBandInitV3.parse({});

import {PsarConfig} from './interface/PsarConfig';

/**
 * Calculate sar value
 * @param config
 * @returns
 */
export default function psarCalculateSar(config: PsarConfig) {
  const calculatedSar = config.sar + config.accelerationFactor * (config.extreme - config.sar);
  return config.isUptrend
    ? Math.min(calculatedSar, config.furthestBar?.low || calculatedSar, config.previousBar?.low || calculatedSar)
    : Math.max(calculatedSar, config.furthestBar?.high || calculatedSar, config.previousBar?.high || calculatedSar);
}

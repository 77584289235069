import {defaultIMarket, IMarket} from '@algo/model';

export function createMarket(market: Partial<IMarket>): IMarket {
  return {
    epic: market.epic || defaultIMarket.epic,
    lotSize: market.lotSize || defaultIMarket.lotSize,
    scalingFactor: market.scalingFactor || defaultIMarket.scalingFactor,
    bid: market.bid || defaultIMarket.bid,
    offer: market.offer || defaultIMarket.offer,
    high: market.high || defaultIMarket.high,
    low: market.low || defaultIMarket.low,
    open: market.open || defaultIMarket.open,
    close: market.close || defaultIMarket.close,
    netChange: market.netChange || defaultIMarket.netChange,
    percentageChange: market.percentageChange || defaultIMarket.percentageChange,
    updateTime: market.updateTime || defaultIMarket.updateTime,
    updateTimeUTC: market.updateTimeUTC || defaultIMarket.updateTimeUTC,
  };
}

import {calculateIPositionV2Profit} from '@algo/helper';
import {IPositionV2} from '@algo/model';

/**
 * Filter positions for profit/loss only, or not filter if undefined
 * @param positions
 * @param profitLoss
 * @returns
 */
export default function positionFilterProfitLoss(positions: IPositionV2[], profit?: number) {
  // no filter for undefined
  if (!profit) return positions;

  // filter for matching profit and filter
  return positions.filter((p) => (profit > 0 ? calculateIPositionV2Profit(p) > 0 : calculateIPositionV2Profit(p) < 0));
}

import {defaultIPositionMain, IPositionMain} from '@algo/model';

/**
 * Create position main fields
 * @param position
 * @returns
 */
export function createPositionMain(position: Partial<IPositionMain>): IPositionMain {
  return {
    id: position.id || defaultIPositionMain.id,
    broker: position.broker || defaultIPositionMain.broker,
    assetId: position.assetId || defaultIPositionMain.assetId,
    size: position.size || defaultIPositionMain.size,
    direction: position.direction || defaultIPositionMain.direction,
    open: position.open || defaultIPositionMain.open,
    openTsUtc: position.openTsUtc || defaultIPositionMain.openTsUtc,
    limit: position.limit || defaultIPositionMain.limit,
    stop: position.stop || defaultIPositionMain.stop,
    close: position.close || defaultIPositionMain.close,
    closeTsUtc: position.closeTsUtc || defaultIPositionMain.closeTsUtc,
  };
}

import z, {ZodType} from 'zod';

/**
 * Bollinger Band config
 */
export interface BollingerBandConfig {
  period: number;
  numberOfSd: number;

  /** Current price */
  price: number[];
}

export const initBollingerBandConfig: BollingerBandConfig = {
  period: 0,
  numberOfSd: 0,
  price: [],
};

export const zBollingerBandConfig: ZodType<BollingerBandConfig> = z.object({
  period: z.number().min(2),
  numberOfSd: z.number().positive(),
  price: z.array(z.number().positive()),
});

export enum IgPositionStatusEnum {
  /** Amended */
  AMENDED = 'AMENDED',
  /** Closed */
  CLOSED = 'CLOSED',
  /** Deleted */
  DELETED = 'DELETED',
  /** Open */
  OPEN = 'OPEN',
  /** Partially Closed */
  PARTIALLY_CLOSED = 'PARTIALLY_CLOSED',
}

import {IgDealStatusEnum} from '../../enum/IgDealStatusEnum';
import {IgPositionConfirmV1Response} from '../../position/confirmIgPosition';

/**
 * Check if update trade confirmation is successful or not
 * @param response
 * @returns
 */
export function igHelperIsUpdateTradeSuccessful(response: IgPositionConfirmV1Response | undefined) {
  return (
    response && response.dealStatus === IgDealStatusEnum.ACCEPTED
    // response.status === IgPositionStatusEnum.OPEN &&
    // response.reason === IgReasonEnum.SUCCESS
  );
}

import {IUserBroker} from '@algo/firebase-common';
import {useLoadStrategyAlgoCopierOpen} from '@algo/firebase-web';
import {Firestore} from 'firebase/firestore';
import {AlgoStageEnum} from '../../../__generated__/resolvers-types';
import useLoadIgPositionsActive from '../../../hooks/useLoadIgPositionsActive';
import useQueryAlgoCopyConfig from '../../../hooks/useQueryAlgoCopyConfig';
import getAlgoCopierName from '../../../logic/helper/getAlgoCopierName';
import {MatchAlgoOptionsEnum} from '../enum/MatchAlgoOptionsEnum';
import buildIMatchAlgoPosition from '../helper/buildIMatchAlgoPosition';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

/**
 * Matching `copy record` with `broker`
 * @description Not recorded - exist on `copy record` only
 * - happen when position is closed by broker via stoploss
 * - ACTION: update position record - close info can be obtained from historic activity from ig
 * @description Orphan - exist on `broker` only
 * - manual open of position
 * - failed closing should not cause orphan
 * - ACTION: manual close in broker
 * @param firestoreApp
 * @param uid
 * @param brokerAccount
 * @param selection
 * @returns
 */
export function useMatchAlgoCopier(
  firestoreApp: Firestore,
  uid: string,
  brokerAccount: IUserBroker | undefined,
  selection?: MatchAlgoOptionsEnum,
): IMatchAlgoPosition[] | undefined {
  console.debug('useMatchAlgoCopier');

  const isActive = selection === MatchAlgoOptionsEnum.ALGO_DEV || selection === MatchAlgoOptionsEnum.ALGO_PROD;
  const isProd = selection === MatchAlgoOptionsEnum.ALGO_PROD;
  const fs = isActive ? firestoreApp : undefined;
  const broker = isActive ? brokerAccount : undefined;

  // get list of algos
  const listOfAlgos = Object.keys(useQueryAlgoCopyConfig(isProd ? AlgoStageEnum.PROD : AlgoStageEnum.DEV));

  // get copier position in firestore
  const fsPositions = useLoadStrategyAlgoCopierOpen(fs, getAlgoCopierName(isProd), listOfAlgos);

  // data from ig
  const igPositions = useLoadIgPositionsActive(uid, broker);

  return isActive ? buildIMatchAlgoPosition(fsPositions, igPositions.positions) : undefined;
}

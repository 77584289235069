import {getPathAlgoCollection} from './getPathAlgoCollection';

/**
 * algo config document
 * @param docName emaStoc-AUDUSD-5m
 * @returns
 */
export function getPathAlgoConfigDocument(docName: string) {
  // potentially use /algo/emaStoc/AUDUSD/5m as config, but can get complicated thus not used for now
  // currently uses /algo/emaStoc-AUDUSD-MINUTE_5 similar to before
  console.debug('getPathAlgoConfigDocument');
  return `${getPathAlgoCollection()}/${docName}`;
}

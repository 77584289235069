import dayjs from 'dayjs';
import {DatetimeSplit} from './split';

/**
 * join datetimeSplit into dayjs object
 * @param datetimeSplit
 * @returns
 */
export function join(datetimeSplit: DatetimeSplit) {
  return dayjs(
    `${datetimeSplit.year}-${datetimeSplit.month + 1}-${datetimeSplit.day}T${datetimeSplit.hour}:${
      datetimeSplit.minute
    }:${datetimeSplit.second}.${datetimeSplit.millisecond}`,
  );
}

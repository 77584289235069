import {Slider} from '@mui/material';

export interface IFilterBarProfitProps {
  isProfit: number;
  handleChange: (value: number) => void;
}

const marks = [
  {
    value: -1,
    label: 'Loss only',
  },
  {
    value: 0,
    label: 'off',
  },
  {
    value: 1,
    label: 'Profit only',
  },
];

export default function FilterBarProfit(props: IFilterBarProfitProps) {
  const {isProfit, handleChange} = props;

  return (
    <Slider
      sx={{width: 120, marginLeft: 5, marginRight: 5}}
      aria-label="Always visible"
      value={isProfit}
      step={1}
      min={-1}
      max={1}
      size="small"
      marks={marks}
      onChangeCommitted={(_e, val) => {
        handleChange(val as number);
      }}
    />
  );
}

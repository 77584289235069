import {IPositionV2} from '@algo/model';
import {Chip, Grid} from '@mui/material';

export interface PositionTilePriceProps {
  position: IPositionV2;
}

export default function PositionTilePrice(props: PositionTilePriceProps) {
  const {position} = props;
  return (
    <Grid container spacing={2} alignItems="center" direction="row">
      <Grid item>Tags:</Grid>
      {position.tags.map((t) => (
        <Grid item key={`${position.id}-${t}`}>
          <Chip label={t} />
        </Grid>
      ))}
    </Grid>
  );
}

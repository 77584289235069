import {ZodType, literal, object, string} from 'zod';
import {IgOrderExecutionTypeEnum} from '../../enum';
import {IgSendRequestBody} from '../internal/IgSendRequestBody';
import zodPositiveDecimalNumber from '../zod/zodPositiveDecimalNumber';
import {IgPositionOpenPartCommon, zIgPositionOpenPartCommon} from './IgPositionOpenPartCommon';
import {IgPositionOpenPartStopLimit, zIgPositionOpenPartStopLimit} from './IgPositionOpenPartStopLimit';

/**
 * Request to open position with order type quote
 */
export interface IgPositionOpenQuoteV2Request
  extends IgPositionOpenPartStopLimit,
    IgPositionOpenPartCommon,
    IgSendRequestBody {
  /** Describes the order level model to be used for a position operation */
  orderType: IgOrderExecutionTypeEnum.QUOTE;

  /** Deal level */
  level: number;

  /** Lightstreamer price quote identifier */
  quoteId: string;
}

export const zIgPositionOpenQuoteV2Request = zIgPositionOpenPartStopLimit.merge(zIgPositionOpenPartCommon).merge(
  object({
    orderType: literal(IgOrderExecutionTypeEnum.QUOTE),
    level: zodPositiveDecimalNumber(3),
    quoteId: string(),
  }),
) satisfies ZodType<IgPositionOpenQuoteV2Request>;

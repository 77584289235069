import {std} from 'mathjs';
import {IndicatorReturn} from '../../common/interface/IndicatorReturn';
import {SdConfig} from './interface/SdConfig';

/**
 * Calculate next SMA value
 * @param config new config
 * @param nextPrice
 * @returns
 */
export function sdCalculate(config: SdConfig, nextPrice: number): IndicatorReturn<SdConfig> {
  const price = [...config.price, nextPrice].slice(-config.period);

  return {config: {...config, price}, result: std(price, 'uncorrected') as number};
}

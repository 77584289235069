import {calculateIPositionV2Duration, calculateIPositionV2Profit, sortIPositionV2CloseTs} from '@algo/helper';
import {IPositionV2} from '@algo/model';
import {Grid} from '@mui/material';
import SummaryGraph from '../../components/summaryGraph/SummaryGraph';
import positionConvertCumulativeReturn from '../../logic/helper/position/convert/positionConvertCumulativeReturn';
import positionConvertCumulativeWinRatio from '../../logic/helper/position/convert/positionConvertCumulativeWinRatio';
import SummaryRowDataField from './SummaryRowDataField';

export interface SummaryRowDataProps {
  positions: IPositionV2[];
}

export default function SummaryRowData(props: SummaryRowDataProps) {
  const {positions} = props;

  positions.sort(sortIPositionV2CloseTs(true));

  const zeroArr = new Array(100).fill(0);
  const profitArr = [...zeroArr, ...positions.map((p) => calculateIPositionV2Profit(p))].slice(-100);
  const cumulativeProfitArr = [...zeroArr, ...positionConvertCumulativeReturn(positions)].slice(-100);
  const profit = cumulativeProfitArr.at(-1);

  const winRate = positionConvertCumulativeWinRatio(positions).at(-1) || 0;
  return (
    <Grid container direction="row" spacing={2} wrap="wrap" sx={{minHeight: 150}}>
      <Grid item>
        <Grid container direction="column" justifyContent="space-evenly" wrap="wrap" spacing={3}>
          <Grid item>
            <SummaryRowDataField name="Profit" value={profit.toFixed(2)} isPositive={profit > 0} />
          </Grid>
          <Grid item>
            <SummaryRowDataField name="Win rate" value={winRate.toFixed(2)} isPositive={winRate > 0.5} />
          </Grid>
          <Grid item>
            <SummaryRowDataField name="Number of trades" value={positions.length.toString()} />
          </Grid>
          <Grid item>
            <SummaryRowDataField name="Day span" value={calculateIPositionV2Duration(positions, 'day').toFixed(0)} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SummaryGraph
          data={profitArr}
          height={150}
          width={500}
          // yDomain={[0, 1]}
          // yTick={[0, 0.25, 0.5, 0.75, 1]}
          // xDomain={[0, 99]}
          // xTick={[0, 25, 50, 100]}
        />
      </Grid>
      <Grid item>
        <SummaryGraph
          data={cumulativeProfitArr}
          height={150}
          width={500}
          // yDomain={[0, 1]}
          // yTick={[0, 0.25, 0.5, 0.75, 1]}
          // xDomain={[0, 99]}
          // xTick={[0, 25, 50, 100]}
        />
      </Grid>
    </Grid>
  );
}

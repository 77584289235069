import {IgPriceCandle, IgPriceResolutionEnum} from '@algo/broker-ig';
import {extractIgPriceCandleMax, extractIgPriceCandleMin} from '@algo/helper';
import {IPositionV2} from '@algo/model';
import {useState} from 'react';
import {CursorCoordinatesPropType} from 'victory';
import useWindowSize from '../../hooks/useWindowSize';
import {GraphData} from '../graph/GraphData';
import GraphMolecule from '../graph/GraphMolecule';
import createBarGraphFromPositionsWithProfit from './createBarGraphFromPositionsWithProfit';
import createCandleGraphFromPriceCandles from './createCandleGraphFromPriceCandles';

function extractGraphData(graphData: GraphData[]): Record<string, GraphData[]> {
  return graphData.reduce(
    (prev: Record<string, GraphData[]>, cur: GraphData) => ({
      ...prev,
      [cur.graphId]: [...(prev[cur.graphId] || []), cur],
    }),
    {},
  );
}

export interface CandleGraphOrganismProps {
  /** candle bars, auto plot in *main* */
  candles?: IgPriceCandle[];
  positions?: IPositionV2[];

  resolution: IgPriceResolutionEnum;
  graphData?: GraphData[];

  width?: number;
  height?: number;
}

export default function CandleGraphOrganism(props: CandleGraphOrganismProps) {
  const {candles, resolution, graphData, width, height, positions} = props;
  const winSize = useWindowSize();
  const size = {width: 0.9 * (winSize.width || 10), height: winSize.height ? winSize.height * 0.6 : 10};
  // const [zoom, setZoom] = useState<GraphZoomDomain>(undefined);
  const [cursor, setCursor] = useState<CursorCoordinatesPropType>({x: 0, y: 0});

  if (!candles || candles.length === 0) return null;

  const graphDataWithGenerated = [
    ...(graphData || []),
    createCandleGraphFromPriceCandles(candles),
    ...createBarGraphFromPositionsWithProfit(candles, positions || []),
  ];
  const {main, ...graphDataObj} = extractGraphData(graphDataWithGenerated || []);
  const numberOfGraph = Object.keys(graphDataObj).length + 1;
  const graphWidth = width || size.width;
  const graphHeight = (height || size.height) / numberOfGraph;

  const candleMin = extractIgPriceCandleMin(candles);
  const candleMax = extractIgPriceCandleMax(candles);
  const diff = (candleMax - candleMin) * 0.1;
  return (
    <>
      <GraphMolecule
        key="main"
        graphData={[...(candles ? [createCandleGraphFromPriceCandles(candles)] : []), ...(main || [])]}
        width={graphWidth}
        height={graphHeight}
        resolution={resolution}
        minYDomain={candleMin - diff}
        maxYDomain={candleMax + diff}
        // zoom={{x: zoom?.x}}
        // setZoom={(d) => setZoom(d)}
        cursor={cursor}
        setCursor={setCursor}
      />

      {graphDataObj &&
        Object.entries(graphDataObj).map((localGraphData) => (
          <GraphMolecule
            key={localGraphData[0]}
            graphData={localGraphData[1]}
            width={graphWidth}
            height={graphHeight}
            resolution={resolution}
            // zoom={{x: zoom?.x}}
            // setZoom={(d) => setZoom(d)}
            cursor={cursor}
            setCursor={setCursor}
          />
        ))}
    </>
  );
}

import {number} from 'zod';

/**
 * Sma output
 */
export type SmaOutputV3 = number;

export const zSmaOutputV3 = number();

export const initSmaOutputV3 = 0;

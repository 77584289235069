import {literal, number, object, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {initSmaConfigV3, SmaConfigV3, zSmaConfigV3} from '../../../movingAverage/sma/interface/SmaConfigV3';
import {StochasticOutputV3, zStochasticOutputV3} from './StochasticOutputV3';

export interface StochasticConfigV3 extends IndicatorConfigV3<StochasticOutputV3, IndicatorCandle> {
  type: 'stocConfigV3';
  /// ////////////////config////////////////
  /** Fast Stochastic period */
  fastPeriod: number;
  /** D line, SMA of K line */
  // slowPeriod: number;
  smaConfig: SmaConfigV3;
  /// ////////////////operation variable////////////////
}

export const zStochasticConfigV3 = zIndicatorConfigV3(zStochasticOutputV3, zIndicatorCandle).merge(
  object({
    type: literal('stocConfigV3').default('stocConfigV3'),
    fastPeriod: number().min(2).default(14),
    smaConfig: zSmaConfigV3.default(initSmaConfigV3),
  }),
) satisfies ZodType<StochasticConfigV3, any, any>;

export const initStochasticConfigV3 = zStochasticConfigV3.parse({});

import {CollectionReference, getDocs} from 'firebase/firestore';

/**
 * Firestore helper to list all docs in given collection, and return as string array
 * @param collection
 * @returns
 */
export async function firestoreListAllDocs<T extends Record<string, any>>(
  collection: CollectionReference<T>,
): Promise<string[]> {
  const querySnapshot = await getDocs(collection);

  const result: string[] = [];
  querySnapshot.forEach((doc) => {
    result.push(doc.id);
  });
  return result;
}

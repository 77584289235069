import {IPositionV2} from '@algo/model';
import {UtilArrayNumber} from '@algo/util';
import positionConvertCumulativeReturn from '../convert/positionConvertCumulativeReturn';

/**
 * Calculate max drawdown of all trades. Start from earliest open date, end at latest close date
 * @param ascClosedpositions closed position in ascending order
 * @returns ratio of drawdown
 */
export default function positionCalculateMaxDrawdown(initalCapital: number, ascClosedpositions: IPositionV2[]) {
  const capitalArray = positionConvertCumulativeReturn(ascClosedpositions).map((r) => r + initalCapital);

  // identify peak and trough
  const turningPointHigh = UtilArrayNumber.extractTurningPointIndex(capitalArray, true, 3);
  const turningPointLow = UtilArrayNumber.extractTurningPointIndex(capitalArray, false, 3);

  return turningPointHigh.reduce((prev, cur) => {
    // find next low point
    const lowFollowHigh = turningPointLow.filter((l) => l > cur);
    if (!lowFollowHigh) return prev;
    const lowIndex = lowFollowHigh[0] as number;
    const trough = capitalArray[lowIndex] as number;
    const peak = capitalArray[cur] as number;
    const drawdown = (trough - peak) / peak;
    return drawdown < prev ? drawdown : prev;
  }, 0);
}

import {number} from 'zod';

/**
 * Psar output
 */
export type PsarOutputV3 = number;

export const zPsarOutputV3 = number();

export const initPsarOutputV3 = 0;

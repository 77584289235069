import {number} from 'zod';

/**
 * Cci output
 */
export type CciOutputV3 = number;

export const zCciOutputV3 = number();

export const initCciOutputV3 = 0;

import {getPathStrategyCollection} from './getPathStrategyCollection';

/**
 * collection path of data dump for strategy
 * @param strategyName
 * @returns
 */
export function getPathStrategyDumpCollection(strategyName: string) {
  console.debug('getPathStrategyDumpCollection');
  return `${getPathStrategyCollection()}/${strategyName}/dump`;
}

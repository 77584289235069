import {IPositionV2, PositionDirectionEnum} from '@algo/model';

/**
 * Ratio of long to short position
 * @param positions
 * @returns
 */
export default function positionCalculateLongRatio(positions: IPositionV2[]) {
  return positions.filter((p) => p.direction === PositionDirectionEnum.LONG).length / (positions.length || 1);
}

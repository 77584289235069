import {number, object, ZodType} from 'zod';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface StochasticInitV3 extends IndicatorInitV3 {
  /** Fast Stochastic period */
  fastPeriod: number;
  /** D line, SMA of K line */
  slowPeriod: number;
}

export const zStochasticInitV3 = zIndicatorInitV3.merge(
  object({
    fastPeriod: number().min(2).default(14),
    slowPeriod: number().min(2).default(3),
  }),
) satisfies ZodType<StochasticInitV3, any, any>;

// export type StochasticInitV3 = input<typeof zStochasticInitV3>;

export const initStochasticInitV3 = zStochasticInitV3.parse({});

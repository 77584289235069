import {IPositionV2} from '@algo/model';
import {Grid} from '@mui/material';

export interface PositionTileLimitProps {
  position: IPositionV2;
}

export default function PositionTileLimit(props: PositionTileLimitProps) {
  const {position} = props;
  return (
    <Grid container spacing={2} alignItems="center" direction="row">
      <Grid item>Take profit:</Grid>
      <Grid item>{position.limit}</Grid>
      <Grid item>Stop loss:</Grid>
      <Grid item>{position.stop}</Grid>
    </Grid>
  );
}

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {EmaOutputV3, zEmaOutputV3} from './EmaOutputV3';

export interface EmaConfigV3 extends IndicatorConfigV3<EmaOutputV3, number> {
  type: 'emaConfigV3';
  period: number;
  hloc: IndicatorCandlePriceEnum;
  exponent: number;
}

export const zEmaConfigV3 = zIndicatorConfigV3(zEmaOutputV3, number()).merge(
  object({
    type: literal('emaConfigV3').default('emaConfigV3'),
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
    exponent: number().nonnegative().default(0),
  }),
) satisfies ZodType<EmaConfigV3, any, any>;

// export type EmaConfigV3 = output<typeof zEmaConfigV3>;

export const initEmaConfigV3 = zEmaConfigV3.parse({});

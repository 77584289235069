import {mean} from 'mathjs';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {AtrConfigV2, zAtrConfigV2} from './interface/AtrConfigV2';
import {AtrInitV2, zAtrInitV2} from './interface/AtrInitV2';
import {AtrOutputV2, initAtrOutputV2} from './interface/AtrOutputV2';

/**
 * Calculate percentage change from ma
 */
export class AtrClassV2 extends IndicatorTemplate<AtrConfigV2, AtrOutputV2, AtrInitV2, IndicatorCandle> {
  override type = IndicatorTypeEnum.ATR;

  protected calculateTrueRange(previousCandle: IndicatorCandle, candle: IndicatorCandle) {
    const currentHigh = candle.high;
    const currentLow = candle.low;
    const previousClose = previousCandle.close;

    const range1 = currentHigh - currentLow;
    const range2 = Math.abs(currentHigh - previousClose);
    const range3 = Math.abs(currentLow - previousClose);
    return Math.max(range1, range2, range3);
  }

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<AtrConfigV2, AtrOutputV2, IndicatorCandle> {
    if (this.config.data.length < 2) {
      return {config: this.config, result: initAtrOutputV2};
    }
    const prevCandle = this.config.data.at(-2) as IndicatorCandle;
    const currentCandle = this.config.data.at(-1) as IndicatorCandle;
    const tr = this.calculateTrueRange(prevCandle, currentCandle);

    if (this.config.trueRange.length < this.config.period) {
      const trueRange = [...this.config.trueRange, tr];
      const atr = mean(trueRange);
      return {config: {...this.config, trueRange}, result: atr};
    }

    return {
      config: this.config,
      result: ((this.config.results.at(-1) as number) * (this.config.period - 1) + tr) / this.config.period,
    };
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(params: Partial<AtrInitV2> | AtrConfigV2 = {}): AtrConfigV2 {
    const testInit = zAtrInitV2.strict().safeParse(params);

    if (testInit.success) {
      return zAtrConfigV2.strip().parse({
        ...testInit.data,
        maxPriceLength: testInit.data.period,
      });
    }

    // this is config
    return zAtrConfigV2.strict().parse(params);
  }

  /**
   * map command string to init variable
   * - period
   */
  override mapCmdToInit(s: string[]): AtrInitV2 {
    const period = s[0] ? convertStrToInt(s[0]) : undefined;
    return zAtrInitV2.parse({period});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): IndicatorCandle {
    return candle;
  }
}

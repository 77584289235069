import {generateTokenExpiry} from '../generate';
import {createIgUserTokenKey, IgUserTokenKey} from './createIgUserTokenKey';

export interface IgUserToken extends IgUserTokenKey {
  /** account token, obtained after login */
  accountToken: string;
  /** client token, obtained after login */
  clientToken: string;
  /** lightstream endpoint, obtained after login */
  lightstreamerEndpoint: string;
  /** current account id, obtained after login */
  accountId: string;
  /** Expected expiry date/time for token, local datetime string in standard format */
  expiry: string;
}

/**
 * create user token from provided parameters
 * @param apiKey
 * @param isLive
 * @param accountToken
 * @param clientToken
 * @param accountId
 * @param lightstreamerEndpoint
 * @returns
 */
export function createIgUserToken(
  apiKey: string,
  isLive: boolean,
  accountToken: string,
  clientToken: string,
  accountId: string,
  lightstreamerEndpoint: string,
  expiry?: string,
): IgUserToken {
  console.debug('createIgUserToken');

  return {
    ...createIgUserTokenKey(apiKey, isLive),
    accountToken,
    clientToken,
    accountId,
    lightstreamerEndpoint,
    expiry: expiry || generateTokenExpiry(),
  };
}

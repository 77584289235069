import {mean} from 'mathjs';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorReturn} from '../../common/interface/IndicatorReturn';
import {StochasticConfig} from './interface/StochasticConfig';
import {StochasticOutput} from './interface/StochasticOutput';
import stochasticCalculateK from './stochasticCalculateK';

/**
 * Calculate Stochastic next value
 * @param config new config
 * @param high
 * @param low
 * @returns
 */
export function stochasticCalculate(
  config: StochasticConfig,
  high: number,
  low: number,
  close: number,
): IndicatorReturn<StochasticConfig, StochasticOutput> {
  const tick: IndicatorCandle = {high, low, close, open: 0};

  const data = [...config.data, tick].slice(-config.fastPeriod);

  const k = stochasticCalculateK(data);
  const kValues = [...config.kValues, k].slice(-config.slowPeriod);
  const d = mean(kValues);

  return {
    config: {
      ...config,
      data,
      kValues,
    },
    result: {k, d},
  };
}

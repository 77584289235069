/**
 * Price resolution
 */
export enum IgPriceResolutionEnum {
  DAY = 'DAY',
  HOUR = 'HOUR',
  HOUR_2 = 'HOUR_2',
  HOUR_3 = 'HOUR_3',
  HOUR_4 = 'HOUR_4',
  MINUTE = 'MINUTE',
  MINUTE_10 = 'MINUTE_10',
  MINUTE_15 = 'MINUTE_15',
  MINUTE_2 = 'MINUTE_2',
  MINUTE_3 = 'MINUTE_3',
  MINUTE_30 = 'MINUTE_30',
  MINUTE_5 = 'MINUTE_5',
  MONTH = 'MONTH',
  SECOND = 'SECOND',
  WEEK = 'WEEK',
}

import {number, object, ZodType} from 'zod';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

export interface StochasticInitV2 extends IndicatorInit {
  /** Fast Stochastic period */
  fastPeriod: number;
  /** D line, SMA of K line */
  slowPeriod: number;
}

export const zStochasticInitV2 = zIndicatorInit.merge(
  object({
    fastPeriod: number().min(2).default(14),
    slowPeriod: number().min(2).default(3),
  }),
) satisfies ZodType<StochasticInitV2, any, any>;

// export type StochasticInitV2 = input<typeof zStochasticInitV2>;

export const initStochasticInitV2 = zStochasticInitV2.parse({});

import {BaseGraphProps} from '../BaseGraphProps';
import {GraphTypeEnum} from '../GraphTypeEnum';
import {CandleStickColor} from './CandleStickColor';
import {CandleStickData} from './CandleStickData';

export interface CandleGraphAtomProps extends Omit<BaseGraphProps, 'color'> {
  graphType: GraphTypeEnum.CANDLE;
  data: CandleStickData[];
  color?: CandleStickColor;
}

export default function createCandleGraphAtomProps(
  name: string,
  graphId: string,
  data: CandleStickData[],
  color?: CandleStickColor,
): CandleGraphAtomProps {
  return {name, graphId, data, color, graphType: GraphTypeEnum.CANDLE};
}

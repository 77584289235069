import {UtilLogging} from '@algo/util';
import Cookie from 'js-cookie';
import {envEnvironment, EnvironmentNameEnum} from '../helper/env';

declare global {
  interface Window {
    setLog: (level: string) => void;
    info: string;
  }
}

window.setLog = (level?: string) => {
  const logLevel = UtilLogging.convertStrToLogLevel(level);
  if (logLevel && envEnvironment() === EnvironmentNameEnum.DEV) {
    Cookie.set('log', logLevel);
  } else {
    Cookie.remove('log');
  }
};

fetch('/info.json')
  .then((result) => result.json())
  .then((result) => {
    window.info = result;
  })
  .catch(() => {});

import {CollectionBaseEnum} from '../enum/CollectionBaseEnum';

/**
 * collection path of session
 * - holds ISessions
 * @returns
 */
export function getPathSessionCollection() {
  console.debug('getPathSessionCollection', CollectionBaseEnum.SESSION);
  return CollectionBaseEnum.SESSION;
}

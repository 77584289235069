import {IgPriceCandle} from '@algo/broker-ig';
import {IPositionV2} from '@algo/model';
import {calculateIPositionV2StopTrigger} from '../../calculate/IPositionV2/calculateIPositionV2StopTrigger';

/**
 * Checks if position hit stoploss
 * @returns
 */
export function isIPositionV2Stoploss(position: IPositionV2, candle: IgPriceCandle): boolean {
  return !!calculateIPositionV2StopTrigger(position, candle);
}

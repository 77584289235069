import {IgMarketData} from '@algo/broker-ig';
import {List, ListSubheader} from '@mui/material';
import MarketMarketListItem from './MarketMarketListItem';

export interface MarketMarketListProps {
  market: IgMarketData;
}

export default function MarketMarketList(props: MarketMarketListProps) {
  const {market} = props;
  return (
    <List
      sx={{width: '100%', maxWidth: 360, bgcolor: 'grey'}}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" sx={{color: 'white', bgcolor: 'black'}}>
          Market Detail
        </ListSubheader>
      }
    >
      {Object.entries(market).map(([key, value]) => (
        <MarketMarketListItem key={`MarketMarketList-${market.epic}-${key}`} text={`${key} - ${value}`} />
      ))}
    </List>
  );
}

import {IPositionV2} from '@algo/model';
import {Grid, Chip} from '@mui/material';

export interface PositionTileInternalProps {
  position: IPositionV2;
}

export default function PositionTileInternal(props: PositionTileInternalProps) {
  const {position} = props;
  return (
    <Grid container spacing={2} alignItems="center" direction="row">
      <Grid item>Internal:</Grid>
      <Grid item>
        <Chip label={position.broker} />
      </Grid>
      <Grid item>
        <Chip label={position.id} />
      </Grid>
    </Grid>
  );
}

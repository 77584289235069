import {IndicatorCandle} from '../../common';
import {StochasticOutput} from './interface/StochasticOutput';
import {stochastic} from './stochastic';

/**
 * Function to calculate a range of stochastic values given candles
 * @param candles
 * @param fastPeriod
 * @param slowPeriod
 * @returns
 */
export function stochasticCandle(
  candles: IndicatorCandle[],
  fastPeriod?: number,
  slowPeriod?: number,
): StochasticOutput[] {
  return stochastic(
    candles.map((candle) => candle.high),
    candles.map((candle) => candle.low),
    candles.map((candle) => candle.close),
    fastPeriod,
    slowPeriod,
  );
}

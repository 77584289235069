import LogoutIcon from '@mui/icons-material/Logout';
import {IconButton} from '@mui/material';
import {signOut} from 'firebase/auth';
import {IAuthState} from 'hooks/auth/IAuth';
import {firebaseAuth} from '../../logic/helper/firebase/app';

export interface HeaderButtonsAccountProps {
  user: IAuthState;
}

function HeaderButtonsAccount(props: HeaderButtonsAccountProps) {
  const {user} = props;

  if (!user) return null;

  return (
    <IconButton
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      color="inherit"
      onClick={() => signOut(firebaseAuth)}
    >
      <LogoutIcon />
    </IconButton>
  );
}

export default HeaderButtonsAccount;

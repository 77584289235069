import {z, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';

/**
 * Parabolic stop-and-reverse (PSAR) config
 */
export interface PsarConfig {
  /// ////////////////config////////////////
  /** acceleration factor step */
  accelerationFactorStep: number;
  /** acceleration factor start */
  accelerationFactorStart: number;
  /** acceleration factor max */
  accelerationFactorMax: number;
  /** Min points before starting to give data */
  // minPoints: number;
  /// ////////////////operation variable////////////////
  /** define if it is up or down trend */
  isUptrend: boolean;
  /** sar value */
  sar: number;
  /** extreme value */
  extreme: number;
  /** acceleration factor */
  accelerationFactor: number;
  /** previous bar */
  previousBar?: IndicatorCandle;
  /** further bar data */
  furthestBar?: IndicatorCandle;
}

export const initPsarConfig: PsarConfig = {
  accelerationFactorStep: 0,
  accelerationFactorStart: 0,
  accelerationFactorMax: 0,
  isUptrend: false,
  sar: 0,
  extreme: 0,
  accelerationFactor: 0,
};

export const zPsarConfig: ZodType<PsarConfig> = z.object({
  accelerationFactorStep: z.number().nonnegative(),
  accelerationFactorStart: z.number().nonnegative(),
  accelerationFactorMax: z.number().nonnegative(),
  isUptrend: z.boolean(),
  sar: z.number().nonnegative(),
  extreme: z.number().nonnegative(),
  accelerationFactor: z.number().nonnegative(),
  previousBar: zIndicatorCandle.optional(),
  furthestBar: zIndicatorCandle.optional(),
});

import {ZodType, boolean, nativeEnum, object, string} from 'zod';
import {IgTimeInForceEnum} from '../../enum';
import {IgPositionCommonDealReference, zIgPositionCommonDealReference} from './IgPositionCommonDealReference';
import {IgPositionCommonEpicPrice, zIgPositionCommonEpicPrice} from './IgPositionCommonEpicPrice';

/**
 * Common parameters among order types for opening position
 */
export interface IgPositionOpenPartCommon
  extends Omit<IgPositionCommonEpicPrice, 'level'>,
    IgPositionCommonDealReference {
  /**
   * Currency. Restricted to available instrument currencies
   *   [Constraint: NotNull]
   *   [Constraint: Pattern(regexp="[A-Z]{3}")]
   */
  currencyCode: string;

  /**
   * True if force open is required
   *   [Constraint: NotNull]
   */
  forceOpen: boolean;

  /**
   * Instrument expiry
   *   [Constraint: NotNull] if epic is defined
   *   [Constraint: Pattern(regexp="(\\d{2}-)?[A-Z]{3}-\\d{2}|-|DFB")]
   */
  expiry: string;

  /** The time in force determines the order fill strategy. */
  timeInForce: IgTimeInForceEnum;
}

export const zIgPositionOpenPartCommon = zIgPositionCommonEpicPrice
  .omit({level: true})
  .merge(zIgPositionCommonDealReference)
  .merge(
    object({
      currencyCode: string().regex(/[A-Z]{3}/),
      forceOpen: boolean(),
      expiry: string().refine((v) => {
        if (v === '-') return true;
        if (v === 'DFB') return true;
        return /(\d{2}-)?[A-Z]{3}-\d{2}/.test(v);
      }),
      timeInForce: nativeEnum(IgTimeInForceEnum),
    }),
  ) satisfies ZodType<IgPositionOpenPartCommon>;

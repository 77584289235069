import {number, object, ZodType} from 'zod';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

export interface CciInitV2 extends IndicatorInit {
  period: number;
  // overbought: number;
  // oversold: number;
}

export const zCciInitV2 = zIndicatorInit.merge(
  object({
    period: number().min(3).default(20),
    // overbought: number().min(1).default(100),
    // oversold: number().min(1).default(-100),
  }),
) satisfies ZodType<CciInitV2, any, any>;

// export type CciInitV2 = input<typeof zCciInitV2>;

export const initCciInitV2 = zCciInitV2.parse({});

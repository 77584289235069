import {TableCell, TableHead, TableRow} from '@mui/material';
import {IPositionTableFields, positionTableFieldsOrder} from './IPositionTableFields';

const titles: IPositionTableFields = {
  name: 'Market',
  size: 'Size',
  open: 'Opening',
  latest: 'Latest',
  stop: 'Stop',
  limit: 'Limit',
  profitLoss: 'P/L',
  totalRisk: 'Total Risk',
  limitRisk: 'Limit Risk',
  tags: 'Tags',
};

export interface IPositionTableHeaderProps {}

/**
 * Display table header
 * @param _props
 * @returns
 */
function PositionTableHeader(_props: IPositionTableHeaderProps) {
  return (
    <TableHead>
      <TableRow>
        {positionTableFieldsOrder.map((t) => (
          <TableCell key={`PositionTableHeader-${t}`} align="center">
            {titles[t as keyof IPositionTableFields]}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default PositionTableHeader;

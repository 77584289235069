import {number} from 'zod';

/**
 * 0 means no trend, 1 means trending and in between means some trending
 */

export const zSdOutputV2 = number().min(0);

export type SdOutputV2 = number;

export const initSdOutputV2: SdOutputV2 = 0;

import {IPositionV2} from '@algo/model';
import {Box, Grid} from '@mui/material';
import PositionTileAsset from './PositionTileAsset';
import PositionTileDate from './PositionTileDate';
import PositionTileInternal from './PositionTileInternal';
import PositionTileLimit from './PositionTileLimit';
import PositionTilePrice from './PositionTilePrice';
import PositionTileTags from './PositionTileTags';

export interface PositionTileProps {
  position: IPositionV2;
}

export default function PositionTile(props: PositionTileProps) {
  const {position} = props;
  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <PositionTileInternal position={position} />
        </Grid>
        <Grid item>
          <PositionTileAsset position={position} />
        </Grid>
        <Grid item>
          <PositionTilePrice position={position} />
        </Grid>
        <Grid item>
          <PositionTileDate position={position} />
        </Grid>
        <Grid item>
          <PositionTileLimit position={position} />
        </Grid>
        <Grid item>
          <PositionTileTags position={position} />
        </Grid>
      </Grid>
    </Box>
  );
}

// limit: number;
// stop: number;
// tags: string[];

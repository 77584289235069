import {CollectionBaseEnum} from '../enum/CollectionBaseEnum';

/**
 * collection path of user
 * @returns
 */
export function getPathMailCollection() {
  console.debug('getPathMailCollection');
  return CollectionBaseEnum.MAIL;
}

import {Grid, Paper} from '@mui/material';
import InfoTileBody, {InfoTileBodyProps} from './InfoTileBody';
import InfoTileHeader, {InfoTileHeaderProps} from './InfoTileHeader';

export interface InfoTileProps extends InfoTileBodyProps, InfoTileHeaderProps {}

export default function InfoTile(prop: InfoTileProps) {
  const {title, description, data, graph, labelLeft, labelRight, switchState, setSwitch} = prop;
  return (
    <Paper sx={{margin: 2, padding: 2}}>
      <Grid container direction="column">
        <Grid item>
          <InfoTileHeader
            title={title}
            description={description}
            labelLeft={labelLeft}
            labelRight={labelRight}
            switchState={switchState}
            setSwitch={setSwitch}
          />
        </Grid>
        <Grid item>
          <InfoTileBody data={data} graph={graph} />
        </Grid>
      </Grid>
    </Paper>
  );
}

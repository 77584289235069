import {extractIgEpicToCurrency} from './extractIgEpicToCurrency';

/**
 * Extract currency string if currency
 * @param epic
 * @returns currency
 * @throws if not currency epic
 */
export function extractIgEpicToCurrencyThrow(epic: string): string {
  const rtn = extractIgEpicToCurrency(epic);
  if (!rtn) throw new Error(`Cannot extract currency: ${epic}`);
  return rtn;
}

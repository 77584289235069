import {getPathStrategyCollection} from './getPathStrategyCollection';

/**
 * collection path of positions for strategy
 * @param strategyName
 * @returns
 */
export function getPathStrategyPositionCollection(strategyName: string) {
  console.debug('getStrategyPositionsCollectionPath');
  return `${getPathStrategyCollection()}/${strategyName}/positions`;
}

import {CollectionReference, doc, Unsubscribe} from 'firebase/firestore';
import {firestoreSubscribeDocument} from './firestoreSubscribeDocument';

/**
 * Subscribe to given document and return as record
 */
export function firestoreSubscribeDocumentInCollection<T extends Record<string, any>>(
  collection: CollectionReference<T>,
  docName: string,
  handleData: (data: T | undefined) => void,
): Unsubscribe {
  return firestoreSubscribeDocument(doc(collection, docName), handleData);
}

import {Autocomplete, AutocompleteChangeReason, AutocompleteRenderInputParams, Chip, TextField} from '@mui/material';

export interface ITagCellProps {
  /** string to create unique id */
  name: string;
  /** List of tags, comma separated string */
  tagsCSV: string;
  /** Options to display in dropdown box */
  options: string[];
  /** handler to set tags when changed */
  setTags?: (tagArr: string[]) => void;
}

/**
 * Display table cell with tags list
 * @param props
 * @returns
 */
export default function TagsField(props: ITagCellProps): JSX.Element {
  const {name, tagsCSV, setTags, options} = props;

  const tagArray: string[] = tagsCSV ? tagsCSV.split(',') : [];

  return (
    <Autocomplete
      multiple
      autoComplete
      autoHighlight
      value={tagArray}
      options={options}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const {className, disabled, tabIndex, onDelete} = getTagProps({index});
          return (
            <Chip
              variant="outlined"
              label={option}
              // eslint-disable-next-line
              key={`TagCell-${name}-${option}`}
              className={className}
              disabled={disabled}
              tabIndex={tabIndex}
              onDelete={onDelete}
            />
          );
        })
      }
      renderInput={(params: AutocompleteRenderInputParams) => {
        const {disabled, fullWidth, InputLabelProps, inputProps, InputProps, size, id} = params;
        return (
          <TextField
            id={id}
            disabled={disabled}
            fullWidth={fullWidth}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            inputProps={inputProps}
            size={size}
            variant="filled"
            label="Tags"
            placeholder="Tags for your trade"
          />
        );
      }}
      onChange={(_e, value: string[], reason: AutocompleteChangeReason, _details) => {
        if (reason !== 'blur' && setTags) {
          setTags(value);
        }
      }}
    />
  );
}

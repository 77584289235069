import {literal, z} from 'zod';
import {IPositionV2, PositionDirectionEnum} from '../__generated__/resolvers-types';

export const initIPositionV2: IPositionV2 = {
  id: '',
  broker: '',
  assetId: '',
  size: 0,
  direction: PositionDirectionEnum.LONG,
  open: 0,
  openTsUtc: '1900-01-01T00:00:00',
  close: 0,
  closeTsUtc: '',
  limit: 0,
  stop: 0,
  tags: [],
};

/**
 * data validator using zod
 */
export const zIPositionV2: z.ZodType<IPositionV2> = z.object({
  __typename: literal('IPositionV2').optional(),
  id: z.string(),
  broker: z.string(),
  assetId: z.string(),
  size: z.number().min(0),
  direction: z.nativeEnum(PositionDirectionEnum),
  open: z.number().min(0),
  openTsUtc: z.string().min(19),
  close: z.number().min(0),
  closeTsUtc: z.string(),
  limit: z.number().min(0),
  stop: z.number().min(0),
  tags: z.array(z.string()),
  trailingStop: z.number().min(0).optional(),
  trailingStep: z.number().min(0).optional(),
  meta: z.record(z.union([z.string(), z.number()])).optional(),
});

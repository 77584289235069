import {mean} from 'mathjs';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {SmaConfigV2, zSmaConfigV2} from './interface/SmaConfigV2';
import {SmaInitV2, zSmaInitV2} from './interface/SmaInitV2';
import {SmaOutputV2} from './interface/SmaOutputV2';

/**
 * Calculate percentage change from ma
 * - calculation different to technicalanalysis package
 */
export class SmaClassV2 extends IndicatorTemplate<SmaConfigV2, SmaOutputV2, SmaInitV2> {
  override type = IndicatorTypeEnum.SMA;

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<SmaConfigV2, SmaOutputV2> {
    return {config: this.config, result: mean(this.config.data)};
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(params: Partial<SmaInitV2> | SmaConfigV2 = {}): SmaConfigV2 {
    const testInit = zSmaInitV2.strict().safeParse(params);

    if (testInit.success) {
      return zSmaConfigV2.strip().parse({
        ...testInit.data,
        maxPriceLength: testInit.data.period,
      });
    }

    // this is config
    return zSmaConfigV2.strict().parse(params);
  }

  /**
   * map command string to init variable
   */
  override mapCmdToInit(s: string[]): SmaInitV2 {
    const period = s[0] ? convertStrToInt(s[0]) : undefined;
    return zSmaInitV2.parse({period});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): number {
    return mapIndicatorCandleToNumberArr(this.config.hloc)(candle);
  }
}

import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {SdOutputV3, zSdOutputV3} from './SdOutputV3';

/**
 * SD config
 */
export interface SdConfigV3 extends IndicatorConfigV3<SdOutputV3, number> {
  type: 'sdConfigV3';
  /** Period of sd */
  period: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zSdConfigV3 = zIndicatorConfigV3(zSdOutputV3, number()).merge(
  object({
    type: literal('sdConfigV3').default('sdConfigV3'),
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<SdConfigV3, any, any>;

export const initSdConfigV3 = zSdConfigV3.parse({});

import {IgPositionConfirmV1Response} from '@algo/broker-ig';
import {createFirestoreStrategyUnknownCollectionHelper} from '@algo/firebase-web';
import {firestoreApp} from '../../helper/firebase/app';

/**
 * create a firestore object for duplicate position
 */
export default function firestoreDuplicatePosition(strategyName: string, accountName: string) {
  return createFirestoreStrategyUnknownCollectionHelper<IgPositionConfirmV1Response>(
    firestoreApp,
    strategyName,
    `acc_${accountName}`,
  );
}

import {IgMarketNodeHierarchy} from '@algo/broker-ig';
import {ListItemButton, ListItemText} from '@mui/material';

export interface MarketListItemProps {
  setNodeId: (s: string) => void;
  node: IgMarketNodeHierarchy;
}

export default function MarketListItem(props: MarketListItemProps) {
  const {setNodeId, node} = props;

  return (
    <ListItemButton onClick={() => setNodeId(node.id)}>
      <ListItemText primary={node.name} />
    </ListItemButton>
  );
}

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgMarketData} from '../interface/market/IgMarketData';

export interface IgWatchlistGetV1Response {
  markets: IgMarketData[];
}

/**
 * get watchlist
 * @param userToken
 * @param watchlistId
 * @returns
 */
export async function getWatchlist(userToken: IgUserToken, watchlistId: string) {
  console.debug('getWatchlist');
  const result = await sendIgRequest<IgWatchlistGetV1Response>(userToken, `/watchlists/${watchlistId}`, 'get', 1);

  return createIgResponse(userToken, result);
}

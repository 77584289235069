import {Autocomplete, Chip, TextField} from '@mui/material';

export interface IFilterBarTagsProps {
  title: string;
  allTags: string[];
  tags: string[];
  handleChange: (tags: string[]) => void;
}

export default function FilterBarTags(props: IFilterBarTagsProps) {
  const {tags, allTags, handleChange, title} = props;

  return (
    <Autocomplete
      sx={{width: 320, marginLeft: 1, marginRight: 1}}
      multiple
      autoComplete
      autoHighlight
      value={tags}
      options={allTags}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option}
            // eslint-disable-next-line
            {...getTagProps({index})}
            key={`FilterBarTags-includeTags-${option}`}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line
          {...params}
          variant="filled"
          label={title}
          placeholder="Tags to filter"
        />
      )}
      onChange={(_e, value, reason, _details) => {
        switch (reason) {
          case 'createOption':
          case 'selectOption':
          case 'removeOption':
          case 'clear':
            handleChange(value);
            break;
          default: // blur
        }
      }}
      // onClose={(_e, reason) => console.log('close', reason)}
    />
  );
}

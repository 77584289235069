import {ZodType, literal, object} from 'zod';
import {IgOrderExecutionTypeEnum} from '../../enum';
import {IgSendRequestBody} from '../internal/IgSendRequestBody';
import zodPositiveDecimalNumber from '../zod/zodPositiveDecimalNumber';
import {IgPositionOpenPartCommon, zIgPositionOpenPartCommon} from './IgPositionOpenPartCommon';
import {IgPositionOpenPartStopLimit, zIgPositionOpenPartStopLimit} from './IgPositionOpenPartStopLimit';

/**
 * Request to open position with order type limit
 */
export interface IgPositionOpenLimitV2Request
  extends IgPositionOpenPartStopLimit,
    IgPositionOpenPartCommon,
    IgSendRequestBody {
  /** Describes the order level model to be used for a position operation */
  orderType: IgOrderExecutionTypeEnum.LIMIT;

  /** Deal level */
  level: number;
}

export const zIgPositionOpenLimitV2Request = zIgPositionOpenPartStopLimit.merge(zIgPositionOpenPartCommon).merge(
  object({
    orderType: literal(IgOrderExecutionTypeEnum.LIMIT),
    level: zodPositiveDecimalNumber(3),
  }),
) satisfies ZodType<IgPositionOpenLimitV2Request>;

import {IPositionV2} from '@algo/model';
import {Done} from '@mui/icons-material';
import {Box, Paper, Popover} from '@mui/material';
import {useState} from 'react';
import PositionTile from '../../../components/PositionTile/PositionTile';

export interface PositionDetailProps {
  position?: IPositionV2;
}

export default function PositionDetail(props: PositionDetailProps) {
  const {position} = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!position) {
    return null;
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} margin={1}>
      <Done />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper sx={{margin: 2, padding: 2}}>
          <PositionTile position={position} />
        </Paper>
      </Popover>
    </Box>
  );
}

import {number} from 'zod';

/**
 * Ema output
 */
export type EmaOutputV2 = number;

export const zEmaOutputV2 = number();

export const initEmaOutputV2 = 0;

import {IPositionV2} from '@algo/model';
import {Grid, Typography} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';

export const openTsColumn: GridColDef = {
  field: 'openTsUtc',
  headerName: 'Open TS',
  width: 100,
  align: 'center',
  headerAlign: 'center',
  editable: false,
  valueGetter: (value: string) => value.substring(0, 19).replace('T', ' '),
  renderCell: (params: GridRenderCellParams<IPositionV2, string, any>) => {
    const val = params.value || '';
    return (
      <Grid container wrap="wrap">
        <Typography variant="subtitle2">{val.substring(0, 10)}</Typography>
        <Typography variant="subtitle2">{val.substring(10)}</Typography>
      </Grid>
    );
  },
};

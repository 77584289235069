import {IPositionV2} from '@algo/model';
import {UtilDatetime} from '@algo/util';
import {Grid} from '@mui/material';

export interface PositionTileDateProps {
  position: IPositionV2;
}

export default function PositionTileDate(props: PositionTileDateProps) {
  const {position} = props;

  const diffMinutes = UtilDatetime.calculateDatetimeDiff(position.openTsUtc, position.closeTsUtc);

  return (
    <Grid container spacing={2} alignItems="center" direction="row" wrap="wrap">
      <Grid item>Date:</Grid>
      <Grid item>{`${position.openTsUtc} => ${position.closeTsUtc}`}</Grid>
      {position.closeTsUtc && (
        <>
          <Grid item>{diffMinutes.days}d</Grid>
          <Grid item>{diffMinutes.hours}h</Grid>
          <Grid item>{diffMinutes.minutes}m</Grid>
        </>
      )}
    </Grid>
  );
}

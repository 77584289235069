import {IPositionV2} from '@algo/model';

/**
 * sorter for IPositionV2 openTimestamp
 * @param isAscending
 */
export function sortIPositionV2OpenTs(isAscending: boolean) {
  const switcher = isAscending ? 1 : -1;
  return (a: IPositionV2, b: IPositionV2) => (a.openTsUtc > b.openTsUtc ? switcher : -switcher);
}

import {CollectionBaseEnum} from '../enum/CollectionBaseEnum';

/**
 * collection path of cache
 * @returns
 */
export function getPathCacheCollection() {
  console.debug('getPathCacheCollection');
  return CollectionBaseEnum.CACHE;
}

import {nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

export interface RsiInitV2 extends IndicatorInit {
  period: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zRsiInitV2 = zIndicatorInit.merge(
  object({
    period: number().min(3).default(14),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<RsiInitV2, any, any>;

export const initRsiInitV2 = zRsiInitV2.parse({});

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorReturn} from '../../common/interface/IndicatorReturn';
import {PsarConfig} from './interface/PsarConfig';
import psarCalculateAccelerateExtreme from './psarCalculateAccelerateExtreme';
import psarCalculateSar from './psarCalculateSar';
import psarIsChangeTrend from './psarIsChangeTrend';
import psarIsFirstDatapoint from './psarIsFirstDatapoint';

/**
 * Calculate Parabolic stop-and-reverse (PSAR) next value
 * @param config new config
 * @param high
 * @param low
 * @returns
 */
export function psarCalculate(
  config: PsarConfig,
  high: number,
  low: number,
): IndicatorReturn<PsarConfig, number | undefined> {
  const currentBar: IndicatorCandle = {high, low, close: 0, open: 0};

  // first data point
  if (psarIsFirstDatapoint(config)) {
    return {
      config: {
        ...config,
        furthestBar: currentBar,
        previousBar: currentBar,
        isUptrend: true,
        sar: low,
        extreme: high,
        accelerationFactor: config.accelerationFactorStart,
      },
      result: low,
    };
  }

  // calculate next set of values
  const sar = psarCalculateSar(config);
  const other = psarCalculateAccelerateExtreme(config, currentBar);
  const isChangeTrend = psarIsChangeTrend(config, currentBar, sar);

  if (isChangeTrend) {
    return {
      config: {
        ...config,
        furthestBar: config.previousBar,
        previousBar: currentBar,
        accelerationFactor: config.accelerationFactorStart,
        sar: other.extreme,
        isUptrend: !config.isUptrend,
        extreme: config.isUptrend ? currentBar.low : currentBar.high,
      },
      result: config.extreme,
    };
  }
  return {
    config: {
      ...config,
      furthestBar: config.previousBar,
      previousBar: currentBar,
      sar,
      ...other,
    },
    result: sar,
  };
}

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgMarketData} from '../interface/market/IgMarketData';
import {IgPosition} from '../interface/position/IgPosition';

export interface IgPositionGetV2Response {
  market: IgMarketData;
  position: IgPosition;
}

/**
 * get position with dealID
 * @param userToken
 * @param dealId
 * @returns
 */
export async function getIgPosition(userToken: IgUserToken, dealId: string) {
  console.debug('getIgPosition');
  const result = await sendIgRequest<IgPositionGetV2Response>(userToken, `/positions/${dealId}`, 'get', 2);

  return createIgResponse(userToken, result);
}

/**
 * Calculated data for given position
 */
export interface IPositionCalculate {
  profitLoss: number;
}

export const defaultIPositionCalculate: IPositionCalculate = {
  profitLoss: 0,
};

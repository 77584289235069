import {sortIPositionV2CloseTs} from '@algo/helper';
import {IPositionV2} from '@algo/model';

/**
 * Sort closed position using close timestamp
 * @param positions
 * @param isAsc true for ascending, false descending
 * @returns
 */
export default function positionSortByCloseTs<T extends IPositionV2>(positions: T[], isAsc = false) {
  return [...positions].sort(sortIPositionV2CloseTs(isAsc));
}

import {zPctMaConfig} from './interface';
import {PctMaConfig} from './interface/PctMaConfig';

/**
 * Initialise config
 * @param period
 * @returns
 */
export function pctMaInit(period = 5): PctMaConfig {
  return zPctMaConfig.parse({period, price: []});
}

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import convertStrToInt from '../../common/util/convertStrToInt';
import {sdCandle} from './sdCandle';

/**
 * Function to calculate a range of sd values given candles and cmd
 * @param cmd sd_period_hloc e.g. sd_25_2
 * @param candles
 * @returns
 */
export function sdCmd(cmd: string, candles: IndicatorCandle[]): number[] | undefined {
  const cmdArr = cmd.split('_');
  if (cmdArr[0] !== IndicatorTypeEnum.SD) return undefined;
  return sdCandle(candles, convertStrToInt(cmdArr[1]));
}

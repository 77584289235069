export enum IgActivityDetailActionEnum {
  /** Limit order amended */
  LIMIT_ORDER_AMENDED = 'LIMIT_ORDER_AMENDED',
  /** Limit order deleted */
  LIMIT_ORDER_DELETED = 'LIMIT_ORDER_DELETED',
  /** Limit order filled */
  LIMIT_ORDER_FILLED = 'LIMIT_ORDER_FILLED',
  /** Limit order opened */
  LIMIT_ORDER_OPENED = 'LIMIT_ORDER_OPENED',
  /** Limit order rolled */
  LIMIT_ORDER_ROLLED = 'LIMIT_ORDER_ROLLED',
  /** Position closed */
  POSITION_CLOSED = 'POSITION_CLOSED',
  /** Position deleted */
  POSITION_DELETED = 'POSITION_DELETED',
  /** Position opened */
  POSITION_OPENED = 'POSITION_OPENED',
  /** Position partially closed */
  POSITION_PARTIALLY_CLOSED = 'POSITION_PARTIALLY_CLOSED',
  /** Position rolled */
  POSITION_ROLLED = 'POSITION_ROLLED',
  /** Stop or limit amended */
  STOP_LIMIT_AMENDED = 'STOP_LIMIT_AMENDED',
  /** Stop order amended */
  STOP_ORDER_AMENDED = 'STOP_ORDER_AMENDED',
  /** Stop order deleted */
  STOP_ORDER_DELETED = 'STOP_ORDER_DELETED',
  /** Stop order filled */
  STOP_ORDER_FILLED = 'STOP_ORDER_FILLED',
  /** Stop order opened */
  STOP_ORDER_OPENED = 'STOP_ORDER_OPENED',
  /** Stop order rolled */
  STOP_ORDER_ROLLED = 'STOP_ORDER_ROLLED',
  /** Unknown */
  UNKNOWN = 'UNKNOWN',
  /** Working order deleted */
  WORKING_ORDER_DELETED = 'WORKING_ORDER_DELETED',
}

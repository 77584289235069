import {getPathAlgoPositionOpenCollection} from '@algo/firebase-common';
import {IPositionV2} from '@algo/model';
import {collection, CollectionReference, Firestore} from 'firebase/firestore';

/**
 * collection for Open positions of algo
 * @param firestoreApp
 * @param docName algo name
 * @returns
 */
export function getReferenceAlgoOpenCollection(firestoreApp: Firestore, docName: string) {
  return collection(firestoreApp, getPathAlgoPositionOpenCollection(docName)) as CollectionReference<IPositionV2>;
}

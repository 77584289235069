import {literal, number, object, union, ZodType} from 'zod';

export interface ISignalReturn {
  /** position size, 1 for long, -1 for short, 0 for no position hold */
  size: 1 | 0 | -1;
  /** value of stoploss */
  stoploss?: number;
  /** value of takeprofit */
  takeprofit?: number;
  /** trailing stop distance */
  trailingStop?: number;
  /** trailing step distance */
  trailingStep?: number;
}

export const initISignalReturn: ISignalReturn = {
  size: 0,
};

export const zISignalReturn: ZodType<ISignalReturn> = object({
  size: union([literal(-1), literal(0), literal(1)]),
  stoploss: number().positive().optional(),
  takeprofit: number().positive().optional(),
  trailingStop: number().positive().optional(),
  trailingStep: number().positive().optional(),
});

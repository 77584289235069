import {CollectionBaseEnum} from '../enum/CollectionBaseEnum';

/**
 * Get collection path for config
 * @returns
 */
export function getPathConfigCollection() {
  console.debug('getPathConfigCollection');
  return CollectionBaseEnum.CONFIG;
}

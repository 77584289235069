import {literal, nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {BollingerBandOutput, zBollingerBandOutput} from './BollingerBandOutput';

/**
 * bollinger config
 */
export interface BollingerBandConfigV2 extends IndicatorConfig<BollingerBandOutput, number> {
  type: 'bollConfigV2';
  /** period to check, min 3 */
  period: number;
  numberOfSd: number;
  hloc: IndicatorCandlePriceEnum;
}

// export const initBollingerBandConfig: BollingerBandConfig = {
//   ...initIndicatorConfig,
//   period: 3,
// };

export const zBollingerBandConfigV2 = zIndicatorConfig(zBollingerBandOutput, number()).merge(
  object({
    type: literal('bollConfigV2').default('bollConfigV2'),
    period: number().min(3).default(25),
    numberOfSd: number().min(0.1).default(2),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<BollingerBandConfigV2, any, any>;

export const initBollingerBandConfigV2 = zBollingerBandConfigV2.parse({});

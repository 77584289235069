import {getPathUserCollection} from './getPathUserCollection';

/**
 * collection path of house for user
 * @returns
 */
export function getPathUserHouseCollection(uid: string) {
  console.debug('getPathUserHouseCollection');
  return `${getPathUserCollection()}/${uid}/house`;
}

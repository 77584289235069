import {mean} from 'mathjs';
import {IndicatorReturn} from '../../common/interface/IndicatorReturn';
import {SmaConfig} from './interface/SmaConfig';

/**
 * Calculate next SMA value
 * @param config new config
 * @param nextPrice
 * @returns
 */
export function smaCalculate(config: SmaConfig, nextPrice: number): IndicatorReturn<SmaConfig> {
  const price = [...config.price, nextPrice].slice(-config.period);

  return {config: {...config, price}, result: mean(price)};
}

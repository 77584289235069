import {AxiosRequestConfig, Method} from 'axios';
import {IgUserToken} from '../create/createIgUserToken';
import {IgUserTokenKey} from '../create/createIgUserTokenKey';
import getIgRequestHeader from './getIgRequestHeader';
import getIgUrl from './getIgUrl';

export default function getIgRequestConfig<TParam>(
  userToken: IgUserToken | IgUserTokenKey,
  appendUrl: string,
  method: Method = 'get',
  version = 1,
  params?: TParam,
  header: Record<string, any> = {},
  body: Record<string, any> = {},
) {
  console.debug('getIgRequestConfig');

  const url = getIgUrl(userToken.isLive, appendUrl);
  const headers = getIgRequestHeader(
    userToken.apiKey,
    'accountToken' in userToken ? userToken.accountToken : undefined,
    'clientToken' in userToken ? userToken.clientToken : undefined,
    header,
    version,
  );
  const authOptions: AxiosRequestConfig = {
    method,
    url,
    params: params || {},
    headers,
    data: JSON.stringify(body),
  };
  return authOptions;
}

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgMarketData} from '../interface/market/IgMarketData';
import {IgMarketNodeHierarchy} from '../interface/market/IgMarketNodeHierarchy';

export interface IgMarketNavigationGetV1Response {
  markets: IgMarketData[];
  nodes: IgMarketNodeHierarchy[];
}

/**
 * Get market category in navigation hierarchy
 * @param userToken
 * @param nodeId
 * @returns
 */
export async function getMarketNavigation(userToken: IgUserToken, nodeId?: string) {
  console.debug('getMarketNavigation');
  const nodeIdPath = nodeId ? `/${nodeId}` : '';
  const result = await sendIgRequest<IgMarketNavigationGetV1Response>(
    userToken,
    `/marketnavigation${nodeIdPath}`,
    'get',
    1,
  );

  return createIgResponse(userToken, result);
}

import {ZodType, literal, number, object} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {AtrConfigV3, initAtrConfigV3, zAtrConfigV3} from '../../../volatility/atr/interface/AtrConfigV3';
import {SuperTrendOutput, zSuperTrendOutput} from './SuperTrendOutput';

/**
 * super trend config
 */
export interface SuperTrendConfigV3 extends IndicatorConfigV3<SuperTrendOutput, IndicatorCandle> {
  type: 'superTrendV3';
  /** Period of ATR */
  period: number;
  /** what the ATR is multiplied by to offset the bands from price */
  multiplier: number;
  atr: AtrConfigV3;
}

export const zSuperTrendConfigV3 = zIndicatorConfigV3(zSuperTrendOutput, zIndicatorCandle).merge(
  object({
    type: literal('superTrendV3').default('superTrendV3'),
    period: number().int().min(3).default(10),
    multiplier: number().min(0.1).default(3),
    atr: zAtrConfigV3.default(initAtrConfigV3),
  }),
) satisfies ZodType<SuperTrendConfigV3, any, any>;

export const initSuperTrendConfigV3 = zSuperTrendConfigV3.parse({});

import {Grid, Box} from '@mui/material';
import {MatchAlgoOptionsEnum} from './enum/MatchAlgoOptionsEnum';
import {MatchAlgoStageEnum} from './enum/MatchAlgoStageEnum';

/**
 * Description for different button
 * - Paper config
 * - Paper open
 * - IG record
 * - IG
 */
const description: Record<MatchAlgoOptionsEnum, string> = {
  [MatchAlgoOptionsEnum.ALGO_PAPER]: `Compare ${MatchAlgoStageEnum.PAPER_CONFIG} to ${MatchAlgoStageEnum.PAPER_OPEN}`,
  [MatchAlgoOptionsEnum.ALGO_PAPER_DEV]: `Compare open collection to algo copier collection in dev. Orphan when position exists in ${MatchAlgoStageEnum.PAPER_OPEN} but not on ${MatchAlgoStageEnum.COPY_RECORD}, which happens when size is 0/not copying. Not recorded is when ${MatchAlgoStageEnum.COPY_RECORD} has open position but ${MatchAlgoStageEnum.PAPER_OPEN} already closed, when algoCopy not triggered`,
  [MatchAlgoOptionsEnum.ALGO_PAPER_PROD]: `Compare open collection to algo copier collection in prod. Orphan when position exists in ${MatchAlgoStageEnum.PAPER_OPEN} but not on ${MatchAlgoStageEnum.COPY_RECORD}, which happens when size is 0/not copying. Not recorded is when ${MatchAlgoStageEnum.COPY_RECORD} has open position but ${MatchAlgoStageEnum.PAPER_OPEN} already closed, when algoCopy not triggered`,
  [MatchAlgoOptionsEnum.ALGO_DEV]: `Compare algo copier collection in dev to ig demo. Orphan when exists on ${MatchAlgoStageEnum.BROKER} but not ${MatchAlgoStageEnum.COPY_RECORD}. Not recorded when position in ig is closed, but not in ${MatchAlgoStageEnum.COPY_RECORD}`,
  [MatchAlgoOptionsEnum.ALGO_PROD]: `Compare algo copier collection in dev to ig prod. Orphan when exists on IG but not ${MatchAlgoStageEnum.COPY_RECORD}. Not recorded when position in ${MatchAlgoStageEnum.BROKER} is closed, but not in ${MatchAlgoStageEnum.COPY_RECORD}`,
};

export interface MatchAlgoDescriptionProps {
  selection?: MatchAlgoOptionsEnum;
}

export default function MatchAlgoDescription(props: MatchAlgoDescriptionProps) {
  const {selection} = props;

  if (!selection) return null;

  return (
    <Grid item>
      <Box>{description[selection]}</Box>
    </Grid>
  );
}

import {Firestore} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {createFirestorePriceCollectionHelper} from '../../firestore/price/createFirestorePriceCollectionHelper';

/**
 * @returns list of epics in price collection
 */
export function useListPriceEpic(firestoreApp: Firestore) {
  console.debug('useListPriceEpic');
  const [data, setData] = useState<string[]>([]);

  useEffect(() => {
    const downloadData = async () => {
      setData(await createFirestorePriceCollectionHelper<any>(firestoreApp).list());
    };
    downloadData();
    // eslint-disable-next-line
  }, []);

  return data;
}

import {BollingerBandConfig, zBollingerBandConfig} from './interface/BollingerBandConfig';

/**
 * Initialise config
 * @param period
 * @returns
 */
export function bollingerBandInit(period = 25, numberOfSd = 2): BollingerBandConfig {
  return zBollingerBandConfig.parse({period, numberOfSd, price: []});
}

import {numberAboveMin} from './numberAboveMin';

/**
 *
 * @param num
 * @param minNum
 * @returns
 */
export function floorAboveMin(num: number, minNum: number) {
  return numberAboveMin(Math.floor(num), minNum);
}

import {number, object, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfig, zIndicatorConfig} from '../../../common/template/IndicatorConfig';
import {NeighbourBarRangingOutput, zNeighbourBarRangingOutput} from './NeighbourBarRangingOutput';

/**
 * Neighbouring bar ranging indicator config
 */
export interface NeighbourBarRangingConfig extends IndicatorConfig<NeighbourBarRangingOutput, IndicatorCandle> {
  /** period to check, min 3 */
  period: number;
}

export const zNeighbourBarRangingConfig = zIndicatorConfig(zNeighbourBarRangingOutput, zIndicatorCandle).merge(
  object({
    /** period to check, min 3 */
    period: number().min(3).default(3),
  }),
) satisfies ZodType<NeighbourBarRangingConfig, any, any>;

export const initNeighbourBarRangingConfig = zNeighbourBarRangingConfig.parse({});

/**
 * Specified how the order is fulfilled
 */
export enum IgTimeInForceEnum {
  /**
   * Fill order as much as possible at required price.
   * May result in partial fill
   */
  EXECUTE_AND_ELIMINATE = 'EXECUTE_AND_ELIMINATE',

  /**
   * Fill order as a whole or not
   * order may fail
   */
  FILL_OR_KILL = 'FILL_OR_KILL',
}

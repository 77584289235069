import {number, object, ZodType} from 'zod';

export interface IndicatorInit {
  maxPriceLength: number;
  // hloc: IndicatorCandlePriceEnum;
  maxResultLength: number;
}

export const zIndicatorInit = object({
  maxPriceLength: number().min(1).default(10),
  // hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  maxResultLength: number().min(1).default(10),
}) satisfies ZodType<IndicatorInit, any, any>;

/**
 * Type for input only, field with default is optional. Everything should be partial in Init type
 */
// export type IndicatorInit = input<typeof zIndicatorInit>;

export const initIndicatorInit = zIndicatorInit.parse({});

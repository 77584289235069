/**
 * Get ig url based on live or demo
 * @param userToken
 * @returns IG api service base url
 */
export default function getIgUrl(isLive: boolean, serviceUri = '') {
  console.debug('getIgUrl');
  const fixedUri = serviceUri.charAt(0) === '/' || serviceUri.length === 0 ? serviceUri : `/${serviceUri}`;
  return `https://${isLive ? '' : 'demo-'}api.ig.com/gateway/deal${fixedUri}`;
}

import {nativeEnum, object, z, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface SdInitV3 extends IndicatorInitV3 {
  period: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zSdInitV3 = zIndicatorInitV3.merge(
  object({
    period: z.number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<SdInitV3, any, any>;

export const initSdInitV3 = zSdInitV3.parse({});

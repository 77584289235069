import {IgPriceResolutionEnum} from '@algo/broker-ig';
import {Firestore} from 'firebase/firestore';
import {FirestoreCollectionHelper} from '../../base/FirestoreCollectionHelper';
import {getReferencePriceEpicResolutionCollection} from '../../reference/price/getReferencePriceEpicResolutionCollection';

export const createFirestorePriceResolutionCollectionHelper = (
  firestoreApp: Firestore,
  epic: string,
  resolution: IgPriceResolutionEnum,
) => new FirestoreCollectionHelper(getReferencePriceEpicResolutionCollection(firestoreApp, epic, resolution));

import {listIgPositions} from '@algo/broker-ig';
import {IUserBroker} from '@algo/firebase-common';
import igSessionQuery from './igSessionQuery';

/**
 * Download positions from ig
 * @param uid
 * @param brokerAccount
 * @returns
 */
export async function igDownloadActivePositions(uid: string, brokerAccount: IUserBroker) {
  console.debug('igDownloadActivePositions');
  // get positions
  const positions = await igSessionQuery(uid, brokerAccount, async (userToken) => {
    const data = await listIgPositions(userToken);
    return {token: data.token, data};
  });

  // set positions
  return positions.data.data?.positions;
}

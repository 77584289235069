import {linearRegression, linearRegressionLine} from 'simple-statistics';
import {IndicatorReturn} from '../../common/interface/IndicatorReturn';
import {PctMaOutput} from './interface';
import {PctMaConfig} from './interface/PctMaConfig';

/**
 * Calculate next pct ma value
 * @param config new config
 * @param nextPrice
 * @returns
 */
export function pctMaCalculate(config: PctMaConfig, nextPrice: number): IndicatorReturn<PctMaConfig, PctMaOutput> {
  const price = [...config.price, nextPrice].slice(-config.period);
  const data = price.map((p, i) => [i, p]);
  const param = linearRegression(data);
  const y0 = linearRegressionLine(param)(0); // should be equal to b
  const y1 = linearRegressionLine(param)(price.length - 1);
  const middle = (y0 + y1) / 2;

  return {
    config: {...config, price},
    result: {slope: param.m, last: y1, first: y0, pct: ((y1 - middle) / middle) * 100},
  };
}

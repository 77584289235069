import {IPositionV2, IPositionV2Handle} from '@algo/model';
import {GridColDef} from '@mui/x-data-grid';
// import { closePriceColumn } from './closePriceColumn';
import {closeTsColumn} from './closeTsColumn';
import {detailButtonColumn} from './detailButtonColumn';
import {epicColumn} from './epicColumn';
import {openPriceColumn} from './openPriceColumn';
import {openTsColumn} from './openTsColumn';
import {profitLossColumn} from './profitLossColumn';
import {sizeColumn} from './sizeColumn';
import {tagsColumn} from './tagsColumn';

export const analysisTableColumns = (
  setTags: (position: IPositionV2, tags: string[]) => void,
  showDetail: IPositionV2Handle,
  showOnGraph: IPositionV2Handle,
) =>
  [
    detailButtonColumn(showDetail, showOnGraph),
    epicColumn,
    sizeColumn,
    openPriceColumn,
    // closePriceColumn,
    profitLossColumn,
    tagsColumn(setTags),
    openTsColumn,
    closeTsColumn,
  ] as GridColDef[];

import {Firestore} from 'firebase/firestore';
import {FirestoreCollectionHelper} from '../../base/FirestoreCollectionHelper';
import {getReferenceAlgoOpenCollection} from '../../reference/algo/getReferenceAlgoOpenCollection';

/**
 * Fetch algo open position
 * @param firestoreApp
 * @param docName
 * @returns
 */
export const createFirestoreAlgoOpenCollectionHelper = (firestoreApp: Firestore, docName: string) =>
  new FirestoreCollectionHelper(getReferenceAlgoOpenCollection(firestoreApp, docName));

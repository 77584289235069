import {DocumentReference, getDoc} from 'firebase/firestore';

/**
 * Firestore helper to load a doc
 * @param doc
 * @returns
 */
export async function firestoreLoadDoc<T extends Record<string, any>>(
  doc: DocumentReference<T>,
): Promise<T | undefined> {
  console.debug('firestoreLoadDoc', doc.id);
  try {
    const querySnapshot = await getDoc(doc);

    return querySnapshot.data();
  } catch {
    return undefined;
  }
}

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgDealStatusEnum} from '../enum/IgDealStatusEnum';
import {IgPositionStatusEnum} from '../enum/IgPositionStatusEnum';
import {IgReasonEnum} from '../enum/IgReasonEnum';
import {IgPositionAffectedDeal} from '../interface/position/IgPositionAffectedDeal';
import {IgPositionCommonDealReference} from '../interface/position/IgPositionCommonDealReference';
import {IgPositionCommonEpicPrice} from '../interface/position/IgPositionCommonEpicPrice';
import {IgPositionCommonGuaranteeStop} from '../interface/position/IgPositionCommonGuaranteeStop';
import {IgPositionCommonStopLimit} from '../interface/position/IgPositionCommonStopLimit';
import {IgPositionCommonTrailing} from '../interface/position/IgPositionCommonTrailing';

/**
 * Response from deal confirmation
 */
export interface IgPositionConfirmV1Response
  extends IgPositionCommonDealReference,
    IgPositionCommonEpicPrice,
    IgPositionCommonGuaranteeStop,
    IgPositionCommonStopLimit,
    Pick<IgPositionCommonTrailing, 'trailingStop'> {
  /** affected deal object array */
  affectedDeals: IgPositionAffectedDeal[];

  /** Transaction date */
  date: string;

  /** Deal identifier */
  dealId: string;

  /** Deal status */
  dealStatus: IgDealStatusEnum;

  /** Instrument expiry */
  expiry: string;

  /** Profit */
  profit: number | null;

  /** Profit currency */
  profitCurrency: string | null;

  /** Describes the error (or success) condition for the specified trading operation */
  reason: IgReasonEnum;

  /** Position status */
  status: IgPositionStatusEnum;
}

/**
 * use deal reference to get order result
 * @param userToken
 * @param dealReference
 * @returns
 */
export async function confirmIgPosition(userToken: IgUserToken, dealReference: string) {
  console.debug('confirmIgPosition');
  const result = await sendIgRequest<IgPositionConfirmV1Response>(userToken, `/confirms/${dealReference}`, 'get', 1);

  return createIgResponse(userToken, result);
}

import {UtilObject} from '@algo/util';
import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgDirectionEnum} from '../enum/IgDirectionEnum';
import {IgOrderExecutionTypeEnum} from '../enum/IgOrderExecutionTypeEnum';
import {IgTimeInForceEnum} from '../enum/IgTimeInForceEnum';
import {IgSendRequestBody} from '../interface/internal/IgSendRequestBody';
import {IgPositionUpdateV2Response} from './updateIgPosition';

export interface IgPositionCloseV1Request extends IgSendRequestBody {
  /** Describes the order level model to be used for a position operation */
  orderType: IgOrderExecutionTypeEnum;

  /**
   * Deal size
   *   [Constraint: NotNull]
   *   [Constraint: Check precision is not more than 12 decimal places]
   */
  size: number;

  /** The time in force determines the order fill strategy. */
  timeInForce: IgTimeInForceEnum;

  /**
   * Deal identifier
   *   [Constraint: Pattern(regexp=".{1,30}")]
   */
  dealId?: string;

  /** Deal direction */
  direction?: IgDirectionEnum;

  /**
   * Instrument epic identifier
   *   [Constraint: Pattern(regexp="[A-Za-z0-9._]{6,30}")]
   */
  epic?: string;

  /**
   * Instrument expiry
   *   [Constraint: Pattern(regexp="(\\d{2}-)?[A-Z]{3}-\\d{2}|-|DFB")]
   */
  expiry?: string;

  /** Closing deal level */
  level?: number;

  /** Lightstreamer price quote identifier */
  quoteId?: string;
}

export type IgPositionCloseV1Response = IgPositionUpdateV2Response;

/**
 * Checks if data is valid
 * @param param
 */
function isValid(param: IgPositionCloseV1Request) {
  // [Constraint: If epic is defined, then set expiry]
  if (param.epic && !param.expiry) return false;

  // [Constraint: If orderType equals LIMIT, then DO NOT set quoteId]
  // [Constraint: If orderType equals LIMIT, then set level]
  if (param.orderType === IgOrderExecutionTypeEnum.LIMIT) {
    if (param.quoteId || !param.level) return false;
  }

  // [Constraint: If orderType equals MARKET, then DO NOT set level,quoteId]
  if (param.orderType === IgOrderExecutionTypeEnum.MARKET) {
    if (param.quoteId || param.level) return false;
  }

  // [Constraint: If orderType equals QUOTE, then set level,quoteId]
  if (param.orderType === IgOrderExecutionTypeEnum.QUOTE) {
    if (!param.quoteId || !param.level) return false;
  }

  // [Constraint: Set only one of {dealId,epic}]
  if (param.dealId && param.epic) return false;

  return true;
}

/**
 * close ig position
 * @param userToken
 * @param params
 * @returns
 */
export async function closeIgPosition(userToken: IgUserToken, params: IgPositionCloseV1Request) {
  console.debug('closeIgPosition');

  const newParams = UtilObject.filterUndefined(params) as IgPositionCloseV1Request;
  if (!isValid(newParams)) {
    throw new Error('closeIgPosition - data incorrect');
  }

  const result = await sendIgRequest<IgPositionCloseV1Response>(
    userToken,
    '/positions/otc',
    'put',
    1,
    {_method: 'DELETE'},
    undefined,
    newParams,
  );

  return createIgResponse(userToken, result);
}

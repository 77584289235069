import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {PsarConfig} from './interface/PsarConfig';

/**
 * Check if trend change happened
 * @param config
 * @param currentBar
 * @param sar
 * @returns
 */
export default function psarIsChangeTrend(config: PsarConfig, currentBar: IndicatorCandle, sar: number) {
  const down = config.isUptrend && currentBar.low < sar;
  const up = !config.isUptrend && currentBar.high > sar;
  return up || down;
}

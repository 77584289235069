import {PctMaConfig} from './interface/PctMaConfig';
import {pctMaCalculate} from './pctMaCalculate';
import {pctMaInit} from './pctMaInit';

/**
 * Calculate percentage change from ma
 */
export class PctMa {
  config: PctMaConfig;

  constructor(period?: number) {
    this.config = pctMaInit(period);
  }

  next(nextValue: number) {
    const result = pctMaCalculate(this.config, nextValue);
    this.config = result.config;
    return result.result;
  }
}

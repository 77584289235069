import {AlgoStageEnum} from '../__generated__/resolvers-types';
import useQueryAlgoCopyConfig from './useQueryAlgoCopyConfig';
import useQueryListAllPaperAlgos from './useQueryListAllPaperAlgos';

export enum StrategyTypeEnum {
  ALGO = 'ALGO',
  COPY_DEV = 'COPY DEV',
  COPY_PROD = 'COPY PROD',
}

/**
 * Show a list of algos for any environments
 * @param firestoreApp
 * @param strategyType
 * @param reloadTrigger string that force reload when changed
 * @returns
 */
export function useListAlgoStrategy(strategyType: StrategyTypeEnum, reloadTrigger?: string): string[] {
  console.debug('useListAlgoStrategy');
  const algoList = useQueryListAllPaperAlgos(reloadTrigger);
  const algoCopierDev = Object.keys(useQueryAlgoCopyConfig(AlgoStageEnum.DEV, reloadTrigger));
  const algoCopierProd = Object.keys(useQueryAlgoCopyConfig(AlgoStageEnum.PROD, reloadTrigger));

  if (strategyType === StrategyTypeEnum.ALGO) return algoList;
  return strategyType === StrategyTypeEnum.COPY_DEV ? algoCopierDev : algoCopierProd;
}

import {number, object, ZodType} from 'zod';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface CciInitV3 extends IndicatorInitV3 {
  period: number;
  // overbought: number;
  // oversold: number;
}

export const zCciInitV3 = zIndicatorInitV3.merge(
  object({
    period: number().min(3).default(20),
    // overbought: number().min(1).default(100),
    // oversold: number().min(1).default(-100),
  }),
) satisfies ZodType<CciInitV3, any, any>;

// export type CciInitV3 = input<typeof zCciInitV3>;

export const initCciInitV3 = zCciInitV3.parse({});

import {number, object, ZodType} from 'zod';

/**
 * Stochastic output data type
 */
export interface MacdOutput {
  /** fast-slow line */
  macd: number;
  /** ema of macd line, acting as reference */
  signal: number;
  /** macd - signal */
  histogram: number;
}

export const initMacdOutput: MacdOutput = {
  macd: 0,
  signal: 0,
  histogram: 0,
};

export const zMacdOutput: ZodType<MacdOutput> = object({
  macd: number(),
  signal: number(),
  histogram: number(),
}) satisfies ZodType<MacdOutput, any, any>;

import {isIPositionV2InProfit} from '@algo/helper';
import {IPositionV2} from '@algo/model';

/**
 * Calculate an array of cumulative win ratio
 * can see how win ratio changes over time
 * @param positions
 * @returns
 */
export default function positionConvertCumulativeWinRatio(positions: IPositionV2[]) {
  let totalWin = 0;
  if (positions.length === 0) return [];
  return positions.reduce((prev, position, i) => {
    if (isIPositionV2InProfit(position)) totalWin += 1;
    return [...prev, (totalWin * 1.0) / (i + 1)];
  }, [] as number[]);
}

import {CopyAll} from '@mui/icons-material';
import {Grid, IconButton, Typography} from '@mui/material';
import {breakStrategyCode} from './utilSummary';

export interface SummaryRowTitleProps {
  name: string;
}

export default function SummaryRowTitle(props: SummaryRowTitleProps) {
  const {name} = props;

  const handlCopy = () => {
    navigator.clipboard.writeText(breakStrategyCode(name).strategyName);
  };

  return (
    <Grid container alignItems="center" sx={{borderBottom: 1}}>
      <Grid item>
        <Typography variant="subtitle1">{name}</Typography>
      </Grid>

      <Grid item>
        <IconButton aria-label="copy" onClick={handlCopy}>
          <CopyAll />
        </IconButton>
      </Grid>
    </Grid>
  );
}

import {Grid, TextField, Typography} from '@mui/material';

export interface SummaryHeaderPositionProps {
  setMinPosition: (s: string) => void;
}

export default function SummaryHeaderPosition(props: SummaryHeaderPositionProps) {
  const {setMinPosition} = props;

  return (
    <Grid container alignItems="center" direction="row" spacing={1}>
      <Grid item>
        <Typography>Position count</Typography>
      </Grid>
      <Grid item>
        <TextField label="minimum" name="minimum" onChange={(e) => setMinPosition(e.target.value)} />
      </Grid>
    </Grid>
  );
}

import {DocumentReference} from 'firebase/firestore';
import {firestoreDelDoc} from './firestoreDelDoc';
import {firestoreLoadDoc} from './firestoreLoadDoc';
import {firestoreSaveDoc} from './firestoreSaveDoc';
import {firestoreSubscribeDocument} from './firestoreSubscribeDocument';

export class FirestoreDocumentHelper<T extends Record<string, any>> {
  document: DocumentReference<T>;

  constructor(document: DocumentReference<T>) {
    this.document = document;
  }

  del = () => firestoreDelDoc(this.document);

  load = () => firestoreLoadDoc(this.document);

  save = (data: T, isMerge?: boolean) => firestoreSaveDoc(this.document, data, isMerge);

  subscribe = (handleData: (data: T | undefined) => void) => firestoreSubscribeDocument(this.document, handleData);
}

import {CollectionReference, onSnapshot, Unsubscribe} from 'firebase/firestore';

/**
 * Subscribe to all docs in given collection, and return as record
 * @param collection
 * @param handleData
 * @returns
 */
export function firestoreSubscribeCollection<T extends Record<string, any>>(
  collection: CollectionReference<T>,
  handleData: (data: Record<string, T>) => void,
): Unsubscribe {
  return onSnapshot(
    collection,
    (querySnapshot) => {
      console.debug('firestoreSubscribeCollection.onSnapshot');

      const result: Record<string, T> = {};
      querySnapshot.forEach((doc) => {
        result[doc.id] = doc.data();
      });
      handleData(result);
    },
    (_e) => {
      // console.error('firestoreSubscribeCollection.onSnapshot.error', e);
      handleData({});
    },
  );
}

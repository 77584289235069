import {IgPriceCandle} from '@algo/broker-ig';

/**
 * sorter for IgPriceCandle snapshotTime
 * @param isAscending
 */
export function sortIgPriceCandleSnapshotTime(isAscending: boolean) {
  const switcher = isAscending ? 1 : -1;
  return (a: IgPriceCandle, b: IgPriceCandle) => (a.snapshotTime > b.snapshotTime ? switcher : -switcher);
}

import {IPositionV2} from '@algo/model';
import {UtilDatetime} from '@algo/util';

/**
 * Calculate trade duration, separate by days, hours, mins, seconds
 * @param position
 * @returns
 */
export function calculateIPositionV2DurationCategory(position: IPositionV2) {
  return UtilDatetime.calculateDatetimeDiff(position.openTsUtc, position.closeTsUtc);
}

import {IPositionV2} from '@algo/model';
import {isIPositionV2Closed} from '../../status/IPositionV2/isIPositionV2Closed';

/**
 * Filter for open position
 * @param isOpen
 * @returns
 */
export function filterIPositionV2Status(isOpen: boolean) {
  return (p: IPositionV2, _index: number, _array: IPositionV2[]) =>
    isOpen ? !isIPositionV2Closed(p) : isIPositionV2Closed(p);
}

import {IgPositionConfirmV1Response} from '@algo/broker-ig';
import {IUserBroker} from '@algo/firebase-common';
import {Box, Grid} from '@mui/material';
import {IPositionRecordV2} from 'model/IPositionRecord';
import {useEffect, useState} from 'react';
import useLoadIgPositionsActive from '../../hooks/useLoadIgPositionsActive';
import firestoreDuplicatePosition from '../../logic/service/duplicate/firestoreDuplicatePosition';
import MatchAccounts from './MatchAccounts';
import MatchTable from './MatchTable';

export interface MatchPositionsPageProps {
  uid: string;
  accounts: IUserBroker[];
  positionRecords: IPositionRecordV2;
}

/**
 * This is specific to personal use
 * @param props
 * @returns
 */
export default function MatchPositionsPage(props: MatchPositionsPageProps) {
  const {accounts, positionRecords, uid} = props;
  const [accountLeft, setAccountLeft] = useState('ska');
  const [accountRight, setAccountRight] = useState('live');
  const [duplicate, setDuplicate] = useState<Record<string, IgPositionConfirmV1Response>>({});

  const accLeft = accounts.find((a) => a.name === accountLeft);
  const accRight = accounts.find((a) => a.name === accountRight);
  // const dualPositions = useLoadIgDualPositionsActive(accLeft, accRight, positionRecords);
  const positionLeft = useLoadIgPositionsActive(uid, accLeft, positionRecords);
  const positionRight = useLoadIgPositionsActive(uid, accRight, positionRecords);

  // download CopyTradeProd data
  useEffect(() => {
    async function effect() {
      setDuplicate(accRight ? await firestoreDuplicatePosition('copyTrade', accRight.name).loadAll() : {});
    }
    effect();
  }, [accRight]);

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <MatchAccounts
            accounts={accounts}
            accountLeft={accountLeft}
            setAccountLeft={setAccountLeft}
            accountRight={accountRight}
            setAccountRight={setAccountRight}
          />
        </Grid>

        <Grid item>
          <MatchTable
            duplicate={duplicate}
            positionLeft={positionLeft.positions}
            positionRight={positionRight.positions}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgWatchlistStatusEnum} from '../interface/watchlist/IgWatchlistStatusEnum';

export interface IgWatchlistCreateV1Response {
  status: IgWatchlistStatusEnum;
  watchlistId: string;
}

/**
 * Create a new watchlist of given name
 * @param userToken
 * @param name
 * @param epics
 * @returns
 */
export async function createWatchlist(userToken: IgUserToken, name: string, epics: string[]) {
  console.debug('createWatchlist', name, epics);
  const result = await sendIgRequest<IgWatchlistCreateV1Response>(
    userToken,
    '/watchlists',
    'post',
    1,
    undefined,
    undefined,
    {name: name.substring(0, 40), epics},
  );

  return createIgResponse(userToken, result);
}

import {DocumentReference, setDoc} from 'firebase/firestore';

/**
 * Firestore helper to save doc
 * @param doc
 * @param data
 * @param isMerge
 * @returns
 */
export function firestoreSaveDoc<T extends Record<string, any>>(
  doc: DocumentReference<T>,
  data: T,
  isMerge = false,
): Promise<void> {
  console.debug('firestoreSaveDoc', doc.id);
  return setDoc(doc, data, {merge: isMerge});
}

import {IgPriceResolutionEnum} from '@algo/broker-ig';
import {useListPriceEpic, useListPriceEpicResolutionArray} from '@algo/firebase-web';
import {mapIgPriceCandleToIndicatorCandle} from '@algo/helper';
import {IndicatorTypeEnum} from '@algo/indicators';
import {IPositionV2} from '@algo/model';
import {DatetimeEnum, UtilObject} from '@algo/util';
import {Grid, Paper} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import {useEffect, useState} from 'react';
import CandleGraphOrganism from '../../components/candleGraph/CandleGraphOrganism';
import createLineGraphAtomProps from '../../components/graph/line/LineGraphAtomProps';
import mapListIgPriceCandleIndicatorToVictoryLine from '../../components/graph/line/mapListIgPriceCandleIndicatorToVictoryLine';
// import createScatterGraphAtomProps from '../../components/graph/scatter/ScatterGraphAtomProps';
import {envEnvironment, EnvironmentNameEnum} from '../../logic/helper/env';
import {firestoreApp} from '../../logic/helper/firebase/app';
import calculateCandleIndicators from '../../logic/service/calculate/calculateCandleIndicators';
// import AnalysisCandleNavigation from './AnalysisCandleNavigation';
import AnalysisCandleSelection from './AnalysisCandleSelection';

export interface AnalysisCandleProps {
  /** Positions to display for open close */
  positions?: IPositionV2[];
  positionOnGraph?: IPositionV2;
}

const resolutions = [IgPriceResolutionEnum.MINUTE_5, IgPriceResolutionEnum.HOUR];
/**
 * Show candlestick chart
 * @param props
 * @returns
 */
export default function AnalysisCandle(props: AnalysisCandleProps) {
  const {positions, positionOnGraph} = props;
  const epics = useListPriceEpic(firestoreApp);
  const [epic, setEpic] = useState<string>('');

  const [resolution, setResolution] = useState<IgPriceResolutionEnum>(IgPriceResolutionEnum.MINUTE_5);

  const requiredDate = envEnvironment() === EnvironmentNameEnum.DEV ? dayjs('2022-09-05T00:00:00') : dayjs();
  const [startDate, setStartDate] = useState<Dayjs>(requiredDate.subtract(1, 'day'));
  const [endDate, setEndDate] = useState<Dayjs>(requiredDate);
  const [reload, setReload] = useState(false);

  const [endDays, setEndDays] = useState(1);

  useEffect(() => {
    // end days button to set end date
    if (endDays === 0) return;
    if (!startDate) return;
    setEndDate(startDate.add(endDays, 'day'));
  }, [endDays, startDate]);

  useEffect(() => {
    // position on graph button
    if (!positionOnGraph) return;
    setStartDate(dayjs(positionOnGraph.openTsUtc).subtract(3, 'hour'));
    if (positionOnGraph.closeTsUtc.length > 0) {
      setEndDays(0);
      setEndDate(dayjs(positionOnGraph.closeTsUtc).add(2, 'hour'));
    }
    if (epic !== positionOnGraph.assetId) {
      setEpic(positionOnGraph.assetId);
    }
    setReload(!reload);
    // eslint-disable-next-line
  }, [positionOnGraph]);

  // full data
  const candles = useListPriceEpicResolutionArray(
    firestoreApp,
    epic,
    resolution,
    startDate?.format(DatetimeEnum.STANDARD_DATETIME) || undefined,
    endDate?.format(DatetimeEnum.STANDARD_DATETIME) || undefined,
    reload,
  );
  const indicatorCandles = candles.map(mapIgPriceCandleToIndicatorCandle);

  // calculate indicators
  const strSma20 = `${IndicatorTypeEnum.SMA}_200`;
  const strEma50 = `${IndicatorTypeEnum.EMA}_50`;
  const strEma150 = `${IndicatorTypeEnum.EMA}_21`;
  const strMacd = `${IndicatorTypeEnum.MACD}_26_12_9`;
  const strStoc = `${IndicatorTypeEnum.STOCHASTIC}_60_5`;
  const strSd = `${IndicatorTypeEnum.SD}_16`;
  const strBoll = `${IndicatorTypeEnum.BOLLINGER_BAND}_20_2`;
  const strPctMa = `${IndicatorTypeEnum.PCT_MA}_30`;
  const strNeighbour = `${IndicatorTypeEnum.NEIGHBOUR_BAR_RANGING}_8`;
  const strRsi = `${IndicatorTypeEnum.RSI}_20`;
  const strCci = `${IndicatorTypeEnum.CCI}_14`;
  const strAtr = `${IndicatorTypeEnum.ATR}_14`;
  const strPreviousHighLow = `${IndicatorTypeEnum.PREVIOUS_HL}_7`;
  const strSuperTrend = `${IndicatorTypeEnum.SUPER_TREND}_20_3.5`;
  const indicatorData = calculateCandleIndicators(
    indicatorCandles,
    envEnvironment() === EnvironmentNameEnum.DEV
      ? [
          strSma20,
          strEma50,
          strEma150,
          strStoc,
          strMacd,
          strSd,
          strBoll,
          strPctMa,
          strPreviousHighLow,
          strNeighbour,
          strRsi,
          strCci,
          strAtr,
          strSuperTrend,
        ]
      : [],
  );

  // convert to indicator lines
  const indicatorLines = UtilObject.executeFnToValue(indicatorData, (d) =>
    mapListIgPriceCandleIndicatorToVictoryLine(candles, d),
  );
  // filter position to related epic only
  const relatedPositions = positions && epic.length ? positions.filter((p) => p.assetId === epic) : [];
  return (
    <Paper sx={{padding: 2}}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <AnalysisCandleSelection
            epics={epics}
            epic={epic}
            setEpic={setEpic}
            resolutions={resolutions}
            resolution={resolution}
            setResolution={setResolution}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            endDays={endDays}
            setEndDays={setEndDays}
            handleLoad={() => setReload(!reload)}
          />
        </Grid>
        <Grid item>
          <CandleGraphOrganism
            candles={Object.values(candles)}
            resolution={resolution}
            positions={relatedPositions}
            graphData={[
              createLineGraphAtomProps(strSma20, 'main', indicatorLines[strSma20] || [], 'blue'),
              // createLineGraphAtomProps(strEma50, 'main', indicatorLines[strEma50] || [], 'blue'),
              // createLineGraphAtomProps(strEma150, 'main', indicatorLines[strEma150] || [], 'red'),
              // createLineGraphAtomProps(strBoll, 'main', indicatorLines[`${strBoll}_ma`] || [], 'black'),
              // createLineGraphAtomProps(strBoll, 'main', indicatorLines[`${strBoll}_upper`] || [], 'orange'),
              // createLineGraphAtomProps(strBoll, 'main', indicatorLines[`${strBoll}_lower`] || [], 'orange'),
              // createLineGraphAtomProps(strPctMa, 'pctma', indicatorLines[`${strPctMa}_slope`] || [], 'black'),
              // createLineGraphAtomProps(strPctMa, 'pctma', indicatorLines[`${strPctMa}_pct`] || [], 'red'),
              // createScatterGraphAtomProps('psar', 'main', indicatorLines['psar'] || [], 'green'),
              // createLineGraphAtomProps('macd', 'macd', indicatorLines[`${strMacd}_macd`] || [], 'green'),
              // createLineGraphAtomProps('signal', 'macd', indicatorLines[`${strMacd}_signal`] || [], 'red'),
              // createLineGraphAtomProps('histogram', 'macd', indicatorLines[`${strMacd}_histogram`] || [], 'blue'),
              // createLineGraphAtomProps('histogram', 'sd', indicatorLines[strSd] || [], 'blue'),
              // createLineGraphAtomProps(strNeighbour, 'neighbour', indicatorLines[strNeighbour] || [], 'blue'),
              createLineGraphAtomProps(
                strPreviousHighLow,
                'main',
                indicatorLines[`${strPreviousHighLow}_high`] || [],
                'green',
              ),
              createLineGraphAtomProps(
                strPreviousHighLow,
                'main',
                indicatorLines[`${strPreviousHighLow}_low`] || [],
                'red',
              ),
              // createLineGraphAtomProps(strRsi, 'rsi', indicatorLines[strRsi] || [], 'blue'),
              // createLineGraphAtomProps(strCci, 'cci', indicatorLines[strCci] || [], 'blue'),
              // createLineGraphAtomProps(strAtr, 'atr', indicatorLines[strAtr] || [], 'red'),
              // createLineGraphAtomProps('k', 'stochastic', indicatorLines[`${strStoc}_k`] || [], 'green'),
              // createLineGraphAtomProps('d', 'stochastic', indicatorLines[`${strStoc}_d`] || [], 'red'),
              // createLineGraphAtomProps(
              //   strSuperTrend,
              //   'main',
              //   indicatorLines[`${strSuperTrend}_superTrendUpper`] || [],
              //   'red',
              // ),
              // createLineGraphAtomProps(
              //   strSuperTrend,
              //   'main',
              //   indicatorLines[`${strSuperTrend}_superTrendLower`] || [],
              //   'green',
              // ),
            ]}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

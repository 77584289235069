import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {NeighbourBarRangingConfig, zNeighbourBarRangingConfig} from './interface';
import {NeighbourBarRangingInit, zNeighbourBarRangingInit} from './interface/NeighbourBarRangingInit';
import {NeighbourBarRangingOutput} from './interface/NeighbourBarRangingOutput';

/**
 * Calculate percentage change from ma
 */
export class NeighbourBarRangingClass extends IndicatorTemplate<
  NeighbourBarRangingConfig,
  NeighbourBarRangingOutput,
  NeighbourBarRangingInit,
  IndicatorCandle
> {
  override type = IndicatorTypeEnum.NEIGHBOUR_BAR_RANGING;

  /**
   * Identify if candle is considered ranging.
   *
   * Condition when NOT ranging
   * - candle high lower than current low
   * - candle low higher than current high
   * @returns
   */
  protected calculateRange(previousCandle: IndicatorCandle, currentHigh: number, currentLow: number): number {
    if (previousCandle.high < currentLow) return 1;
    if (previousCandle.low > currentHigh) return -1;
    return 0;
  }

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<
    NeighbourBarRangingConfig,
    NeighbourBarRangingOutput,
    IndicatorCandle
  > {
    const {period} = this.config;
    if (this.config.data.length < period) return {result: 0, config: this.config};

    const candles = this.config.data.slice(-period, -1);
    // const maxPrice = Math.max(...candles.map(mapIndicatorCandleToNumberArr(IndicatorCandlePriceEnum.HIGH)));
    // const minPrice = Math.min(...candles.map(mapIndicatorCandleToNumberArr(IndicatorCandlePriceEnum.LOW)));
    const {high, low} = this.config.data.at(-1) as IndicatorCandle;

    const sumOfRange = candles.reduce((prev, candle) => prev + this.calculateRange(candle, high, low), 0);
    return {config: this.config, result: sumOfRange / (period - 1)};
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(
    params: Partial<NeighbourBarRangingInit> | NeighbourBarRangingConfig = {},
  ): NeighbourBarRangingConfig {
    const testInit = zNeighbourBarRangingInit.strict().safeParse(params);

    if (testInit.success) {
      return zNeighbourBarRangingConfig.strip().parse({
        ...testInit.data,
        maxPriceLength: testInit.data.period,
      });
    }

    // this is config
    return zNeighbourBarRangingConfig.strict().parse(params);
  }

  /**
   * map command string to init variable
   */
  override mapCmdToInit(s: string[]): NeighbourBarRangingInit {
    const period = s[0] ? convertStrToInt(s[0]) : undefined;
    return zNeighbourBarRangingInit.parse({period});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): IndicatorCandle {
    return candle;
  }
}

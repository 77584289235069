import {filterArrayUndefined} from '@coolcolduk/util';
import useQueryListAlgoPaperConfigPosition from '../../../hooks/useQueryListAlgoPaperConfigPosition';
import useQueryListAlgoPaperOpenPosition from '../../../hooks/useQueryListAlgoPaperOpenPosition';
import useQueryListAllPaperAlgos from '../../../hooks/useQueryListAllPaperAlgos';
import {MatchAlgoOptionsEnum} from '../enum/MatchAlgoOptionsEnum';
import buildIMatchAlgoPosition from '../helper/buildIMatchAlgoPosition';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

/**
 * Matching `paper config` with `paper open` collection
 * @description Not recorded - exist on `paper config` only
 * - failed writing to firebase
 * - ACTION: N/A. creation of files in `paper open` may trigger copier, at the wrong time
 * @description Orphan - exist on `paper open` only
 * - candle stick created and updated, causing 2 paper trade to run simultaneously
 * - failed closing open position
 * - ACTION: remove position from `paper open`
 * @param firestoreApp
 * @param selection
 * @returns
 */
export function useMatchAlgoPaper(selection?: MatchAlgoOptionsEnum): IMatchAlgoPosition[] | undefined {
  console.debug('useMatchAlgoPaper');

  const isActive = selection === MatchAlgoOptionsEnum.ALGO_PAPER;

  // get a list of algos
  const listOfAlgoPaper = useQueryListAllPaperAlgos();

  // fetch position from Paper open
  const paperOpenPositions = useQueryListAlgoPaperOpenPosition(isActive ? listOfAlgoPaper : []);

  // fetch position from Paper config
  const paperConfigOpenPositionsData = useQueryListAlgoPaperConfigPosition(isActive ? listOfAlgoPaper : []);
  const activePaperTradePosition = filterArrayUndefined(
    Object.entries(paperConfigOpenPositionsData).map(([_k, v]) => v),
  );

  return isActive ? buildIMatchAlgoPosition(paperOpenPositions, activePaperTradePosition) : undefined;
}

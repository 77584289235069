import z, {ZodType} from 'zod';

/**
 * percentage ma config
 */
export interface PctMaConfig {
  period: number;

  /** Current price */
  price: number[];
}

export const initPctMaConfig: PctMaConfig = {
  period: 0,
  price: [],
};

export const zPctMaConfig: ZodType<PctMaConfig> = z.object({
  period: z.number().min(2),
  price: z.array(z.number().positive()),
});

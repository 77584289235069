import {sleepMs} from '@coolcolduk/util';
import {IgUserToken} from '../create/createIgUserToken';
import {confirmIgPosition, updateIgPosition} from '../position';

/**
 * update position and resolve deal reference to position detail
 */
export async function igHelperUpdatePositionDetail(
  userToken: IgUserToken,
  dealId: string,
  stopLevel?: number | null,
  limitLevel?: number | null,
  trailingStopDistance?: number | null,
  trailingStopIncrement?: number | null,
) {
  console.debug('igHelperUpdatePositionDetail');

  const result = await updateIgPosition(userToken, dealId, {
    stopLevel: stopLevel || null,
    limitLevel: limitLevel || null,
    trailingStopDistance: trailingStopDistance || undefined,
    trailingStopIncrement: trailingStopIncrement || undefined,
    trailingStop: !!(trailingStopDistance || trailingStopIncrement),
  });

  if (!result.data) {
    return undefined;
  }

  await sleepMs(300);
  return confirmIgPosition(userToken, result.data.dealReference);
}

/**
 * Check if index is lowest/highest around `lookback` number of points
 * @param data
 * @param forLocalHigh true to check if value is highest, false to check is lowest
 * @param lookback number of points to check around
 * @param i
 * @param value
 * @returns true if turning
 */
function checkAround(data: number[], forLocalHigh: boolean, lookback: number, i: number, value: number) {
  const startIndex = i - lookback > 0 ? i - lookback : 0;
  const endIndex = i + lookback < data.length ? i + lookback : data.length - 1;

  // end index is exclusive thus +1
  const dataToCheck = data.slice(startIndex, endIndex + 1);

  return !dataToCheck.some((d) => (forLocalHigh ? d > value : d < value));
}

/**
 * Identify all turning points in the data
 * @param data
 * @param forLocalHigh true if identify local high, false for local low
 * @param lookback the number of points to look back
 */
export function extractTurningPointIndex(data: number[], forLocalHigh: boolean, lookback = 3) {
  if (data.length < lookback) return [] as number[];

  // index number if lowest, otherwise undefined
  return data
    .map((d, i) => (checkAround(data, forLocalHigh, lookback, i, d) ? i : undefined))
    .filter((l) => l !== undefined) as number[];
}

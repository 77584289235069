import {nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

export interface SmaInitV2 extends IndicatorInit {
  period: number;
  hloc: IndicatorCandlePriceEnum;
}

export const zSmaInitV2 = zIndicatorInit.merge(
  object({
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
  }),
) satisfies ZodType<SmaInitV2, any, any>;

// export type SmaInitV2 = input<typeof zSmaInitV2>;

export const initSmaInitV2 = zSmaInitV2.parse({});

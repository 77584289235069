import {mapStringToEnumValue} from '@coolcolduk/util';
import {AlgoStageEnum} from '../../__generated__/resolvers-types';

/**
 * build strategy code
 * - strategy code is stage + strategy name
 * @param stage
 * @param strategyName
 * @returns
 */
export function buildStrategyCode(stage: AlgoStageEnum, strategyName: string) {
  return `${stage}-${strategyName}`;
}

/**
 * break up strategy code to stage and name
 * @param strategyCode
 * @returns
 */
export function breakStrategyCode(strategyCode: string) {
  const sArr = strategyCode.split('-');
  const stage = mapStringToEnumValue(AlgoStageEnum, sArr[0] || '');
  const strategyName = sArr.slice(1).join('-');

  if (stage === undefined) {
    throw new Error(`Strategy code error: stage - ${stage}, strategy code - ${strategyCode}`);
  }

  return {stage, strategyName};
}

/**
 * @deprecated switch to breakStrategyCode
 * @param s
 * @returns
 */
export function extractStrategyCode(s: string) {
  return s.split('-').slice(1).join('-');
}

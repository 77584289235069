import {number} from 'zod';

/**
 * Cci output
 */
export type CciOutputV2 = number;

export const zCciOutputV2 = number();

export const initCciOutputV2 = 0;

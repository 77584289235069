import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';

export interface IgWatchlistUpdateAddV1Response {
  status: 'SUCCESS';
}

/**
 * Add epic to watchlist
 * @param userToken
 * @param name
 * @param epics
 * @returns
 */
export async function updateWatchlistAddEpic(userToken: IgUserToken, watchlistId: string, epic: string) {
  console.debug('updateWatchlistAddEpic');
  const result = await sendIgRequest<IgWatchlistUpdateAddV1Response>(
    userToken,
    `/watchlists/${watchlistId}`,
    'put',
    1,
    undefined,
    undefined,
    {epic},
  );

  return createIgResponse(userToken, result);
}

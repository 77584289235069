import {IgMarketNodeHierarchy} from '@algo/broker-ig';
import {List, ListSubheader} from '@mui/material';
import MarketListItem from './MarketListItem';

export interface MarketListProps {
  setNodeId: (s: string) => void;
  nodes: IgMarketNodeHierarchy[];
}

export default function MarketList(props: MarketListProps) {
  const {setNodeId, nodes} = props;

  nodes.sort((n1, n2) => (n1.name > n2.name ? 1 : -1));
  return (
    <List
      sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Nodes
        </ListSubheader>
      }
    >
      {nodes.map((n) => (
        <MarketListItem node={n} setNodeId={setNodeId} key={`MarketList-${n.id}`} />
      ))}
    </List>
  );
}

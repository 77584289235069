import {number, object, ZodType} from 'zod';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface AtrInitV3 extends IndicatorInitV3 {
  period: number;
}

export const zAtrInitV3 = zIndicatorInitV3.merge(
  object({
    period: number().min(3).default(20),
  }),
) satisfies ZodType<AtrInitV3, any, any>;

export const initAtrInitV3 = zAtrInitV3.parse({});

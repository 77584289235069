import dayjs from 'dayjs';
import {DatetimeEnum} from '../enum/DateTimeEnum';

/**
 * Get local datetime string in given format
 * @param dtFormat
 * @returns
 */
export function getLocalDatetime(dtFormat: DatetimeEnum): string {
  return dayjs().format(dtFormat);
}

import {getPathUserCollection} from './getPathUserCollection';

/**
 * collection of positions for the user
 * @param uid
 * @returns
 */
export function getPathUserPositionCollection(uid: string) {
  console.debug('getPathUserPositionCollection');
  return `${getPathUserCollection()}/${uid}/positions`;
}

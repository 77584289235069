import {IgPriceCandle} from '@algo/broker-ig';
import {isIPositionV2InProfit, isIPositionV2Long} from '@algo/helper';
import {IPositionV2} from '@algo/model';
import {GraphData} from '../graph/GraphData';
import mapPositionProfitToBarGraph from './mapPositionProfitToBarGraph';

export default function createBarGraphFromPositionsWithProfit(
  candles: IgPriceCandle[],
  positions: IPositionV2[],
): GraphData[] {
  const positionLongProfit = positions.filter(isIPositionV2Long).filter(isIPositionV2InProfit);
  const positionLongLoss = positions.filter(isIPositionV2Long).filter((p) => !isIPositionV2InProfit(p));
  const positionShortProfit = positions.filter((p) => !isIPositionV2Long(p)).filter(isIPositionV2InProfit);
  const positionShortLoss = positions.filter((p) => !isIPositionV2Long(p)).filter((p) => !isIPositionV2InProfit(p));

  return [
    mapPositionProfitToBarGraph('positions-long-profit', 'green', candles, positionLongProfit),
    mapPositionProfitToBarGraph('positions-long-loss', 'green', candles, positionLongLoss),
    mapPositionProfitToBarGraph('positions-short-profit', 'red', candles, positionShortProfit),
    mapPositionProfitToBarGraph('positions-short-loss', 'red', candles, positionShortLoss),
  ];
}

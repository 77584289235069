/**
 * Base collection name in firestore
 */
export enum CollectionBaseEnum {
  /** Algo related data */
  ALGO = 'algo',

  /** Configuration for script based on name */
  CONFIG = 'config',

  /**
   * Used by sendMail to send email
   */
  MAIL = 'mail',

  /** Price data, document using script name */
  PRICE = 'price',

  /**
   * Session information
   * - key using broker with api key
   * - session info to be used by FE and BE
   */
  SESSION = 'session',

  /**
   * Script data, such as copyTrade
   * - ~~temporary data for strategy~~
   * - ~~close position for strategy for analysing~~ - doesn't do this yet
   */
  STRATEGY = 'strategy',

  /**
   * User data
   * - broker information
   */
  USER = 'users',

  /**
   * Cache data, stored temporary and can be deleted
   */
  CACHE = 'cache',

  /**
   * Position stored by paper trade/strategies. Document using script name
   * @deprecated currently not in use
   */
  POSITION = 'position',

  /**
   * Monitor account position
   * @deprecated currently not in use
   */
  MONITOR = 'monitor',
}

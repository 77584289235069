import {array, number, object, ZodType} from 'zod';

export interface IndicatorConfig<Output, Input> {
  /** Price will always be candle stick */
  data: Input[];
  /** Maximum number of price stored */
  maxPriceLength: number;
  /** hloc needed for calculation */
  // hloc: IndicatorCandlePriceEnum;
  /** Calculated result will be stored */
  results: Output[];
  /** Maximum number of result stored */
  maxResultLength: number;
}

// export const initIndicatorConfig: IndicatorConfig<number> = {
//   prices: [],
//   maxPriceLength: 10,
//   hloc: IndicatorCandlePriceEnum.CLOSE,
//   results: [],
//   maxResultLength: 10,
// };

export const zIndicatorConfig = <Output, Input>(outputSchema: ZodType<Output>, dataSchema: ZodType<Input>) =>
  object({
    /** Values can be candle stick or number */
    data: array(dataSchema).default([]),
    /** Maximum number of price stored */
    maxPriceLength: number().min(1).default(10),
    /** hloc needed for calculation */
    // hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
    /** Calculated result will be stored */
    results: array(outputSchema).default([]),
    /** Maximum number of result stored */
    maxResultLength: number().min(1).default(10),
  }) satisfies ZodType<IndicatorConfig<Output, Input>, any, any>;

export const initIndicatorConfig = zIndicatorConfig(number().default(0), number()).parse({});

// export type IndicatorConfig1<Output> = output<typeof zIndicatorConfig<Output>>;

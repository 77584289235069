import {UtilNumber} from '@algo/util';
import {Grid, Paper, TextField} from '@mui/material';
import {IFilter} from 'model/filter/IFilter';
import FilterBarProfit from './FilterBarProfit';
import FilterBarTags from './FilterBarTags';

export interface IFilterBarProps {
  allTags: string[];
  currentFilter: IFilter;
  setFilter: (newFilter: IFilter) => void;
}

export default function FilterBar(props: IFilterBarProps) {
  const {currentFilter, setFilter, allTags} = props;

  // avoid tags already selected from showing up
  const filteredTags = allTags.filter(
    (t) => !currentFilter.excludeTags?.includes(t) && !currentFilter.includeTags?.includes(t),
  );

  return (
    <Paper sx={{padding: 2}}>
      <Grid container direction="row" justifyContent="space-evenly">
        {currentFilter.includeTags !== undefined && (
          <Grid item>
            <FilterBarTags
              title="Include tags"
              allTags={filteredTags}
              tags={currentFilter.includeTags}
              handleChange={(includeTags: string[]) => {
                setFilter({...currentFilter, includeTags});
              }}
            />
          </Grid>
        )}
        {currentFilter.excludeTags !== undefined && (
          <Grid item>
            <FilterBarTags
              title="Exclude tags"
              allTags={filteredTags}
              tags={currentFilter.excludeTags}
              handleChange={(excludeTags: string[]) => {
                setFilter({...currentFilter, excludeTags});
              }}
            />
          </Grid>
        )}
        {currentFilter.profitLoss !== undefined && (
          <Grid item>
            <FilterBarProfit
              isProfit={currentFilter.profitLoss}
              handleChange={(isProfit: number) => {
                setFilter({...currentFilter, profitLoss: isProfit});
              }}
            />
          </Grid>
        )}
        {currentFilter.maxLatestPosition !== undefined && (
          <Grid item>
            <TextField
              label="Max latest data"
              value={currentFilter.maxLatestPosition}
              inputProps={{type: 'number', min: 0, step: 1}}
              onChange={(e) =>
                setFilter({...currentFilter, maxLatestPosition: UtilNumber.tryParseInt(e.target.value) || 0})
              }
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgPositionGetV2Response} from './getIgPosition';

export interface IgPositionListV2Response {
  positions: IgPositionGetV2Response[];
}
/**
 * Get list of positions
 * @param userToken
 * @returns
 */
export async function listIgPositions(userToken: IgUserToken) {
  console.debug('listIgPositions');
  const result = await sendIgRequest<IgPositionListV2Response>(userToken, '/positions', 'get', 2);

  return createIgResponse(userToken, result);
}

import {AxiosError} from 'axios';
import {ISendIgRequest} from '../core/sendIgRequest';
import {IgStatusEnum} from '../enum';
import {IgError} from '../interface/IgError';
import {IgUserToken} from './createIgUserToken';
import {IgUserTokenKey} from './createIgUserTokenKey';

/**
 * IG session returned data format
 */
export interface IgResponse<T> {
  /** Original token or new token */
  token: IgUserToken;

  /** Response from server */
  data?: T;

  /** status code from server response */
  status?: number;

  /** Error object from axios */
  error?: AxiosError<IgError>;

  /** Status code from request */
  statusCode: IgStatusEnum;
}

/**
 * Create standard response
 * @param token
 * @param result
 * @returns
 */
export function createIgResponse<TResult, TBody>(
  token: IgUserToken | IgUserTokenKey,
  result: ISendIgRequest<TResult, TBody>,
) {
  return {
    token,
    data: result.response?.data,
    status: result.response?.status || result.error?.status,
    error: result.error,
    statusCode: result.statusCode,
  } as IgResponse<TResult>;
}

import {IPositionV2, PositionDirectionEnum} from '@algo/model';
import {MatchTableData} from '../interface/MatchTableData';

/**
 * map data when only src exists
 * @param srcPosition
 * @returns
 */
export default function mapSrcToMatchTableData(srcPosition: IPositionV2): MatchTableData {
  return {
    duplicate: false,
    dealIdLeft: srcPosition.id,
    dealIdRight: '',
    assetId: srcPosition?.assetId || '',
    size: srcPosition.direction === PositionDirectionEnum.LONG ? srcPosition.size : -srcPosition.size,
    openPrice: srcPosition.open,
    price: srcPosition.close,
    profitLoss: (
      (srcPosition.close - srcPosition.open) *
      srcPosition.size *
      (srcPosition.direction === PositionDirectionEnum.LONG ? 1 : -1)
    ).toFixed(1),
    stoplossMatch: srcPosition.stop.toString(),
    takeprofitMatch: srcPosition.limit.toString(),
    openTs: srcPosition?.openTsUtc || '',
  };
}

import {getPathUserCollection} from './getPathUserCollection';

/**
 * collection path of broker for user
 * @returns
 */
export function getPathUserBrokerCollection(uid: string) {
  console.debug('getPathUserBrokerCollection');
  return `${getPathUserCollection()}/${uid}/broker`;
}

import {IgPriceResolutionEnum} from '@algo/broker-ig';
import {
  CursorCoordinatesPropType,
  VictoryAxis,
  VictoryBar,
  VictoryCandlestick,
  VictoryChart,
  VictoryCursorContainer,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
} from 'victory';
import {GraphData} from './GraphData';
import {GraphTypeEnum} from './GraphTypeEnum';
// import {GraphZoomDomain, GraphZoomFn} from './GraphZoomFn';

function resolveTick(resolution: IgPriceResolutionEnum) {
  return (t: Date) =>
    resolution === IgPriceResolutionEnum.MINUTE_5
      ? `${t.getHours()}:${t.getMinutes()}`
      : `${t.getDate()}/${t.getMonth()}`;
}

export interface GraphMoleculeProps {
  width?: number;
  height?: number;
  resolution: IgPriceResolutionEnum;
  graphData: GraphData[];
  minYDomain?: number;
  maxYDomain?: number;

  // zoom: GraphZoomDomain;
  // setZoom: GraphZoomFn;

  cursor: CursorCoordinatesPropType;
  setCursor: (coord: CursorCoordinatesPropType) => void;
}

export default function GraphMolecule(props: GraphMoleculeProps) {
  const {width, height, resolution, graphData, setCursor, cursor, minYDomain, maxYDomain} = props;

  if (graphData.length === 0) return null;
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={{x: (graphData[0] as GraphData).data.length / 5}}
      scale={{x: 'time', y: 'linear'}}
      height={height}
      width={width}
      minDomain={{y: minYDomain}}
      maxDomain={{y: maxYDomain}}
      containerComponent={
        <VictoryCursorContainer
          // zoomDimension="x"
          // zoomDomain={zoom}
          // onZoomDomainChange={setZoom}
          onCursorChange={setCursor}
          defaultCursorValue={typeof cursor === 'number' ? cursor : {x: cursor.x, y: 0}}
          cursorLabel={() => (typeof cursor === 'number' ? cursor : cursor.y)}
        />
      }
    >
      <VictoryAxis tickFormat={resolveTick(resolution)} tickCount={20} />
      <VictoryAxis dependentAxis tickCount={5} />
      {graphData.map((data) => {
        const key = data.name;
        if (data.graphType === GraphTypeEnum.LINE)
          return <VictoryLine key={key} data={data.data} style={{data: {stroke: data.color}}} />;
        if (data.graphType === GraphTypeEnum.CANDLE)
          return <VictoryCandlestick key={key} candleColors={data.color} data={data.data} />;

        if (data.graphType === GraphTypeEnum.BAR)
          return <VictoryBar barRatio={1} key={key} style={{data: {fill: data.color}}} data={data.data} />;

        if (data.graphType === GraphTypeEnum.SCATTER)
          return <VictoryScatter key={key} style={{data: {fill: data.color}}} data={data.data} />;
        throw new Error('Graph type not found');
      })}
    </VictoryChart>
  );
}

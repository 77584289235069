import {sum} from 'mathjs';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {RsiConfigV2, zRsiConfigV2} from './interface/RsiConfigV2';
import {RsiInitV2, zRsiInitV2} from './interface/RsiInitV2';
import {RsiOutputV2, initRsiOutputV2, zRsiOutputV2} from './interface/RsiOutputV2';

/**
 * Calculate percentage change from ma
 */
export class RsiClassV2 extends IndicatorTemplate<RsiConfigV2, RsiOutputV2, RsiInitV2> {
  override type = IndicatorTypeEnum.RSI;

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<RsiConfigV2, RsiOutputV2> {
    const {period, data, previousGain, previousLoss} = this.config;

    if (data.length <= period) return {result: initRsiOutputV2, config: this.config};

    // fetch profits
    const isFirst = previousGain === 0;
    const closePrice = isFirst ? data : data.slice(-2);
    const profits = closePrice.map((p, i) => (i === 0 ? 0 : p - (closePrice[i - 1] as number))).slice(1);

    // find all gains and loss
    const gains = profits.filter((v) => v > 0);
    const loss = profits.filter((v) => v < 0);

    // calculate total gain/loss
    const totalGain = sum(gains) + previousGain * (period - 1);
    const totalLoss = sum(loss) + previousLoss * (period - 1);

    const RS = Math.abs(totalGain / totalLoss);

    return {
      config: {...this.config, previousGain: totalGain / period, previousLoss: totalLoss / period},
      result: zRsiOutputV2.parse(totalLoss === 0 ? 100 : 100 - 100 / (1 + RS)),
    };
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(params: Partial<RsiInitV2> | RsiConfigV2 = {}): RsiConfigV2 {
    const testInit = zRsiInitV2.strict().safeParse(params);

    if (testInit.success) {
      return zRsiConfigV2.strip().parse({
        ...testInit.data,
        maxPriceLength: testInit.data.period + 1,
      });
    }

    // this is config
    return zRsiConfigV2.strict().parse(params);
  }

  /**
   * map command string to init variable
   */
  override mapCmdToInit(s: string[]): RsiInitV2 {
    const period = s[0] ? convertStrToInt(s[0]) : undefined;
    return zRsiInitV2.parse({period});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): number {
    return mapIndicatorCandleToNumberArr(this.config.hloc)(candle);
  }
}

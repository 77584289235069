import {IPositionV2} from '@algo/model';
import {UtilObject} from '@algo/util';

/**
 * Map ipositionv2 array to record with id as key
 * @param positions
 * @returns
 */
export function mapIPositionV2ArrToRecord(positions: IPositionV2[]): Record<string, IPositionV2> {
  return UtilObject.fromArray(positions.map((v) => ({id: v.id, data: v})));
}

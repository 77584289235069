import {sum} from 'mathjs';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import mapIndicatorV3InputToNumber from '../../common/template/v3/mapIndicatorV3InputToNumber';
import convertStrToInt from '../../common/util/convertStrToInt';
import {RsiConfigV3, zRsiConfigV3} from './interface/RsiConfigV3';
import {RsiInitV3, zRsiInitV3} from './interface/RsiInitV3';
import {RsiOutputV3, initRsiOutputV3, zRsiOutputV3} from './interface/RsiOutputV3';

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<RsiInitV3>) {
  const parsedInit = zRsiInitV3.strict().parse(init);

  return zRsiConfigV3.strip().parse({
    ...parsedInit,
    maxInputLength: parsedInit.period + 1,
  });
}

/**
 * Calculate Rsi
 * @param config
 * @returns
 */
function calculate(config: RsiConfigV3): IndicatorReturnV3<RsiConfigV3, RsiOutputV3, number> {
  const {period, input: data, previousGain, previousLoss} = config;

  if (data.length <= period) return {result: initRsiOutputV3, config};

  // fetch profits
  const isFirst = previousGain === 0;
  const closePrice = isFirst ? data : data.slice(-2);
  const profits = closePrice.map((p, i) => (i === 0 ? 0 : p - (closePrice[i - 1] as number))).slice(1);

  // find all gains and loss
  const gains = profits.filter((v) => v > 0);
  const loss = profits.filter((v) => v < 0);

  // calculate total gain/loss
  const totalGain = sum(gains) + previousGain * (period - 1);
  const totalLoss = sum(loss) + previousLoss * (period - 1);

  const RS = Math.abs(totalGain / totalLoss);

  return {
    config: {...config, previousGain: totalGain / period, previousLoss: totalLoss / period},
    result: zRsiOutputV3.parse(totalLoss === 0 ? 100 : 100 - 100 / (1 + RS)),
  };
}

/**
 * Rsi indicator
 */
export const rsiV3 = createIndicatorV3(
  IndicatorTypeEnum.RSI,
  createConfig,
  calculate,
  (s) =>
    zRsiInitV3.parse({
      period: convertStrToInt(s[0]),
      hloc: convertStrToInt(s[1]),
    }),
  mapIndicatorV3InputToNumber,
  zRsiConfigV3,
);

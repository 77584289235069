export enum IgActivityTypeEnum {
  /** Amend stop or limit activity */
  EDIT_STOP_AND_LIMIT = 'EDIT_STOP_AND_LIMIT',
  /** Position activity */
  POSITION = 'POSITION',
  /** System generated activity */
  SYSTEM = 'SYSTEM',
  /** Working order activity */
  WORKING_ORDER = 'WORKING_ORDER',
}

import {isIPositionV2Closed} from '@algo/helper';
import {IPositionV2} from '@algo/model';
import dayjs, {OpUnitType, QUnitType} from 'dayjs';

/**
 * Extract trade duration from position
 * @param position
 * @returns
 */
export default function positionExtractTradeDuration(position: IPositionV2, unit: QUnitType | OpUnitType) {
  const closeTs = position.closeTsUtc;
  const openTs = position.openTsUtc;
  if (!isIPositionV2Closed(position)) return -1;

  // identify difference of given unit
  const diff = dayjs(closeTs).diff(openTs, unit, true);
  return diff;
}

import {calculateIPositionV2Profit} from '@algo/helper';
import {IPositionV2} from '@algo/model';
import {Grid, Typography} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';

export const profitLossColumn: GridColDef = {
  field: 'close',
  headerName: 'Profit/Loss',
  width: 100,
  headerAlign: 'center',
  editable: false,
  valueGetter: (_value, row: IPositionV2) => calculateIPositionV2Profit(row),
  renderCell: (params: GridRenderCellParams<IPositionV2, number, any>) => {
    const val = params.value || 0;
    const isOnProfit = val >= 0;
    return (
      <Grid
        container
        sx={{backgroundColor: isOnProfit ? 'green' : 'red', width: '100%', height: '100%'}}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography color="white">{val.toFixed(2)}</Typography>
        </Grid>
      </Grid>
    );
  },
};

import {mean} from 'mathjs';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import mapIndicatorV3InputToNumber from '../../common/template/v3/mapIndicatorV3InputToNumber';
import convertStrToFloat from '../../common/util/convertStrToFloat';
import convertStrToInt from '../../common/util/convertStrToInt';
import {EmaConfigV3, zEmaConfigV3} from './interface/EmaConfigV3';
import {EmaInitV3, zEmaInitV3} from './interface/EmaInitV3';
import {EmaOutputV3} from './interface/EmaOutputV3';

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<EmaInitV3>) {
  const parsedInit = zEmaInitV3.strict().parse(init);

  return zEmaConfigV3.strip().parse({
    ...parsedInit,
    exponent: parsedInit.smoothing / (1 + parsedInit.period),
    maxInputLength: parsedInit.period,
  });
}

/**
 * Calculate Ema
 * @param config
 * @returns
 */
function calculate(config: EmaConfigV3): IndicatorReturnV3<EmaConfigV3, EmaOutputV3> {
  // https://www.investopedia.com/terms/e/ema.asp
  // const multiplier = config.smoothing / (1 + config.period);
  const nextPrice = config.input.at(-1);

  if (nextPrice === undefined) throw new Error('No price available');

  const previousEma = config.results.at(-1);
  // calculate ema
  const result =
    config.input.length === config.period && previousEma
      ? nextPrice * config.exponent + previousEma * (1 - config.exponent) // calculating ema
      : mean(config.input);

  // once period reach, always define previousEma to enable ema calculation
  return {config, result};
}

/**
 * Ema indicator
 */
export const emaV3 = createIndicatorV3<EmaInitV3, EmaConfigV3, EmaOutputV3, number>(
  IndicatorTypeEnum.EMA,
  createConfig,
  calculate,
  (s) =>
    zEmaInitV3.parse({
      period: convertStrToInt(s[0]),
      smoothing: convertStrToFloat(s[1]),
      hloc: convertStrToInt(s[2]),
    }),
  mapIndicatorV3InputToNumber,
  zEmaConfigV3,
);

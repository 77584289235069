import {number} from 'zod';

/**
 * 0 means no trend, 1 means trending and in between means some trending
 */

export const zSdOutputV3 = number().min(0);

export type SdOutputV3 = number;

export const initSdOutputV3: SdOutputV3 = 0;

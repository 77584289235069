import {IPositionV2} from '@algo/model';
import {calculateIPositionV2Profit} from '../../calculate/IPositionV2/calculateIPositionV2Profit';

/**
 * Check if position is in profit
 * @param position
 * @returns
 */
export function isIPositionV2InProfit(position: IPositionV2) {
  return calculateIPositionV2Profit(position) >= 0;
}

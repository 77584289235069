import {boolean, literal, number, object, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {PsarOutputV3, zPsarOutputV3} from './PsarOutputV3';

export interface PsarConfigV3 extends IndicatorConfigV3<PsarOutputV3, IndicatorCandle> {
  type: 'psarConfigV3';
  /// ////////////////config////////////////
  /** acceleration factor start */
  accelerationFactorStart: number;
  /** acceleration factor step */
  accelerationFactorStep: number;
  /** acceleration factor max */
  accelerationFactorMax: number;
  /** Min points before starting to give data */
  // minPoints: number;
  /// ////////////////operation variable////////////////
  /** define if it is up or down trend */
  isUptrend: boolean;
  /** sar value */
  sar: number;
  /** extreme value */
  extreme: number;
  /** acceleration factor */
  accelerationFactor: number;
  /** previous bar */
  previousBar?: IndicatorCandle;
  /** further bar data */
  furthestBar?: IndicatorCandle;
}

export const zPsarConfigV3 = zIndicatorConfigV3(zPsarOutputV3, zIndicatorCandle).merge(
  object({
    type: literal('psarConfigV3').default('psarConfigV3'),
    accelerationFactorStart: number().nonnegative().default(0.02),
    accelerationFactorStep: number().nonnegative().default(0.02),
    accelerationFactorMax: number().nonnegative().default(0.2),
    isUptrend: boolean().default(false),
    sar: number().nonnegative().default(0),
    extreme: number().nonnegative().default(0),
    accelerationFactor: number().nonnegative().default(0),
    previousBar: zIndicatorCandle.optional(),
    furthestBar: zIndicatorCandle.optional(),
  }),
) satisfies ZodType<PsarConfigV3, any, any>;

// export type PsarConfigV3 = output<typeof zPsarConfigV3>;

export const initPsarConfigV3 = zPsarConfigV3.parse({});

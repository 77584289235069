import {IPositionV2} from '@algo/model';
import {UtilDatetime} from '@algo/util';
import {Grid, Typography} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

export const openTsColumn: GridColDef = {
  field: 'openTsUtc',
  headerName: 'Open TS',
  width: 100,
  align: 'center',
  headerAlign: 'center',
  editable: false,
  valueGetter: (_v, row: IMatchAlgoPosition) =>
    ((row.monitorPosition || row.referencePosition) as IPositionV2).openTsUtc.substring(0, 19).replace('T', ' '),
  renderCell: (params: GridRenderCellParams<IMatchAlgoPosition, string, any>) => {
    const val = params.value || '';
    const diff = UtilDatetime.calculateDatetimeDiff(params.value || '', dayjs().toISOString());
    return (
      <Grid container wrap="wrap">
        <Typography variant="subtitle2">{val.substring(0, 10)}</Typography>
        <Typography variant="subtitle2">{val.substring(10)}</Typography>
        <Typography variant="subtitle2">{diff.days} days</Typography>
      </Grid>
    );
  },
};

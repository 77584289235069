import {LogLevelEnum} from './enum';
import {convertStrToLogLevel} from './helper/convertStrToLogLevel';
import {setDebug, setError, setInfo, setLog, setTrace, setWarning} from './set';

/**
 * based on parameter passed, disable required logger
 * @param logLevel
 */
export function setLogLevel(logLevel?: string) {
  const level = convertStrToLogLevel(logLevel);

  switch (level) {
    case LogLevelEnum.TRACE:
      break;
    case LogLevelEnum.DEBUG:
      setTrace();
      break;
    case LogLevelEnum.LOG:
      setTrace();
      setDebug();
      break;
    case LogLevelEnum.INFO:
      setTrace();
      setDebug();
      setLog();
      break;
    case LogLevelEnum.WARNING:
      setTrace();
      setDebug();
      setLog();
      setInfo();
      break;
    case LogLevelEnum.ERROR:
      setTrace();
      setDebug();
      setLog();
      setInfo();
      setWarning();
      break;
    case LogLevelEnum.OFF:
      setTrace();
      setDebug();
      setLog();
      setInfo();
      setWarning();
      setError();
      break;
    default: // undefined or any string not found
  }
}

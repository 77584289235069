import {array, literal, number, object, ZodType} from 'zod';
import {IndicatorCandle, zIndicatorCandle} from '../../../common/interface/IndicatorCandle';
import {IndicatorConfigV3, zIndicatorConfigV3} from '../../../common/template/v3/IndicatorConfigV3';
import {AtrOutputV3, zAtrOutputV3} from './AtrOutputV3';

/**
 * atr config
 */
export interface AtrConfigV3 extends IndicatorConfigV3<AtrOutputV3, IndicatorCandle> {
  type: 'atrConfigV3';
  /** Period of sd */
  period: number;

  /** True range calculation */
  trueRange: number[];
}

// export const initAtrConfigV3: AtrConfigV3 = {
//   ...initIndicatorConfig,
//   period: 0,
// };

export const zAtrConfigV3 = zIndicatorConfigV3(zAtrOutputV3, zIndicatorCandle).merge(
  object({
    type: literal('atrConfigV3').default('atrConfigV3'),
    period: number().min(3).default(14),
    trueRange: array(number()).default([]),
  }),
) satisfies ZodType<AtrConfigV3, any, any>;

export const initAtrConfigV3 = zAtrConfigV3.parse({});

import {calculateIPositionV2Profit} from '@algo/helper';
import {IPositionV2} from '@algo/model';
import {useState} from 'react';
import {VictoryAxis, VictoryBar, VictoryChart} from 'victory';
import InfoTile from '../InfoTile/InfoTile';
import calculateStartIndex from '../../logic/helper/calculate/calculateStartIndex';

export interface ProfitOfTradeTileProps {
  /** All closed position sorted by close TS in asc order */
  sortedClosePositions: IPositionV2[];

  /** limit number to display on graph */
  limit?: number;
}

export default function ProfitOfTradeTile(props: ProfitOfTradeTileProps) {
  const {sortedClosePositions, limit} = props;
  const [isRatio, setIsRatio] = useState(true);

  if (sortedClosePositions.length === 0) return null;

  const startIndex = calculateStartIndex(sortedClosePositions.length, limit || sortedClosePositions.length);

  const plotData = sortedClosePositions
    .map((val, i) => ({
      x: i + 1,
      y: isRatio
        ? (calculateIPositionV2Profit(val) / Math.abs(val.size) / val.open) * 100
        : calculateIPositionV2Profit(val),
    }))
    .slice(startIndex);

  const height = 200;
  const width = 250;
  return (
    <InfoTile
      title="Profit of trade"
      description={`Profit of individual trade for the last ${limit} trades`}
      switchState={isRatio}
      setSwitch={setIsRatio}
      labelLeft="Net"
      labelRight="%"
      graph={
        sortedClosePositions.length ? (
          <VictoryChart
            height={height}
            width={width}
            domainPadding={0}
            padding={{top: 20, bottom: 50, right: 20, left: 50}}
          >
            <VictoryBar data={plotData} height={height} width={width} />

            <VictoryAxis
              crossAxis
              width={width}
              height={height}
              standalone={false}
              // tickValues={xTick}
              // domain={xDomain}
              offsetY={50}
              orientation="bottom"
              label="Trade #"
              // style={{
              //   axis: { stroke: '#ffffff' },
              // }}
            />

            <VictoryAxis
              dependentAxis
              crossAxis
              width={width}
              height={height}
              label={`Profit ${isRatio ? '%' : '£'}`}
              // tickValues={yTick}
              // domain={yDomain}
              // domain={[-10, 10]}
              // offsetY={50}
              // standalone={false}
            />
            {/* <VictoryAxis tickFormat={() => ''} height={0} width={0} /> */}
          </VictoryChart>
        ) : undefined
      }
    />
  );
}

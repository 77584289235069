import {ApolloClient, gql, useMutation} from '@apollo/client';
import {AccountCircle} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {useCallback} from 'react';
import {IAuthState} from '../../hooks/auth/IAuth';
import createApolloClient from '../../logic/helper/gql/createApolloClient';

export interface HeaderButtonsDetailProps {
  user: IAuthState;
}

const QUERY = gql`
  mutation Mutation {
    setUserDetail
  }
`;

function HeaderButtonsDetail(props: HeaderButtonsDetailProps) {
  // const client = useApolloClient()
  const {user} = props;

  const client = useCallback(
    () => createApolloClient(user ? {Authorization: `Bearer ${user.accessToken}`} : undefined) as ApolloClient<any>,
    [user],
  );
  const [mutateFn] = useMutation(QUERY, {
    // skip: true,
    client: client(),
  });
  const handleTest = () => {
    // refetch();
    // console.log(data);
    mutateFn();
  };

  return (
    <IconButton aria-label="test btn" color="inherit" onClick={handleTest}>
      <AccountCircle />
    </IconButton>
  );
}

export default HeaderButtonsDetail;

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import convertStrToInt from '../../common/util/convertStrToInt';
import {emaCandle} from './emaCandle';

/**
 * Function to calculate a range of ema values given candles and cmd
 * @param cmd ema_period_hloc e.g. ema_25_2
 * @param candles
 * @returns
 */
export function emaCmd(cmd: string, candles: IndicatorCandle[]): number[] | undefined {
  const cmdArr = cmd.split('_');
  if (cmdArr[0] !== IndicatorTypeEnum.EMA) return undefined;
  return emaCandle(candles, convertStrToInt(cmdArr[1]), convertStrToInt(cmdArr[2]));
}

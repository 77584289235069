import {PositionDirectionEnum} from '@algo/model';

/**
 * calculate profit loss based on direction, size, open and close
 * 0 by default or position still open
 */
export function calculatePositionProfitLoss(
  direction: PositionDirectionEnum,
  size: number,
  openPrice: number,
  closePrice?: number,
) {
  if (size <= 0 || openPrice <= 0 || !closePrice || closePrice <= 0) {
    return 0;
  }

  return (direction === PositionDirectionEnum.LONG ? size : -size) * (closePrice - openPrice);
}

import {MacdConfig} from './interface/MacdConfig';
import {macdCalculate} from './macdCalculate';
import {macdInit} from './macdInit';

/**
 * Calculate Macd
 */
export class Macd {
  config: MacdConfig;

  constructor(slowPeriod?: number, fastPeriod?: number, signalPeriod?: number) {
    this.config = macdInit(slowPeriod, fastPeriod, signalPeriod);
  }

  next(nextValue: number) {
    const result = macdCalculate(this.config, nextValue);
    this.config = result.config;
    return result.result;
  }
}

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import convertStrToInt from '../../common/util/convertStrToInt';
import {StochasticOutput} from './interface/StochasticOutput';
import {stochasticCandle} from './stochasticCandle';

/**
 * Function to calculate a range of stochastic values, using cmd string to provide parameter
 *
 * @param cmd stochastic_fastPeriod_slowPeriod e.g. stochastic_14_3
 * @param candles
 * @returns
 */
export function stochasticCmd(cmd: string, candles: IndicatorCandle[]): StochasticOutput[] | undefined {
  const cmdArr = cmd.split('_');
  if (cmdArr[0] !== IndicatorTypeEnum.STOCHASTIC) return undefined;

  return stochasticCandle(candles, convertStrToInt(cmdArr[1]), convertStrToInt(cmdArr[2]));
}

import {UtilNumber} from '@algo/util';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {EmaClassV2} from '../ema';
import {MacdConfigV2, zMacdConfigV2} from './interface/MacdConfigV2';
import {MacdInitV2, zMacdInitV2} from './interface/MacdInitV2';
import {MacdOutput} from './interface/MacdOutput';

/**
 * Calculate percentage change from ma
 */
export class MacdClassV2 extends IndicatorTemplate<MacdConfigV2, MacdOutput, MacdInitV2, number> {
  override type = IndicatorTypeEnum.MACD;

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<MacdConfigV2, MacdOutput> {
    const nextPrice = this.config.data.at(-1);

    if (!nextPrice) throw new Error('No price available');

    const fastCalculation = new EmaClassV2(this.config.emaFastConfig);
    const fastResult = fastCalculation.nextValue(nextPrice);

    const slowCalculation = new EmaClassV2(this.config.emaSlowConfig);
    const slowResult = slowCalculation.nextValue(nextPrice);

    const macd = fastResult - slowResult;

    // signal calculation
    const signalCalculation = new EmaClassV2(this.config.signalConfig);
    const signal = signalCalculation.nextValue(macd);
    const histogram = macd - signal;

    // update config

    return {
      config: {
        ...this.config,
        emaFastConfig: fastCalculation.getConfig(),
        emaSlowConfig: slowCalculation.getConfig(),
        signalConfig: signalCalculation.getConfig(),
      },
      result: {
        macd,
        signal,
        histogram,
      },
    };
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(params: Partial<MacdInitV2> | MacdConfigV2 = {}): MacdConfigV2 {
    const testInit = zMacdInitV2.strict().safeParse(params);

    if (testInit.success) {
      return zMacdConfigV2.strip().parse({
        ...testInit.data,
        emaFastConfig: new EmaClassV2({period: testInit.data.fastPeriod, hloc: testInit.data.hloc}).getConfig(),
        emaSlowConfig: new EmaClassV2({period: testInit.data.slowPeriod, hloc: testInit.data.hloc}).getConfig(),
        signalConfig: new EmaClassV2({period: testInit.data.signalPeriod, hloc: testInit.data.hloc}).getConfig(),
        maxPriceLength: 1,
      });
    }

    // this is config
    return zMacdConfigV2.strict().parse(params);
  }

  /**
   * map command string to init variable
   * - slow period
   * - fast period
   * - signal period
   */
  override mapCmdToInit(s: string[]): MacdInitV2 {
    const slowPeriod = s[0] ? convertStrToInt(s[0]) : undefined;
    const fastPeriod = s[1] ? convertStrToInt(s[1]) : undefined;
    const signalPeriod = s[2] ? convertStrToInt(s[2]) : undefined;
    return zMacdInitV2.parse({slowPeriod, fastPeriod, signalPeriod});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): number {
    return mapIndicatorCandleToNumberArr(this.config.hloc)(candle);
  }

  /**
   * Check if cross over occured, undefined means no data point
   * @param location position to check (default = -1)
   * @returns true when cross over occured, false when not and undefined when cannot identify
   */
  isCrossOver(location = -1): boolean | undefined {
    try {
      const current = this.config.results.at(location);
      const before = this.config.results.at(location - 1);
      if (!current || !before) return undefined;
      return UtilNumber.isCrossover(before.macd, current.macd, before.signal, current.signal);
    } catch (e) {
      return undefined;
    }
  }

  /**
   * Check if cross under occured, undefined means no data point
   * @param location position to check (default = -1)
   * @returns true when cross under occured, false when not and undefined when cannot identify
   */
  isCrossUnder(location = -1): boolean | undefined {
    try {
      const current = this.config.results.at(location);
      const before = this.config.results.at(location - 1);
      if (!current || !before) return undefined;
      return UtilNumber.isCrossunder(before.macd, current.macd, before.signal, current.signal);
    } catch (e) {
      return undefined;
    }
  }
}

import {emaCalculate} from './emaCalculate';
import {emaInit} from './emaInit';
import {EmaConfig} from './interface/EmaConfig';

/**
 * Calculate exponential moving average
 */
export class EMA {
  config: EmaConfig;

  constructor(period?: number, smoothing?: number) {
    this.config = emaInit(period, smoothing);
  }

  next(nextValue: number) {
    const result = emaCalculate(this.config, nextValue);
    this.config = result.config;
    return result.result;
  }
}

import {IUserHouse} from '@algo/firebase-common';
import {Box, Grid, Switch, Typography} from '@mui/material';

export interface MortgageCalculatorBuyerProps {
  formData: IUserHouse;

  setFormData: (k: string, v: string | boolean) => void;
}

/**
 * Show analysis page to do analysis
 * @returns
 */
export default function MortgageCalculatorBuyer(props: MortgageCalculatorBuyerProps) {
  const {formData, setFormData} = props;

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography typography="title">Buyer</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">First time buyer</Typography>
          <Switch
            size="small"
            checked={formData.firstTimeBuyer}
            onChange={() => setFormData('firstTimeBuyer', !formData.firstTimeBuyer)}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">Additional home</Typography>
          <Switch
            size="small"
            checked={formData.additionalHome}
            onChange={() => setFormData('additionalHome', !formData.additionalHome)}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">Overseas</Typography>
          <Switch size="small" checked={formData.oversea} onChange={() => setFormData('oversea', !formData.oversea)} />
        </Grid>
      </Grid>
    </Box>
  );
}

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorTemplate} from '../../common/template/IndicatorTemplate';
import {IndicatorTemplateReturn} from '../../common/template/IndicatorTemplateReturn';
import convertStrToInt from '../../common/util/convertStrToInt';
import {SmaClassV2} from '../../movingAverage/sma/SmaClassV2';
import {CciConfigV2, zCciConfigV2} from './interface/CciConfigV2';
import {CciInitV2, zCciInitV2} from './interface/CciInitV2';
import {CciOutputV2} from './interface/CciOutputV2';

/**
 * Calculate percentage change from ma
 * - calculation different to technicalanalysis package
 */
export class CciClassV2 extends IndicatorTemplate<CciConfigV2, CciOutputV2, CciInitV2, IndicatorCandle> {
  override type = IndicatorTypeEnum.CCI;

  private calculateTypicalPrice(config: CciConfigV2) {
    // const high = Math.max(...config.data.map((d) => d.high));
    // const low = Math.min(...config.data.map((d) => d.low));
    // return (high + low + (config.data.at(-1)?.close || 0)) / 3;
    const lastData = config.data.at(-1);
    if (!lastData) throw new Error('CCI - No data found');
    return (lastData.high + lastData.low + lastData.close) / 3;
  }

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected override calculate(): IndicatorTemplateReturn<CciConfigV2, CciOutputV2, IndicatorCandle> {
    // https://www.babypips.com/forexpedia/commodity-channel-index
    const typicalPrice = this.calculateTypicalPrice(this.config);
    const sma = new SmaClassV2(this.config.smaConfig);
    const tpAve = sma.nextValue(typicalPrice);

    const tpArr = sma.getConfig().data;
    const md = tpArr.reduce((prev, tp) => Math.abs(tp - tpAve) + prev, 0) / tpArr.length;

    // once period reach, always define previousCci to enable ema calculation
    return {
      config: {...this.config, smaConfig: sma.getConfig()},
      result: md === 0 ? 0 : (typicalPrice - sma.getLastResult()) / (0.015 * md),
    };
  }

  /**
   * Convert init to config
   */
  override mapInitToConfig(params: Partial<CciInitV2> | CciConfigV2 = {}): CciConfigV2 {
    const testInit = zCciInitV2.strict().safeParse(params);

    if (testInit.success) {
      return zCciConfigV2.strip().parse({
        ...testInit.data,
        maxPriceLength: testInit.data.period,
        smaConfig: new SmaClassV2({period: testInit.data.period}).getConfig(),
      });
    }

    // this is config
    return zCciConfigV2.strict().parse(params);
  }

  /**
   * map command string to init variable
   */
  override mapCmdToInit(s: string[]): CciInitV2 {
    const period = s[0] ? convertStrToInt(s[0]) : undefined;
    return zCciInitV2.parse({period});
  }

  /**
   * map indicator candle to data
   * @param candle
   */
  override mapCandleToInput(candle: IndicatorCandle): IndicatorCandle {
    return candle;
  }
}

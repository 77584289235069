import {IPositionV2} from '@algo/model';

/**
 * Calculate total risk
 * Same asset with one long and one short will result in 2x risk instead of cancel out
 * @param positions
 * @returns
 */
export default function positionCalculateTotalRisk(positions: IPositionV2[]) {
  return positions.reduce((prev, cur) => prev + Math.abs(cur.open * cur.size), 0);
}

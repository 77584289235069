export interface IAsset {
  /** id of asset used by broker */
  id: string;

  /** name of asset */
  name: string;

  /** asking price */
  ask?: number;

  /** bid price */
  bid?: number;

  /** lot size */
  lotSize?: number;

  /** highest price */
  high?: number;
  /** lowest price */
  low?: number;

  /** net change in price */
  changeNet?: number;
  /** percentage change in price */
  changePct?: number;

  /** last update datetime in UTC ISO string */
  lastUpdateUtc?: string;
}

export const defaultIAsset: IAsset = {
  id: '',
  name: '',
};

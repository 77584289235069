import {sleepMs} from '@coolcolduk/util';
import {IgUserToken} from '../create/createIgUserToken';
import {IgDirectionEnum} from '../enum/IgDirectionEnum';
import {confirmIgPosition} from '../position';
import {igHelperOpenPositionMarket, IgOpenPositionMarketOption} from './igHelperOpenPositionMarket';

/**
 * open ig position market order and resolve deal reference
 */
export async function igHelperOpenPositionMarketDetail(
  userToken: IgUserToken,
  epic: string,
  size: number,
  direction = IgDirectionEnum.BUY,
  options: IgOpenPositionMarketOption = {},
) {
  console.debug('igHelperOpenPositionMarketDetail');

  const openResult = await igHelperOpenPositionMarket(userToken, epic, size, direction, options);

  if (!openResult.data) {
    return undefined;
  }

  await sleepMs(300);
  return confirmIgPosition(userToken, openResult.data.dealReference);
}

import {deleteDoc, DocumentReference} from 'firebase/firestore';

/**
 * Firestore helper to del doc
 * @param doc
 * @returns
 */
export function firestoreDelDoc<T extends Record<string, any>>(doc: DocumentReference<T>): Promise<void> {
  return deleteDoc(doc);
}

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import mapIndicatorV3InputToCandle from '../../common/template/v3/mapIndicatorV3InputToCandle';
import convertStrToInt from '../../common/util/convertStrToInt';
import {PreviousHighLowConfigV3, zPreviousHighLowConfigV3} from './interface/PreviousHighLowConfigV3';
import {PreviousHighLowInitV3, zPreviousHighLowInitV3} from './interface/PreviousHighLowInitV3';
import {PreviousHighLowOutputV3, zPreviousHighLowOutputV3} from './interface/PreviousHighLowOutputV3';

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<PreviousHighLowInitV3>) {
  const parsedInit = zPreviousHighLowInitV3.strict().parse(init);

  return zPreviousHighLowConfigV3.strip().parse({
    ...parsedInit,
    maxInputLength: parsedInit.period, // maxInputLength determines the actual period, not period variable
  });
}

/**
 * Calculate PreviousHighLow
 * @param config
 * @returns
 */
function calculate(
  config: PreviousHighLowConfigV3,
): IndicatorReturnV3<PreviousHighLowConfigV3, PreviousHighLowOutputV3, IndicatorCandle> {
  const nextPrice = config.input.at(-1);

  if (nextPrice === undefined) throw new Error('No price available');

  const high = Math.max(...config.input.map((v) => v.high));
  const low = Math.min(...config.input.map((v) => v.low));

  return {
    config,
    result: zPreviousHighLowOutputV3.parse({high, low}),
  };
}

/**
 * PreviousHighLow indicator
 */
export const previousHighLowV3 = createIndicatorV3<
  PreviousHighLowInitV3,
  PreviousHighLowConfigV3,
  PreviousHighLowOutputV3,
  IndicatorCandle
>(
  IndicatorTypeEnum.PREVIOUS_HL,
  createConfig,
  calculate,
  (s) =>
    zPreviousHighLowInitV3.parse({
      period: convertStrToInt(s[0]),
    }),
  mapIndicatorV3InputToCandle,
  zPreviousHighLowConfigV3,
);

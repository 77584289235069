import {nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInit, zIndicatorInit} from '../../../common/template/IndicatorInit';

export interface EmaInitV2 extends IndicatorInit {
  period: number;
  hloc: IndicatorCandlePriceEnum;
  smoothing: number;
}

export const zEmaInitV2 = zIndicatorInit.merge(
  object({
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
    smoothing: number().min(1).default(2),
  }),
) satisfies ZodType<EmaInitV2, any, any>;

// export type EmaInitV2 = input<typeof zEmaInitV2>;

export const initEmaInitV2 = zEmaInitV2.parse({});

import {initIUserHouse} from '@algo/firebase-common';
import {Box} from '@mui/material';
import {useState} from 'react';
import useQueryUserHouse from '../../hooks/useQueryUserHouse';
import MortgageCalculator from './MortgageCalculator';
import MortgageData from './MortgageData';
// import { castPositiveFloat } from './validate/castPositiveFloat';

// function extractPositiveFloat(s: string) {
//   const result = castPositiveFloat(s);
//   return result ? s.replace(/^0+/, '') : '0';
// }

export interface MortgagePageProps {
  uid: string;
}
/**
 * Show analysis page to do analysis
 * @returns
 */
export default function MortgagePage(props: MortgagePageProps) {
  const {uid} = props;
  const houses = useQueryUserHouse();
  const [formData, setFormData] = useState(initIUserHouse);

  return (
    <Box sx={{padding: 3}}>
      <MortgageData formData={formData} setFormData={setFormData} houses={houses} uid={uid} />

      <MortgageCalculator
        formData={formData}
        setFormData={(k: string, v: string | boolean) => {
          setFormData({...formData, [k]: v});
          // setFormData(castIUserHouse({ ...formData, [k]: v }) || formData);
        }}
      />
    </Box>
  );
}

import {Grid} from '@mui/material';
import ButtonGroupOrganism from '../../components/ButtonGroup/ButtonGroupOrganism';

export interface MatchAlgoHeaderProps<T extends string> {
  selectionList: Record<string, T>;
  handleSelection: (v: T) => void;
  selection?: T;
}

export default function MatchAlgoHeader<T extends string>(props: MatchAlgoHeaderProps<T>) {
  const {selectionList, handleSelection, selection} = props;

  return (
    <Grid item>
      <ButtonGroupOrganism enumItem={selectionList} handleClick={handleSelection} value={selection} />
    </Grid>
  );
}

import {mean} from 'mathjs';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import {IndicatorReturnV3} from '../../common/template/v3/IndicatorReturnV3';
import {createIndicatorV3} from '../../common/template/v3/createIndicatorV3';
import {getIndicatorV3Input} from '../../common/template/v3/getIndicatorV3Input';
import {getIndicatorV3Result} from '../../common/template/v3/getIndicatorV3Result';
import mapIndicatorV3InputToCandle from '../../common/template/v3/mapIndicatorV3InputToCandle';
import convertStrToInt from '../../common/util/convertStrToInt';
import {AtrConfigV3, zAtrConfigV3} from './interface/AtrConfigV3';
import {AtrInitV3, zAtrInitV3} from './interface/AtrInitV3';
import {AtrOutputV3, initAtrOutputV3} from './interface/AtrOutputV3';

function calculateTrueRange(previousCandle: IndicatorCandle, candle: IndicatorCandle) {
  const currentHigh = candle.high;
  const currentLow = candle.low;
  const previousClose = previousCandle.close;

  const range1 = currentHigh - currentLow;
  const range2 = Math.abs(currentHigh - previousClose);
  const range3 = Math.abs(currentLow - previousClose);
  return Math.max(range1, range2, range3);
}

/**
 * Create config with init
 * @param init
 * @returns
 */
function createConfig(init: Partial<AtrInitV3>) {
  const parsedInit = zAtrInitV3.strict().parse(init);

  return zAtrConfigV3.strip().parse({
    ...parsedInit,
    maxInputLength: parsedInit.period,
  });
}

/**
 * Calculate atr
 * @param config
 * @returns
 */
function calculate(config: AtrConfigV3): IndicatorReturnV3<AtrConfigV3, AtrOutputV3, IndicatorCandle> {
  if (config.input.length < 2) {
    return {config, result: initAtrOutputV3};
  }
  const prevCandle = getIndicatorV3Input<AtrConfigV3, AtrOutputV3, IndicatorCandle>(config, -2);
  const currentCandle = getIndicatorV3Input<AtrConfigV3, AtrOutputV3, IndicatorCandle>(config);

  const tr = calculateTrueRange(prevCandle, currentCandle);

  if (config.trueRange.length < config.period) {
    const trueRange = [...config.trueRange, tr];
    const atr = mean(trueRange);
    return {config: {...config, trueRange}, result: atr};
  }

  return {
    config,
    result:
      (getIndicatorV3Result<AtrConfigV3, AtrOutputV3, IndicatorCandle>(config) * (config.period - 1) + tr) /
      config.period,
  };
}

/**
 * ATR indicator
 */
export const atrV3 = createIndicatorV3<AtrInitV3, AtrConfigV3, AtrOutputV3, IndicatorCandle>(
  IndicatorTypeEnum.ATR,
  createConfig,
  calculate,
  (s) => zAtrInitV3.parse({period: convertStrToInt(s[0]), hloc: convertStrToInt(s[1])}),
  mapIndicatorV3InputToCandle,
  zAtrConfigV3,
);

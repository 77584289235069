import {IgPriceCandle} from '@algo/broker-ig';
import dayjs from 'dayjs';
import {LineGraphData} from './LineGraphData';

export default function mapIgPriceCandleIndicatorToVictoryLine(
  candle: IgPriceCandle,
  indicator: number,
): LineGraphData {
  return {
    x: dayjs(candle.snapshotTime).toDate(),
    y: indicator,
  };
}

import {ZodType, number, object} from 'zod';

/**
 * Stochastic output data type
 */
export interface StochasticOutput {
  /** Signal line, fast moving */
  k: number;
  /** Slow line, SMA of K line */
  d: number;
}

export const initStochasticOutput: StochasticOutput = {
  k: 0,
  d: 0,
};

export const zStochasticOutput: ZodType<StochasticOutput> = object({k: number(), d: number()});

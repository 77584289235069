import {IgUserToken} from '../create/createIgUserToken';
import {IgDirectionEnum} from '../enum';
import {IgTimeInForceEnum} from '../enum/IgTimeInForceEnum';
import {getIgPosition} from '../position';
import {igHelperClosePosition} from './igHelperClosePosition';

/**
 * Close ig position given deal id only
 */
export async function igHelperClosePositionWithDealId(userToken: IgUserToken, dealId: string) {
  console.debug('igHelperClosePositionWithDealId');
  const position = await getIgPosition(userToken, dealId);
  const positionDetail = position.data;
  if (!positionDetail) {
    return undefined;
  }

  return igHelperClosePosition(
    userToken,
    dealId,
    positionDetail.position.size,
    positionDetail.position.direction === IgDirectionEnum.BUY ? IgDirectionEnum.SELL : IgDirectionEnum.BUY,
    IgTimeInForceEnum.EXECUTE_AND_ELIMINATE,
  );
}

import {PositionDirectionEnum} from '@algo/model';
import {Grid, Typography} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';

export const sizeColumn: GridColDef = {
  field: 'size',
  headerName: 'Size',
  width: 70,
  headerAlign: 'center',
  editable: false,
  renderCell: (params: GridRenderCellParams<IMatchAlgoPosition, number, any>) => {
    const direction = (params.row.referencePosition?.direction ||
      params.row.monitorPosition?.direction) as PositionDirectionEnum;
    const size = (params.row.referencePosition?.size || params.row.monitorPosition?.size) as number;
    const isLong = direction === PositionDirectionEnum.LONG;
    const sizeSigned = (isLong ? 1 : -1) * size;
    return (
      <Grid
        container
        sx={{backgroundColor: isLong ? 'green' : 'red', width: '100%', height: '100%'}}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography color="white">{sizeSigned}</Typography>
        </Grid>
      </Grid>
    );
  },
};

import {mean} from 'mathjs';
import {IndicatorReturn} from '../../common/interface/IndicatorReturn';
import {EmaConfig} from './interface/EmaConfig';

/**
 * Calculate EMA next value
 * @param config new config
 * @param nextPrice
 * @returns
 */
export function emaCalculate(config: EmaConfig, nextPrice: number): IndicatorReturn<EmaConfig> {
  // https://www.investopedia.com/terms/e/ema.asp
  // const multiplier = config.smoothing / (1 + config.period);

  // add data point to values
  const values = [...config.values, nextPrice].slice(-config.period);

  // calculate ema
  const result = config.previousEma
    ? nextPrice * config.exponent + config.previousEma * (1 - config.exponent) // calculating ema
    : mean(values);
  // once period reach, always define previousEma to enable ema calculation
  const previousEma = values.length === config.period ? result : null;
  return {config: {...config, previousEma, values}, result};
}

import {StochasticOutput} from './interface/StochasticOutput';
import {Stochastic} from './StochasticClass';

/**
 * Function to calculate a range of stochastic values
 * @param high
 * @param low
 * @param close
 * @param fastPeriod
 * @param slowPeriod
 * @returns
 */
export function stochastic(
  high: number[],
  low: number[],
  close: number[],
  fastPeriod?: number,
  slowPeriod?: number,
): StochasticOutput[] {
  if (high.length !== low.length || low.length !== close.length) {
    throw new Error(
      `high, low and close has to be same length. High ${high.length}, low ${low.length}, close ${close.length}`,
    );
  }

  const calculation = new Stochastic(fastPeriod, slowPeriod);
  return low.map((_, i) => calculation.next(high[i] as number, low[i] as number, close[i] as number));
}

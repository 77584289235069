/**
 * Different stages in Algo Copy
 */
export enum MatchAlgoStageEnum {
  /** Paper trade config file */
  PAPER_CONFIG = 'Paper config',

  /** Paper trade open collection */
  PAPER_OPEN = 'Paper open',

  /** Algo copier record */
  COPY_RECORD = 'Copy record',

  /** Broker position */
  BROKER = 'Broker',
}

/**
 * filter the keys in object to include or exclude
 * @param obj record to check
 * @param keys keys to check
 * @param isInclude true to include, false to exclude
 * @returns new record
 */
export function filterKey<T = string>(obj: Record<string, T>, keys: string[], isInclude = true) {
  return Object.keys(obj).reduce((acc: Record<string, T>, cur: string) => {
    const isFound = keys.indexOf(cur) >= 0;
    return isFound === isInclude ? ({...acc, [cur]: obj[cur]} as Record<string, T>) : acc;
  }, {} as Record<string, T>);
}

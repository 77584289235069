import dayjs from 'dayjs';
import {IgUserToken} from '../create/createIgUserToken';
import {getHistoryActivity} from '../history';
import {IgActivity} from '../interface/history/IgActivity';

/**
 * Fetch all activity related to given deal Id and epic within a day
 * @param userToken
 * @param dealId
 * @param epic
 * @param showAcceptedOnly
 * @returns
 */
export async function igHelperHistoryActivityRecentWithDealId(
  userToken: IgUserToken,
  dealId: string,
  epic: string,
  showAcceptedOnly = false,
  from?: Date,
): Promise<IgActivity[]> {
  console.debug('igHelperHistoryActivityRecentWithDealId');

  const filters = ['type==POSITION', `epic==${epic}`];
  if (showAcceptedOnly) filters.push('status==ACCEPTED');

  const recentActivities = await getHistoryActivity(userToken, {
    from: from || new Date(dayjs().subtract(1, 'day').toISOString()),
    filter: filters.join(';'),
    detailed: true,
    pageSize: 500,
  });

  return (
    recentActivities.data?.activities.filter((a) => a.details?.actions.some((ac) => ac.affectedDealId === dealId)) || []
  );
}

import {Close} from '@mui/icons-material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {IMatchAlgoPosition} from '../interface/IMatchAlgoPosition';
import PositionDetail from './PositionDetail';

export const monitorColumn: GridColDef = {
  field: 'monitorPosition',
  headerName: 'Monitor',
  align: 'center',
  headerAlign: 'center',
  width: 100,
  valueGetter: (_v, row: IMatchAlgoPosition) => !!row.monitorPosition,
  renderCell: (params: GridRenderCellParams<IMatchAlgoPosition, string, any>) =>
    params.value ? <PositionDetail position={params.row.monitorPosition} /> : <Close />,
};

import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../../common/interface/IndicatorTypeEnum';
import convertStrToInt from '../../common/util/convertStrToInt';
import {PctMaOutput} from './interface';
import {pctMaCandle} from './pctMaCandle';

/**
 * Function to calculate a range of pct ma values given candles and cmd
 * @param cmd pctMa_period_hloc e.g. pctMasd_25_2
 * @param candles
 * @returns
 */
export function pctMaCmd(cmd: string, candles: IndicatorCandle[]): PctMaOutput[] | undefined {
  const cmdArr = cmd.split('_');
  if (cmdArr[0] !== IndicatorTypeEnum.PCT_MA) return undefined;
  return pctMaCandle(candles, convertStrToInt(cmdArr[1]), convertStrToInt(cmdArr[2]));
}

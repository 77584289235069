// import { IgPositionGetV2Response } from './getIgPosition';
import {UtilObject} from '@algo/util';
import {union} from 'zod';
import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {IgUserToken} from '../create/createIgUserToken';
import {IgPositionCommonDealReference} from '../interface/position/IgPositionCommonDealReference';
import {
  IgPositionOpenLimitV2Request,
  zIgPositionOpenLimitV2Request,
} from '../interface/position/IgPositionOpenLimitV2Request';
import {
  IgPositionOpenMarketV2Request,
  zIgPositionOpenMarketV2Request,
} from '../interface/position/IgPositionOpenMarketV2Request';
import {
  IgPositionOpenQuoteV2Request,
  zIgPositionOpenQuoteV2Request,
} from '../interface/position/IgPositionOpenQuoteV2Request';
import {validateDealReference} from '../validate/validateDealReference';

/**
 * Request parameter required
 */
export type IgPositionOpenV2Request =
  | IgPositionOpenMarketV2Request
  | IgPositionOpenLimitV2Request
  | IgPositionOpenQuoteV2Request;

const zIgPositionOpenV2Request = union([
  zIgPositionOpenMarketV2Request,
  zIgPositionOpenLimitV2Request,
  zIgPositionOpenQuoteV2Request,
])
  .refine(
    (data) => !((data.limitDistance || data.limitLevel || data.stopDistance || data.stopLevel) && !data.forceOpen),
    {
      message: 'forceOpen must be true if either limitDistance, limitLevel, stopDistance, stopLevel is set',
      path: ['forceOpen'],
    },
  )
  .refine((data) => !(data.guaranteedStop && !data.stopDistance && !data.stopLevel), {
    message: 'If guaranteedStop equals true, then set only one of stopLevel,stopDistance]',
    path: ['guaranteedStop'],
  })
  .refine((data) => !(!data.trailingStop && data.trailingStopIncrement), {
    message: 'If trailingStop equals false, then DO NOT set trailingStopIncrement]',
    path: ['trailingStopIncrement'],
  })
  .refine((data) => !(data.trailingStop && data.stopLevel), {
    message: 'If trailingStop equals true, then DO NOT set stopLevel]',
    path: ['stopLevel'],
  })
  .refine((data) => !(data.trailingStop && data.guaranteedStop), {
    message: 'If trailingStop equals true, then guaranteedStop must be false]',
    path: ['guaranteedStop'],
  })
  .refine((data) => !(data.trailingStop && (!data.trailingStopIncrement || !data.stopDistance)), {
    message: ' If trailingStop equals true, then set stopDistance,trailingStopIncrement]',
  })
  .refine((data) => !(data.limitDistance && data.limitLevel), {
    message: 'Set only one of {limitLevel,limitDistance}]',
  })
  .refine((data) => !(data.stopDistance && data.stopLevel), {
    message: 'Set only one of {stopLevel,stopDistance}]',
  });

/**
 * Response from open position
 */
export type IgPositionOpenV2Response = IgPositionCommonDealReference;

/**
 * Checks if data is valid
 * @param param
 */
function isValid(param: IgPositionOpenV2Request) {
  // [Constraint: If a limitDistance is set, then forceOpen must be true]
  // [Constraint: If a limitLevel is set, then forceOpen must be true]
  // [Constraint: If a stopDistance is set, then forceOpen must be true]
  // [Constraint: If a stopLevel is set, then forceOpen must be true]
  if (param.limitDistance || param.limitLevel || param.stopDistance || param.stopLevel) {
    if (!param.forceOpen) {
      return false;
    }
  }

  // [Constraint: If guaranteedStop equals true, then set only one of stopLevel,stopDistance]
  if (param.guaranteedStop && !param.stopDistance && !param.stopLevel) {
    return false;
  }

  // following is done via interface
  // [Constraint: If orderType equals LIMIT, then DO NOT set quoteId]
  // [Constraint: If orderType equals LIMIT, then set level]
  // [Constraint: If orderType equals MARKET, then DO NOT set level,quoteId]
  // [Constraint: If orderType equals QUOTE, then set level,quoteId]

  // [Constraint: If trailingStop equals false, then DO NOT set trailingStopIncrement]
  if (!param.trailingStop && param.trailingStopIncrement) {
    return false;
  }

  // [Constraint: If trailingStop equals true, then DO NOT set stopLevel]
  // [Constraint: If trailingStop equals true, then guaranteedStop must be false]
  // [Constraint: If trailingStop equals true, then set stopDistance,trailingStopIncrement]
  if (param.trailingStop) {
    if (param.stopLevel || param.guaranteedStop || !param.trailingStopIncrement || !param.stopDistance) {
      return false;
    }
  }

  // [Constraint: Set only one of {limitLevel,limitDistance}]
  // [Constraint: Set only one of {stopLevel,stopDistance}]
  if (param.limitDistance && param.limitLevel) return false;
  if (param.stopDistance && param.stopLevel) return false;

  // other
  if (!validateDealReference(param.dealReference)) return false;

  return true;
}

/**
 * open ig position
 * - sl/tp must be smaller than 75% of current price
 * @param userToken
 * @param params
 * @returns
 */
export async function openIgPosition(userToken: IgUserToken, params: IgPositionOpenV2Request) {
  console.debug('openIgPosition');

  const newParams = UtilObject.filterUndefined(params) as IgPositionOpenV2Request;
  if (!isValid(newParams)) {
    throw new Error('openIgPosition - data incorrect');
  }

  const tryParse = zIgPositionOpenV2Request.safeParse(newParams);
  if (!tryParse.success) {
    console.info('parse failed');
    console.warn(tryParse.error);
  } else {
    console.info('parse success');
    console.info(tryParse.data);
  }

  const result = await sendIgRequest<IgPositionOpenV2Response>(
    userToken,
    '/positions/otc',
    'post',
    2,
    undefined,
    undefined,
    newParams,
  );

  return createIgResponse(userToken, result);
}

import {array, number, object, string, ZodType} from 'zod';

export interface IndicatorConfigV3<Output, Input> {
  type: string;
  /** Input, candle stick or number */
  input: Input[];
  /** Maximum number of input stored */
  maxInputLength: number;

  /** hloc needed for calculation */
  // hloc: IndicatorCandlePriceEnum;

  /** Calculated result will be stored */
  results: Output[];
  /** Maximum number of result stored */
  maxResultLength: number;

  /** value for calculation, ALL to use whole candle */
  // hloc: IndicatorCandlePriceEnum;
}

// export const initIndicatorConfig: IndicatorConfig<number> = {
//   prices: [],
//   maxPriceLength: 10,
//   hloc: IndicatorCandlePriceEnum.CLOSE,
//   results: [],
//   maxResultLength: 10,
// };

export const zIndicatorConfigV3 = <Output, Input>(outputSchema: ZodType<Output>, dataSchema: ZodType<Input>) =>
  object({
    type: string().default(''),
    /** Values can be candle stick or number */
    input: array(dataSchema).default([]),
    /** Maximum number of price stored */
    maxInputLength: number().min(1).default(10),
    /** hloc needed for calculation */
    // hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
    /** Calculated result will be stored */
    results: array(outputSchema).default([]),
    /** Maximum number of result stored */
    maxResultLength: number().min(1).default(10),
    /** value for calculation, ALL to use whole candle */
    // hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.ALL),
  }) satisfies ZodType<IndicatorConfigV3<Output, Input>, any, any>;

export const initIndicatorConfigV3 = zIndicatorConfigV3(number().default(0), number()).parse({});

// export type IndicatorConfig1<Output> = output<typeof zIndicatorConfig<Output>>;

import {IPositionV2} from '@algo/model';
import {calculateIPositionV2NetTradeCount} from '../../calculate/IPositionV2/calculateIPositionV2NetTradeCount';
import {filterIPositionV2Direction} from '../../filter/IPositionV2/filterIPositionV2Direction';
import {sortIPositionV2OpenTs} from '../../sort/IPositionV2/sortIPositionV2OpenTs';

/**
 * Extract positions from array to reach required trade count
 * - close only the require direction
 * - close in order of open
 * @param positions
 * @param requiredTradeCount
 */
export function extractIPositionV2TradeToClose(positions: IPositionV2[], requiredTradeCount: number) {
  // close everything when require trade count 0
  if (requiredTradeCount === 0) {
    const newPositions = [...positions];
    newPositions.sort(sortIPositionV2OpenTs(true));
    return newPositions;
  }

  const netTradeCount = calculateIPositionV2NetTradeCount(positions);
  // nothing to close when trade count matches
  if (requiredTradeCount === netTradeCount) return [];

  // assume both long and short position
  // required=1,net=0 => changes=1 (close -1/short)
  // required=1,net=2 => changes=-1 (close 1/long)
  // required=1,net=-1 => change=2 (close -1/short)
  const changes = requiredTradeCount - netTradeCount;

  const extractedPositions = positions.filter(filterIPositionV2Direction(changes < 0));
  extractedPositions.sort(sortIPositionV2OpenTs(true));
  return extractedPositions.slice(0, Math.abs(changes));
}

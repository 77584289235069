import {IUserBroker} from '@algo/firebase-common';
import {Box, Grid} from '@mui/material';
import {useState} from 'react';
import {firestoreApp} from '../../logic/helper/firebase/app';
import MatchAlgoDescription from './MatchAlgoDescription';
import MatchAlgoDirection from './MatchAlgoDirection';
import MatchAlgoHeader from './MatchAlgoHeader';
import MatchAlgoTable from './MatchAlgoTable';
import {MatchAlgoOptionsEnum} from './enum/MatchAlgoOptionsEnum';
import {useMatchAlgoCopier} from './hooks/useMatchAlgoCopier';
import {useMatchAlgoPaper} from './hooks/useMatchAlgoPaper';
import {useMatchAlgoPaperCopier} from './hooks/useMatchAlgoPaperCopier';

export interface MatchAlgoPageProps {
  uid: string;
  accounts: IUserBroker[];
}

/**
 * matching algo copier page
 * @param props
 * @returns
 */
export default function MatchAlgoPage(props: MatchAlgoPageProps) {
  const {uid, accounts} = props;

  const [selection, setSelection] = useState<MatchAlgoOptionsEnum | undefined>();
  const igAccount = accounts.find((a) => a.name === selection);

  const paper = useMatchAlgoPaper(selection);
  const copier = useMatchAlgoCopier(firestoreApp, uid, igAccount, selection);
  const paperCopier = useMatchAlgoPaperCopier(firestoreApp, selection);

  // process data to position array
  const positionArr = paper || copier || paperCopier || [];
  // const assets = igPositions.assets;

  // select dev or prod-
  // download all ig position-
  // download all open position in firestore-
  // table showing status
  // potential action for all e.g. download deal information (and save to firestore), or close ig position

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="column" spacing={2}>
        <MatchAlgoHeader
          selectionList={MatchAlgoOptionsEnum}
          selection={selection}
          handleSelection={(s) => setSelection(s)}
        />
        <MatchAlgoDescription selection={selection} />
        <MatchAlgoDirection selection={selection} />
        {selection && (
          <MatchAlgoTable uid={uid} account={igAccount} openPositions={positionArr} selection={selection} />
        )}
      </Grid>
    </Box>
  );
}

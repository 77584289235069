import {IUserHouse} from '@algo/firebase-common';
import {createFirestoreUserHouseCollectionHelper} from '@algo/firebase-web';
import {Box, Button, NativeSelect, TextField} from '@mui/material';
import {useState} from 'react';
import {firestoreApp} from '../../logic/helper/firebase/app';

function onSaveHandle(name: string, data: IUserHouse) {
  return (uid: string) => createFirestoreUserHouseCollectionHelper(firestoreApp, uid).save(name, data, true);
}

const newName = 'New';
export interface MortgageDataProps {
  uid: string;
  houses: Record<string, IUserHouse>;
  formData: IUserHouse;
  setFormData: (data: IUserHouse) => void;
}

export default function MortgageData(props: MortgageDataProps) {
  const [house, setHouse] = useState<undefined | string>();
  const [newHouse, setNewHouse] = useState<string>('');
  const {houses, uid, formData, setFormData} = props;

  // get list of options
  const options = [newName, ...Object.values(houses).map((h) => h.name)];
  const nameError = options.includes(newHouse);

  return (
    <Box sx={{paddingTop: 2, paddingBottom: 2}}>
      <NativeSelect
        value={house || newName}
        onChange={(e) => setHouse(e.target.value === newName ? undefined : e.target.value)}
        sx={{minWidth: 100, margin: 2}}
      >
        {options.map((s) => (
          <option value={s} key={s}>
            {s}
          </option>
        ))}
      </NativeSelect>

      <TextField
        placeholder="New house name"
        onChange={(e) => setNewHouse(e.target.value)}
        value={newHouse}
        error={nameError}
        disabled={!!house}
      />

      <Button
        onClick={() => {
          if (nameError) {
            return;
          }
          const houseName = house || newHouse;
          onSaveHandle(houseName, {...formData, name: houseName})(uid);
          setNewHouse('');
        }}
      >
        Save
      </Button>

      <Button
        onClick={() => {
          if (house) {
            const loadHouse = Object.values(houses).find((h) => h.name === house);
            if (loadHouse) {
              setFormData(loadHouse);
            }
          }
        }}
      >
        Load
      </Button>

      <Button
        onClick={() => {
          if (house) {
            createFirestoreUserHouseCollectionHelper(firestoreApp, uid).del(house);
          }
        }}
      >
        Delete
      </Button>
    </Box>
  );
}

import {IgUserToken} from '../create/createIgUserToken';
import {IgDirectionEnum} from '../enum/IgDirectionEnum';
import {IgOrderExecutionTypeEnum} from '../enum/IgOrderExecutionTypeEnum';
import {IgTimeInForceEnum} from '../enum/IgTimeInForceEnum';
import {closeIgPosition} from '../position/closeIgPosition';

/**
 * Close ig position given deal id
 */
export async function igHelperClosePosition(
  userToken: IgUserToken,
  dealId: string,
  size: number,
  direction = IgDirectionEnum.BUY,
  timeInForce = IgTimeInForceEnum.FILL_OR_KILL,
) {
  console.debug('igHelperClosePosition');

  return closeIgPosition(userToken, {
    orderType: IgOrderExecutionTypeEnum.MARKET,
    dealId,
    size,
    direction,
    timeInForce,
  });
}

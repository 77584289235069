import {UtilObject} from '@algo/util';
import {AlgoStageEnum} from '../../__generated__/resolvers-types';
import useQueryAlgoCopyConfig from '../../hooks/useQueryAlgoCopyConfig';
import {buildStrategyCode} from '../../pages/summary/utilSummary';

/**
 * Build summary page config
 */
export default function useSummaryConfig() {
  const devConfig = useQueryAlgoCopyConfig(AlgoStageEnum.DEV);
  const prodConfig = useQueryAlgoCopyConfig(AlgoStageEnum.PROD);

  // prefix keys

  return {
    ...UtilObject.executeFnToKey(devConfig, (l) => buildStrategyCode(AlgoStageEnum.DEV, l)),
    ...UtilObject.executeFnToKey(prodConfig, (l) => buildStrategyCode(AlgoStageEnum.PROD, l)),
  };
}

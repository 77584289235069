import {defaultIPositionMeta, IPositionMeta} from '@algo/model';

/**
 * Create position meta fields
 * @param position
 * @returns
 */
export function createPositionMeta(position?: Partial<IPositionMeta>): IPositionMeta {
  if (!position) return defaultIPositionMeta;
  return {
    tags: position.tags || defaultIPositionMeta.tags,
    note: position.note || defaultIPositionMeta.note,
  };
}

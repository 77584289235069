import {IPositionV2} from '@algo/model';
import {useState} from 'react';
import InfoTile from '../InfoTile/InfoTile';
import SummaryGraph from '../summaryGraph/SummaryGraph';
import calculateStartIndex from '../../logic/helper/calculate/calculateStartIndex';
import positionConvertCumulativeReturn from '../../logic/helper/position/convert/positionConvertCumulativeReturn';

export interface CumulativeReturnTileProps {
  /** All closed position sorted by close TS in asc order */
  sortedClosePositions: IPositionV2[];

  /** limit number to display on graph */
  limit?: number;
}

export default function CumulativeReturnTile(props: CumulativeReturnTileProps) {
  const {sortedClosePositions, limit} = props;
  const [isPct, setIsPct] = useState(false);

  if (sortedClosePositions.length === 0) return null;

  const cumulativeReturn = positionConvertCumulativeReturn(sortedClosePositions, isPct).map(
    (r) => r * (isPct ? 100 : 1),
  );

  const startIndex = calculateStartIndex(sortedClosePositions.length, limit || sortedClosePositions.length);

  const plotData = cumulativeReturn.map((val, i) => ({x: i + 1, y: val})).slice(startIndex);
  const finalReturn = cumulativeReturn[cumulativeReturn.length - 1];
  return (
    <InfoTile
      title={`${isPct ? '%' : 'Net'} Return`}
      description="Return on investment"
      switchState={isPct}
      setSwitch={setIsPct}
      labelLeft="Net"
      labelRight="%"
      data={[
        {
          title: 'Cumulative',
          prefix: isPct ? '' : '£',
          suffix: isPct ? '%' : '',
          value: (finalReturn || 0).toFixed(2),
        },
        {
          title: 'Average',
          prefix: isPct ? '' : '£',
          suffix: isPct ? '%' : '',
          value: ((finalReturn || 0) / cumulativeReturn.length).toFixed(2),
        },
      ]}
      graph={cumulativeReturn.length ? <SummaryGraph data={plotData} height={150} /> : undefined}
    />
  );
}

import axios, {AxiosError, AxiosResponse, Method} from 'axios';
import {IgUserToken} from '../create/createIgUserToken';
import {IgUserTokenKey} from '../create/createIgUserTokenKey';
import {IgStatusEnum} from '../enum';
import {mapIgStatusEnum} from '../enum/mapIgStatusEnum';
import {IgSendRequestBody, IgSendRequestHeader} from '../interface';
import {IgError} from '../interface/IgError';
import {isIgResponseValid} from '../status/isIgResponseValid';
import getIgRequestConfig from './getIgRequestConfig';

/**
 * Interface returned by sendIgRequest to accommodate data and error
 */
export interface ISendIgRequest<TResult, TBody> {
  /** Response from server - only exists when success and http code valid */
  response?: AxiosResponse<TResult, TBody>;
  /** Error thrown by axios if error occured - exists when error occured */
  error?: AxiosError<IgError>;
  /** status enum */
  statusCode: IgStatusEnum;
}

/**
 * Send request to IG
 * @param userToken
 * @param appendUrl
 * @param method
 * @param version
 * @param params
 * @param header
 * @param body
 * @returns
 */
export async function sendIgRequest<
  TResult,
  TParam extends IgSendRequestBody = IgSendRequestBody,
  TBody extends IgSendRequestBody = IgSendRequestBody,
>(
  userToken: IgUserToken | IgUserTokenKey,
  appendUrl: string,
  method: Method = 'get',
  version = 1,
  params?: TParam,
  header?: IgSendRequestHeader,
  body?: TBody,
): Promise<ISendIgRequest<TResult, IgSendRequestBody>> {
  console.debug('sendIgRequest');
  const authOptions = getIgRequestConfig(userToken, appendUrl, method, version, params, header || {}, body || {});

  try {
    const axiosResponse = await axios.request<TResult, AxiosResponse<TResult, TBody>>(authOptions);

    if (!isIgResponseValid(axiosResponse)) {
      console.error('request - response failed', axiosResponse.status, axiosResponse.statusText);
      throw Error('Request to IG server failed');
    }
    return {response: axiosResponse, statusCode: IgStatusEnum.OK};
  } catch (err) {
    const error = err as AxiosError<IgError>;

    // return IgError if there is matching error code
    const statusCode = mapIgStatusEnum(error.response?.data.errorCode || '');

    console.error(JSON.stringify({statusCode, params}));

    if (statusCode !== IgStatusEnum.UNKNOWN) return {error, statusCode};

    console.error(
      `request - request failed to ${method.toUpperCase()} ${appendUrl} (${JSON.stringify(params || {})}) getting ${
        error.response?.status
      } because ${JSON.stringify(error.response?.data || {})} with ${JSON.stringify(
        body || {},
      )} and request ${JSON.stringify(Object.keys(error.request || {}))}`,
    );
    throw error;
  }
}

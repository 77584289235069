import {nativeEnum, number, object, ZodType} from 'zod';
import {IndicatorCandlePriceEnum} from '../../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorInitV3, zIndicatorInitV3} from '../../../common/template/v3/IndicatorInitV3';

export interface EmaInitV3 extends IndicatorInitV3 {
  period: number;
  hloc: IndicatorCandlePriceEnum;
  smoothing: number;
}

export const zEmaInitV3 = zIndicatorInitV3.merge(
  object({
    period: number().min(3).default(20),
    hloc: nativeEnum(IndicatorCandlePriceEnum).default(IndicatorCandlePriceEnum.CLOSE),
    smoothing: number().min(1).default(2),
  }),
) satisfies ZodType<EmaInitV3, any, any>;

// export type EmaInitV3 = input<typeof zEmaInitV3>;

export const initEmaInitV3 = zEmaInitV3.parse({});

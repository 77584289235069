export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> = T | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  _FieldSet: {input: any; output: any};
};

/** Algo copy sizing config */
export type AlgoCopyConfigObject = {
  __typename?: 'AlgoCopyConfigObject';
  /** sizing factor for copying */
  data: Scalars['Float']['output'];
  id: Scalars['String']['output'];
};

/** Algo config for running algo */
export type AlgoPaperConfig = {
  __typename?: 'AlgoPaperConfig';
  /** name of epic/asset */
  epic: Scalars['String']['output'];
  /** Key for conversion to object, related to document name */
  id: Scalars['String']['output'];
  /** Current open position */
  position?: Maybe<IPositionV2>;
  /** resolution of paper trade */
  resolution: Scalars['String']['output'];
  /** name of strategy */
  strategyName: Scalars['String']['output'];
};

export type AlgoPaperPosition = {
  __typename?: 'AlgoPaperPosition';
  /** List of positions */
  data: Array<IPositionV2>;
  /** Strategy name */
  id: Scalars['String']['output'];
};

export enum AlgoStageEnum {
  DEV = 'DEV',
  PAPER = 'PAPER',
  PROD = 'PROD',
}

export type AlgoSummary = {
  __typename?: 'AlgoSummary';
  name: Scalars['String']['output'];
  /** Latest 100 closed positions */
  positions?: Maybe<Array<IPositionV2>>;
};

export type Broker = {
  __typename?: 'Broker';
  /** Account id within login credential */
  accountId: Scalars['String']['output'];
  /** api keys */
  apiKey: Scalars['String']['output'];
  /** Normally IG, but can be expended in the future */
  broker: Scalars['String']['output'];
  /** True for production account, false for demo */
  isLive: Scalars['Boolean']['output'];
  /** Local identifier */
  name: Scalars['String']['output'];
  /** Login password */
  password: Scalars['String']['output'];
  /** Login username */
  username: Scalars['String']['output'];
};

export enum DataResolutionEnum {
  /** Daily data, start from 00:00:00 to 23:59:59 */
  DAY = 'DAY',
  /** 1 hour candle, start from 00:00:00 to 00:59:59 */
  HOUR = 'HOUR',
  /** 4 hour candle, start from 00:00:00 to 03:59:59 */
  HOUR_4 = 'HOUR_4',
  /** 1 min candle - not in use */
  MINUTE = 'MINUTE',
  /** 5 min candle */
  MINUTE_5 = 'MINUTE_5',
  /** 15 min candle, start from 00:00:00 to 14:59:59 */
  MINUTE_15 = 'MINUTE_15',
  /** 1 second candle - not in use */
  SECOND = 'SECOND',
}

export type House = {
  __typename?: 'House';
  /** Does owner have additional home for stamp duty calculation */
  additionalHome: Scalars['Boolean']['output'];
  /** Cash reserved for purchase */
  cash: Scalars['String']['output'];
  /** Combined fees */
  fee: Scalars['String']['output'];
  /** if user is first time buyer */
  firstTimeBuyer: Scalars['Boolean']['output'];
  /** Name of house */
  name: Scalars['String']['output'];
  /** Living abroad would incur extra stamp duty */
  oversea: Scalars['Boolean']['output'];
  /** Price of property */
  price: Scalars['String']['output'];
  /** Mortgage rate in percentage */
  ratePct: Scalars['String']['output'];
  /** Year terms of mortgage */
  termsYrs: Scalars['String']['output'];
};

export type IPositionV2 = {
  __typename?: 'IPositionV2';
  /** asset id from broker, or identifiable String for asset */
  assetId: Scalars['String']['output'];
  /** broker name */
  broker: Scalars['String']['output'];
  /** Actualy close price for closed position, or ask/bid for open position */
  close: Scalars['Float']['output'];
  /** Position close timestamp, empty String is not closed */
  closeTsUtc: Scalars['String']['output'];
  /** Direction of position */
  direction: PositionDirectionEnum;
  /** position id from broker or generated locally */
  id: Scalars['ID']['output'];
  /** Limit price, 0 means not set */
  limit: Scalars['Float']['output'];
  /** Position open price */
  open: Scalars['Float']['output'];
  /** Position open timestamp */
  openTsUtc: Scalars['String']['output'];
  /** Position size */
  size: Scalars['Float']['output'];
  /** Stop price, 0 means not set */
  stop: Scalars['Float']['output'];
  /** list of tags */
  tags: Array<Scalars['String']['output']>;
  /** Trailing stop step size, only available when set */
  trailingStep?: Maybe<Scalars['Float']['output']>;
  /** Trailing stop, only available when set */
  trailingStop?: Maybe<Scalars['Float']['output']>;
};

export type IgPriceCandle = {
  __typename?: 'IgPriceCandle';
  /** Close price */
  closePrice: IgPriceDetail;
  /** High price */
  highPrice: IgPriceDetail;
  /** Last traded volume. This will generally be null for non exchange-traded instruments */
  lastTradedVolume?: Maybe<Scalars['Int']['output']>;
  /** Low price */
  lowPrice: IgPriceDetail;
  /** Open price */
  openPrice: IgPriceDetail;
  /** Snapshot local time, format is yyyy/MM/dd hh:mm:ss, corresponding to start time of the candle */
  snapshotTime: Scalars['String']['output'];
  /** Snapshot time UTC */
  snapshotTimeUTC: Scalars['String']['output'];
};

export type IgPriceCandleInput = {
  /** Close price */
  closePrice: IgPriceDetailInput;
  /** High price */
  highPrice: IgPriceDetailInput;
  /** Last traded volume. This will generally be null for non exchange-traded instruments */
  lastTradedVolume?: InputMaybe<Scalars['Int']['input']>;
  /** Low price */
  lowPrice: IgPriceDetailInput;
  /** Open price */
  openPrice: IgPriceDetailInput;
  /** Snapshot local time, format is yyyy/MM/dd hh:mm:ss, corresponding to start time of the candle */
  snapshotTime: Scalars['String']['input'];
  /** Snapshot time UTC */
  snapshotTimeUTC: Scalars['String']['input'];
};

export type IgPriceDetail = {
  __typename?: 'IgPriceDetail';
  /** Asking price, lowest price a seller will accept for a security */
  ask: Scalars['Float']['output'];
  /** Bid price, highest price a buyer will pay for a security */
  bid: Scalars['Float']['output'];
  /** Last traded price. This will generally be null for non exchange-traded instruments */
  lastTraded?: Maybe<Scalars['Float']['output']>;
};

export type IgPriceDetailInput = {
  /** Asking price, lowest price a seller will accept for a security */
  ask: Scalars['Float']['input'];
  /** Bid price, highest price a buyer will pay for a security */
  bid: Scalars['Float']['input'];
  /** Last traded price. This will generally be null for non exchange-traded instruments */
  lastTraded?: InputMaybe<Scalars['Float']['input']>;
};

export type IgSession = {
  __typename?: 'IgSession';
  accountId: Scalars['String']['output'];
  accountToken: Scalars['String']['output'];
  apiKey: Scalars['String']['output'];
  clientToken: Scalars['String']['output'];
  expiry: Scalars['String']['output'];
  lightstreamerEndpoint: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** generate mongo data of given epic and resolution for given candle count */
  generateMongoData: Array<Scalars['String']['output']>;
  /** set algo copy config, will return the resulting value */
  setAlgoCopyConfig: Scalars['Boolean']['output'];
  /** set historical price in mongo */
  setMongoHistoricalPrice: Scalars['Boolean']['output'];
  /** set strategy runner config of given name */
  setStrategyRunnerConfig?: Maybe<StrategyConfig>;
  /** set user detail in record */
  setUserDetail?: Maybe<Scalars['Boolean']['output']>;
};

export type MutationGenerateMongoDataArgs = {
  candleCount?: InputMaybe<Scalars['Int']['input']>;
  epic: Scalars['String']['input'];
  resolution: DataResolutionEnum;
};

export type MutationSetAlgoCopyConfigArgs = {
  name: Scalars['String']['input'];
  stage: AlgoStageEnum;
  value: Scalars['Float']['input'];
};

export type MutationSetMongoHistoricalPriceArgs = {
  candles: Array<IgPriceCandleInput>;
  epic: Scalars['String']['input'];
  resolution: DataResolutionEnum;
};

export type MutationSetStrategyRunnerConfigArgs = {
  config: StrategyConfigInput;
};

export enum PositionDirectionEnum {
  LONG = 'long',
  SHORT = 'short',
}

export type Query = {
  __typename?: 'Query';
  /** Get algo copy config in sterilised object array. this shows the sizing for copy trade */
  getAlgoCopyConfig: Array<AlgoCopyConfigObject>;
  /** Get individual config of algo in paper trade. certain details such as position is shown */
  getAlgoPaperConfig?: Maybe<AlgoPaperConfig>;
  /** Get a list of all brokers from user */
  getBrokers: Array<Broker>;
  /** Get historical pricing */
  getHistoricalPrice: Array<IgPriceCandle>;
  /** Get a list of all brokers from user */
  getHouses: Array<House>;
  /** Get ig login credential, login if required and save to session */
  getIgLogin: IgSession;
  /** Get historical pricing from mongo, sorted by snapshotTime ascending */
  getMongoHistoricalPrice: Array<IgPriceCandle>;
  /** Get historical pricing from mongo after afterDatetime for count number of candles, sorted by snapshotTime ascending */
  getMongoNextCandle: Array<IgPriceCandle>;
  /** Get strategy runner config of given name */
  getStrategyRunnerConfig?: Maybe<StrategyConfig>;
  getUserId?: Maybe<Scalars['String']['output']>;
  /** Get all algo paper close position for given strategies, or all strategy if no strategy names provided */
  listAlgoPaperClosePosition: Array<AlgoPaperPosition>;
  /** Get all config of algo in paper trade, or only ones that matches parameter */
  listAlgoPaperConfig: Array<AlgoPaperConfig>;
  /** Get all algo paper open position for given strategies, or all strategy if no strategy names provided */
  listAlgoPaperOpenPosition: Array<AlgoPaperPosition>;
  /** All algo strategy using paper trade */
  listAlgoSummary: Array<AlgoSummary>;
  /** Get list of all algo directly from collection */
  listAllCopyAlgos: Array<Scalars['String']['output']>;
  /** Get a list of all algos in paper trade */
  listAllPaperAlgos: Array<Scalars['String']['output']>;
  /** Get all strategy runner config */
  listStrategyRunnerConfig: Array<StrategyConfig>;
  /** Load strategy runner config of given id */
  loadStrategyRunnerConfig?: Maybe<StrategyConfig>;
  /** login to ig */
  loginIgV2: IgSession;
};

export type QueryGetAlgoCopyConfigArgs = {
  stage: AlgoStageEnum;
};

export type QueryGetAlgoPaperConfigArgs = {
  strategyName: Scalars['String']['input'];
};

export type QueryGetHistoricalPriceArgs = {
  epic: Scalars['String']['input'];
  from: Scalars['String']['input'];
  resolution: DataResolutionEnum;
  to: Scalars['String']['input'];
};

export type QueryGetIgLoginArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  apiKey: Scalars['String']['input'];
  isLive: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type QueryGetMongoHistoricalPriceArgs = {
  epic: Scalars['String']['input'];
  from: Scalars['String']['input'];
  resolution: DataResolutionEnum;
  to: Scalars['String']['input'];
};

export type QueryGetMongoNextCandleArgs = {
  afterDatetime?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  epic: Scalars['String']['input'];
  resolution: DataResolutionEnum;
};

export type QueryGetStrategyRunnerConfigArgs = {
  name: Scalars['String']['input'];
};

export type QueryListAlgoPaperClosePositionArgs = {
  strategyNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryListAlgoPaperConfigArgs = {
  strategyNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryListAlgoPaperOpenPositionArgs = {
  strategyNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryListAlgoSummaryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  stage: AlgoStageEnum;
};

export type QueryListAllCopyAlgosArgs = {
  stage: AlgoStageEnum;
};

export type QueryLoadStrategyRunnerConfigArgs = {
  id: Scalars['String']['input'];
};

export type QueryLoginIgV2Args = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  apiKey: Scalars['String']['input'];
  isLive: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type StrategyConfig = {
  __typename?: 'StrategyConfig';
  /** string that matches <epic>/<resolution> will run e.g. USD in here will cause any trade with USD to work, CS will cause all currency to work, empty will disable strategy, null enable all epic/resolution */
  allowList?: Maybe<Array<Scalars['String']['output']>>;
  /** string that matches <epic>/<resolution> will not run e.g. USD in here will cause any trade with USD stop working */
  denyList?: Maybe<Array<Scalars['String']['output']>>;
  /** optional metadata, this will be sent with request as string, that can be parsed by strategy itself */
  metadata?: Maybe<Scalars['String']['output']>;
  /** name of strategy */
  name: Scalars['String']['output'];
  /** email address to contact when error */
  onErrorContact?: Maybe<Scalars['String']['output']>;
  /** strategy status */
  status: StrategyStatusEnum;
  /** request url to send to */
  url: Scalars['String']['output'];
  /** owner user id */
  userId: Scalars['String']['output'];
};

export type StrategyConfigInput = {
  /** string that matches <epic>/<resolution> will run e.g. USD in here will cause any trade with USD to work, CS will cause all currency to work, empty will disable strategy, null enable all epic/resolution */
  allowList?: InputMaybe<Array<Scalars['String']['input']>>;
  /** string that matches <epic>/<resolution> will not run e.g. USD in here will cause any trade with USD stop working */
  denyList?: InputMaybe<Array<Scalars['String']['input']>>;
  /** optional metadata, this will be sent with request as string, that can be parsed by strategy itself */
  metadata?: InputMaybe<Scalars['String']['input']>;
  /** name of strategy */
  name: Scalars['String']['input'];
  /** email address to contact when error */
  onErrorContact?: InputMaybe<Scalars['String']['input']>;
  /** strategy status */
  status: StrategyStatusEnum;
  /** request url to send to */
  url: Scalars['String']['input'];
};

export enum StrategyStatusEnum {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

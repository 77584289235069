import {IPositionV2, ITradeSummary} from '@algo/model';
import {gql, useQuery} from '@apollo/client';
import {AlgoStageEnum} from '../../__generated__/resolvers-types';
import {buildStrategyCode} from '../../pages/summary/utilSummary';

const QUERY = gql`
  query ListAlgoSummary($stage: AlgoStageEnum!) {
    listAlgoSummary(stage: $stage) {
      name
      positions {
        id
        broker
        assetId
        size
        direction
        open
        openTsUtc
        close
        closeTsUtc
        limit
        stop
        tags
        trailingStop
        trailingStep
      }
    }
  }
`;

export interface GqlTradeSummary {
  listAlgoSummary: {
    name: string;
    positions: IPositionV2[];
  }[];
}

/**
 * convert summary to required format
 * @param data
 * @returns
 */
function mapGqlTradeSummaryToRecordTradeSummary(stage: AlgoStageEnum, data?: GqlTradeSummary) {
  if (!data) return {};
  return data.listAlgoSummary.reduce(
    (prev, cur) => ({...prev, [buildStrategyCode(stage, cur.name)]: cur.positions}),
    {},
  );
}

export default function useQueryTradeSummary(): Record<string, ITradeSummary> {
  const paper = useQuery<GqlTradeSummary>(QUERY, {variables: {stage: AlgoStageEnum.PAPER}});
  const dev = useQuery<GqlTradeSummary>(QUERY, {variables: {stage: AlgoStageEnum.DEV}});
  const prod = useQuery<GqlTradeSummary>(QUERY, {variables: {stage: AlgoStageEnum.PROD}});

  const paperResult = mapGqlTradeSummaryToRecordTradeSummary(AlgoStageEnum.PAPER, paper.data);
  const devResult = mapGqlTradeSummaryToRecordTradeSummary(AlgoStageEnum.DEV, dev.data);
  const prodResult = mapGqlTradeSummaryToRecordTradeSummary(AlgoStageEnum.PROD, prod.data);

  return {...paperResult, ...devResult, ...prodResult};
}

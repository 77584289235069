import {DatetimeEnum} from '@algo/util';
import dayjs from 'dayjs';

/**
 * Generate expiry timestamp with preset or expiry in seconds
 * @param expireSec
 * @returns
 */
export function generateTokenExpiry(expireSec?: string) {
  const now = dayjs();
  return expireSec && !Number.isNaN(parseInt(expireSec, 10))
    ? now.add(parseInt(expireSec, 10), 'seconds').format(DatetimeEnum.STANDARD_DATETIME)
    : now.add(10, 'hours').format(DatetimeEnum.STANDARD_DATETIME);
  // token expire in 12 hours - https://labs.ig.com/faq
  // use 10 to make sure it get refreshed earlier
}

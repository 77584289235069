import {IPositionV2} from '@algo/model';

/**
 * Check if position has limit
 * @param position
 * @returns
 */
export function iPositionV2HasLimit(position: IPositionV2) {
  return position.limit > 0;
}

import {IAsset, IPositionV2} from '@algo/model';
import {TableCell, TableRow} from '@mui/material';
import updatePositionTags from '../../logic/helper/data/updatePositionTags';
import positionCalculateLimitRisk from '../../logic/helper/position/calculate/positionCalculateLimitRisk';
import positionCalculateTotalProfit from '../../logic/helper/position/calculate/positionCalculateTotalProfit';
import positionCalculateTotalRisk from '../../logic/helper/position/calculate/positionCalculateTotalRisk';
import {IPositionTableFields, positionTableFieldsOrder} from '../../pages/positions/IPositionTableFields';
import TagsField from '../TagsField/TagsField';

/**
 * Convert data
 * @param position
 * @param asset
 * @returns
 */
function convertData(position: IPositionV2, asset?: IAsset): Partial<IPositionTableFields> {
  const profit = asset && asset.ask && asset.bid ? positionCalculateTotalProfit([position], asset.ask, asset.bid) : 0;
  return {
    name: position.openTsUtc,
    size: position.size.toString(),
    open: position.open.toPrecision(5),
    latest: asset ? ((position.size > 0 ? asset.bid : asset.ask) ?? 0).toString() : '0',
    stop: position.stop ? position.stop.toString() : '',
    limit: position.limit ? position.limit.toString() : '',
    profitLoss: profit.toFixed(2),
    totalRisk: positionCalculateTotalRisk([position]).toFixed(2),
    limitRisk: positionCalculateLimitRisk([position]).toFixed(2),
    tags: (position.tags || []).join(','),
  };
}

export interface IPositionTableRowProps {
  /** User id */
  uid: string;
  /** asset this row belongs to */
  asset?: IAsset;
  /** position data */
  position: IPositionV2;
  /** State of row, accordion open/close state */
  show: boolean;
  /** List of all tags in string, for all positions */
  allTags: string[];
  /** Action when row is clicked on */
  onClickHandle: () => void;
}

/**
 * Display ONE position data
 * @param props
 * @returns
 */
function PositionTableRow(props: IPositionTableRowProps) {
  const {position, show, asset, onClickHandle, allTags, uid} = props;

  const data = convertData(position, asset);

  const setTags = (tags: string[]) => updatePositionTags(uid, position, tags);

  return (
    <TableRow
      key={`PositionTableRow-row-${position.id}`}
      sx={{
        '&:last-child td, &:last-child th': {border: 0},
        display: show ? 'table-row' : 'none',
      }}
    >
      {positionTableFieldsOrder.map((d) => {
        const key = `PositionTableRow-cell-${position.id}-${d}`;
        if (d === 'tags') {
          return (
            <TableCell align="center" key={key}>
              <TagsField name={position.id} tagsCSV={data[d] || ''} setTags={setTags} options={allTags} />
            </TableCell>
          );
        }
        return (
          <TableCell key={key} align="center" onClick={onClickHandle}>
            {d in data && data[d as keyof IPositionTableFields]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default PositionTableRow;

import {getPathPriceCollection} from './getPathPriceCollection';

/**
 * Get collection path for price for given resolution and epic
 * @param epic
 * @param resolution
 * @returns
 */
export function getPathPriceEpicDocument(epic: string) {
  console.debug('getPathPriceEpicDocument');
  return `${getPathPriceCollection()}/${epic}`;
}

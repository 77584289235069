/**
 * Convert array to object, inverse of `toArray`
 * @param obj
 * @param keyToField
 * @returns
 */
export function fromArray<T>(
  arr: {
    id: string;
    data: T;
  }[],
): Record<string, T> {
  return Object.fromEntries(arr.map((d) => [d.id, d.data]));
}

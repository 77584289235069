import {IPositionV2} from '@algo/model';

/**
 * Calculate total profit of multiple position based on bid and ask price
 * @param positions
 * @param currentPrice
 * @returns total profit
 */
export default function positionCalculateTotalProfit(positions: IPositionV2[], ask?: number, bid?: number) {
  if (!ask || !bid) return 0;
  return positions.reduce(
    (prev, cur) => prev + (cur.size > 0 ? cur.size * ((bid || 0) - cur.open) : cur.size * ((ask || 0) - cur.open)),
    0,
  );
}

import {ZodType} from 'zod';
import {IgPositionCommonGuaranteeStop, zIgPositionCommonGuaranteeStop} from './IgPositionCommonGuaranteeStop';
import {IgPositionCommonStopLimit, zIgPositionCommonStopLimit} from './IgPositionCommonStopLimit';
import {IgPositionCommonTrailing, zIgPositionCommonTrailing} from './IgPositionCommonTrailing';

/**
 * Common parameters among order types for stop and limit
 */
export type IgPositionOpenPartStopLimit = Partial<IgPositionCommonStopLimit> &
  Partial<Pick<IgPositionCommonTrailing, 'trailingStop' | 'trailingStopIncrement'>> &
  IgPositionCommonGuaranteeStop;

export const zIgPositionOpenPartStopLimit = zIgPositionCommonStopLimit
  .partial()
  .merge(zIgPositionCommonTrailing.pick({trailingStop: true, trailingStopIncrement: true}).partial())
  .merge(zIgPositionCommonGuaranteeStop) satisfies ZodType<IgPositionOpenPartStopLimit>;

import {IPositionV2} from '@algo/model';
import {useState} from 'react';
import {VictoryAxis, VictoryChart, VictoryHistogram} from 'victory';
import InfoTile from '../InfoTile/InfoTile';
import positionExtractTradeDuration from '../../logic/helper/position/extract/positionExtractTradeDuration';

export interface TradeDurationTileProps {
  /** All closed position sorted by close TS in asc order */
  sortedClosePositions: IPositionV2[];
}

export default function TradeDurationTile(props: TradeDurationTileProps) {
  const {sortedClosePositions} = props;
  const [isHr, setIsHr] = useState(false);
  const height = 200;
  const width = 250;
  const unit = isHr ? 'hour' : 'minute';

  if (sortedClosePositions.length === 0) return null;

  const positionTradeDuration = sortedClosePositions.map((pos) => positionExtractTradeDuration(pos, unit));
  const plotData = positionTradeDuration.map((d) => ({x: d}));
  const totalDuration = positionTradeDuration.reduce((prev, cur) => prev + cur, 0);
  return (
    <InfoTile
      title="Trade duration"
      description="Duration of all trade add together. Multiple trade run in parallel counts separately"
      switchState={isHr}
      setSwitch={setIsHr}
      labelLeft="Mins"
      labelRight="Hour"
      data={[
        {
          title: 'Average',
          value: (totalDuration / (sortedClosePositions.length || 1)).toFixed(1),
          suffix: unit,
        },
        {
          title: 'Total',
          value: totalDuration.toFixed(1),
          suffix: unit,
        },
      ]}
      graph={
        sortedClosePositions.length ? (
          <VictoryChart
            height={height}
            width={width}
            domainPadding={0}
            padding={{top: 20, bottom: 50, right: 20, left: 50}}
          >
            <VictoryHistogram data={plotData} height={height} width={width} />

            <VictoryAxis crossAxis width={width} height={height} standalone={false} label={`Duration ${unit}`} />

            <VictoryAxis dependentAxis crossAxis width={width} height={height} />
            {/* <VictoryAxis tickFormat={() => ''} height={0} width={0} /> */}
          </VictoryChart>
        ) : undefined
      }
    />
  );
}

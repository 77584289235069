import {number} from 'zod';

/**
 * Ema output
 */
export type EmaOutputV3 = number;

export const zEmaOutputV3 = number();

export const initEmaOutputV3 = 0;

import {Grid} from '@mui/material';
import InfoTileBodyBlock, {InfoTileBodyBlockProps} from './InfoTileBodyBlock';

export interface InfoTileBodyProps {
  data?: InfoTileBodyBlockProps[];
  graph?: JSX.Element;
}
export default function InfoTileBody(prop: InfoTileBodyProps) {
  const {data, graph} = prop;
  return (
    <Grid container direction="row" wrap="wrap">
      {data ? (
        <Grid item>
          <Grid container direction="column">
            {data.map((d) => (
              <Grid item key={`InfoTileBody-${d.title}`}>
                <InfoTileBodyBlock
                  title={d.title}
                  description={d.description}
                  value={d.value}
                  prefix={d.prefix}
                  suffix={d.suffix}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
      <Grid item>{graph}</Grid>
    </Grid>
  );
}

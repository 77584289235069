import {FirebaseOptions, initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import {envLocalDb} from '../env';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAvvySJkPi1ZqxQWYo_B4YUcjdAzziWj68',
  authDomain: 'algo-trading-c9daa.firebaseapp.com',
  databaseURL: 'https://algo-trading-c9daa-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'algo-trading-c9daa',
  storageBucket: 'algo-trading-c9daa.appspot.com',
  messagingSenderId: '724295580399',
  appId: '1:724295580399:web:fa2f0b538a4822d401391a',
  measurementId: 'G-DC7F1BV0J4',
};

export const firebaseApp = initializeApp(firebaseConfig);

export const firestoreApp = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseFn = getFunctions(firebaseApp, 'europe-west2');

if (window.location.href.includes('localhost')) {
  if (envLocalDb()) {
    console.log('local db');
    connectFirestoreEmulator(firestoreApp, 'localhost', 8080);
    connectFunctionsEmulator(firebaseFn, 'localhost', 5001);
  } else {
    console.log(`firebase db on the cloud`);
  }
}

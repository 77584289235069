/**
 * Direction of open order
 */
export enum IgDirectionEnum {
  /**
   * Long order, profit if price go up
   */
  BUY = 'BUY',

  /**
   * Short order, profit if price go down
   */
  SELL = 'SELL',
}

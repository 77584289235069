export enum MatchAlgoOptionsEnum {
  /** match paper trade config to trade in open collection */
  ALGO_PAPER = 'algoPaper',

  /** match paper trade open collection to algo copier dev */
  ALGO_PAPER_DEV = 'algoPaperToDemo',

  /** match paper trade open collection to algo copier prod */
  ALGO_PAPER_PROD = 'algoPaperToProd',

  /** match algo copier dev to ig */
  ALGO_DEV = 'algoDemo',

  /** match algo copier prod to ig */
  ALGO_PROD = 'algoProd',
}

import {nativeEnum, object, string, ZodType} from 'zod';
import {IgActivityDetailActionEnum} from '../../enum/IgActivityDetailActionEnum';

/**
 * Activity details
 */
export interface IgActivityDetailAction {
  /** Action type */
  actionType: IgActivityDetailActionEnum;
  /** Affected deal id */
  affectedDealId: string;
}

export const zIgActivityDetailAction: ZodType<IgActivityDetailAction> = object({
  actionType: nativeEnum(IgActivityDetailActionEnum),
  affectedDealId: string().min(1),
});

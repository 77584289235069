import {IPosition, IPositionMain} from '@algo/model';
import {createPositionFromParts} from './createPositionFromParts';
import {createPositionMain} from './createPositionMain';
import {createPositionMeta} from './createPositionMeta';

/**
 * Create IPosition
 * @param position
 * @returns
 */
export function createPosition(position: Partial<IPosition>) {
  const positionMain = createPositionMain(position as IPositionMain);
  return createPositionFromParts(positionMain, createPositionMeta(position.meta));
}

import {number} from 'zod';

/**
 * Sma output
 */
export type SmaOutputV2 = number;

export const zSmaOutputV2 = number();

export const initSmaOutputV2 = 0;

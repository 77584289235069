import {flatten, uniq} from 'lodash';
/**
 * Convert array of object to object of array
 * @param arr
 * @param keyPrefix prefix keys in object
 * @returns
 */
export function fromArrayObject<T = number>(arr?: any[], keyPrefix?: string): Record<string, T[]> | undefined {
  if (!arr) return undefined;
  if (!Array.isArray(arr)) return undefined;
  if (arr.length === 0) return {};
  if (arr.some((a) => typeof a !== 'object')) return undefined;

  const data = arr as Record<string, T>[];

  // find all unique keys
  const keys = data.map((a) => Object.keys(a));
  const uniqueKeys = uniq(flatten(keys));

  const defaultData = Object.fromEntries(uniqueKeys.map((k) => [k, [] as T[]]));
  const rtn = data.reduce((prev, cur) => {
    Object.entries(cur).forEach(([k, v]) => {
      prev[k]?.push(v);
    });
    return prev;
  }, defaultData);

  if (!keyPrefix) return rtn;
  return Object.fromEntries(Object.entries(rtn).map(([k, v]) => [`${keyPrefix}${k}`, v]));
}

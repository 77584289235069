import {IPositionV2} from '@algo/model';

/**
 * Filter for id (ig deal id)
 * @param dealIds list of deal id to match
 * @param include true to include deal included in deal id, false to exclude
 * @returns
 */
export function filterIPositionV2Id(dealIds: string[], include: boolean) {
  return (p: IPositionV2, _index: number, _array: IPositionV2[]) =>
    include ? dealIds.includes(p.id) : !dealIds.includes(p.id);
}

import {IUserBroker} from '@algo/firebase-common';
import {Box, Grid, Paper, TableContainer, Typography} from '@mui/material';
import {useState} from 'react';
import FilterBar from '../../components/FilterBar/FilterBar';
import DropdownListOrganism from '../../components/dropdownList/DropdownListOrganism';
import useLoadIgPositionsActive from '../../hooks/useLoadIgPositionsActive';
import positionExtractTags from '../../logic/helper/position/extract/positionExtractTags';
import positionFilterAll from '../../logic/helper/position/filter/positionFilterAll';
import {IPositionRecordV2} from '../../model/IPositionRecord';
import createFilter from '../../model/filter/createFilter';
import PositionTable from './PositionTable';

export interface PositionsPageProps {
  uid: string;
  accounts: IUserBroker[];
  positionRecords: IPositionRecordV2;
}

function PositionsPage(props: PositionsPageProps) {
  const {accounts, positionRecords, uid} = props;

  // account and account anme
  const [accountName, setAccountName] = useState('');
  const [filter, setFilter] = useState(
    createFilter({
      profitLoss: 0,
      includeTags: [],
      excludeTags: [],
    }),
  );
  const account = accounts.find((a) => a.name === accountName);
  const sortedAccountNames = accounts.map((a) => a.name);
  sortedAccountNames.sort((a, b) => (a > b ? 1 : -1));

  const igData = useLoadIgPositionsActive(uid, account, positionRecords);
  const allTags = positionExtractTags(igData.positions);

  // all filtering for position
  const filteredPositions = positionFilterAll(igData.positions, filter);

  return (
    <Box sx={{padding: 3}}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <DropdownListOrganism
            selectedItem={accountName || 'Select account'}
            onClickHandle={(name) => setAccountName(name)}
            label="account of current user"
            items={sortedAccountNames}
          />
        </Grid>

        <Grid item>
          {account ? (
            <TableContainer component={Paper}>
              <FilterBar allTags={allTags} currentFilter={filter} setFilter={setFilter} />
              <PositionTable uid={uid} positions={filteredPositions} allTags={allTags} assetRecord={igData.assets} />
            </TableContainer>
          ) : (
            <Typography variant="h4">Please select an account before proceed</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default PositionsPage;

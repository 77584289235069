import dayjs from 'dayjs';

const DAY_IN_SECONDS = 86400;
const HOUR_IN_SECONDS = 3600;
const MINUTE_IN_SECONDS = 60;

export interface IDatetimeDiff {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

/**
 * Calculate datetime difference and separate them out in day, hours, min, secs
 * @param start
 * @param end
 * @returns
 */
export function calculateDatetimeDiff(start: string, end: string): IDatetimeDiff {
  const diffSeconds = dayjs(end).diff(start, 'seconds');
  const days = Math.floor(diffSeconds / DAY_IN_SECONDS);
  const hours = Math.floor((diffSeconds - days * DAY_IN_SECONDS) / HOUR_IN_SECONDS);

  const remainingSeconds = diffSeconds - days * DAY_IN_SECONDS - hours * HOUR_IN_SECONDS;
  const minutes = Math.floor(remainingSeconds / MINUTE_IN_SECONDS);
  const seconds = remainingSeconds - minutes * MINUTE_IN_SECONDS;
  return {days, hours, minutes, seconds};
}

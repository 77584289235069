import {GridColDef} from '@mui/x-data-grid';

export const openPriceColumn: GridColDef = {
  field: 'open',
  headerName: 'Open price',
  width: 100,
  align: 'center',
  headerAlign: 'center',
  editable: false,
};

import {getPathPriceCollection} from './getPathPriceCollection';

/**
 * Get collection path for price for given resolution and epic
 * @param epic
 * @param resolution
 * @returns
 */
export function getPathPriceEpicResolutionCollection(epic: string, resolution: string) {
  console.debug('getPathPriceEpicResolutionCollection');
  return `${getPathPriceCollection()}/${epic}/${resolution}`;
}

import {IndicatorConfigV3} from './IndicatorConfigV3';

/**
 * Get item from results array
 * @returns
 * @throws when no results available
 */
export function getIndicatorV3Result<Config extends IndicatorConfigV3<Output, Input>, Output = number, Input = number>(
  config: Config,
  position: number = -1,
) {
  if (config.results.length === 0) {
    throw new Error('No results available');
  }
  const result = config.results.at(position);
  if (result === undefined) {
    throw new Error(
      `Result out of range, position: ${position}, result length: ${config.results.length}, data: ${JSON.stringify({
        results: config.results,
      })}`,
    );
  }
  return result;
}

import {calculateIPositionV2Profit} from '@algo/helper';
import {IPositionV2} from '@algo/model';

/**
 * Calculate an array of cumulative return net value/ratio
 * @param positions
 * @returns
 */
export default function positionConvertCumulativeReturn(positions: IPositionV2[], isRatio?: boolean) {
  let totalRtn = 0;
  if (positions.length === 0) return [];
  return positions.reduce((prev, position) => {
    totalRtn += isRatio ? calculateIPositionV2Profit(position) / position.open : calculateIPositionV2Profit(position);
    return [...prev, totalRtn];
  }, [] as number[]);
}

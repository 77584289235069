/**
 * try to run parseFloat, and return undefined if invalid
 * @param s
 * @returns
 */
export function tryParseFloat(s: string = '') {
  try {
    if (s.length > 0) {
      const result = parseFloat(s);
      return Number.isNaN(result) ? undefined : result;
    }
  } catch (e) {
    return undefined;
  }
  return undefined;
}

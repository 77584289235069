import {IPosition, IPositionCalculate, IPositionMain, IPositionMeta} from '@algo/model';
import {createPositionCalculate} from './createPositionCalculate';
import {createPositionMeta} from './createPositionMeta';

/**
 * Combine main and meta to form position
 * @param positionMain
 * @param positionMeta
 * @returns
 */
export function createPositionFromParts(
  positionMain: IPositionMain,
  positionMeta?: IPositionMeta,
  positionCalculate?: IPositionCalculate,
): IPosition {
  return {
    ...positionMain,
    calculate: positionCalculate || createPositionCalculate(positionMain),
    meta: createPositionMeta(positionMeta),
  };
}

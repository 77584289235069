import {ZodType, object} from 'zod';
import zodPositiveDecimalNumber from '../zod/zodPositiveDecimalNumber';

export interface IgPositionCommonStopLimit {
  /** Limit distance */
  limitDistance: number | null;

  /** Limit level */
  limitLevel: number | null;

  /** Stop distance */
  stopDistance: number | null;

  /** Stop level */
  stopLevel: number | null;
}

export const zIgPositionCommonStopLimit = object({
  limitDistance: zodPositiveDecimalNumber(3).nullable(),
  limitLevel: zodPositiveDecimalNumber(3).nullable(),
  stopDistance: zodPositiveDecimalNumber(3).nullable(),
  stopLevel: zodPositiveDecimalNumber(3).nullable(),
}) satisfies ZodType<IgPositionCommonStopLimit>;

export const initIgPositionCommonStopLimit: IgPositionCommonStopLimit = zIgPositionCommonStopLimit.parse({
  limitDistance: null,
  limitLevel: null,
  stopDistance: null,
  stopLevel: null,
});

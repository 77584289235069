import {Grid, Typography} from '@mui/material';
import {MatchAlgoOptionsEnum} from './enum/MatchAlgoOptionsEnum';
import {MatchAlgoStageEnum} from './enum/MatchAlgoStageEnum';

type StageType = {monitor: MatchAlgoStageEnum; reference: MatchAlgoStageEnum};
const stages: Record<MatchAlgoOptionsEnum, StageType> = {
  [MatchAlgoOptionsEnum.ALGO_PAPER]: {
    monitor: MatchAlgoStageEnum.PAPER_OPEN,
    reference: MatchAlgoStageEnum.PAPER_CONFIG,
  },
  [MatchAlgoOptionsEnum.ALGO_PAPER_DEV]: {
    monitor: MatchAlgoStageEnum.COPY_RECORD,
    reference: MatchAlgoStageEnum.PAPER_OPEN,
  },
  [MatchAlgoOptionsEnum.ALGO_PAPER_PROD]: {
    reference: MatchAlgoStageEnum.PAPER_OPEN,
    monitor: MatchAlgoStageEnum.COPY_RECORD,
  },
  [MatchAlgoOptionsEnum.ALGO_DEV]: {monitor: MatchAlgoStageEnum.COPY_RECORD, reference: MatchAlgoStageEnum.BROKER},
  [MatchAlgoOptionsEnum.ALGO_PROD]: {monitor: MatchAlgoStageEnum.COPY_RECORD, reference: MatchAlgoStageEnum.BROKER},
};

export interface MatchAlgoDirectionProps {
  selection?: MatchAlgoOptionsEnum;
}

export default function MatchAlgoDirection(props: MatchAlgoDirectionProps) {
  const {selection} = props;

  if (!selection) return null;

  return (
    <Grid item>
      <Grid container direction="row" justifyContent="space-around" sx={{flexGrow: 1}}>
        <Grid item>
          <Typography variant="h5">Monitor: {stages[selection].monitor}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">Reference: {stages[selection].reference}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

import {IPositionV2} from '@algo/model';

/**
 * Check if position is closed or open
 * - uses close timestamp
 * - close price can be current price, but position not actually closed
 * @param position
 * @returns
 */
export function isIPositionV2Closed(position: IPositionV2) {
  // NOTE: close price can be used to hold current price so it is not checked for position close
  return position.closeTsUtc.length > 0;
}

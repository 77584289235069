import {ITradeSummary} from '@algo/model';
import {UtilNumber} from '@algo/util';

/**
 * Filter trade summary for number of positions
 * @param minPositionStr
 * @returns
 */
export function filterITradeSummaryMinPosition(minPositionStr = '') {
  return (tradeSummary: ITradeSummary) => {
    if (minPositionStr.length === 0) return true;
    const minPositionNum = UtilNumber.tryParseInt(minPositionStr);

    // invalid value means not set
    if (!minPositionNum) return true;

    const count = Object.values(tradeSummary).length;

    return count >= minPositionNum;
  };
}

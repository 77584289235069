import {IPositionV2} from '@algo/model';

interface AverageCalculate {
  total: number;
  size: number;
}

/**
 * Calculate average open price
 * @param positions
 * @returns undefined if size=0, otherwise number
 */
export default function positionCalculateAverageOpenPrice(positions: IPositionV2[]) {
  const result = positions.reduce(
    (prev, cur) => ({total: prev.total + cur.size * cur.open, size: prev.size + cur.size}),
    {total: 0, size: 0} as AverageCalculate,
  );

  return result.size === 0 ? undefined : result.total / result.size;
}

import {ZodType, nativeEnum, object, string} from 'zod';
import {IgPositionAffectedDealStatusEnum} from '../../enum/IgPositionAffectedDealStatusEnum';

export interface IgPositionAffectedDeal {
  dealId: string;
  status: IgPositionAffectedDealStatusEnum;
}

export const zIgPositionAffectedDeal = object({
  dealId: string(),
  status: nativeEnum(IgPositionAffectedDealStatusEnum),
}) satisfies ZodType<IgPositionAffectedDeal>;

export const initIgPositionAffectedDeal: IgPositionAffectedDeal = zIgPositionAffectedDeal.parse({
  dealId: 'a',
  status: IgPositionAffectedDealStatusEnum.OPENED,
});

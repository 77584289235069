import {ZodType, record} from 'zod';
import {IPositionV2} from '../__generated__/resolvers-types';
import {zIPositionV2} from '../positionv2';

export interface ITradeSummary extends Record<string, IPositionV2> {}

export const initITradeSummary: ITradeSummary = {};

export const zITradeSummary: ZodType<ITradeSummary> = record(zIPositionV2);

export const titleITradeSummary = 'tradeSummary';

import {sendIgRequest} from '../core/sendIgRequest';
import {createIgResponse} from '../create/createIgResponse';
import {createIgUserToken, IgUserToken} from '../create/createIgUserToken';

export interface IgSessionGetV1Response {
  accountId: string;
  clientId: string;
  currency: string;
  lightstreamerEndpoint: string;
  locale: string;
  timezoneOffset: number;
}

/**
 * Get session information
 * @param userToken
 * @returns
 */
export async function getIgSession(userToken: IgUserToken) {
  console.debug('getIgSession');
  const result = await sendIgRequest<IgSessionGetV1Response>(userToken, '/session', 'get', 1);

  return createIgResponse(
    result.response
      ? createIgUserToken(
          userToken.apiKey,
          userToken.isLive,
          userToken.accountToken,
          userToken.clientToken,
          result.response.data.accountId,
          result.response.data.lightstreamerEndpoint,
          userToken.expiry,
        )
      : userToken,
    result,
  );
}

import {ZodType, nativeEnum, object, string} from 'zod';
import {IgDirectionEnum} from '../../enum';
import zodPositiveDecimalNumber from '../zod/zodPositiveDecimalNumber';

export interface IgPositionCommonEpicPrice {
  /** Deal direction */
  direction: IgDirectionEnum;

  /**
   * Instrument epic identifier
   *   [Constraint: NotNull]
   *   [Constraint: Pattern(regexp="[A-Za-z0-9._]{6,30}")]
   */
  epic: string;

  /** Level */
  level: number;

  /**
   * Deal size
   *   [Constraint: NotNull]
   *   [Constraint: Check precision is not more than 12 decimal places]
   */
  size: number;
}

export const zIgPositionCommonEpicPrice = object({
  direction: nativeEnum(IgDirectionEnum),
  epic: string().regex(/[A-Za-z0-9._]{6,30}/),
  level: zodPositiveDecimalNumber(3),
  size: zodPositiveDecimalNumber(2),
}) satisfies ZodType<IgPositionCommonEpicPrice>;

export const initIgPositionCommonEpicPrice: IgPositionCommonEpicPrice = zIgPositionCommonEpicPrice.parse({
  direction: IgDirectionEnum.BUY,
  epic: 'abcdef',
  level: 1,
  size: 1,
});

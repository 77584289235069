import {DocumentReference, onSnapshot, Unsubscribe} from 'firebase/firestore';

/**
 * Subscribe to given document and return as record
 * @param doc
 * @param handleData
 * @returns
 */
export function firestoreSubscribeDocument<T extends Record<string, any>>(
  doc: DocumentReference<T>,
  handleData: (data: T | undefined) => void,
): Unsubscribe {
  return onSnapshot(
    doc,
    (querySnapshot) => {
      console.debug('firestoreSubscribeDocument.onSnapshot');

      const result = querySnapshot.data();
      handleData(result);
    },
    (e) => {
      console.log(JSON.stringify(doc.id));
      console.error('firestoreSubscribeDocument.onSnapshot.error', e);
      handleData(undefined);
    },
  );
}

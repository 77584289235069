import {SmaConfig} from './interface/SmaConfig';
import {smaCalculate} from './smaCalculate';
import {smaInit} from './smaInit';

/**
 * Calculate simple moving average
 */
export class SMA {
  config: SmaConfig;

  constructor(period?: number) {
    this.config = smaInit(period);
  }

  next(nextValue: number) {
    const result = smaCalculate(this.config, nextValue);
    this.config = result.config;
    return result.result;
  }
}

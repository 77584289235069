import {enterTube} from '@coolcolduk/util';
import {IndicatorCandle} from '../interface/IndicatorCandle';
import {IndicatorTypeEnum} from '../interface/IndicatorTypeEnum';
import {IndicatorConfig} from './IndicatorConfig';
import {IndicatorTemplateReturn} from './IndicatorTemplateReturn';

export class IndicatorTemplate<Config extends IndicatorConfig<Output, Input>, Output, Init = Config, Input = number> {
  protected config: Config;

  type = IndicatorTypeEnum.TEMPLATE;

  /**
   * can construct using either init or config
   * @param init
   */
  constructor(init: Partial<Init> | Config = {}) {
    this.config = this.mapInitToConfig(init);
  }

  /**
   * Calculate next value given candle
   * @param data
   * @returns
   */
  nextValue(data: Input): Output {
    // update price
    this.config = {
      ...this.config,
      data: enterTube(this.config.data, data, this.config.maxPriceLength),
    };

    const rtn = this.calculate();

    // add result
    this.config = {
      ...rtn.config,
      results: enterTube(this.config.results, rtn.result, this.config.maxResultLength),
    };
    return rtn.result;
  }

  /**
   * Calculate assume price has latest added to prices, result is returned from function
   */
  protected calculate(): IndicatorTemplateReturn<Config, Output, Input> {
    throw new Error('Not implemented');
  }

  /**
   * Convert init to config
   * - cast with zInit
   * - cast with zConfig
   *   - spread with init first
   *   - spread with converted init
   *   - anything else such as ema config etc
   * *note*: config has result thus will not using strict will make it identify-able
   */
  mapInitToConfig(_params: Partial<Init> | Config = {}): Config {
    throw new Error('Not implemented');
  }

  /**
   * map command string to init variable
   * @param _s
   */
  mapCmdToInit(_s: string[]): Init {
    throw new Error('Not implemented');
  }

  /**
   * map indicator candle to data
   * @param _candle
   */
  mapCandleToInput(_candle: IndicatorCandle): Input {
    throw new Error('Not implemented');
  }

  /**
   * Set config for class
   */
  setConfig(config: Config) {
    this.config = config;
  }

  /**
   * Get config for class
   * @returns
   */
  getConfig() {
    return this.config;
  }

  /**
   * Get last item from results array
   * @returns
   * @throws when no results available
   */
  getLastResult() {
    return this.getResult(-1);
  }

  /**
   * Get last item from results array
   * @returns
   * @throws when no results available
   */
  getResult(position: number = -1) {
    if (this.config.results.length === 0) throw new Error('No results available');
    return this.config.results.at(position) as Output;
  }

  /**
   * calculate output array from candle array
   * @param values
   * @returns
   */
  series(values: Input[]): Output[] {
    return values.map((v) => this.nextValue(v));
  }

  /**
   * calculate output array from candle array
   * @param values
   * @returns
   */
  candles(values: IndicatorCandle[]): Output[] {
    return values.map((v) => this.nextValue(this.mapCandleToInput(v)));
  }

  /**
   * Convert command string to config, then calculate candle array
   * @param commandString
   * @param candles
   * @returns
   */
  cmd(commandString: string, candles: IndicatorCandle[]) {
    const cmdArr = commandString.split('_');
    const [type, ...rest] = cmdArr;
    if (type !== this.type) return undefined;

    this.setConfig(this.mapInitToConfig(this.mapCmdToInit(rest)));
    return this.candles(candles);
  }
}

import {SdConfig, zSdConfig} from './interface/SdConfig';

/**
 * Initialise config
 * @param period
 * @returns
 */
export function sdInit(period = 20): SdConfig {
  return zSdConfig.parse({period, price: []});
}

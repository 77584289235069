import {random} from 'lodash';
import {ZodType, number, object} from 'zod';

/**
 * Data for a bar
 */
export interface IndicatorCandle {
  /** Highest price */
  high: number;
  /** Lowest price */
  low: number;
  /** Open price */
  open: number;
  /** Close price */
  close: number;
}

export const initIndicatorCandle: IndicatorCandle = {
  high: 0,
  low: 0,
  open: 0,
  close: 0,
};

export const zIndicatorCandle = object({
  high: number().min(0),
  low: number().min(0),
  open: number().min(0),
  close: number().min(0),
}) satisfies ZodType<IndicatorCandle, any, any>;

/**
 * Generate randome indicator candle
 * @param midPrice middle price, random between 10-100 if undefined
 * @param fluctuatePct fluctuate of price, random between 1-5 when undefined
 * @returns
 */
export function generateIndicatorCandle(price?: number, fluctuatePct?: number) {
  const currentstartPrice = price || random(10, 100, false);
  const currentFluctuate = (fluctuatePct || random(1, 5, false)) / 100;

  const high = random(currentstartPrice, currentstartPrice * (1 + currentFluctuate), false);
  const low = random(currentstartPrice * (1 - currentFluctuate), currentstartPrice, false);
  const open = random(low, high, false);
  return zIndicatorCandle.parse({high, low, open, close: currentstartPrice});
}

import {IgPriceCandle, IgPriceResolutionEnum} from '@algo/broker-ig';
import {Firestore, documentId, where} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {createFirestorePriceResolutionCollectionHelper} from '../../firestore/price/createFirestorePriceResolutionCollectionHelper';

/**
 * Get price candle for given epic+resolution in object
 * @returns object of resolution for given epic
 */
export function useListPriceEpicResolution(
  firestoreApp: Firestore,
  epic?: string,
  resolution?: IgPriceResolutionEnum,
  startDate?: string,
  endDate?: string,
  /** when not undefined, it will only reload when this changed */
  triggerReload?: boolean,
): Record<string, IgPriceCandle> {
  console.debug('useListPriceEpicResolution');
  const [data, setData] = useState<Record<string, IgPriceCandle>>({});
  const [reload, setReload] = useState<boolean | undefined>(triggerReload);

  useEffect(() => {
    const downloadData = async () => {
      if (reload !== undefined && reload === triggerReload) return undefined;
      if (!epic || !resolution) {
        setData({});
        return undefined;
      }
      setData(
        await createFirestorePriceResolutionCollectionHelper(firestoreApp, epic, resolution).loadAll([
          ...(startDate ? [where(documentId(), '>=', startDate)] : []),
          ...(endDate ? [where(documentId(), '<=', endDate)] : []),
        ]),
      );
      setReload(triggerReload);
      return undefined;
    };
    downloadData();
    // eslint-disable-next-line
  }, [epic, resolution, startDate, endDate, triggerReload]);

  return data;
}

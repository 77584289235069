import {CollectionReference, deleteDoc, doc} from 'firebase/firestore';

/**
 * Firestore helper to del doc
 * @param collection
 * @param name
 * @returns
 */
export function firestoreDelDocFromCollection<T extends Record<string, any>>(
  collection: CollectionReference<T>,
  name: string,
): Promise<void> {
  return deleteDoc(doc(collection, name));
}

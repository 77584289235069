import {Help} from '@mui/icons-material';
import {Grid, Tooltip, Typography} from '@mui/material';

export interface InfoTileBodyBlockProps {
  title: string;
  description?: string;
  value?: string;
  prefix?: String;
  suffix?: string;
}

export default function InfoTileBodyBlock(prop: InfoTileBodyBlockProps) {
  const {value, suffix, prefix, title, description} = prop;
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="subtitle2">
          {title}
          {description && (
            <Tooltip arrow title={description || false}>
              <Help viewBox="0 0 50 50" />
            </Tooltip>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">{`${prefix || ''}${value || ''}${suffix || ''}`}</Typography>
      </Grid>
    </Grid>
  );
}

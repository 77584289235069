import {IndicatorCandlePriceEnum} from '../../common/enum/IndicatorCandlePriceEnum';
import {IndicatorCandle} from '../../common/interface/IndicatorCandle';
import {mapIndicatorCandleToNumberArr} from '../../common/map/IndicatorCandle/mapIndicatorCandleToNumberArr';
import {PctMaOutput} from './interface';
import {pctMa} from './pctMa';

/**
 * Function to calculate a range of pct ma values given candles
 * @param candles
 * @param period
 * @param hloc
 * @returns
 */
export function pctMaCandle(
  candles: IndicatorCandle[],
  period?: number,
  hloc = IndicatorCandlePriceEnum.CLOSE,
): PctMaOutput[] {
  return pctMa(candles.map(mapIndicatorCandleToNumberArr(hloc)), period);
}
